// Constants
import {
  DATES_AND_TIMES,
  LOCATION,
  LANGUAGE,
  ADDITIONAL_PARTICIPATING_DISTRICTS,
  PRESIDENTIAL_REPRESENTATIVE,
  COMMENTS,
  REQUEST_PRES_REP,
} from '@domain/districts';

//Types
import { TFunction } from 'i18next';

export const getConferenceHeading: (t: TFunction, value: string) => string = (
  t: TFunction,
  value: string
) => {
  switch (value) {
    case DATES_AND_TIMES:
      return t('conference-constants.dates-and-times', 'Dates and Times');
    case LOCATION:
      return t('conference-constants.location', 'Location');
    case LANGUAGE:
      return t('conference-constants.language', 'Language');
    case ADDITIONAL_PARTICIPATING_DISTRICTS:
      return t(
        'conference-constants.additional-participating-districts',
        'Additional Participating District(s)'
      );
    case PRESIDENTIAL_REPRESENTATIVE:
      return t(
        'conference-constants.presidential-representative',
        'Presidential Representative'
      );
    case COMMENTS:
      return t('conference-constants.comments', 'Comments');
    case REQUEST_PRES_REP:
      return t(
        'conference-constants.request-presidential-representative',
        'Request President Rep'
      );
    default:
      return '';
  }
};

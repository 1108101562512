import { useEffect } from 'react';

import mapValues from 'lodash/mapValues';

import { SearchFormValues } from '@components/Formik/SearchMembersForm/types';
import { MemberFilter } from '@presenters/web/components/DistrictMembersFilters/types';

import { TERMS_YEAR_COUNT } from '@domain/districts';

import {
  getValuesFromOptions,
  isSomeFiltersApplied,
} from '@use-cases/districts';

import { useFetchDistrictMembers } from '@repositories/districts';

import {
  getFutureYearsRange,
  getRotaryYear,
  getYearsRange,
} from '@utils/datetime';

export const useFetchMembersWithFilters = (
  pageSize: number,
  currentPage: number,
  searchValues: SearchFormValues,
  filterValues: MemberFilter,
  districtId: string,
  riDistrictId?: number | null
) => {
  const [
    fetchDistrictMembers,
    { data, loading: membersLoading },
  ] = useFetchDistrictMembers();

  const getMemberTypesToFetch = (memberTypes: string[]) => {
    if (memberTypes.length) {
      return memberTypes;
    }
    return [];
  };

  // pass terms to yearRange parameter if includeDLHistory history is true.//
  const getTermsYearRange = () => {
    let termsYearRange = [];
    const currentYear = Number(getRotaryYear());
    const pastTermYears = getYearsRange(currentYear, TERMS_YEAR_COUNT.PAST);
    const futureTermYears = getFutureYearsRange(
      currentYear,
      TERMS_YEAR_COUNT.FUTURE
    );
    termsYearRange = futureTermYears.concat(pastTermYears);
    return termsYearRange;
  };

  useEffect(() => {
    if (districtId && riDistrictId) {
      const formattedFilter = mapValues(filterValues, item =>
        getValuesFromOptions(item)
      );

      const { memberTypes, ...filters } = formattedFilter;
      const memberTypesToFetch = getMemberTypesToFetch(memberTypes);

      const isFilterApplied = isSomeFiltersApplied(filterValues);

      const isRoleFilterApplied = Boolean(
        filterValues.districtRoles.length > 0
      );

      // we should include Membership part in case when:
      // - we have memberTypes (even if we have other filters, like when we have districtRoles)
      // - we have clubNames and we don't have districtRoles (if we have districtRoles, then we don't need Membership)
      // - we don't have any filters applied (first request | empty filters)
      // NOTE: if we are filtering by membershipType, we can receive some wrong data with this clubLeadershipPart
      // like member with type `Member` can be returned on filter `Honorary`
      const isIncludedDistrictMembership = Boolean(
        !isFilterApplied ||
          memberTypesToFetch ||
          (formattedFilter.clubNames.length &&
            !formattedFilter.districtRoles.length)
      );

      fetchDistrictMembers({
        variables: {
          district: `${riDistrictId}`,
          isIncludedDistrictLeadership: false,
          isIncludedDistrictMembership,
          pageSize,
          page: currentPage,
          ...searchValues,
          ...filters,
          memberTypes: memberTypesToFetch,
          yearRange: isRoleFilterApplied ? getTermsYearRange() : [],
          includeDLHistory: isRoleFilterApplied,
        },
      });
    }
  }, [
    riDistrictId,
    pageSize,
    currentPage,
    districtId,
    searchValues,
    filterValues,
  ]);

  return {
    members: data?.members,
    membersLoading,
  };
};

import { useQuery } from '@apollo/client';

import { fetchAdminAccessRightsQuery } from '@repositories/clubs';

import {
  FetchAdminAccessRightsQuery,
  FetchAdminAccessRightsQueryVariables,
} from '@typings/operations';

export const useFetchAdminAccessRights = (clubId: string) => {
  // This is needed to prevent warnings about cached data in tests
  const fetchPolicy = process.env.NODE_ENV === 'test' ? 'no-cache' : undefined;

  return useQuery<
    FetchAdminAccessRightsQuery,
    FetchAdminAccessRightsQueryVariables
  >(fetchAdminAccessRightsQuery, {
    variables: { clubId },
    fetchPolicy,
  });
};

// Libs
import { TFunction } from 'i18next';

// Utils
import { getDistrictDetailsPath } from '@utils/districts';
import { constructMenuItem } from './constructMenuItem';

// Types
import { Drupal } from '@typings/drupal';
import { DistrictType } from '@typings/operations';

export const getDistrictsSubItems = (
  t: TFunction,
  cutDistrictsArr: DistrictType[],
  districtsCount: number = 0,
  maxSubItemsCount: number = 5
): Array<Drupal.MenuItem> => {
  const cutDistrictsSubItems = cutDistrictsArr.map(({ riDistrictId, id }) =>
    constructMenuItem(
      `district-${id}`,
      getDistrictDetailsPath(id),
      t('header.main-menu.district-item-label', 'District {{riDistrictId}}', {
        riDistrictId,
      })
    )
  );

  if (districtsCount > maxSubItemsCount) {
    return [
      ...cutDistrictsSubItems,
      constructMenuItem(
        'all-my-districts',
        '/my-districts',
        t('header.main-menu.all-my-districts-label', 'All my districts')
      ),
    ];
  }

  return cutDistrictsSubItems;
};

import ExploreCardsNPM from '@rotaryintl/component-explore-cards';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { createLink } from '../../utilities/link-helpers';

const ExploreCards = ({ params: { ComponentId } = {}, fields: { Title, LinkText, Link, Cards: SelectedCards, children: AllCards } = {} }) => {
  /** Get `selectedCards` from `AllCards` array because `SelectedCards` array is missing `children` items. */
  const selectedCards = SelectedCards?.map((s) => AllCards?.find((c) => c.id === s.id));

  return (
    <ExploreCardsNPM
      id={ComponentId}
      title={<Text field={Title} />}
      link={createLink(Link, LinkText)}
      exploreCards={selectedCards?.map(({ fields: { Title: CardTitle, children: CardLinks } = {} }) => ({
        title: CardTitle?.value,
        links: CardLinks?.map(({ fields: { Link: CardLink, LinkText: CardLinkText } }) => createLink(CardLink, CardLinkText)),
      }))}
    />
  );
};

export default withSitecoreContext()(ExploreCards);

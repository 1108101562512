import { BrowserLang } from '@domain/language-negotiation';
import { IO, of } from 'fp-ts/lib/IO';
import { normalizeBrowserLangs } from './';
import { isIE } from '@utils/browser-detection';

// getBrowserLangs :: navigator -> BrowserLang[]
export const getBrowserLangs = (nav: Navigator): IO<Array<BrowserLang>> =>
  of(
    ([] as string[]).concat(
      normalizeBrowserLangs(isIE ? [nav.language] : (nav.languages as string[]))
    )
  );

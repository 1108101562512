import { useLazyQuery, useQuery } from '@apollo/client';
import { isNil } from 'lodash';

import { fetchClubSelectOptions } from '../queries';

import { Maybe } from '@typings/graphql';
import {
  ClubSelectOptionsQuery,
  ClubSelectOptionsQueryVariables,
} from '@typings/operations';

export const useFetchClubSelectOptions = (
  input: string,
  riDistrictId?: Maybe<number>,
  riClubId?: Maybe<string>
) =>
  useQuery<ClubSelectOptionsQuery, ClubSelectOptionsQueryVariables>(
    fetchClubSelectOptions,
    {
      variables: {
        clubName: input,
        riDistrictId,
        riClubId,
      },
      skip: input === '' && isNil(riDistrictId) && !riClubId,
    }
  );

export const useFetchClubSelectOptionsOnQuery = () =>
  useLazyQuery<ClubSelectOptionsQuery, ClubSelectOptionsQueryVariables>(
    fetchClubSelectOptions
  );

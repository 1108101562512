// Utils
import moment from 'moment';
import { find } from 'lodash';

// Types
import {
  Country,
  TimeZone,
  GetConferenceDetailsResult,
  PhoneType,
} from '@typings/graphql';

export const getInitialConferenceFields = (
  {
    districtId,
    venueType,
    timeZone,
    startDate,
    startTime,
    endDate,
    endTime,
    venueName,
    conferenceAddress,
    language,
    comments,
    participatingDistricts,
    onlineLocation,
    detailForJoiningRemotely,
    prAddress,
    presRepAccPhone,
    isPresRepRequested,
    prAccommodation,
  }: GetConferenceDetailsResult,
  formattedCountries: Country[],
  defaultTimeZone: string,
  timeZones?: TimeZone[],
  hasStates?: boolean,
  prHasStates?: boolean
) => {
  const conferenceCountryValue =
    find(formattedCountries, ['name', conferenceAddress?.country])?.id || '';
  const presRepCountryValue =
    find(formattedCountries, ['name', prAddress?.country])?.id || '';

  const predefinedTimeZone =
    find(timeZones, ['timeZoneName', timeZone])?.id || defaultTimeZone;

  const accomodationPhoneCountry =
    find(formattedCountries, ['id', presRepAccPhone?.countryId])?.name || '';

  return {
    districtId,
    venueType,
    dateTime: {
      timeZone: predefinedTimeZone,
      startDate: startDate ? moment(startDate).toDate() : '',
      startTime: startTime || '',
      endDate: endDate ? moment(endDate).toDate() : '',
      endTime: endTime || '',
    },
    venueName: venueName || '',
    conferenceAddress: {
      city: conferenceAddress?.city || '',
      country: conferenceCountryValue,
      lineOne: conferenceAddress?.lineOne || '',
      lineTwo: conferenceAddress?.lineTwo || '',
      lineThree: conferenceAddress?.lineThree || '',
      postalCode: conferenceAddress?.postalCode || '',
      internationalProvince: conferenceAddress?.internationalProvince || '',
      state: conferenceAddress?.state || '',
      hasStates,
    },
    language: language || '',
    comments: comments || '',
    participatingDistricts:
      participatingDistricts?.map(({ id, riDistrictId }) => ({
        id,
        value: riDistrictId,
      })) || null,
    onlineLocation: onlineLocation || '',
    detailForJoiningRemotely: detailForJoiningRemotely || '',
    prAddress: {
      city: prAddress?.city || '',
      country: presRepCountryValue || '',
      lineOne: prAddress?.lineOne || '',
      lineTwo: prAddress?.lineTwo || '',
      lineThree: prAddress?.lineThree || '',
      postalCode: prAddress?.postalCode || '',
      internationalProvince: prAddress?.internationalProvince || '',
      state: prAddress?.state || '',
      hasStates: prHasStates,
    },
    isPresRepRequested,
    accomodationPhone: {
      number: presRepAccPhone?.number || '',
      type: PhoneType.Mobile,
      countryId: presRepAccPhone?.countryId || '',
      country: accomodationPhoneCountry,
    },
    prAccommodation: prAccommodation || '',
  };
};

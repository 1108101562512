// Libs
import { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';

// Types
import { NotificationOptions, Notification } from '@domain/notifications/types';

// Constants
import { initialNotification } from '@domain/notifications/constants';

export const getFormattedNotification = (
  message: string | ReactElement,
  options?: NotificationOptions
): Notification => ({
  ...initialNotification,
  id: uuid(),
  ...options,
  message,
});

import { gql } from '@apollo/client';

export const fetchCommitteeRolesQuery = gql`
  query getCommitteeRoles($individualId: String!) {
    results: getCommitteeRoles(individualId: $individualId) {
      id
      type
      startDate
      endDate
    }
  }
`;

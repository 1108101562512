import { Drupal } from '@typings/drupal';

export const constructMenuItem = (
  id: string,
  path: string,
  label: string,
  menuItems: Array<Drupal.MenuItem> = [],
  languagePrefix = 'en'
) => ({
  id,
  path,
  label,
  children: [...menuItems],
  language_prefix: languagePrefix,
});

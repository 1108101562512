import { QueryResult, useQuery } from '@apollo/client';
import {
  FetchProfessionalExperienceQuery,
  FetchProfileQueryVariables,
} from '@typings/operations';
import { fetchProfessionalExperienceQuery } from '../queries';

export type FetchProfessionalExperienceQueryResult = Pick<
  QueryResult<FetchProfessionalExperienceQuery>,
  'data' | 'error' | 'loading'
> & {
  refetch: () => Promise<any>;
};

export const useFetchProfessionalExperience = (
  individualId: string
): FetchProfessionalExperienceQueryResult => {
  return useQuery<FetchProfessionalExperienceQuery, FetchProfileQueryVariables>(
    fetchProfessionalExperienceQuery,
    {
      variables: {
        id: individualId!,
      },
    }
  );
};

import { useQuery } from '@apollo/client';

import { IdentificationParameters } from '@domain/clubs';

import searchIndividualQuery from '@queries/searchIndividualQuery';

import {
  SearchIndividualQuery,
  SearchIndividualQueryVariables,
} from '@typings/operations';

export const useSearchIndividual = (
  filters: IdentificationParameters,
  currentPage: number,
  pageSize: number,
  searchNonMembers: Boolean
) =>
  useQuery<SearchIndividualQuery, SearchIndividualQueryVariables>(
    searchIndividualQuery,
    {
      variables: {
        firstName: filters.firstName,
        lastName: filters.lastName,
        email: filters.email,
        riIndividualId: filters.id?.toString(),
        riClubId: filters.riClubId,
        countryId: filters.region,
        clubTypes: filters.clubTypes,
        pageSize,
        page: currentPage,
        advanced: true,
        searchNonMembers: !!searchNonMembers,
      },
      skip: !(
        filters.firstName ||
        filters.lastName ||
        filters.email ||
        filters.id
      ),
    }
  );

import * as React from 'react';

import classNames from 'classnames';

import { AccountData } from '@components/Header/types';
import Icon from '@components/Icon';
import MenuLinks, { ShowChildrenProps } from '@components/MenuLinks';

import LanguageSwitcher from '../LanguageSwitcher';
import Modal from '../Modal';
import ModalControls from '../ModalControls';
import UserLinks from '../UserLinks';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

import { Drupal } from '@typings/drupal';

const ModalNavigation: React.FC<{
  siteName: string;
  showNav: boolean;
  toggleNav: () => void;
  showSearch: boolean;
  toggleSearch: () => void;
  homeMenuItem: Drupal.MenuItem;
  accountInfo: AccountData;
}> = ({
  siteName,
  showNav,
  toggleNav,
  showSearch,
  toggleSearch,
  homeMenuItem,
  accountInfo,
}) => {
  const { i18n } = useTranslation();
  const labelID = 'modal-label';

  const {
    data: mainMenu,
    error: mainMenuError,
    loading: mainMenuLoading,
  } = useMenu('menu-my-rotary-main', i18n.language);
  const {
    data: callToActionMenu,
    error: callToActionMenuError,
    loading: callToActionMenuLoading,
  } = useMenu('menu-my-rotary-call-to-action', i18n.language);
  const {
    data: globalMenu,
    error: globalMenuError,
    loading: globalMenuLoading,
  } = useMenu('menu-my-rotary-global', i18n.language);
  const {
    data: helpMenu,
    error: helpMenuError,
    loading: helpMenuLoading,
  } = useMenu('menu-my-rotary-footer-help', i18n.language);

  return (
    <Modal show={showNav} toggle={toggleNav} labelledbyID={labelID}>
      <ModalControls
        modalLabel={labelID}
        siteName={siteName}
        showNav={showNav}
        toggleNav={toggleNav}
        showSearch={showSearch}
        toggleSearch={toggleSearch}
      />
      <div
        style={{
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          maxWidth: '100%',
        }}
      >
        <div className="px-10 py-6 bg-dark-blue-600 text-white">
          <UserLinks
            toggleMenu={toggleNav}
            accountInfo={accountInfo}
            className="text-xs leading-xs uppercase font-bold"
            linkClassName="LinkDivider LinkDividerThick text-gold-500"
            buttonClassName="text-gold-500 text-inherit leading-inherit uppercase hover:underline focus:underline"
          />
        </div>
        <div className="p-10 bg-dark-blue-500 text-white">
          {!mainMenuLoading && !mainMenuError && mainMenu && (
            <MenuLinks
              toggleMenu={toggleNav}
              menuItems={[homeMenuItem, ...mainMenu]}
              className="NavModalMainLinks -mx-10"
              itemClassName="px-10 py-2"
              linkClassName="text-white"
              ShowChildrenComponent={({
                link,
                show,
                toggleChildren,
                toggleChildrenLabel,
                children,
              }: ShowChildrenProps) => (
                <>
                  <div
                    className={classNames('flex justify-between', {
                      'pb-2': show,
                    })}
                  >
                    {link}
                    <button
                      type="button"
                      onClick={toggleChildren}
                      className="w-15 -mr-5 border-l border-dotted text-baseline"
                    >
                      <span className="sr-only">{toggleChildrenLabel}</span>
                      <Icon
                        name={show ? 'ui/minus' : 'ui/plus'}
                        color="white"
                        size="12"
                        className="inline-block align-middle"
                      />
                    </button>
                  </div>
                  {show && children}
                </>
              )}
            />
          )}
          {!callToActionMenuLoading &&
            !callToActionMenuError &&
            callToActionMenu && (
              <MenuLinks
                toggleMenu={toggleNav}
                menuItems={callToActionMenu}
                className="CallToActionContainer mt-10 pt-3"
                itemClassName="CallToActionItem"
                linkClassName="CallToActionButton bg-dark-blue-400 font-bold text-bright-blue-100 text-center link-styles-off hover:underline focus:underline"
              />
            )}
        </div>
        <div className="px-10 py-12 bg-dark-blue-500 text-white border-dashed border-t border-gray-300 text-xs leading-xs">
          {!globalMenuLoading && !globalMenuError && globalMenu && (
            <MenuLinks
              toggleMenu={toggleNav}
              menuItems={globalMenu.slice(0, 1)}
              linkClassName="text-white mr-5"
              LabelComponent={({ label, id }) => {
                const icon =
                  id === 'rotary-org' ? 'ui/house' : 'ui/map-pin-outlined';
                return (
                  <>
                    <Icon
                      name={icon}
                      color="white"
                      size="14"
                      className={classNames(
                        'inline-block align-baseline mr-1',
                        // Force the club finder icon lower.
                        { 'relative top-2px': id !== 'rotary-org' }
                      )}
                    />
                    {label}
                  </>
                );
              }}
            />
          )}

          <div className="my-10">
            <LanguageSwitcher />
          </div>

          {!helpMenuLoading && !helpMenuError && helpMenu && (
            <MenuLinks
              menuItems={helpMenu}
              linkClassName="text-white"
              toggleMenu={toggleNav}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalNavigation;

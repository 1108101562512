import { useMutation } from '@apollo/client';
import {
  CreateFeedbackMutation,
  CreateFeedbackMutationVariables,
} from '@typings/operations';
import { createFeedbackQuery } from '../queries';

export const useCreateFeedback = () =>
  useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(
    createFeedbackQuery
  );

import React, { useEffect, useState } from 'react';

import Icon from '@components/Icon';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const SessionExpiredHeader: React.FC = () => {
  const { t } = useTranslation();
  const { user, oktaWidget } = useAppConfig();
  const [isVisible, setIsVisible] = useState(false);

  const handleClose = () => {
    window.localStorage.removeItem('sessionExpired');
    setIsVisible(false);
  };

  useEffect(() => {
    const isSessionExpired = window.localStorage.getItem('sessionExpired');
    if (!user?.isLoggedIn && isSessionExpired && !isVisible) {
      setIsVisible(true);
      oktaWidget.clearTokens();
    }
    if (user?.isLoggedIn && isVisible) {
      handleClose();
    }
  }, [user?.isLoggedIn, isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="flex text-small text-center text-dark-blue-600 py-2 px-2 bg-gold-600">
      <div className="mx-auto">
        {t(
          'sessions-expired-message',
          'Your session has expired, please login again'
        )}
      </div>
      <button onClick={handleClose} type="button" className="mr-2">
        <Icon name="ui/close" color="dark-blue-600" size="20" />
      </button>
    </div>
  );
};

export default SessionExpiredHeader;

//Types
import { ParticipatingDistricts } from '@typings/resolvers';

export const getParticipatingDistrictsRow = (
  districts: ParticipatingDistricts[],
  districtID: string
): string => {
  return districts
    .map(({ id, riDistrictId }) =>
      id === districtID ? `${riDistrictId}*` : `${riDistrictId}`
    )
    .join(', ');
};

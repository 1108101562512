import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const tailwind = resolveConfig(tailwindConfig);

export default tailwind;

// Example usage:
//
// import tailwind from 'src/styles/tailwind';
//
// tailwind.theme.width[4]
// => '1rem'
//
// tailwind.theme.screens.md
// => '768px'
//
// tailwind.theme.boxShadow['2xl']
// => '0 25px 50px -12px rgba(0, 0, 0, 0.25)'

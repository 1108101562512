import { TFunction } from 'i18next';

import { normalizeClubById } from '../normalizers/normalizeClubById';

import { ClubType, DistrictClub } from '@typings/operations';

export default (clubs: DistrictClub[], t: TFunction) =>
  clubs
    .filter(club => club.type !== ClubType.SatelliteClub)
    .map(item => normalizeClubById(t, item));

import { GetConferencesResults } from '@typings/graphql';

export const getConferencePage = (districtId: string): string =>
  `/district/${districtId}/conferences`;

export const getPresRepConferencePage = (): string =>
  `/district/conferences/feedback/PR`;

export const createConferencePage = (districtId: string): string =>
  `${getConferencePage(districtId)}/create`;

export const getEditConferencePath = (
  districtId: string,
  conferenceId: string
): string => `${getConferencePage(districtId)}/${conferenceId}/edit`;

export const getConferenceViewPage = (
  districtId: string,
  conferenceId: string
): string => `${getConferencePage(districtId)}/${conferenceId}/details`;

export const getFeedbackViewPage = (
  districtId: string,
  relatedRotaryYear: number,
  conferenceId: string,
  feedbackId: string
): string =>
  `${getConferencePage(
    districtId
  )}/${conferenceId}/${relatedRotaryYear}/feedback/PR/${feedbackId}`;

export const getCreateOrUpdateFeedbackPage = (
  {
    districtId,
    id: conferenceId,
    relatedRotaryYear,
  }: Partial<GetConferencesResults>,
  type: string
) =>
  `/district/${districtId}/conferences/${conferenceId}/${relatedRotaryYear}/feedback/${type}`;

export const getPresRepPageUrl = () => '/district/conferences/feedback/PR';

// Libs
import React from 'react';

import classNames from 'classnames';

// Components
import Icon from '@components/Icon';

type Props = {
  isChecked: boolean;
  toggleIsChecked: () => void;
  prompt: string;
  mobileTextLeft?: boolean;
};

const CheckBoxPrompt: React.FC<Props> = ({
  isChecked,
  toggleIsChecked,
  prompt,
  mobileTextLeft,
}) => (
  <div className="flex items-top">
    <div
      data-cy="checkbox-prompt"
      className={classNames(
        'flex-shrink-0 flex items-center justify-center',
        'mt-1 leading-6 h-5 w-5 cursor-pointer border rounded-1px',
        { 'border-bright-blue-600 bg-bright-blue-600': isChecked },
        { 'border-gray-300 bg-white': !isChecked }
      )}
      onClick={toggleIsChecked}
    >
      <Icon name="ui/check" size="16" color="white" />
    </div>
    <div
      className={classNames('ml-4 text-justify', {
        'mobile:text-left': mobileTextLeft,
      })}
    >
      {prompt}
    </div>
  </div>
);

export default CheckBoxPrompt;

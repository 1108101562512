import { useMutation } from '@apollo/client';
import {
  EditFeedbackMutation,
  EditFeedbackMutationVariables,
} from '@typings/operations';
import { updateFeedbackQuery } from '../queries';

export const useUpdateFeedback = () =>
  useMutation<EditFeedbackMutation, EditFeedbackMutationVariables>(
    updateFeedbackQuery
  );

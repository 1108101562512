import { defaultLanguage } from '../../../languages';

export const defaultConfig = {
  user: { login: '', isLoggedIn: false, individualId: '' },
  userAnalytics: undefined,
  isMaintenanceMode: false,
  language: defaultLanguage,
  delocalizedPath: '/',
  localizedPath: `/${defaultLanguage}/`,
};

import i18next from 'i18next';
import moment from 'moment';

import { getFormattedDate } from '@utils/datetime';

export const localizeDayMonth = (
  dayMonth: string,
  parseAs: string = 'D MMM',
  formatOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' }
) => {
  let { language } = i18next;
  // MRD-2426 to make the date formatted like 'DD MMM', not 'MMM DD'
  language = language === 'en' ? 'en-GB' : language;

  return moment(dayMonth, parseAs)
    .toDate()
    .toLocaleString(language, formatOptions);
};

export const localizeFullDate = (date: string, format: string = 'DD MMM') => {
  let { language } = i18next;
  language = language === 'en' ? 'en-GB' : language;

  return `${moment(getFormattedDate(date, format), format)
    .toDate()
    .toLocaleString(language, {
      day: '2-digit',
      month: 'short',
    })} ${moment(date).year()}`;
};

// Libs
import { groupBy } from 'lodash';

// Utils
import { getTermsByTermLength } from './';

// Types
import { DORoles, Role, OfficerAssignedToRole } from '@domain/districts';

export const groupDOsByRole = (
  roles: Role[],
  officers: OfficerAssignedToRole[],
  year: number,
  isViewer: boolean = false
): DORoles => {
  const officersGroupedByRole = groupBy(officers, 'role');
  return roles.map(({ id, value, detail: { TermLength } }) => ({
    id,
    role: value,
    termYears: TermLength,
    terms: getTermsByTermLength(
      officersGroupedByRole,
      TermLength,
      value,
      year,
      isViewer
    ),
  }));
};

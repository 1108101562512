import React, { useEffect, useState } from 'react';

import { pipe } from 'fp-ts/lib/function';
import { add, toNumber, uniqBy } from 'lodash/fp';

import Loading from '@components/Loading';

import DesktopHeader from './DesktopHeader';
import ModalControls from './ModalControls';
import ModalNavigation from './ModalNavigation';
import ModalSearch from './ModalSearch';
import { getHomeMenuItems } from './utils/getHomeMenuItems';

import { SPECIAL_REPRESENTATIVE } from '@domain/districts';

import {
  doesUserHaveMembership,
  getFormattedDistricts,
} from '@use-cases/districts';

import { useUserAccount } from '@repositories/auth/hooks';
import { useFetchDistrictsForLeadership } from '@repositories/districts/hooks/useFetchDistrictsForLeadership';
import { useSearchDESByIndividualId } from '@repositories/districts/hooks/useSearchDESByIndividualId';

import { getRotaryYear } from '@utils/datetime';
import { getDistrictsList } from '@utils/districts';
import { getUserClubs } from '@utils/getUserClubs';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import {
  ClubAffiliation,
  ClubLeadership,
  DistrictType,
  FetchUserAccountQuery,
} from '@typings/operations';

const Header: React.FC = () => {
  const { t } = useTranslation();
  const {
    user = {
      isLoggedIn: false,
      attributes: undefined,
      individualId: null,
    },
    isProcessingLogin,
    setProcessingLogin,
  } = useAppConfig();

  const individualIdForFetch =
    user.isLoggedIn && !isProcessingLogin && user?.individualId
      ? user.individualId
      : null;
  const { data, loading } = useUserAccount(individualIdForFetch);
  const [
    fetchSearchDES,
    { data: searchDESData, loading: searchDESLoading },
  ] = useSearchDESByIndividualId();

  const [
    fetchDistrictsForLeadership,
    { data: districtsForLeadership, loading: districtsForLeadershipLoading },
  ] = useFetchDistrictsForLeadership();

  useEffect(() => {
    if (user.individualId) {
      fetchSearchDES({
        variables: {
          individualId: user.individualId,
        },
      });
      fetchDistrictsForLeadership({
        variables: {
          role: SPECIAL_REPRESENTATIVE,
          rotaryYear: pipe(getRotaryYear(), toNumber, add(1)),
        },
      });
    }
  }, [user.individualId]);

  if (isProcessingLogin && !loading && user.individualId) {
    setProcessingLogin(false);
  }

  const [showNav, setShowNav] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  if (loading || searchDESLoading || districtsForLeadershipLoading) {
    return <Loading />;
  }
  const accountInfo = { ...user?.attributes, ...data?.getIndividual };

  const toggleNav = () => {
    if (showSearch && !showNav) {
      setShowSearch(false);
    }
    setShowNav(!showNav);
  };
  const toggleSearch = () => {
    if (showNav && !showSearch) {
      setShowNav(false);
    }
    setShowSearch(!showSearch);
  };

  // The modals and the ModalControl share the same props.
  const modalControlProps = {
    siteName: t('global.siteName', 'My Rotary'),
    showNav,
    toggleNav,
    showSearch,
    toggleSearch,
    accountInfo,
  };

  const DESInfo = searchDESData?.searchDESByIndividualId.affiliationInfo;

  const clubsAffiliation =
    ((accountInfo as FetchUserAccountQuery['getIndividual'])
      ?.clubAffiliations as ClubAffiliation[]) || [];

  const userClubsForClubsMenuItems = getUserClubs(clubsAffiliation);
  const userClubsForDistrictsMenuItems = getUserClubs(clubsAffiliation, true);

  const clubs: ClubLeadership[] =
    (userClubsForClubsMenuItems as ClubLeadership[]) || [];

  const specRepDistricts =
    districtsForLeadership?.getDistrictsForLeadership.districts || [];

  const districtsInfo: DistrictType[] = DESInfo
    ? getFormattedDistricts(DESInfo.districts)
    : getDistrictsList(userClubsForDistrictsMenuItems);

  const districts = uniqBy('id', [...districtsInfo, ...specRepDistricts]);

  const homeMenuItem = getHomeMenuItems(t, user.isLoggedIn, districts, clubs);

  const canUserSearchMembers = user.isLoggedIn
    ? doesUserHaveMembership(userClubsForClubsMenuItems)
    : true;

  const zIndex = isProcessingLogin ? '-z-1' : '';

  return (
    <header className={zIndex}>
      <ModalControls {...modalControlProps} />
      <ModalNavigation {...modalControlProps} homeMenuItem={homeMenuItem} />
      <ModalSearch
        {...modalControlProps}
        canUserSearchMembers={canUserSearchMembers}
      />
      <DesktopHeader
        accountInfo={accountInfo}
        canUserSearchMembers={canUserSearchMembers}
        homeMenuItem={homeMenuItem}
      />
    </header>
  );
};

export default Header;

export const deleteCookie = (cookieName: string) => {
  if (typeof document === 'undefined' || !getCookie(cookieName)) return;
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
};

export const getCookie = (cookieName: string): string | null => {
  if (typeof document === 'undefined') return null;
  const name = cookieName + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const setCookie = (
  name: string,
  value: string,
  options: { [key: string]: string } = {
    path: '/',
    expires: 'Sun, 1 Jan 2023 00:00:00 UTC',
  }
) => {
  if (typeof document === 'undefined') return;

  let updatedCookie = `${name}=${value}`;

  for (const optionKey in options) {
    updatedCookie = `${updatedCookie}; ${optionKey}=${options[optionKey]}`;
  }

  document.cookie = updatedCookie;
};

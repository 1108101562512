import { buildSurveyOption } from './mappers';

import { CreateConferenceFormResult } from '../types';

import { Answers } from './types';

export const DATES_AND_TIMES = 'Dates and Times';
export const LOCATION = 'Location';
export const LANGUAGE = 'Language';
export const ADDITIONAL_PARTICIPATING_DISTRICTS =
  'Additional Participating District(s)';
export const COMMITTEE_NAME_PRESIDENTIAL_REPRESENTATIVE =
  'District Conference Presidential Representative';
export const PRESIDENTIAL_REPRESENTATIVE = 'Presidential Representative';
export const COMMENTS = 'Comments';
export const REQUEST_PRES_REP = 'Request President Rep';

export const MEETING_TYPE = 'Meeting Type';
export const TIME_ZONE = 'Time Zone';
export const START_DATE = 'Start Date';
export const START_TIME = 'Start Time';
export const END_DATE = 'End Date';
export const END_TIME = 'End Time';
export const ADDRESS = 'Address';
export const URL = 'URL';
export const DETAILS_FOR_JOINING_REMOTELY = 'Details for joining remotely';
export const ADDITIONAL_DISTRICTS = 'Additional District(s)';
export const ACCOMMODATION_PHONE = 'Accommodation Phone';

export const EMPTY_CONFERENCE_ADDRESS = {
  city: '',
  country: '',
  lineOne: '',
  lineTwo: '',
  lineThree: '',
  postalCode: '',
  internationalProvince: '',
  state: '',
};

export const CREATE_CONFERENCE_INITIAL_VALUES: CreateConferenceFormResult = {
  districtId: '',
  venueType: 'Physical',
  dateTime: {
    timeZone: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  },
  venueName: '',
  conferenceAddress: EMPTY_CONFERENCE_ADDRESS,
  language: '',
  comments: '',
  participatingDistricts: null,
  onlineLocation: '',
  detailForJoiningRemotely: '',
  isPresRepRequested: false,
  prAddress: EMPTY_CONFERENCE_ADDRESS,
  accomodationPhone: {
    countryId: '',
    number: '',
  },
  prAccommodation: '',
};

export const CONFERENCE_EVALUATION_ID = '045e5b31-f867-4d64-8e35-1f4ef9282983';
export const GOVERNOR_EVALUATION_ID = 'd9ef640a-499c-4ab9-bde3-7f5518d98608';

export const RADIO_QUESTIONS = [
  'a7dd84d3-9be3-4a52-9b2e-894722ab77d5',
  'a9960c36-d615-40b9-a45f-c7cca68bfde3',
];

export const CUSTOM_QUESTION_19_ID = 'custom-question-19';
export const CUSTOM_QUESTION_20_ID = 'custom-question-20';

export const DG_NAME_OPTION = 'name';
export const DG_YEARS_SERVED_OPTION = 'DG year';

export const SURVEY_OPTIONS = {
  FORMATTED: buildSurveyOption([
    Answers.EXCELLENT,
    Answers.GOOD,
    Answers.FAIR,
    Answers.WEAK,
  ]),
  YES_NO: buildSurveyOption([Answers.YES, Answers.NO]),
  TRUE_FALSE: buildSurveyOption([Answers.TRUE, Answers.FALSE]),
  EXPECTATIONS: buildSurveyOption([
    Answers.EXCEEDED_EXPECTATIONS,
    Answers.MET_EXPECTATIONS,
    Answers.DID_NOT_MET_EXPECTATIONS,
  ]),
};

export const COMMENTS_BOX_MAX_CHARS = 300;

export const QUESTION_IDS = [
  '95b02946-0e94-4b1d-aab7-002c1e339ca3',
  '51d64e69-7270-46fd-a927-019bf1b10361',
  'b1fb13d0-79ff-4235-9264-03db1d67d75e',
  'c9285db5-8a6a-4ea5-8a20-0494c7a2dd0d',
  '0c5ca1fb-7b4c-4410-af65-05b8e8f7d56c',
  '187766e4-c7ab-4697-bc1b-09476a8df461',
  '441e1913-5c07-4715-88d2-0f99ce2c4d9a',
  '533fb6d0-9d1a-4b7b-a5cd-116f41897565',
  'ebd244ae-8fa7-4c57-8627-15c239aeb12e',
  '75c1d971-83b3-4bd7-8843-1de0009c365b',
  'd3ed45f7-dad6-4640-8824-27eada7d38d5',
  '7ca8de2d-8f2f-41c1-bfd7-2b65502af0b8',
  'd7f8b495-e635-4687-ab79-315835880e0f',
  '0156ea77-c317-4678-9189-32db976f9b75',
  'c6665155-c406-4830-b978-373b6b78a2d3',
  'c099af8b-ce99-40d7-b97b-3a190d682ef6',
  'ba93f446-1161-42d9-87aa-3ddf0a690d60',
  '35a8f22d-c501-4e97-8a77-46a151f8ef0c',
  'a5d14b08-4274-415b-89cf-4886e12f8132',
  '21423d80-19ee-4101-bea3-4a1bf4f7368f',
  'dacf9b8d-a396-4ee5-98e8-4b0f97f7b3d5',
  'c102a5ee-58cc-434b-b645-4b83bc36773a',
  'cf8ed6c0-3a43-48b7-bfd5-4c0833790bc1',
  'aec9ef52-50ce-4c66-8f60-4c8e372547c8',
  'e2536418-8b92-4671-8bed-4e4d46b85217',
  'a4d349f8-e153-4b76-a563-4f61c2e57245',
  'fdb4ea5e-f03b-4707-b124-4fc9454d05bb',
  '229e4a1f-f344-49a8-acef-50dfab378b35',
  '1206e94a-e2da-49d9-9596-523514c8eeff',
  'eecbeac4-897e-4af3-b960-52ab373ec24d',
  '6f3469f8-f63f-478c-bae8-539dc8b20d00',
  '63b1c614-7337-4979-9fda-53f549382c2d',
  '58c943f0-7f74-4cfd-9b86-54ed43c05ed5',
  '675c028a-8594-410c-bb03-552a03afa14f',
  '97b02f12-4503-4de4-a88c-563d49f8ed77',
  '8a57a474-efd7-438c-b6e1-5cc667cbf171',
  'df600d37-5521-425e-86e5-6132a209e4f9',
  'e00bc7dc-85e1-4002-97ac-619875cc0a45',
  '53a535b4-c5f3-454e-b5aa-619a20159eb6',
  '797e61b0-2b4e-4c06-9378-6202c509c90e',
  'fea33375-25fa-439f-beca-63187975bf9d',
  'c94f786b-ee07-42f0-a645-67b388d21208',
  '64d860d5-ec4d-422f-8f65-685381590f5b',
  'b26e536d-b894-4d64-a804-68d03f53e1c6',
  '7579a5a2-269f-428e-9df3-68e513e76901',
  '05b6e2c6-a2a2-45b0-b272-69119e3436ba',
  '0ea0742a-80fa-4de7-9312-6c219add40f2',
  'aee1a1b6-0ed1-4088-bc17-6dcced6c9b62',
  '9c53e5b2-4680-4fc4-bb69-7285e07b6102',
  'fdd495eb-78e7-487d-9ac5-7462922be1d9',
  'd66047bf-3e14-427f-ba0a-79fa9134404a',
  '22c0fa96-4c2b-4b41-89fc-7b67e762d357',
  '2fac4a66-1355-4581-ba57-7cbc6bba71b1',
  '18da5723-b412-4d65-b8ba-82e1cfc79ffc',
  '511c6d67-6fda-4601-a04c-883493144d79',
  'a7dd84d3-9be3-4a52-9b2e-894722ab77d5',
  'e24cb5af-84a1-4938-866c-89e06e86535a',
  '5bfde7cb-5779-4478-b8a0-8ba4ec65063a',
  '5073684e-e48d-4b43-b0cd-8c8a27dd40e6',
  'a9014173-d9de-41ae-aac1-8e511ba86264',
  '32a82904-732a-4554-a604-8e70798149c6',
  'a249f3f8-e856-45fc-83c1-908cffa77e93',
  '88857d36-4d5b-4e91-b969-9375deae8136',
  'eb593284-ba44-421b-abed-96b072038db8',
  '2e7b8322-5af8-4fc8-9103-96e9aced6029',
  '1842131b-be75-4ed5-af32-97cba7a990ad',
  '1eb648ab-c9a8-45eb-a43d-9847d905bd3c',
  'f351c562-7b0b-438a-853a-99ac13fc6f12',
  'df164b51-5809-4682-a3ad-9f47ae150537',
  '245ef428-6cbb-49e7-ae1e-9f499cb887eb',
  'ea4e36d8-4ed2-4d5f-8208-9f9b9d1412e0',
  'a576d145-c8c1-436a-a652-a2d74f15afb7',
  '96c5189e-1538-4871-875a-a4c1a755c337',
  '8b8ae811-5224-4fa2-acc9-a702a3cd8bc2',
  '42b7fd11-223f-4951-a859-a95144f5739c',
  '1f92f708-1601-4b03-b9b7-a9923e462670',
  'f8c6695e-fcb6-40f8-9219-a9f8493d7b78',
  'ee073909-464b-400b-aa13-ae4de6fba938',
  'c755605b-7046-4850-acb4-aeccef9b3ded',
  'e6ae84e8-d12a-4fb6-8bb9-b357f4f0e367',
  '2c1bdfcc-fa45-40dd-872f-b506bf397773',
  'c7e28e70-c8c9-4008-85c5-b60e388607a8',
  '9399a534-83eb-40d6-bb14-b6ca9350187e',
  'b8cf6c15-f1dd-4ded-9043-b6d812d6f86a',
  '5fdecce1-16b9-4f53-80ca-b750f9cc7abf',
  '130f22d9-fdb2-4da0-bf79-b775b6ce33de',
  '1dc684f6-f7d6-4dd0-8f6d-b8bc4df60acb',
  '56706ae1-d6f2-4565-9eb3-b97641793572',
  '97c83a82-91be-4c21-a810-bd29d1f3ca92',
  'f57b69f6-be05-4ab4-84de-be472f90a053',
  'ce5452be-cce4-4ed0-8f66-bf87da381abe',
  '2cb42cff-a4ba-4504-be2a-c46aa993f144',
  'a9960c36-d615-40b9-a45f-c7cca68bfde3',
  '97b09e3b-9a87-46a3-94fa-c938c7d2b0cd',
  'fbaf6d4b-fc89-477f-8d37-ca2b762748f8',
  '3092fdd4-5a79-426d-9ade-ca640095f579',
  '49f4464c-aa4c-4db2-9510-cada88fc52cf',
  'd5aae8d2-3ef1-4206-989f-cbd8da9d0df3',
  '29476a74-872c-4efc-b625-cd27d9fd271e',
  '81988ced-6162-43f5-8892-cefde9ebac36',
  '63ed79bc-3548-4b46-9106-d0123965679f',
  'd63b318d-6682-4fbb-93a2-d2b82e043218',
  '94e0f9a0-0f12-4484-9dd6-d7ad6770a906',
  'c42f6476-204d-49d4-92a7-dab5bc0ba14d',
  '800ad6ce-4b6e-4c74-b225-dc58c4e2bb3c',
  '0e359331-e186-40f9-8950-dd2c1232b7a1',
  '37f83fee-376e-456d-b246-dd37d5eddc1c',
  'fea0074d-eedc-40be-a5c6-dd50118fc031',
  '361b5630-24b9-4f49-a959-dfd354452eb0',
  '929cd8f4-f4bc-4f4b-b38d-e01c074f950c',
  'bb7830da-3a1b-4316-b453-e03129d18825',
  '14593d64-6c0f-45aa-99eb-e0c24b7b96ab',
  '1bdbbffe-e433-4d74-afc7-e4d19474800e',
  'b5f3be51-429a-42b9-92a4-e6cb2e5a7763',
  '576a6b4d-27be-4b1f-bada-e7c996e31bd6',
  '5aa9c8e3-093b-49d8-8e61-eb8e137f11f4',
  'cc6b483c-d2d7-4091-9fc8-ec585c67bce3',
  '5f2812f0-7afd-4374-ba19-ed4c2e12cf06',
  '5be0e61f-e75d-4e36-b395-f0f96a979472',
  '7e3ee8ee-cd26-47ac-a15c-f6e5ed14560b',
  'a00f50b2-f1d3-40c5-bfa0-f72148a6861e',
  'db71c90d-1167-4883-bf07-f9ed5cf1dcc7',
  '0578183a-a80b-4f0b-a171-fb15a17958cc',
];

export const SECTION_IDS = [
  'c114fee7-1f8a-4906-921f-963cac0ec7af',
  'a01bb60b-41c1-41d5-9aa4-3d8ae6405ede',
  '19af6014-58d4-4298-9764-ff70dffc2a5d',
  'afaec596-cf10-4515-b1ec-17f6909f062d',
  'custom-question-19',
  'custom-question-20',
  CONFERENCE_EVALUATION_ID,
];

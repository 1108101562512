/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import { transform } from 'lodash';

export const capitalizeFirstLetter = (key: String) =>
  key && key.charAt(0).toUpperCase() + key.slice(1);

export const transformUserInfo = (basicUserInfo: any) => {
  return (
    basicUserInfo &&
    transform(basicUserInfo, (result: any, value: any, key: any) => {
      const isValueAnObject = typeof value === 'object';
      if (isValueAnObject) {
        if (Array.isArray(value)) {
          if (key.toLowerCase() === 'rolesauthenticationticketdata') {
            result[capitalizeFirstLetter(key)] = value.map(val =>
              transform(val, (result: any, value: any, key: any) => {
                if (key.toLowerCase() === 'role') {
                  result[capitalizeFirstLetter(key)] = value;
                } else {
                  result[key] = value;
                }
              })
            );
          } else {
            result[capitalizeFirstLetter(key)] = value.map(val =>
              transformUserInfo(val)
            );
          }
        } else {
          result[capitalizeFirstLetter(key)] = transformUserInfo(value);
        }
      } else {
        result[capitalizeFirstLetter(key)] = value;
      }
    })
  );
};

// Libs
import { mapValues, omit, orderBy, take, takeRight } from 'lodash';

// Utils
import formatNumberWithSuffix from './formatNumberWithSuffix';
// Types
import { DIS } from '@typings/dis';
import { Contribution } from '@typings/operations';

// Should return current year and N prior years
export default (chartsData: Contribution, yearsCount: number = 5) =>
  mapValues(omit(chartsData, '__typename'), data => {
    if (!data) {
      return [];
    }

    const slice = take(
      data as Array<DIS.ContributionChartItem | DIS.ClubCreditsChartData>,
      yearsCount
    );

    return orderBy(slice, 'rotaryYear', 'asc');
  });

export const getCurrentContributionDelta = (
  data: Array<DIS.ContributionChartItem>
) => {
  const [priorYearData, currentYearData] = takeRight(data, 2);

  if (currentYearData?.priorMonthEnd) {
    const { priorMonthEnd: currentPriorMonthEnd } = currentYearData;
    const { priorMonthEnd: prevPriorMonthEnd } = priorYearData || {};

    return {
      currentValue: currentPriorMonthEnd,
      prevYearDelta: currentPriorMonthEnd - (prevPriorMonthEnd || 0),
      currentValueWithSuffix: formatNumberWithSuffix(currentPriorMonthEnd),
      prevYearDeltaWithSuffix: formatNumberWithSuffix(
        currentPriorMonthEnd - (prevPriorMonthEnd || 0),
        true
      ),
    };
  }

  return {
    currentValue: 0,
    prevYearDelta: 0,
    currentValueWithSuffix: '0',
    prevYearDeltaWithSuffix: '0',
  };
};

import { gql } from '@apollo/client';

export const createMembershipMutation = gql`
  mutation CreateMemberShip(
    $clubId: String!
    $individualId: String!
    $sponsorIds: [String!]
    $type: String!
    $admissionDate: String!
  ) {
    createMembership(
      clubId: $clubId
      individualId: $individualId
      sponsorIds: $sponsorIds
      type: $type
      admissionDate: $admissionDate
    ) {
      membership {
        id
      }
    }
  }
`;

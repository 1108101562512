import axios from 'axios';

import { log } from '@hooks/logger';

import { LogLevel } from '@typings/operations';

interface QueryParams {
  clubId?: string;
  districtId?: string;
  individualNetforumId?: string;
}

export const getBIReports = async (
  oktaId: string,
  reportTechnicalName: string,
  dataSelector: string,
  queryParams: QueryParams,
  language: string
) => {
  const graphqlQuery = `
    query GetBIReportData($input: GetBIReportInput!) {
      getBIReportData(input: $input) {
        dataSelector
        reportUrl
      }
    }
  `;

  const graphqlEndpoint = `${process.env.GATSBY_MRX_API_BUILD_URL}/graphql`;

  const variables = {
    input: {
      oktaId,
      reportTechnicalName,
      dataSelector,
      ...queryParams,
    },
  };

  const isBrowser = typeof window !== 'undefined';

  let compressedUserAppData: string | null = null;
  if (isBrowser) {
    compressedUserAppData = window.localStorage.getItem(
      'compressedUserAppData'
    );
  }

  try {
    const reportResponse = await axios.post(
      graphqlEndpoint,
      {
        query: graphqlQuery,
        variables,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Language-Context': language,
          'User-Apps-Context': !compressedUserAppData
            ? ''
            : compressedUserAppData,
        },
        withCredentials: true,
      }
    );

    const error = reportResponse?.data?.errors?.[0];

    if (error) {
      if (error.extensions?.code !== 'BiReportAccessDeniedError') {
        setTimeout(() => {
          const channel = new BroadcastChannel('bi-report');
          channel.postMessage(error.message);
        }, 5000);
      }
      throw error.message;
    }

    const {
      dataSelector,
      reportUrl,
    } = reportResponse.data.data.getBIReportData;
    if (dataSelector) {
      window.open(reportUrl, '_self');
    } else {
      window.open(reportUrl, '_blank');
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: biReport, 'GraphQL Request Failed:', ${error.message}`,
      });
    } else {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: biReport, 'Unknown error occurred:', ${error}`,
      });
    }
  }
};

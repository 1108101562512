import { gql } from '@apollo/client';

export const fetchMemberSearch = gql`
  fragment MemberSearchResult on MemberSearchResult {
    id
    name
    localizedName
    email
    location
    activeMemberships(riClubId: $club, district: $district) {
      type
      clubName
      clubType
      clubId
      terminationDate
    }
    activeLeaderships(riClubId: $club) {
      role
      clubName
      clubType
      clubId
      endDate
      startDate
    }
    thisDistrictLeadership(district: $district) {
      role
      riDistrictId
      endDate
      startDate
    }
    photoUri
  }

  query MemberSearch(
    $name: String = ""
    $email: String = ""
    $location: String = ""
    $city: String = ""
    $country: String = ""
    $stateId: String = ""
    $clubRoles: [String]
    $club: String = ""
    $memberClubType: String = ""
    $memberTypes: [String]
    $districtRoles: [String]
    $district: String = ""
    $page: Int = 1
    $pageSize: Int = 10
  ) {
    members: memberSearchAdvanced(
      name: $name
      email: $email
      location: $location
      city: $city
      country: $country
      stateId: $stateId
      district: $district
      riClubId: $club
      clubRoles: $clubRoles
      memberClubType: $memberClubType
      memberTypes: $memberTypes
      districtRoles: $districtRoles
      pageSize: $pageSize
      page: $page
    ) {
      totalCount
      results {
        ...MemberSearchResult
      }
    }
  }
`;

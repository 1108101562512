// Officer roles
export const CLUB_EXECUTIVE_SECRETARY = 'Club Executive Secretary/Director';
export const MULTI_DISTRICT_EXECUTIVE_SECRETARY =
  'Multi District Executive Secretary';

export const DISTRICT_GOVERNOR = 'District Governor (DG)';
export const DISTRICT_GOVERNOR_ELECT = 'District Governor-elect (DGE)';
export const DISTRICT_GOVERNOR_NOMINEE = 'District Governor-nominee (DGN)';
export const DISTRICT_EXECUTIVE_SECRETARY = 'District Executive Secretary';
export const SPECIAL_REPRESENTATIVE = 'Special Representative';
export const ASSISTANT_GOVERNOR = 'Assistant Governor';
export const DISTRICT_ROTARY_FOUNDATION_CHAIR =
  'District Rotary Foundation Chair (DRFC)';
export const DISTRICT_ROTARY_PUBLIC_IMAGE_CHAIR =
  'District Rotary Public Image Chair';
export const DISTRICT_LEARNING_FACILITATOR = 'District Learning Facilitator';
export const DISTRICT_FINANCE_CHAIR = 'District Finance Chair';
export const DISTRICT_MEMBERSHIP_CHAIR = 'District Membership Chair';
export const DISTRICT_PUBLIC_IMAGE_CHAIR = 'District Rotary Public Image Chair';

export const CLUB_TYPE_ROTARY = 'Rotary_Club';
export const CLUB_TYPE_ROTARACT = 'Rotaract_Club';
export const CLUB_TYPE_SATELLITE = 'Satellite_Club';
export const CLUB_TYPE_ROTARACT_SATELLITE = 'Rotaract_Satellite_Club';
export const DISTRICT_TYPE = 'District';
export const REGIONAL_GROUP_TYPE = 'Regional_Group';
export const REGIONAL_GROUP_TYPE_DIS = 'Regional Group';

export const MEMBERSHIP_TYPE_MEMBER = 'Member';
export const MEMBERSHIP_TYPE_HONORARY = 'Honorary';
export const MEMBERSHIP_TYPE_ROTARACTOR = 'Rotaractor';
export const MEMBERSHIP_TYPE_HONORARY_ROTARACTOR = 'Rotaract_Honorary';

export const CLUBS_TYPES = [
  CLUB_TYPE_ROTARY,
  CLUB_TYPE_SATELLITE,
  CLUB_TYPE_ROTARACT,
  CLUB_TYPE_ROTARACT_SATELLITE,
];
export const MEMBERSHIP_STATUSES = [
  MEMBERSHIP_TYPE_MEMBER,
  MEMBERSHIP_TYPE_HONORARY,
  MEMBERSHIP_TYPE_ROTARACTOR,
  MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
];

export const OFFICERS_ROLES = [
  DISTRICT_GOVERNOR,
  SPECIAL_REPRESENTATIVE,
  DISTRICT_GOVERNOR_ELECT,
  DISTRICT_GOVERNOR_NOMINEE,
  DISTRICT_EXECUTIVE_SECRETARY,
];

export const DISTRICT_OFFICER_GOVERNOR_ROLES = [
  DISTRICT_GOVERNOR,
  DISTRICT_GOVERNOR_ELECT,
  DISTRICT_GOVERNOR_NOMINEE,
  SPECIAL_REPRESENTATIVE,
];

export const DISTRICT_OFFICER_ROLES_TO_OMIT = [ASSISTANT_GOVERNOR];

export const ROLES_REQUIRED_BY_POLICY = [
  DISTRICT_FINANCE_CHAIR,
  DISTRICT_MEMBERSHIP_CHAIR,
  DISTRICT_ROTARY_PUBLIC_IMAGE_CHAIR,
  DISTRICT_ROTARY_FOUNDATION_CHAIR,
  DISTRICT_LEARNING_FACILITATOR,
];

export const ASSISTANT_GOVERNOR_ROLE_ID =
  '37d681cf-1e7e-4e74-8d74-4381bda5192e';

export const isDRFC = (role: string) =>
  role === DISTRICT_ROTARY_FOUNDATION_CHAIR;
export const isDES = (role?: string) => role === DISTRICT_EXECUTIVE_SECRETARY;
export const isAG = (roleId?: string) => roleId === ASSISTANT_GOVERNOR_ROLE_ID;

export const YEAR_CURRENT_KEY = 'current';
export const YEAR_START_KEY = 'july1st';
export const MAY_KEY = 'May';
export const JUL_KEY = 'Jul';
export const JUN_KEY = 'Jun';
export const JUNE_MONTH_KEY = 'June';
export const FIRST_JUL = '1 Jul';
export const TERM_ALL = 'All';

export const PRIOR_YEAR_MODAL = 'priorYearModal';
export const AVAILABLE_TERMS_MODAL = 'availableTermsModal';

export const ROLE_3YR_COMMUNICATION_CONFIRMATION = `By checking the box, I certify the following: All district governors
selected to serve during the three-year term agree with the appointment
and have provided their written approval. <b>Note: RI and Rotary Foundation
policies require that all district governors selected to serve during the
three-year term agree to the appointment. Written approval of the new
chair must remain on file at the district and be available upon request
from Rotary. Any removal for cause must have the prior written approval of
all district governors selected to serve during the remaining years of the
three-year term. District Rotary Foundation chair (DRFC) replacements
require the approval of the governors and the assigned trustee. To update
your DRFC, contact <a href="mailto:drfc@rotary.org">drfc@rotary.org</a>.</b>`;

export const PRESIDENT_REPRESENTATIVE_ROLE = 'President Representative';

export const TERMS_YEAR_COUNT = {
  PAST: 8,
  FUTURE: 4,
};

// data to be removed after DIS call
export const mockedDistrictAssignedCandidatesData = [
  {
    id: 1,
    name: 'sharath1',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 2,
    name: 'sharath2',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 3,
    name: 'sharath3',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 4,
    name: 'sharath4',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 5,
    name: 'sharath5',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 6,
    name: 'sharath6',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 7,
    name: 'sharath7',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 8,
    name: 'sharath8',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 9,
    name: 'sharath9',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 10,
    name: 'sharath10',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 11,
    name: 'sharath11',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 12,
    name: 'sharath12',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 13,
    name: 'sharath13',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 14,
    name: 'sharath14',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 15,
    name: 'sharath15',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 16,
    name: 'sharath16',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 17,
    name: 'sharath17',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 18,
    name: 'sharath18',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 19,
    name: 'sharath19',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
  {
    id: 20,
    name: 'sharath20',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'district',
  },
];

import {
  ClubContactInfoFormValues,
  GetClubContactInfoValues,
} from '@domain/clubs';

export default (
  values: GetClubContactInfoValues
): ClubContactInfoFormValues => {
  const {
    address,
    primaryWebsite,
    primaryFax,
    primaryPhone,
    primaryEmail,
    officialLanguage,
    email,
    website,
  } = values;

  return {
    address: {
      countryId: address?.countryId || '',
      city: address?.city || '',
      postalCode: address?.postalCode || '',
      lineOne: address?.lineOne || '',
      lineTwo: address?.lineTwo || '',
      lineThree: address?.lineThree || '',
      state: address?.state || '',
      stateId: address?.stateId || '',
      internationalProvince: address?.internationalProvince || '',
      hasStates: false,
      id: address?.id,
      country: address?.country || '',
    },
    primaryEmail: primaryEmail || '',
    primaryWebsite: primaryWebsite || '',
    primaryPhone: {
      number: primaryPhone?.number || '',
      countryId: primaryPhone?.countryId || '',
      extension: primaryPhone?.extension || '',
      country: primaryPhone?.country || '',
      id: primaryPhone?.id,
    },
    primaryFax: {
      number: primaryFax?.number || '',
      countryId: primaryFax?.countryId || '',
      extension: primaryFax?.extension || '',
      country: primaryFax?.country || '',
      id: primaryFax?.id,
    },
    officialLanguage: {
      languageId: officialLanguage?.id || '',
    },
    email,
    website,
  };
};

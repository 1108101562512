import { languages } from '../../languages';

/**
 * Handle language prefixes on paths.
 *
 * Adds, removes or replaces the prefix on a given path, based on the target language.
 *
 * @param path
 *   The path to process.
 * @param targetLanguage
 *   The target language.
 */
export const localizedPath = (
  path: string,
  targetLanguage: string,
  searchString: string = '',
  hash: string = ''
) => {
  const segments = path.split('/').slice(1);

  if (segments[0] in languages) {
    // replace language prefix
    segments.splice(0, 1, targetLanguage.trim() || 'en');
    return (
      ['', ...segments.filter(s => s.length)].join('/') + searchString + hash
    );
  }
  // add language prefix
  return (
    ['', targetLanguage.trim() || 'en', ...segments.filter(s => s.length)].join(
      '/'
    ) +
    searchString +
    hash
  );
};

/**
 * Remove a language prefix from the current path if present.
 */
export const delocalizedPath = (path: string = '') => {
  const segments = path.split('/');
  if (segments[1] in languages) {
    // remove the prefix
    segments.splice(1, 1);
    const newPath = segments.join('/');
    if (newPath[0] !== '/') {
      return `/${newPath}`;
    }
    return newPath;
  }
  return path;
};

export const isLocalizedPath = (path: string = ''): boolean =>
  path.split('/').some(part => languages[part]);

export const getLangFromPath = (path: string = ''): string | undefined =>
  path.split('/').find(part => languages[part]);

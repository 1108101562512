import { useEffect } from 'react';

import { ACCESS_LEVEL_TARGETS } from '@domain/districts';

import { useAppConfig } from '@hooks/appConfig';
import { useFetchAccessLevels } from '@hooks/useFetchAccessLevels';

export const useFetchAccessLevelData = (districtId: string, year?: string) => {
  const { user } = useAppConfig();

  const [
    fetchAccessLevelsQuery,
    { data: accessLevelData, loading: accessDataLoading },
  ] = useFetchAccessLevels();

  useEffect(() => {
    if (user?.isLoggedIn && districtId && year) {
      fetchAccessLevelsQuery({
        variables: {
          options: [
            {
              type: ACCESS_LEVEL_TARGETS.DISTRICT_LEADERSHIP,
              id: districtId,
              targetTermYearRange: [year, String(parseInt(year, 10) + 1)],
            },
          ],
        },
      });
    }
  }, [user?.isLoggedIn, districtId, year]);

  return {
    accessLevelData,
    accessDataLoading,
  };
};

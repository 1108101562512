import React from 'react';

import { Placeholder, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import componentFactory from 'src/sitecore/ComponentFactory';

import CookieConsent from '@components/CookieConsent';
import { titleTemplateParams } from '@components/Title/util';
import SessionExpiredHeader from '@presenters/web/components/SessionExpiredHeader/SessionExpiredHeader';

import { SECURED_PATHS } from '../constants';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const SitecoreHeader: React.FC = () => {
  const {
    user = { individualId: '', isLoggedIn: false },
    sitecoreContent,
    language,
  } = useAppConfig();
  const { t } = useTranslation();
  const { suffix } = titleTemplateParams(t);
  const sitecoreContext = sitecoreContent?.sitecore?.context;
  if (sitecoreContext) {
    sitecoreContext.route = sitecoreContent?.sitecore.route;
    sitecoreContext.itemId = sitecoreContent?.sitecore?.route?.itemId;
  }
  const pageTitle = sitecoreContent?.sitecore?.context?.pagedata?.pagetitle;
  if (pageTitle === undefined) {
    return <>{t('')}</>;
  }

  if (sitecoreContent?.error) {
    return <>{t('sitecore.header.unavailable', 'Header Unavailable')}</>;
  }

  const shouldDisplayOverlay = () => {
    const { pathname } = window.location;
    if (pathname.includes('/account/select-member-status')) return true;
    if (pathname.includes('/account/map-by-email')) return true;
    if (!user.individualId && user.isLoggedIn && pathname.includes('/contact'))
      return true;
    return false;
  };

  const titleForSitecorePath = () => {
    const pathName = window.location.pathname;
    for (let i = 0; i < SECURED_PATHS.length; i++) {
      if (pathName === `/`) return true;
      if (pathName === `/${SECURED_PATHS[i]}`) return true;
      if (pathName === `/${SECURED_PATHS[i]}/`) return true;
      if (pathName === `/${language}/${SECURED_PATHS[i]}`) return true;
      if (pathName === `/${language}/${SECURED_PATHS[i]}/`) return true;
    }

    return false;
  };

  return (
    <>
      <SessionExpiredHeader />
      <CookieConsent />
      {titleForSitecorePath() ? (
        <Helmet
          defaultTitle={`${pageTitle} | ${t(
            'metadata.title.default',
            'My Rotary'
          )}`}
        />
      ) : (
        <Helmet titleTemplate={`%s ${suffix}`} defaultTitle={suffix} />
      )}

      {sitecoreContent?.sitecore?.context &&
      sitecoreContent?.sitecore?.route ? (
        <section style={{ position: 'relative' }}>
          {shouldDisplayOverlay() && (
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 999,
              }}
            />
          )}

          <SitecoreContext
            componentFactory={componentFactory}
            context={sitecoreContext}
          >
            <Placeholder
              name="rotary-header"
              rendering={sitecoreContent.sitecore.route}
            />
          </SitecoreContext>
        </section>
      ) : (
        <div />
      )}
    </>
  );
};

export default SitecoreHeader;

import React from 'react';
import { Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import TwoColumnTextCalloutNPM from '@rotaryintl/component-two-column-text-callout';
import { createLink } from '../../utilities/link-helpers';

const TwoColumnTextCallout = ({
  params: { ComponentId } = {},
  fields: {
    Col1Title,
    Col1Description,
    Col1LinkText,
    Col1LinkUrl,
    Col1LinkIsExternal,
    Col2Title,
    Col2Description,
    Col2LinkText,
    Col2LinkUrl,
    Col2LinkIsExternal,
  } = {},
}) => (
  <TwoColumnTextCalloutNPM
    id={ComponentId}
    col1Title={<Text field={Col1Title} />}
    col1Description={<RichText field={Col1Description} />}
    col1LinkText={<Text field={Col1LinkText} />}
    col1LinkUrl={createLink(Col1LinkUrl)?.linkUrl}
    col1LinkIsExternal={Col1LinkIsExternal?.value}
    col2Title={<Text field={Col2Title} />}
    col2Description={<RichText field={Col2Description} />}
    col2LinkText={<Text field={Col2LinkText} />}
    col2LinkUrl={createLink(Col2LinkUrl)?.linkUrl}
    col2LinkIsExternal={Col2LinkIsExternal?.value}
  />
);

export default withSitecoreContext()(TwoColumnTextCallout);
import { useState } from 'react';

import { isEqual } from 'lodash';

import { Option } from '@components/Formik/MultiSelectFilter/types';
import { SearchFormValues } from '@components/Formik/SearchMembersForm/types';

import {
  CLUB_ROSTER_FILTERS,
  ClubRosterFilter,
  MembershipStatusOption,
} from '@domain/clubs';

import { getDefaultFilters } from '@use-cases/districts';

export const useClubMembersFilters = (
  membershipStatusOption: MembershipStatusOption[],
  options?: Option[]
) => {
  const INITIAL_FILTERS = {
    roles: [],
    membershipStatuses: membershipStatusOption,
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValues, setSearchValues] = useState<SearchFormValues>({
    name: '',
    email: '',
  });

  // Set predefined filters if queryParams are set or initial values
  const defaultFilters = getDefaultFilters(INITIAL_FILTERS, options);
  const [filterValues, setFilterValues] = useState<ClubRosterFilter>(
    defaultFilters as ClubRosterFilter
  );

  const handleResetFilters = (filterName?: string): void => {
    if (
      filterName &&
      filterValues[filterName as keyof ClubRosterFilter]?.length === 0
    ) {
      return;
    }
    setCurrentPage(1);
    // Check if filterName exists in Enum object as value
    // and reset state for this filterName
    if (
      filterName &&
      Object.values(CLUB_ROSTER_FILTERS).includes(
        filterName as CLUB_ROSTER_FILTERS
      )
    ) {
      setFilterValues({ ...filterValues, [filterName]: [] });
    } else {
      setFilterValues(INITIAL_FILTERS);
    }
  };

  const handleSearch = ({ name, email }: SearchFormValues) => {
    setSearchValues({ name, email });
    handleResetFilters();
  };

  const handleApplyFilters = (values: Option[], name: CLUB_ROSTER_FILTERS) => {
    const updatedFilters = {
      ...(values.length ? INITIAL_FILTERS : filterValues),
      [name]: values,
    };

    if (!isEqual(filterValues, updatedFilters)) {
      setCurrentPage(1);
      setFilterValues(updatedFilters);
      setSearchValues(searchValues);
    }
  };

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return {
    searchValues,
    filterValues,
    setFilterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
    handlePagination,
  };
};

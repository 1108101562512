import { gql } from '@apollo/client';

export const updateContactInformationMutation = gql`
  mutation updateContactInformation(
    $id: String!
    $primaryEmail: EmailInput!
    $otherEmails: [EmailInput!]
    $primaryAddress: AddressInput
    $otherAddresses: [AddressInput!]
    $primaryPhone: IndividualPhoneInput
    $otherPhones: [IndividualPhoneInput!]
    $sharingPermissionsExtended: SharingPermissionsInput
  ) {
    updateIndividual(
      input: {
        individualId: $id
        primaryEmail: $primaryEmail
        otherEmails: $otherEmails
        primaryAddress: $primaryAddress
        otherAddresses: $otherAddresses
        primaryPhone: $primaryPhone
        otherPhones: $otherPhones
        sharingPermissionsExtended: $sharingPermissionsExtended
      }
    ) {
      individualId
    }
  }
`;

import { Meeting } from '@typings/operations';

import { ClubMeetingValues } from '../types';

export default ({
  name,
  language,
  languageId,
  comment,
  weekday,
  frequency,
  time,
  type,
  locationVaries,
  locationNameOrDetail,
  address,
  detailForJoiningRemotely,
  onlineLocation,
  latitude,
  longitude,
}: Meeting): ClubMeetingValues => ({
  name: name || '',
  language: language || '',
  languageId: languageId || '',
  comment: comment || '',
  weekday: weekday || '',
  frequency: frequency || '',
  time: time || '',
  type: type || '',
  locationVaries: locationVaries || false,
  locationNameOrDetail: locationNameOrDetail || '',
  lineOne: address?.lineOne || '',
  lineTwo: address?.lineTwo || '',
  lineThree: address?.lineThree || '',
  countryId: address?.countryId || '',
  city: address?.city || '',
  stateId: address?.stateId || '',
  postalCode: address?.postalCode || '',
  internationalProvince: address?.internationalProvince || '',
  detailForJoiningRemotely: detailForJoiningRemotely || '',
  onlineLocation: onlineLocation || '',
  latitude,
  longitude,
});

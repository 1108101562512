import { gql } from '@apollo/client';

export const removeOfficerMutation = gql`
  mutation removeOfficer(
    $clubId: String!
    $leadershipId: String!
    $terminationDate: String!
  ) {
    terminateLeadership(
      clubId: $clubId
      leadershipId: $leadershipId
      terminationDate: $terminationDate
    )
  }
`;

export const earthEquatorRadiusKm = 6378.1;
export const earthEquatorCircumferenceKm = 2 * Math.PI * earthEquatorRadiusKm;
export const earthMeanRadiusKm = 6371.0;
export const earthMeanCircumferenceKm = 2 * Math.PI * earthMeanRadiusKm;
export const earthPoleRadiusKm = 6356.8;
export const earthPoleCircumferenceKm = 2 * Math.PI * earthPoleRadiusKm;
export const milesToKmRate = 1.609344;

export const DISTANCE_OPTIONS = [1, 5, 10, 25, 50];
export const DISTANCE_MI = 'mi';
export const DISTANCE_KM = 'km';

export const MEETING_TYPE_MEETING = 'Meeting';
export const MEETING_TYPE_ONLINE = 'Online';
export const MEETING_TYPE_PHYSICAL = 'Physical';
export const MEETING_TYPE_HYBRID = 'Hybrid';

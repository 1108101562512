import { gql } from '@apollo/client';

export const fetchConferenceDetailsQuery = gql`
  query fetchConference(
    $conferenceId: String!
    $isDistrictPresRep: Boolean = false
  ) {
    conference: getConference(
      conferenceId: $conferenceId
      isDistrictPresRep: $isDistrictPresRep
    ) {
      id
      districtId
      relatedRotaryYear
      venueType
      venueName
      prAddress {
        lineOne
        lineTwo
        lineThree
        country
        city
        state
        postalCode
        internationalProvince
      }
      startDate
      startTime
      timeZone
      endDate
      endTime
      language
      prAccommodation
      presidentialRepresentative {
        id
        firstName
        middleName
        lastName
      }
      participatingDistricts {
        id
        riDistrictId
      }
      onlineLocation
      locationNameOrDetail
      detailForJoiningRemotely
      conferenceAddress {
        lineOne
        lineTwo
        lineThree
        country
        city
        state
        postalCode
        internationalProvince
      }
      comments
      createdByRiDistrictId
      isPresRepRequested
      presRepAccPhone {
        number
        countryId
      }
    }
  }
`;

import React, { useContext, useEffect, useRef, useState } from 'react';
import PlacesAutocomplete, {
  PropTypes as PlacesAutocompleteProps,
} from 'react-places-autocomplete';
import MapsContext from './GoogleMapsJsApiContext';
import { Resolver, Rejecter } from '@utils/promise/types';
import { log } from '@hooks/logger';
import { LogLevel } from '@typings/operations';

type callback = () => void;
interface PlacesAutocompleteElement extends PlacesAutocomplete {
  debouncedFetchPredictions: () => void;
}

declare global {
  interface Window {
    [callbackName: string]: callback;
  }
}

const globalCallbackCheckIntervalMs = 200;
const invokeEventualGlobalCallback = function(
  interval: number,
  timeout: number,
  callbackName: string,
  args: any[]
) {
  if (window[callbackName]) {
    if (typeof window[callbackName] == 'function') {
      window[callbackName]();
    } else {
      throw new Error(
        `Tried to invoke ${callbackName}, but it's not a function.`
      );
    }
  } else {
    const remainingTimeout = timeout - interval;
    if (remainingTimeout > 0) {
      setTimeout(
        invokeEventualGlobalCallback.bind(
          null,
          interval,
          remainingTimeout,
          callbackName,
          args
        ),
        interval
      );
    } else {
      // throw new Error(`Tried to invoke ${callbackName}, but it never appeared.`)
    }
  }
};

const executeGetCurrentRef = function<T>(
  this: React.MutableRefObject<T>,
  patience: number,
  resolve: Resolver<T>,
  reject: Rejecter
): void {
  if (this.current) {
    resolve(this.current);
  } else if (patience > 0) {
    setTimeout(
      // The linter apparently can't figure out the type that results
      // when binding parameters to a function with a parameterized
      // type.
      // @ts-ignore
      executeGetCurrentRef.bind(this, patience - 1, resolve, reject),
      globalCallbackCheckIntervalMs
    );
  } else {
    reject(this);
  }
};
const promiseCurrentRef = function<T>(
  ref: React.MutableRefObject<T>
): Promise<T> {
  // As above, binding functions with parameterized types is confusing.
  // @ts-ignore
  return new Promise<T>(executeGetCurrentRef.bind(ref, 10));
};
const poke = (
  placesAutocompleteElement: PlacesAutocompleteElement
): PlacesAutocompleteElement => {
  placesAutocompleteElement.debouncedFetchPredictions();
  return placesAutocompleteElement;
};

type effectCleanup = () => void;
const wakeupEffect = (
  mapsPromise: Promise<any[]>,
  stateUpdater: (newValue: boolean) => void,
  elementRef: React.MutableRefObject<PlacesAutocompleteElement>,
  callbackName: string
): void | effectCleanup => {
  // As above, binding functions with parameterized types is confusing.
  // @ts-ignore
  const refPromiser: () => Promise<
    PlacesAutocompleteElement
  > = promiseCurrentRef.bind(null, elementRef);
  const elementPromise: Promise<PlacesAutocompleteElement> = mapsPromise
    .then(
      invokeEventualGlobalCallback.bind(
        null,
        globalCallbackCheckIntervalMs,
        10000,
        callbackName
      )
    )
    .then(stateUpdater.bind(null, true))
    .then(refPromiser);

  elementPromise.catch(error =>
    log({
      level: LogLevel.Error,
      message: `Couldn't get a ref for the PlacesAutocomplete element: ${error.toString()}`,
    })
  );

  elementPromise.then(poke);
};

const ContextIntegratedPlacesAutocomplete: React.FC<PlacesAutocompleteProps> = props => {
  const mapsPromise = useContext<Promise<any[]>>(MapsContext);
  const [shouldFetchSuggestions, fetchSuggestions] = useState<boolean>(false);
  const paElement = useRef<PlacesAutocompleteElement>(null);

  useEffect(
    // @ts-ignore
    wakeupEffect.bind(
      null,
      mapsPromise,
      fetchSuggestions,
      paElement,
      props.googleCallbackName
    ),
    [props.googleCallbackName]
  );

  return (
    <PlacesAutocomplete
      {...props}
      ref={paElement}
      debounce={0}
      shouldFetchSuggestions={shouldFetchSuggestions}
    />
  );
};

export default ContextIntegratedPlacesAutocomplete;

import { useLazyQuery, useQuery } from '@apollo/client';

import {
  searchClubOfficersQuery,
  searchDelegationOfficersQuery,
} from '@queries/searchClubOfficersQuery';

import {
  MembershipStatusFilter,
  SearchDelegationOfficersQuery,
  SearchDelegationOfficersQueryVariables,
  SearchOfficersQuery,
  SearchOfficersQueryVariables,
} from '@typings/operations';

export const useSearchClubOfficer = (
  clubId: string,
  currentPage: number,
  pageSize: number,
  name?: string,
  email?: string,
  membershipStatus?: MembershipStatusFilter
) =>
  useQuery<SearchOfficersQuery, SearchOfficersQueryVariables>(
    searchClubOfficersQuery,
    {
      variables: {
        name,
        email,
        clubId,
        pageSize,
        page: currentPage,
        membershipStatus,
      },
    }
  );

export const useSearchClubOfficerLazyQuery = () =>
  useLazyQuery<
    SearchDelegationOfficersQuery,
    SearchDelegationOfficersQueryVariables
  >(searchDelegationOfficersQuery);

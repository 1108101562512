import { gql } from '@apollo/client';

export const fetchSitecoreReportQuery = gql`
  query getSitecoreReport($reportType: SitecoreReportTypeEnum!) {
    reportData: getSitecoreReport(reportType: $reportType) {
      label
      description
      children {
        label
        path
        oktaId
        description
        dataSelector
        subReports {
          reportTechnicalName
          reportTitle
          reportId
        }
      }
    }
  }
`;

import * as React from 'react';
import Modal from '../Modal';
import ModalControls from '../ModalControls';
import SearchSubmenu from '../Search/SearchSubmenu';

const ModalSearch: React.FC<{
  siteName: string;
  showNav: boolean;
  toggleNav: () => void;
  showSearch: boolean;
  toggleSearch: () => void;
  canUserSearchMembers?: boolean;
}> = ({
  siteName,
  showNav,
  toggleNav,
  showSearch,
  toggleSearch,
  canUserSearchMembers = true,
}) => {
  const labelID = 'modal-label';

  return (
    <Modal labelledbyID={labelID} show={showSearch} toggle={toggleSearch}>
      <ModalControls
        modalLabel={labelID}
        siteName={siteName}
        showNav={showNav}
        toggleNav={toggleNav}
        showSearch={showSearch}
        toggleSearch={toggleSearch}
      />
      <SearchSubmenu
        isExpanded={showSearch}
        clickHandler={toggleSearch}
        canUserSearchMembers={canUserSearchMembers}
      />
    </Modal>
  );
};

export default ModalSearch;

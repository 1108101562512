import { gql, useLazyQuery } from '@apollo/client';

import {
  FetchAllDistrictOfficersQuery,
  FetchAllDistrictOfficersQueryVariables,
} from '@typings/operations';

const DistrictOfficerResult = gql`
  fragment DistrictOfficerResult on DistrictOfficer {
    id
    startDate
    endDate
    isDelegated
    role
    roleId
    individual {
      id
      firstName
      lastName
      middleName
      prefix
      suffix
      localizedName
      primaryEmail
      photoUri
      primaryPhone
      clubName
      clubType
      clubId
      membership {
        type
        admissionDate
        terminationDate
        districtId
      }
    }
  }
`;

export const fetchAllDistrictOfficersQuery = gql`
  query fetchAllDistrictOfficers(
    $riDistrictId: Int!
    $rotaryYear: String!
    $districtId: String!
    $districtRoles: [String!]
    $page: Int
    $pageSize: Int
    $options: GetAllDistrictOfficersOptions
  ) {
    districtOfficers: getAllDistrictOfficers(
      riDistrictId: $riDistrictId
      rotaryYear: $rotaryYear
      districtId: $districtId
      districtRoles: $districtRoles
      page: $page
      pageSize: $pageSize
      options: $options
    ) {
      totalCount
      leadership {
        ...DistrictOfficerResult
      }
    }
  }
  ${DistrictOfficerResult}
`;

export const useFetchAllDistrictOfficers = () => {
  // This is needed to prevent warnings about cached data in tests
  const fetchPolicy =
    process.env.NODE_ENV === 'test' ? 'no-cache' : 'cache-first';

  return useLazyQuery<
    FetchAllDistrictOfficersQuery,
    FetchAllDistrictOfficersQueryVariables
  >(fetchAllDistrictOfficersQuery, {
    fetchPolicy,
  });
};

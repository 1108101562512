import { useState } from 'react';

import { isEqual, toNumber } from 'lodash';
import mapValues from 'lodash/mapValues';

import { Option } from '@components/Formik/MultiSelectFilter/types';

import {
  CLUB_ROSTER_FILTERS,
  ClubRosterFilter,
  IdentificationParameters,
  SearchFormValues,
} from '@domain/clubs';

import { getDefaultFilters, getValuesFromOptions } from '@use-cases/districts';

export const useClubAddMemberFilters = (
  setFilters: (filters: IdentificationParameters) => void,
  options?: Option[]
) => {
  const INITIAL_FILTERS = {
    countriesRegions: [],
    clubs: [],
    clubTypes: [],
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  // Set predefined filters if queryParams are set or initial values
  const defaultFilters = getDefaultFilters(INITIAL_FILTERS, options);
  const [filterValues, setFilterValues] = useState<ClubRosterFilter>(
    defaultFilters as ClubRosterFilter
  );

  const handleResetFilters = (filterName?: string): void => {
    if (
      filterName &&
      filterValues[filterName as keyof ClubRosterFilter]?.length === 0
    ) {
      return;
    }
    setCurrentPage(1);
    // Check if filterName exists in Enum object as value
    // and reset state for this filterName
    if (
      filterName &&
      Object.values(CLUB_ROSTER_FILTERS).includes(
        filterName as CLUB_ROSTER_FILTERS
      )
    ) {
      setFilterValues({ ...filterValues, [filterName]: [] });
      if (filterName === CLUB_ROSTER_FILTERS.CLUBS)
        setFilters({ riClubId: [] });

      if (filterName === CLUB_ROSTER_FILTERS.COUNTRIES_REGIONS)
        setFilters({ region: [] });

      if (filterName === CLUB_ROSTER_FILTERS.CLUB_TYPES)
        setFilters({ clubTypes: [] });
    } else {
      setFilterValues(INITIAL_FILTERS);
      setFilters({ riClubId: [], region: [], clubTypes: [] });
    }
  };

  const handleSearch = ({
    firstName,
    lastName,
    email,
    memberId,
  }: SearchFormValues) => {
    handleResetFilters();
    setFilters({
      firstName,
      lastName,
      email,
      id: memberId ? toNumber(memberId) : null,
    });
  };

  const handleApplyFilters = (values: Option[], name: CLUB_ROSTER_FILTERS) => {
    const updatedFilters = {
      ...filterValues,
      [name]: values,
    };

    const mappedFilters = mapValues(updatedFilters, item =>
      getValuesFromOptions(item as Option[])
    );

    const {
      clubs: riClubId,
      countriesRegions: region,
      clubTypes,
    } = mappedFilters;

    if (!isEqual(filterValues, updatedFilters)) {
      setCurrentPage(1);
      setFilterValues(updatedFilters);
      setFilters({
        riClubId: riClubId || [],
        region: region || [],
        clubTypes: clubTypes || [],
      });
    }
  };

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return {
    filterValues,
    setFilterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
    handlePagination,
  };
};

import React from 'react';

import classNames from 'classnames';
import { FieldValidator, useField } from 'formik';

import Tooltip from '../Tooltip';

export type ElementClasses = {
  container?: string;
  content?: string;
  item?: string;
};

export interface ElementProps {
  /**
   * The form elements name.
   */
  name: string;
  /**
   * The form elements label.
   */
  label?: string;
  /**
   * A in-depth description of the form element.
   */
  description?: string;
  /**
   * Visually marks the form element as required.
   *
   * **Does not validate! Required validation has to be added to the Yup validation schema.**
   */
  required?: boolean;
  /**
   * Disable the element.
   */
  disabled?: boolean;
  /**
   * Visually hide the label.
   */
  labelHidden?: boolean;
  /**
   * Visually hide the description.
   */
  descriptionHidden?: boolean;
  /**
   * Highlight the description.
   *
   * Used for less obvious descriptions that should be read by the user.
   */
  descriptionHighlighted?: boolean;
  /**
   * Text to display in a tooltip shown when clicking the question mark.
   */
  tooltipText?: string;

  hasMargins?: boolean;

  hasLabelMargin?: boolean;

  classes?: ElementClasses;
  showError?: boolean;
  displayLabelByDefault?: boolean;
  validate?: FieldValidator;
  notice?: string;
  showErrorMessage?: boolean;
}

/**
 * Base component for form elements, handling label, description and error
 * display.
 */
const Element: React.FC<ElementProps> = props => {
  const {
    labelHidden,
    hasLabelMargin = true,
    name,
    description,
    disabled,
    label,
    tooltipText,
    descriptionHidden,
    descriptionHighlighted,
    required,
    children,
    hasMargins = true,
    classes,
    showError = true,
    displayLabelByDefault = true,
    validate,
    notice,
    showErrorMessage,
  } = props;

  const { container = '', content = 'relative', item = '' } = classes || {};

  const [, meta] = useField({ name, validate });

  return (
    <div
      className={classNames('form-element', container, {
        'mb-4': hasMargins,
      })}
      aria-describedby={description && `${name}-description`}
    >
      {displayLabelByDefault && (
        <div
          className={classNames('flex items-center', item, {
            'mb-3': !labelHidden && hasLabelMargin,
          })}
        >
          <label
            htmlFor={name}
            className={classNames('text-small font-bold block', {
              'sr-only': labelHidden,
              'text-gray-600': !disabled,
              'inline-block': tooltipText,
              block: !tooltipText,
            })}
          >
            <span dangerouslySetInnerHTML={{ __html: label || '' }} />
            {required && label ? <span aria-hidden="true">*</span> : null}
          </label>
          {tooltipText && <Tooltip content={tooltipText} />}
        </div>
      )}
      {notice && <div className="text-xs leading-md mb-2">{notice}</div>}
      <div className={content}>{children}</div>

      {description && (
        <p
          id={`${name}-description`}
          className={classNames('text-xs mt-2 mb-0', {
            'sr-only': descriptionHidden,
            'text-gray-300': disabled,
            'text-gray-500': !disabled,
            'text-bright-blue-500': descriptionHighlighted,
          })}
        >
          {description}
        </p>
      )}

      {((showError && meta.touched && meta.error) || showErrorMessage) && (
        <p className="form-error mb-0 mt-2 text-xs text-red-600">
          {meta.error}
        </p>
      )}
    </div>
  );
};

export default Element;

import { useQuery } from '@apollo/client';

import { fetchClubsByNameQuery } from '@repositories/clubs';

import { ClubTypeFilterValue } from '@typings/graphql';
import {
  IdentifyMemberAutocompleteClubInputQuery,
  IdentifyMemberAutocompleteClubInputQueryVariables,
} from '@typings/operations';

export const useFetchClubsByName = (
  clubName: string,
  clubTypes: ClubTypeFilterValue[] | null
) =>
  useQuery<
    IdentifyMemberAutocompleteClubInputQuery,
    IdentifyMemberAutocompleteClubInputQueryVariables
  >(fetchClubsByNameQuery, {
    variables: {
      clubName,
      clubTypes,
    },
  });

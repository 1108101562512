import { useEffect } from 'react';
import { navigate } from '@reach/router';
import { languages, defaultLanguage } from '../../../languages';
import { AppConfig } from '@domain/appConfig';
import i18n from '../../../i18n';

declare global {
  interface Window {
    __gatsby_language: string;
    // This flag indicates if the language of the user completed the entire
    // process of negotiation. Even if the user comes with a languge prefix in
    // the url, the language can still change according to its profile settings
    // for example.
    __gatsby_language_negotiated: boolean;
    __gatsby_user: any;
    rotaryDDO: any;
    _satellite: any;
    __destinationRedirectProcessed: boolean;
  }
}

export const useNavigateLanguage = (
  { localizedPath, language: negotiatedLang }: AppConfig,
  pageContextLanguage: string
) => {
  pageContextLanguage = pageContextLanguage || defaultLanguage;

  useEffect(() => {
    if (localizedPath && negotiatedLang !== pageContextLanguage) {
      navigate(localizedPath);
    }
    window.__gatsby_language_negotiated = true;
  }, [localizedPath]);

  useEffect(() => {
    i18n.changeLanguage(pageContextLanguage!);
    if (typeof window !== 'undefined') {
      window.__gatsby_language = pageContextLanguage!;

      // If we have the default data layer loaded.
      if (typeof window.rotaryDDO !== 'undefined') {
        // Write label version of language for analytics.
        window.rotaryDDO.pageData.language = languages[pageContextLanguage!];
      }
    }
  }, [pageContextLanguage]);
};

// Libs
import moment, { Moment } from 'moment';

// Hooks
import {
  useAssignDistrictLeadership,
  useDeleteDistrictLeadership,
  useUpdateDistrictLeadership,
  useReplaceAgClubAffiliations,
} from '@repositories/districts';

// Types
import { Maybe } from 'graphql/jsutils/Maybe';

type Props = {
  districtId: string;
  riDistrictId?: number | null;
};

export const useManageDistrictLeadership = ({
  districtId,
  riDistrictId,
}: Props) => {
  const [
    assignDistrictLeadership,
    { loading: assignDistrictLeadershipLoading },
  ] = useAssignDistrictLeadership();
  const [
    updateDistrictLeadership,
    { loading: updateDistrictLeadershipLoading },
  ] = useUpdateDistrictLeadership();
  const [
    deleteDistrictLeadership,
    { loading: deleteDistrictLeadershipLoading },
  ] = useDeleteDistrictLeadership();

  const [
    replaceAgClubAffiliations,
    { loading: replaceAgClubAffiliationsLoading },
  ] = useReplaceAgClubAffiliations();

  const isLoading =
    assignDistrictLeadershipLoading ||
    updateDistrictLeadershipLoading ||
    deleteDistrictLeadershipLoading ||
    replaceAgClubAffiliationsLoading;

  const dateFormat = 'YYYY-MM-DD';

  const updateDLForCurrentRY = async (
    leadershipId: string,
    dateToRemove: Date | Moment,
    isExtraData?: Maybe<boolean>
  ) =>
    updateDistrictLeadership({
      variables: {
        districtId,
        leadershipId,
        terminationDate: moment(dateToRemove).format(dateFormat),
        isExtraData,
      },
    });

  const updateDLForFutureRY = async (leadershipId: string) =>
    deleteDistrictLeadership({
      variables: {
        districtId,
        leadershipId,
      },
    });

  const replaceClubAffiliationsForFutureRY = async (
    individualId: string,
    newIndividualId: string,
    startDate: string
  ) =>
    replaceAgClubAffiliations({
      variables: {
        districtId,
        individualId,
        newIndividualId,
        startDate,
      },
    });

  const assignDL = async (
    individualId: string,
    roleId: string,
    assignDate: Date | string | Moment,
    email: string | null
  ) =>
    await assignDistrictLeadership({
      variables: {
        individualId,
        districtId,
        roleId,
        startDate: moment(assignDate).format(dateFormat),
        email,
        riDistrictId: String(riDistrictId),
      },
    });

  return {
    assignDL,
    updateDLForFutureRY,
    updateDLForCurrentRY,
    replaceClubAffiliationsForFutureRY,
    isLoading,
  };
};

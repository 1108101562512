// Components
import { ClubOfficer, ClubOfficerPosition } from '@components/OfficerResult';

// Constants
import { CLUB_EXECUTIVE_DIRECTOR_ASSISTANT } from '@domain/clubs';

// Utils
import {
  calculateTermCoverage,
  getCurrentTermStartYear,
  mandatoryRoles,
} from '@utils/officers';

// Types
import {
  ClubLeadershipItem,
  GetClubOfficersQuery,
  Role,
} from '@typings/operations';

export const mapClubOfficersDataToClubRole = (
  clubOfficers: ClubLeadershipItem[],
  clubRoles: Role[],
  term: number,
  checkForPastTerm: boolean = false
) =>
  (clubRoles || []).map(({ id, name }) => {
    const filteredClubOfficers =
      clubOfficers.filter(leader => leader.role.id === id) || [];

    const officerRole: ClubOfficerPosition = {
      id,
      name,
      mandatory: mandatoryRoles.includes(name),
      officers: calculateTermCoverage(
        `${term}-07-01`,
        `${term + 1}-06-30`,
        filteredClubOfficers
      ) as ClubOfficer[],
    };

    if (checkForPastTerm && term < getCurrentTermStartYear()) {
      officerRole.officers = officerRole.officers.filter(
        officer => officer.individualName
      );
    }

    return officerRole;
  });

export const getClubOffisersRoles = (
  clubOfficersData: GetClubOfficersQuery,
  termStartYear: number
) => {
  const { roles: clubRoles, club } = clubOfficersData;
  const { leaders: clubOfficers } = club;

  const filteredClubOfficersRolesData = (clubRoles || []).filter(
    role => role.name !== CLUB_EXECUTIVE_DIRECTOR_ASSISTANT
  );

  return mapClubOfficersDataToClubRole(
    clubOfficers || [],
    filteredClubOfficersRolesData,
    termStartYear,
    true
  );
};

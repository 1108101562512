import { navigate as gatsbyNavigate } from 'gatsby';
import queryString from 'query-string';

import i18n from '../../i18n';
import { defaultLanguage } from '../../languages';
import { isLocalizedPath } from './localized-path';
import { isUrlAbsolute } from './url';

/**
 * Override of the standard Gatsby `navigate` function.
 *
 * Adds support for multilingual links by automatically adding the current
 * language as a prefix.
 *
 * To be used identically to Gatsby `navigate`.
 */
export const localizedNavigate = (to: string, options = {}) => {
  if (typeof window === 'undefined') {
    return;
  }

  gatsbyNavigate(addLocalisationToPath(to), options);
};

export const addLocalisationToPath = (path: string) => {
  if (typeof window === 'undefined' || isLocalizedPath(path)) {
    return path;
  }

  const language = window.__gatsby_language || i18n.language || defaultLanguage;

  const languagePrefix = `${language}`;

  return (
    `/${languagePrefix}${path}`
      // Avoid multiple slashes in the beginning of the path.
      .replace(/^\/+/, '/')
  );
};

/**
 * Helpers to check if language is available.
 */
export const isLanguageAvailable = (): boolean => {
  return typeof window !== 'undefined' && !!window.__gatsby_language;
};

/**
 * Returns the destination from the local storage (and, optionally, removes it)
 * or a default path.
 * @param path
 *  The default path to be returned if there's no destination in the local
 * storage.
 * @param localise
 *  Flag if we should also localise the returned path.
 * @param removeDestinationFromStorage
 *  Flag if the destination should be removed from the local storage.
 */
export const destinationOrPath = (
  path: string,
  localise: boolean = true,
  removeDestinationFromStorage: boolean = true
) => {
  let returnedPath = path;
  if (typeof window !== 'undefined') {
    const destination = window.localStorage.getItem('destination');
    if (destination) {
      if (removeDestinationFromStorage) {
        window.localStorage.removeItem('destination');
      }
      returnedPath = destination;
    }

    // Overwrite the destination with the value from the browser location, if
    // any.
    const queryParams = queryString.parse(window.location.search);
    if (
      queryParams.destination &&
      typeof queryParams.destination === 'string'
    ) {
      returnedPath = queryParams.destination;
    }
  }

  return localise && !isUrlAbsolute(returnedPath)
    ? addLocalisationToPath(returnedPath)
    : returnedPath;
};

import * as React from 'react';
import interpolate from 'interpolate';
import sanitizeHtml from 'sanitize-html';
import i18n, { TFunction } from 'i18next';
import { renderToString } from 'react-dom/server';

import { initReactI18next } from '@external/react-i18next';
import { TransHtml } from '@utils/trans-html';
import { mapValues } from '@typings/util';

import { resources } from './plugins/i18n-sync/locales/resources';
import { languages as availableLanguages, defaultLanguage } from './languages';

type TArgs = (string | Record<string, string>)[];

const languages = Object.keys(availableLanguages);

i18n.use(initReactI18next).init(
  {
    // Translatable strings for this project. The translations are maintained in dev.json
    // @TODO use i18next-scanner to scan files to create translation keys automatically, based on the t function.
    resources: resources,
    lng: defaultLanguage,
    whitelist: languages,
    saveMissing: false,
    defaultNS: 'translation',
    // Translations are stored as objects, so return
    // the 'translation' index when the object is returned from resources file.
    returnedObjectHandler: function(key, value, options) {
      // @ts-ignore
      let translation = value['translation'];
      return translation !== undefined
        ? i18n.services.interpolator.interpolate(
            translation,
            options,
            options.lng,
            options
          )
        : key;
    },
    fallbackLng: 'en',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for React - escaped by default.
    },
  },
  () => {
    i18n.originalT = i18n.t;

    // Override t function to allow for HTML rendering (as React escapes HTML),
    i18n.t = (key: string, options: any) => {
      if (key.includes('*')) {
        if (options[options.context]) {
          key = key.replace('*', options[options.context].key);
          const interpolationObject = [];
          interpolationObject[options.context] =
            options[options.context].default;
          options.defaultValue = interpolate(
            options.defaultValue,
            interpolationObject
          );
          // @TODO: interpolation during build.
        }
      }

      // Check if we should just show translation keys instead of the actual translated string.
      // This functionality can be triggered by prepending the path with /dev/ e.g. /dev/member-search/
      if (i18n.language === 'dev') {
        return key;
      }
      // If we have HTML in our default value, parse the translation string as HTML and render correctly.
      if (/<\/?[a-z][\s\S]*>/g.test(options.defaultValue)) {
        return <TransHtml tkey={key} options={options} />;
      }

      // Otherwise use the standard translation function.
      return i18n.originalT(key, options);
    };

    // Passthrough function that is intended to be used for
    // the translations to be synchronized during build time
    i18n.syncT = (...args) => args;
  }
);

/**
 * If the given string contains HTML tags, they will be filtered according to
 * rules from /src/utils/trans-html.tsx. But any HTML will be escaped in the
 * placeholders.
 */
export const backendT: TFunction = (...args: any) => {
  if (typeof args[2] === 'object') {
    args[2] = mapValues(args[2], (value: string) =>
      sanitizeHtml(value, {
        allowedTags: [],
        disallowedTagsMode: 'escape',
      })
    );
  }
  // @ts-ignore
  const result = i18n.getFixedT()(...args);
  return typeof result === 'object' ? renderToString(result) : result;
};

export const emailT = (
  lang: string,
  subjectKeys: TArgs,
  bodyKeys: TArgs,
  options: Record<string, boolean> = { shouldAddLineBreaks: true }
) => {
  const subject = i18n.getFixedT(lang)(...(subjectKeys as [string, string]));

  const [key, template, vars] = bodyKeys;

  const formattedVars = mapValues(
    vars as Record<string, string>,
    (value: string) =>
      sanitizeHtml(value, {
        allowedTags: [],
        disallowedTagsMode: 'escape',
      })
  );

  const formattedBodyKeys = [key, template, formattedVars];

  const body = i18n.getFixedT(lang)(
    ...(formattedBodyKeys as [string, string, Record<string, string>])
  );
  const translatedBody = typeof body === 'object' ? renderToString(body) : body;
  const formattedBody = options.shouldAddLineBreaks
    ? translatedBody.replace(/\n/g, '\n<br>')
    : translatedBody;

  return { subject, body: formattedBody };
};

export default i18n;

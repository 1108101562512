// Types
import {
  CommitteeDistrictLeadership,
  PartTimeStartDate,
  RoleOption,
  TermToAssign,
} from '@domain/districts';
import { TFunction } from 'i18next';

// Utils
import moment from 'moment';
import { getRotaryYear } from '@utils/datetime';
import { getFormattedStartDate } from '@use-cases/districts';

export const extractTermFromLeadership = (
  leadership?: CommitteeDistrictLeadership
) => ({
  endDate: leadership?.endDate || '',
  startDate: leadership?.startDate || '',
});

export const isCurrentTerm = (term: TermToAssign | null) =>
  term?.endDate === getRotaryYear();

export const getStartDateToAssign = (
  term: TermToAssign,
  termToReassign: TermToAssign,
  isReassign: boolean,
  partTimeStartDate: PartTimeStartDate
) => {
  if (isReassign) {
    if (partTimeStartDate.value && isCurrentTerm(termToReassign))
      return moment(partTimeStartDate.value).format('YYYY-MM-DD');

    return getFormattedStartDate(termToReassign.startDate);
  } else {
    if (partTimeStartDate.value) {
      return moment(partTimeStartDate.value).format('YYYY-MM-DD');
    }

    // if nextYear manager -> set start date as start nextRY
    // if current manager -> set start date as today
    return isCurrentTerm(term)
      ? moment().format('YYYY-MM-DD')
      : getFormattedStartDate(term.startDate);
  }
};

export const buildRoleName = (t: TFunction, role: RoleOption | null) => {
  if (role?.name) {
    const termLabel =
      role?.termYears === 3
        ? ` (${t(
            'district-officers.year-term-capitalized',
            '{{termYears}}-Year-Term',
            { termYears: 3 }
          )})`
        : '';
    return `${role.name}${termLabel}`;
  }
};

export const extractRoleInfo = (
  t: TFunction,
  role: RoleOption | null,
  selectedLeadership?: CommitteeDistrictLeadership
) => ({
  role: buildRoleName(t, role) || '',
  term: extractTermFromLeadership(selectedLeadership),
});

// Utils
import { omitBy } from 'lodash';

import { getDateOfBirth } from '@components/Formik/DateOfBirth/utils';

// Types
import { MemberDetailsFormValues } from '../types';

import { EmailType, Gender, PhoneType } from '@typings/operations';

export default ({
  suffix,
  dayOfBirth,
  monthOfBirth,
  yearOfBirth,
  email,
  gender,
  primaryLanguage,
  primaryAddress,
  primaryPhone,
  ...values
}: MemberDetailsFormValues) => {
  // should delete this as this is not in the scheme
  // see StateSelect for the reference
  // eslint-disable-next-line no-param-reassign
  delete primaryAddress.hasStates;

  return {
    ...values,
    suffix: suffix || '',
    ...getDateOfBirth(dayOfBirth, monthOfBirth, yearOfBirth),
    primaryLanguage,
    primaryEmail: { address: email || '', type: EmailType.Personal },
    primaryAddress: {
      // Exclude empty values before sending data to BE
      ...omitBy(primaryAddress, item => !item),
      countryId: primaryAddress?.countryId || '',
      city: primaryAddress?.city || '',
      postalCode: primaryAddress?.postalCode || '',
      lineOne: primaryAddress?.lineOne || '',
    },
    ...(primaryPhone?.number &&
      primaryPhone?.countryId && {
        primaryPhone: {
          number: primaryPhone.number,
          type: PhoneType.Mobile,
          countryId: primaryPhone.countryId,
        },
      }),
    gender: gender || Gender.NotGiven,
  };
};

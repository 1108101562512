import { useMutation } from '@apollo/client';
import { UpdateBackgroundMutation } from '@typings/operations';
import { updateBackgroundMutation } from '../queries';

export const useUpdateBackground = (individualId: string) => {
  return useMutation<UpdateBackgroundMutation>(updateBackgroundMutation, {
    variables: {
      id: individualId!,
    },
  });
};

import { TFunction } from 'i18next';

import { MembershipStatusOption } from '@domain/clubs';

import { MembershipStatusFilter } from '@typings/graphql';

export default (
  t: TFunction,
  canAddMember: boolean,
  isRotaractClub: boolean = false
): MembershipStatusOption[] => {
  const defaultOptions: MembershipStatusOption[] = [
    {
      id: '1',
      value: isRotaractClub
        ? MembershipStatusFilter.Rotaractor
        : MembershipStatusFilter.Active,
      label: t(
        'club-roster.members.search-membership-status-option-active',
        'Active'
      ),
    },
    {
      id: '2',
      value: isRotaractClub
        ? MembershipStatusFilter.HonoraryRotaractor
        : MembershipStatusFilter.Honorary,
      label: t(
        'club-roster.members.search-membership-status-option-honorary',
        'Honorary'
      ),
    },
    {
      id: '3',
      value: MembershipStatusFilter.Terminated,
      label: t(
        'club-roster.members.search-membership-status-option-terminated',
        'Terminated'
      ),
    },
    {
      id: '4',
      value: MembershipStatusFilter.Deceased,
      label: t(
        'club-roster.members.search-membership-status-option-deceased',
        'Deceased'
      ),
    },
  ];

  if (canAddMember) return defaultOptions;

  return defaultOptions.filter(
    (option: MembershipStatusOption) =>
      option.value !== MembershipStatusFilter.Deceased &&
      option.value !== MembershipStatusFilter.Terminated
  );
};

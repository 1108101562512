import { Drupal } from '@typings/drupal';

export const getMenuItemsNewArr = (
  arr: Array<Drupal.MenuItem>
): Array<Drupal.MenuItem> =>
  arr.length
    ? arr.map(item => ({
        ...item,
        children:
          item.children && item.children.length
            ? item.children.map(child => ({ ...child }))
            : [],
      }))
    : [];

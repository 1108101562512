import { useQuery } from '@apollo/client';

import { MemberSearchFormValues } from '@domain/clubs';

import { fetchMemberSearch } from '@repositories/clubs';

import {
  MemberSearchQuery,
  MemberSearchQueryVariables,
} from '@typings/operations';

export const useMemberSearch = (params: MemberSearchFormValues, page: number) =>
  useQuery<MemberSearchQuery, MemberSearchQueryVariables>(fetchMemberSearch, {
    variables: {
      page,
      ...params,
    },
    skip: Object.values(params).filter(Boolean).length === 0,
  });

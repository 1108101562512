import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@external/react-i18next';
import Icon from '@components/Icon';

// This is a separate component because the modal needs the toggle buttons
// at the top of the modal and the regular page needs these buttons to open
// the modal. Unfortunately, this means the controls are actually rendered
// twice on the page. Awkward! But only one set of controls is accessible at
// any time since the modal prevents accessing the rest of the page.
const ModalControls: React.FC<{
  siteName: string;
  modalLabel?: string;
  showNav: boolean;
  toggleNav: () => void;
  showSearch: boolean;
  toggleSearch: () => void;
}> = ({
  siteName,
  modalLabel,
  showNav,
  toggleNav,
  showSearch,
  toggleSearch,
}) => {
  const { t } = useTranslation();
  const headingClassNames = 'm-0 text-2md leading-2md font-bold order-2';
  const withOpenModal = showNav || showSearch;

  return (
    <>
      {/* On desktop, the ModalControls are hidden when the modal is not open,
          so we need to accessibly display the app's name. */}
      {!withOpenModal && <h2 className="hidden desktop:sr-only">{siteName}</h2>}
      <div
        className={classNames('pt-15', {
          'desktop:hidden': !withOpenModal,
        })}
      />
      <div
        className={classNames(
          'ModalControls flex justify-between items-center fixed top-0 w-full bg-dark-blue-500 text-white',
          {
            'desktop:hidden': !withOpenModal,
            ModalControlsInvisible: !modalLabel && withOpenModal,
          }
        )}
      >
        <h2 id={modalLabel} className={headingClassNames}>
          {siteName}
        </h2>
        <button className="w-15 h-15 order-1" onClick={toggleNav}>
          <span className="sr-only">
            {showNav
              ? t('header.navigation.hide-navigation', 'Hide navigation menu')
              : t('header.navigation.show-navigation', 'Show navigation menu')}
          </span>
          <Icon
            name={showNav ? 'ui/close' : 'ui/hamburger'}
            color="white"
            size="24"
            className="inline-block"
          />
        </button>
        <button className="w-15 h-15 order-3" onClick={toggleSearch}>
          <span className="sr-only">
            {showNav
              ? t('header.navigation.hide-search', 'Hide search form')
              : t('header.navigation.show-search', 'Show search form')}
          </span>
          <Icon
            name={showSearch ? 'ui/close' : 'ui/search'}
            color="white"
            size={showSearch ? '24' : '18'}
            className="inline-block"
          />
        </button>
      </div>
    </>
  );
};

export default ModalControls;

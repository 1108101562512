import { useEffect, useState } from 'react';

import tailwind from '@styles/tailwind.js';

import { useWindowSize } from '@hooks/useWindowSize';

export const useMobileLayout = () => {
  const { width } = useWindowSize();
  const [isMobileLayout, setIsMobileLayout] = useState(false);
  const [isTabletLayout, setIsTabletLayout] = useState(false);

  /** Dynamically calculate and update window width */
  useEffect(() => {
    setIsMobileLayout(width < parseInt(tailwind.theme.screens.tablet, 10));
    setIsTabletLayout(
      width >= parseInt(tailwind.theme.screens.tablet, 10) &&
        width < parseInt(tailwind.theme.screens.desktop, 10)
    );
  }, [width]);

  return { isMobileLayout, isTabletLayout };
};

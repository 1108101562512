import { useMutation } from '@apollo/client';
import {
  UpdateResumeMutation,
  UpdateResumeMutationVariables,
} from '@typings/operations';
import { updateResumeMutation } from '../queries';

export const useUpdateResume = () =>
  useMutation<UpdateResumeMutation, UpdateResumeMutationVariables>(
    updateResumeMutation,
    { refetchQueries: ['fetchResume'] }
  );

import React from 'react';

import Icon from '@components/Icon';

import { useTranslation } from '@external/react-i18next';

type Props = {
  label?: string;
  handleClick: () => void;
};

const LocationReset: React.FC<Props> = ({ handleClick, label }) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={handleClick}
      className="absolute top-0625 z-1 right-0 mr-2 cursor-pointer"
      data-element-type="clear-input"
      data-testid="clear-input"
    >
      <span className="sr-only">
        {t('forms.text-field.reset-button', 'Reset “{{label}}”', {
          label,
        })}
      </span>
      <Icon name="circles/close" color="gray-300" />
    </div>
  );
};

export default LocationReset;

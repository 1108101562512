import {
  DistrictDetailsInput,
  DistrictAddressInput,
  EmailType,
} from '@typings/graphql';

import {
  DistrictDetailsValues,
  DistrictDetailsFormValues,
} from '@domain/districts';

export const mapDistrictDetailsToForm = (
  values: DistrictDetailsValues
): DistrictDetailsFormValues => {
  const {
    businessAddress,
    primaryWebsite,
    primaryFax,
    primaryPhone,
    primaryEmail,
  } = values;

  const address = businessAddress && businessAddress[0];

  return {
    address: {
      countryId: address?.countryId || '',
      city: address?.city || '',
      postalCode: address?.postalCode || '',
      lineOne: address?.lineOne || '',
      lineTwo: address?.lineTwo || '',
      lineThree: address?.lineThree || '',
      state: address?.state || '',
      stateId: address?.stateId || '',
      internationalProvince: address?.internationalProvince || '',
      hasStates: false,
    },
    primaryEmail: primaryEmail || '',
    primaryWebsite: primaryWebsite || '',
    primaryPhone: {
      number: primaryPhone?.number || '',
      country: primaryPhone?.country || '',
      countryId: primaryPhone?.countryId || '',
      extension: primaryPhone?.extension || '',
    },
    primaryFax: {
      number: primaryFax?.number || '',
      country: primaryFax?.country || '',
      countryId: primaryFax?.countryId || '',
      extension: primaryFax?.extension || '',
    },
  };
};

export const mapFormToUpdateDistrictDetails = (
  values: DistrictDetailsFormValues
): Omit<DistrictDetailsInput, 'districtId'> => {
  const {
    address,
    primaryWebsite,
    primaryPhone,
    primaryEmail,
    primaryFax,
  } = values;

  const businessAddress: DistrictAddressInput = {
    countryId: address?.countryId,
    city: address?.city,
    postalCode: address?.postalCode,
    lineOne: address?.lineOne,
    lineTwo: address?.lineTwo,
    lineThree: address?.lineThree,
    stateId: address?.stateId,
  };

  if (address?.stateId) {
    businessAddress.stateId = address?.stateId;
  } else {
    businessAddress.internationalProvince = address?.internationalProvince;
  }

  return {
    primaryWebsite: primaryWebsite || null,
    primaryPhone:
      primaryPhone?.number && primaryPhone?.countryId
        ? {
            number: primaryPhone.number,
            countryId: primaryPhone.countryId,
            extension: primaryPhone?.extension || null,
          }
        : null,
    businessAddress: address?.countryId ? [businessAddress] : [],
    primaryEmail: primaryEmail
      ? {
          address: primaryEmail,
          type: EmailType.Business,
        }
      : null,
    primaryFax:
      primaryFax?.number && primaryFax?.countryId
        ? {
            number: primaryFax.number,
            countryId: primaryFax.countryId,
            extension: primaryFax.extension || null,
          }
        : null,
  };
};

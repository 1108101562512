import useSWR from 'swr';

import * as Drupal from '../utils/dataFetching/drupalFetcher';
import { responseInterface } from 'swr/dist/types';
import { MaintenanceMode } from '@domain/appConfig/types';

const path = 'en/restapi/maintenance-mode';
const swrSettings = {
  dedupingInterval: 5000,
};

export const getMaintenanceMode = Drupal.getData<MaintenanceMode>(path);

export const useMaintenanceModeCheck = () =>
  useSWR(path, getMaintenanceMode, swrSettings) as responseInterface<
    MaintenanceMode,
    Error
  >;
export default useMaintenanceModeCheck;

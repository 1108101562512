import { ApolloClient, InMemoryCache } from '@apollo/client';
import { langCodeToLocalizedPath } from '@repositories/language-negotiation/utils';
import { User } from '@domain/auth';
import {
  getIsDev,
  Lang,
  NegotiatedLanguage,
} from '@domain/language-negotiation';
import { getBrowserOrPreferredLang } from '@repositories/language-negotiation/pipelines';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task';
import * as E from 'fp-ts/lib/Either';

export const negotiateLanguagePipe = (
  user: User | null | undefined,
  client: ApolloClient<InMemoryCache>,
  currentLanguage: Lang,
  pageContextLanguage: Lang
): T.Task<NegotiatedLanguage> => {
  return pipe(
    T.of(currentLanguage),
    E.fromPredicate(
      () => getIsDev(pageContextLanguage),
      () => getBrowserOrPreferredLang(pageContextLanguage, client, user || {})
    ),
    E.getOrElse((a: T.Task<Lang>) => a),
    T.map((langCode: Lang) => ({
      langCode,
      localizedPath: langCodeToLocalizedPath(langCode),
    }))
  );
};

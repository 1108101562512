export class UnreachableCaseError extends Error {
  constructor(val: string | never) {
    super(`Unreachable case: ${val}`);
  }
}

export type PartialNullable<T> = {
  [P in keyof T]?: T[P] | null;
};

export function mapValues<T extends object, V>(
  obj: T,
  valueMapper: (k: T[keyof T]) => V
) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [k, valueMapper(v)])
  ) as { [K in keyof T]: V };
}

function reduceOmission<T>(
  original: {
    [prop: string]: any;
  },
  omissions: string[],
  prev: object,
  curr: string
): Partial<T> {
  return omissions.includes(curr) ? prev : { ...prev, [curr]: original[curr] };
}
export function omit<T extends object>(object: T, props: string[]): Partial<T> {
  return Object.keys(object).reduce(
    reduceOmission.bind(null, object, props),
    {}
  );
}

function reduceSelection<T>(
  original: {
    [prop: string]: any;
  },
  selections: string[],
  prev: object,
  curr: string
): Partial<T> {
  return selections.includes(curr) ? { ...prev, [curr]: original[curr] } : prev;
}
export function pick<T extends object>(object: T, props: string[]): Partial<T> {
  return Object.keys(object).reduce(
    reduceSelection.bind(null, object, props),
    {}
  );
}

export type RequiredBy<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;

export function sortByKey<T extends object>(
  obj: T,
  sort: (a: keyof T, b: keyof T) => number
): NonNullable<T[keyof T]>[] {
  return Object.entries(obj)
    .sort(([a], [b]) => sort(a as keyof T, b as keyof T))
    .map(([, v]) => v)
    .filter(v => v !== null && v !== undefined);
}

export type Await<T> = T extends {
  then(onfulfilled?: (value: infer U) => unknown): unknown;
}
  ? U
  : T;

export function isNotNullish<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

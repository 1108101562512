import * as React from 'react';
import { ChangeEvent } from 'react';

import { useMutation } from '@apollo/client';
import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { navigate } from 'gatsby';

import { languages } from '../../../../languages';

import { getDestinationPath } from '@utils/getDestinationPath';
import { uiLanguageToDISLanguageCode } from '@utils/languages';
import { localizedPath } from '@utils/localized-path';

import { REFRESH_DESTINATION_PATHS } from '@constants/index';
import { setPreferredLanguageQuery } from '@queries/setPreferredLanguageQuery';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { log } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';
import {
  SetPreferredLanguageMutation,
  SetPreferredLanguageMutationVariables,
} from '@typings/operations';

export const useSetPreferredLanguageMutation = () =>
  useMutation<
    SetPreferredLanguageMutation,
    SetPreferredLanguageMutationVariables
  >(setPreferredLanguageQuery, {
    refetchQueries: ['fetchPreferredLanguage'],
    awaitRefetchQueries: true,
  });

const languageOptions: { [key: string]: string } = languages;

/**
 * Component that allows the user to switch the site's language.
 */
const LanguageSwitcher: React.FC<{ className?: string }> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const { user, delocalizedPath: currentPath } = useAppConfig();
  const currentLanguage = i18n.language;
  const [setPreferredLanguage] = useSetPreferredLanguageMutation();
  const [, setDrupalLanguage] = useAxios(
    {
      withCredentials: true,
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    const { origin } = window.location;
    const { isDesiredPath: isRefreshDestination } = getDestinationPath(
      REFRESH_DESTINATION_PATHS
    );
    const newLanguage = evt.target.value;
    if (user?.isLoggedIn) {
      const execute = async () => {
        const preferredLanguage = uiLanguageToDISLanguageCode[newLanguage];
        await setPreferredLanguage({
          variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            individualId: user?.individualId!,
            preferredLanguage,
          },
        });
        // also set language on Drupal backend
        setDrupalLanguage({
          url: `${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/user/language/${newLanguage}`,
        });
        log({
          level: LogLevel.Info,
          message: `Drupal: setDrupalLanguage, request to ${process.env.GATSBY_BACKEND_APP_BASE_URL}/en/restapi/user/language/${newLanguage}`,
        });
        if (isRefreshDestination) {
          window.location.href =
            origin + localizedPath(currentPath, newLanguage);
        } else navigate(localizedPath(currentPath, newLanguage));
      };
      execute();
    } else if (isRefreshDestination) {
      window.location.href = origin + localizedPath(currentPath, newLanguage);
    } else navigate(localizedPath(currentPath, newLanguage));
  };

  // Remove dev language from available languages
  // so that it's not rendered in language switcher.
  delete languages.dev;

  const orderedLanguages: { [key: string]: string } = {};
  Object.entries(languages)
    .sort(([, valueA], [, valueB]) => valueA.localeCompare(valueB))
    .forEach(([key, value]) => {
      orderedLanguages[key] = value;
    });

  return (
    <label
      data-testid="language-switcher"
      htmlFor="language-switcher"
      className={classNames('text-white LanguageSwitcher', className)}
    >
      <span className="sr-only">
        {t('global.language-switcher.label', 'Change language:')}
      </span>
      <select
        id="language-switcher"
        onChange={handleChange}
        value={currentLanguage}
        className="pr-4 bg-transparent appearance-none rounded-none hover:underline"
      >
        {Object.keys(orderedLanguages).map(lang => (
          <option key={lang} value={lang} lang={lang} className="text-black">
            {languageOptions[lang]}
          </option>
        ))}
      </select>
    </label>
  );
};

export default LanguageSwitcher;

import moment from 'moment';

import { MembershipForAddMember, MembershipType } from '@typings/operations';

export const getActiveMembershipsByClubType = (
  membershipList: MembershipForAddMember[],
  isRotaractClub?: boolean
) =>
  membershipList.filter(
    ({ type, terminationDate }) =>
      (isRotaractClub
        ? type === MembershipType.Rotaractor
        : type === MembershipType.Member) &&
      (!terminationDate ||
        moment(terminationDate).isSameOrAfter(new Date(), 'day'))
  );

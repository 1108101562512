import { gql } from '@apollo/client';

export const fetchMemberDetailsQuery = gql`
  query MemberDetails($id: String!, $clubId: String, $access: String) {
    addMemberGetIndividual(id: $id, clubId: $clubId, access: $access) {
      individual {
        riIndividualId
        name
        firstName
        middleName
        lastName
        email
        primaryPhone {
          country
          countryId
          number
          type
          extension
        }
        primaryEmail {
          type
          address
        }
        primaryAddress {
          lineOne
          type
          lineTwo
          lineThree
          city
          country
          countryId
          state
          stateId
          postalCode
        }
        primaryLanguage
        yearOfBirth
        dateOfBirth
        gender
        suffix
        prefix
        localizedName
      }
      membershipList {
        type
        terminationDate
        clubId
        clubType
      }
    }
  }
`;

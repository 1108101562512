// Libs
import { gql, useMutation } from '@apollo/client';

// Types
import {
  UpdateDistrictLeadershipMutation,
  UpdateDistrictLeadershipMutationVariables,
} from '@typings/operations';

export const updateDistrictLeadershipQuery = gql`
  mutation updateDistrictLeadership(
    $districtId: String!
    $leadershipId: String!
    $terminationDate: String!
    $isExtraData: Boolean
  ) {
    updateDistrictLeadership(
      districtId: $districtId
      leadershipId: $leadershipId
      terminationDate: $terminationDate
      isExtraData: $isExtraData
    ) {
      id
      error
    }
  }
`;

export const useUpdateDistrictLeadership = () =>
  useMutation<
    UpdateDistrictLeadershipMutation,
    UpdateDistrictLeadershipMutationVariables
  >(updateDistrictLeadershipQuery);

// Libs
import { useEffect } from 'react';
import toInteger from 'lodash/toInteger';

// Hooks
import { useFetchRoles } from '@hooks/useFetchRoles';

// Utils
import { getRotaryYear } from '@utils/datetime';
import { getFormattedStartRY } from '@use-cases/districts';

// Constants
import { DISTRICT_OFFICER_GOVERNOR_ROLES } from '@domain/districts';

// Types
import { IsManager, TermToAssign } from '@domain/districts';

const getManagerTermYear = (endDate: string) => toInteger(endDate) - 1;
const rotaryYear = toInteger(getRotaryYear());

const getQueryVariables = (year: number, organizationType = ['District']) => ({
  variables: {
    organizationType,
    validOn: getFormattedStartRY(year),
    officerManagement: 'District',
    rolesToInclude: DISTRICT_OFFICER_GOVERNOR_ROLES,
  },
});

export const useFetchLeadershipRoles = (
  districtId: string,
  isManager: IsManager,
  isManagerTwoYears: boolean,
  term: TermToAssign | null,
  termsToAssign: TermToAssign[]
) => {
  const [
    getLeadershipRoles,
    { data: leadershipRolesData, loading: leadershipRolesLoading },
  ] = useFetchRoles();

  const [
    getLeadershipNextYearRoles,
    {
      data: leadershipNextYearRolesData,
      loading: leadershipNextYearRolesLoading,
    },
  ] = useFetchRoles();

  const [
    getDistrictRoles,
    { data: districtRoles, loading: districtRolesLoading },
  ] = useFetchRoles();

  useEffect(() => {
    if (districtId && term?.endDate) {
      // we need to replicate logic on the `Officers` page
      // it calls get District Leadership with endDate
      // and endDate - 1 for getRoles
      const currentYearQueryVars = getQueryVariables(
        getManagerTermYear(term.endDate)
      );
      const nextYearQueryVars = getQueryVariables(
        isManagerTwoYears
          ? getManagerTermYear(termsToAssign[1].endDate)
          : getManagerTermYear(term.endDate) + 1
      );
      // get end of year date (we need to get valid roles on the end of the year)
      // if elect, set next year
      // getRoles is waiting start date of the rotary year: 2019-2020 -> 2019-07-01
      if (isManager.currentTerm && isManager.nextTerm) {
        getLeadershipRoles(currentYearQueryVars);
        getLeadershipNextYearRoles(nextYearQueryVars);
      } else if (!isManager.currentTerm && isManager.nextTerm) {
        getLeadershipNextYearRoles(currentYearQueryVars);
      } else {
        getLeadershipRoles(currentYearQueryVars);
      }

      // Values used in the filter by district role
      getDistrictRoles(getQueryVariables(rotaryYear - 1));
    }
  }, [districtId, isManager, term]);

  return {
    leadershipRolesData,
    leadershipNextYearRolesData,
    districtRoles,
    leadershipRolesLoading:
      leadershipRolesLoading ||
      districtRolesLoading ||
      leadershipNextYearRolesLoading,
  };
};

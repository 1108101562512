import { gql } from '@apollo/client';

export const createIndividualMutation = gql`
  mutation CreateIndividual(
    $firstName: String!
    $lastName: String!
    $middleName: String
    $localizedName: String!
    $gender: Gender!
    $primaryLanguage: String!
    $primaryEmail: EmailInput!
    $primaryAddress: AddressInput!
    $primaryPhone: IndividualPhoneInput
    $yearOfBirth: Int
    $dateOfBirth: String
    $suffix: String
    $prefix: String
  ) {
    createIndividual(
      input: {
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        localizedName: $localizedName
        gender: $gender
        primaryLanguage: $primaryLanguage
        primaryEmail: $primaryEmail
        primaryAddress: $primaryAddress
        primaryPhone: $primaryPhone
        yearOfBirth: $yearOfBirth
        dateOfBirth: $dateOfBirth
        suffix: $suffix
        prefix: $prefix
      }
    ) {
      individualId
    }
  }
`;

import { AccessLevel } from '@typings/graphql';

export const isEditLevel = (accessLevel: AccessLevel | null | undefined) => {
  return (
    accessLevel === AccessLevel.Manager ||
    accessLevel === AccessLevel.Contributor
  );
};

export const isViewLevel = (accessLevel: AccessLevel | null | undefined) => {
  return (
    accessLevel === AccessLevel.LimitedViewer ||
    accessLevel === AccessLevel.Viewer ||
    accessLevel === AccessLevel.Manager ||
    accessLevel === AccessLevel.Contributor
  );
};

export const isManagerLevelLead = (
  accessLevel: AccessLevel | null | undefined
) => {
  return accessLevel === AccessLevel.Manager;
};

import { Maybe } from '@typings/graphql';
import { startCase } from 'lodash';

export const getIntlDisplayName = (
  displayLanguage: string,
  displayType: string,
  displayCode?: Maybe<string>
) => {
  const displayName =
    displayCode &&
    new Intl.DisplayNames(`${displayLanguage}`, {
      type: `${displayType}` as
        | 'script'
        | 'language'
        | 'region'
        | 'currency'
        | undefined,
    }).of(displayCode);

  return startCase(displayName as string | undefined);
};

import {
  FetchDgByRyQuery,
  FetchDgByRyQueryVariables,
} from '@typings/operations';
import { useQuery } from '@apollo/client';
import { fetchDGByRY } from '@repositories/memberSearch';

export const useFetchDGByRY = (variables: FetchDgByRyQueryVariables) =>
  useQuery<FetchDgByRyQuery, FetchDgByRyQueryVariables>(fetchDGByRY, {
    variables,
  });

// Libs
import { toString } from 'lodash';
import moment from 'moment';

// Types
import { GetConferencesResults } from '@typings/graphql';
import { ConferencesAccessLvl } from '@domain/districts';

// Utils
import { getRotaryYear } from '@utils/datetime';
import {
  isPastConference,
  getIsConferenceManager,
} from '@use-cases/districts/conferences';

export const getActionsConfig = (
  conference: GetConferencesResults,
  districtId: string,
  conferenceAccessLvls?: ConferencesAccessLvl[] | null
) => {
  const RY = Number(getRotaryYear());
  const {
    relatedRotaryYear,
    startDate,
    endDate,
    districtId: conferenceDistrictId,
    id: conferenceId,
  } = conference;

  const isMyDistrict = conferenceDistrictId === districtId;

  const isPastConf = isPastConference(endDate);

  const isThisYear = RY === relatedRotaryYear;

  const isOneYearPast = RY - relatedRotaryYear === 1;

  const isConferenceManager = getIsConferenceManager(
    toString(relatedRotaryYear),
    conferenceId,
    conferenceAccessLvls
  );

  const canRemoveConference = !moment().isBetween(
    startDate,
    endDate,
    'd',
    '[]'
  );

  return {
    isMyDistrict,
    isPastConf,
    isOneYearPast,
    isThisYear,
    canEditConference: isConferenceManager && !isPastConf,
    canRemoveConference,
  };
};

// Utils
import {
  CLUB_TYPE_ROTARACT,
  CLUB_TYPE_ROTARACT_SATELLITE,
  CLUB_TYPE_ROTARY,
  CLUB_TYPE_SATELLITE,
  MEMBERSHIP_TYPE_HONORARY,
  MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
  MEMBERSHIP_TYPE_MEMBER,
  MEMBERSHIP_TYPE_ROTARACTOR,
} from '@domain/districts';

import tailwind from '@styles/tailwind.js';

const themeColors = tailwind.theme.colors;

export const OVER_40 = 'over40';
export const UNDER_40 = 'under40';
export const UNREPORTED = 'unreported';

export const WOMEN_COUNT = 'womenCount';
export const OTHER_COUNT = 'otherGendersCount';

export const CLUB_CREDITS = 'clubCredits';

export const TEXT_FILL_COLOR = themeColors.gray['600'];

export const AGE_CATEGORIES = [
  { category: UNDER_40, color: themeColors['bright-blue']['400'] },
  { category: OVER_40, color: themeColors['dark-blue']['200'] },
  { category: UNREPORTED, color: themeColors.gray['200'] },
];

export const GENDER_CATEGORIES = [
  { category: WOMEN_COUNT, color: themeColors.gold['500'] },
  { category: OTHER_COUNT, color: themeColors.gray['200'] },
];

export const TOTAL_FOR_30_JUNE = 'totalFor30June';
export const PRIOR_MONTH_END = 'priorMonthEnd';
export const ANNUAL_FUND = 'annualFund';
export const POLIO_PLUS = 'polioPlus';

export const districtContributionChartColors = {
  annualFundColors: [
    {
      lineKey: TOTAL_FOR_30_JUNE,
      stroke: themeColors['bright-blue']['500'],
      fill: themeColors['bright-blue']['400-20'],
    },
    {
      lineKey: PRIOR_MONTH_END,
      stroke: themeColors['dark-blue']['400'],
      fill: themeColors.transparent,
    },
  ],
  polioErradicationColors: [
    {
      lineKey: TOTAL_FOR_30_JUNE,
      stroke: themeColors.gold['600'],
      fill: themeColors.gold['500-20'],
    },
    {
      lineKey: PRIOR_MONTH_END,
      stroke: themeColors.red['300'],
      fill: themeColors.transparent,
    },
  ],
  clubContributionColors: [
    {
      lineKey: ANNUAL_FUND,
      stroke: themeColors['dark-blue']['400'],
      fill: themeColors.transparent,
    },
    {
      lineKey: POLIO_PLUS,
      stroke: themeColors.gold['600'],
      fill: themeColors.transparent,
    },
  ],
};

export const districtDetailsChartColors = [
  { barName: MEMBERSHIP_TYPE_MEMBER, color: themeColors.gold['600'] },
  { barName: MEMBERSHIP_TYPE_HONORARY, color: themeColors['grey-light'] },
  { barName: MEMBERSHIP_TYPE_ROTARACTOR, color: themeColors.red['500'] },
  {
    barName: MEMBERSHIP_TYPE_HONORARY_ROTARACTOR,
    color: themeColors.gray['50'],
  },
  { barName: CLUB_TYPE_ROTARY, color: themeColors.gold['600'] },
  { barName: CLUB_TYPE_SATELLITE, color: themeColors['dark-blue']['400'] },
  { barName: CLUB_TYPE_ROTARACT, color: themeColors.red['500'] },
  { barName: CLUB_TYPE_ROTARACT_SATELLITE, color: themeColors.red['300'] },
];

export enum ChartValueFormats {
  percent = 'percent',
}

export enum SelectDashboardClubTypeOptions {
  All = 'All',
  Rotary = 'Rotary',
  Rotaract = 'Rotaract',
}

import { useMutation } from '@apollo/client';
import { terminateClubVendorMutation } from '@repositories/clubs';

// Types
import {
  TerminateClubVendorMutation,
  TerminateClubVendorMutationVariables,
} from '@typings/operations';

export const useTerminateClubVendor = () => {
  return useMutation<
    TerminateClubVendorMutation,
    TerminateClubVendorMutationVariables
  >(terminateClubVendorMutation);
};

import { gql } from '@apollo/client';

export const updateClubContactsMutation = gql`
  mutation updateClubContactInfo(
    $clubId: String!
    $dominoId: String
    $mailingAddress: BaseAddressInput
    $primaryEmail: String
    $primaryPhone: PhoneInput
    $primaryFax: FaxInput
    $primaryWebsite: String
    $officialLanguage: BasicLanguageInput
    $email: DominoEmailInput
    $website: WebsiteInput
  ) {
    updateClub(
      input: {
        clubId: $clubId
        dominoId: $dominoId
        mailingAddress: $mailingAddress
        primaryEmail: $primaryEmail
        primaryPhone: $primaryPhone
        primaryFax: $primaryFax
        primaryWebsite: $primaryWebsite
        officialLanguage: $officialLanguage
        email: $email
        website: $website
      }
    ) {
      clubId
    }
  }
`;

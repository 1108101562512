import { gql } from '@apollo/client';

export const fetchDGByRY = gql`
  query fetchDGByRY(
    $conferenceDistrictId: String!
    $conferenceStartDate: String!
    $conferenceEndDate: String!
    $relatedRY: String!
  ) {
    searchDGByRY(
      conferenceDistrictId: $conferenceDistrictId
      conferenceStartDate: $conferenceStartDate
      conferenceEndDate: $conferenceEndDate
      relatedRY: $relatedRY
    ) {
      riDistrictId
      roleName
      fullName
    }
  }
`;

// Libs
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';

import { ConferencesAccessLvl } from '@domain/districts';
import { AccessLevel } from '@typings/resolvers';

export const getIsConferenceManager = (
  relatedRotaryYear: string,
  conferenceId: string,
  data?: ConferencesAccessLvl[] | null
) => {
  return pipe(
    O.fromNullable(data),
    O.map(item => item || []),
    O.map(
      A.filter(
        ({ id, targetTermYear, level }) =>
          id === conferenceId &&
          targetTermYear === relatedRotaryYear &&
          level === AccessLevel.Manager
      )
    ),
    O.chain(A.head),
    O.map(Boolean),
    O.getOrElse(() => false)
  );
};

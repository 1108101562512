import { gql } from '@apollo/client';

export const fetchPersonalDetailsQuery = gql`
  query fetchPersonalDetails($id: String!) {
    personalDetails: getIndividual(id: $id) {
      firstName
      middleName
      lastName
      localizedName
      gender
      genderDescription
      photoUri
      yearOfBirth
      sharingPermissionsExtended {
        photo {
          id
        }
      }
      editPermissions {
        photo
      }
      suffix
      prefix
      dateOfBirth
      youthFlag
    }
  }
`;

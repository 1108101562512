import { useMutation } from '@apollo/client';

// Queries
import { updateAGClubAffiliationsQuery } from '../queries';

// Types
import {
  UpdateAgClubAffiliationsMutation,
  UpdateAgClubAffiliationsMutationVariables,
} from '@typings/operations';

export const useUpdateAGClubAffiliations = () =>
  useMutation<
    UpdateAgClubAffiliationsMutation,
    UpdateAgClubAffiliationsMutationVariables
  >(updateAGClubAffiliationsQuery);

import { gql } from '@apollo/client';

export const fetchDistrictsForLeadershipQuery = gql`
  query getDistrictsForLeadership($role: String!, $rotaryYear: Int!) {
    getDistrictsForLeadership(role: $role, rotaryYear: $rotaryYear) {
      error
      districts {
        id
        riDistrictId
        isHomeDistrict
        zone
      }
    }
  }
`;

// Libs
import { get } from 'lodash';

// Constants
import { JUN_KEY, YEAR_START_KEY } from '@domain/districts';

// Types
import { TFunction } from 'i18next';
import { ChartPointData, MembersTrendsChartData } from '@domain/districts';

export default (
  yearsRange: string[],
  chartData: MembersTrendsChartData[],
  t: TFunction
) =>
  yearsRange.reduce((acc, yearLabel) => {
    acc[yearLabel] = chartData
      .map((datum: MembersTrendsChartData) => [
        datum.month === YEAR_START_KEY
          ? t('dashboards.start', 'Start')
          : datum.month,
        datum[yearLabel],
        ...['deltaPrevMonth', 'deltaPrevYear', 'deltaStartYear'].map(key =>
          get(datum, `deltas[${yearLabel}].${key}`, null)
        ),
      ])
      .filter(([month, value]) => month !== JUN_KEY && !!value);
    return acc;
  }, {} as Record<string, ChartPointData[][]>);

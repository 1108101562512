import gql from 'graphql-tag';

export const uploadPhotoMutation = gql`
  mutation uploadPhoto(
    $individualId: String!
    $fileName: String!
    $fileSize: String!
    $fileMimeType: String!
    $fileContentsBase64Encoded: String!
  ) {
    photoUrl: uploadPhoto(
      individualId: $individualId
      fileName: $fileName
      fileSize: $fileSize
      fileMimeType: $fileMimeType
      fileContentsBase64Encoded: $fileContentsBase64Encoded
    )
  }
`;

import { gql } from '@apollo/client';

export const createProgramMutation = gql`
  mutation createProgram(
    $individualId: String!
    $programId: String!
    $fields: [ProgramFieldInput!]
  ) {
    createIndividualProgram(
      input: {
        individualId: $individualId
        programId: $programId
        fields: $fields
      }
    ) {
      individualProgramId
    }
  }
`;

import { useMutation } from '@apollo/client';
import { updateClubMeetingsQuery } from '@repositories/clubs';

// Types
import {
  UpdateClubMeetingsMutation,
  UpdateClubMeetingsMutationVariables,
} from '@typings/operations';

export const useUpdateClubMeeting = () =>
  useMutation<UpdateClubMeetingsMutation, UpdateClubMeetingsMutationVariables>(
    updateClubMeetingsQuery,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        'fetchClubDetails',
        'fetchAddMeeting',
        'fetchEditMeeting',
      ],
    }
  );

// Libs
import React from 'react';
import classNames from 'classnames';

// Types
import {
  NotificationType,
  Notification,
  ClearNotification,
} from '@domain/notifications';

// Utils
import { useTranslation } from '@external/react-i18next';

// Components
import Icon from '@components/Icon';

interface Props {
  notification: Notification;
  clearNotification: ClearNotification;
  dataTestId: string;
}

const NotificationItem: React.FC<Props> = ({
  notification,
  clearNotification,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const isInfo = notification.type === NotificationType.INFO;
  const isSuccess = notification.type === NotificationType.SUCCESS;
  const isError = notification.type === NotificationType.ERROR;

  return (
    <div
      data-testid={dataTestId}
      role="alert"
      className={classNames('text-sm py-5 px-4 flex justify-between', {
        'text-dark-blue-600 bg-bright-blue-100': isInfo,
        'text-green-600 bg-green-100': isSuccess,
        'text-red-600 bg-red-100': isError,
        'fixed left-0 w-full z-60 top-0': notification.isFixed,
      })}
    >
      {typeof notification.message === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: notification.message }} />
      ) : (
        <div>{notification.message}</div>
      )}
      <button
        data-testid={`remove-btn-${dataTestId}`}
        onClick={() => clearNotification(notification.id)}
        className="ml-5"
      >
        <Icon name="ui/close" size="20" />
        <span className="sr-only">
          {t('notification.close-button', 'Close')}
        </span>
      </button>
    </div>
  );
};

export default NotificationItem;

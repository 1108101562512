import { none, Option, some } from 'fp-ts/lib/Option';

import { ApolloUser, User } from '@domain/auth';

// gqlDataToUser :: ApolloData -> User
export const apolloDataToUser = ({
  currentUser,
}: ApolloUser = {}): Option<User> => {
  if (!currentUser) {
    return none;
  }

  return some({
    prerferredLanguage: '',
    nfKey: currentUser.nfKey,

    isLoggedIn: true,
    login: currentUser.login,
    id: currentUser.oktaId,
    individualId: currentUser.individualId,
    currentSecurityQuestion: currentUser.currentSecurityQuestion,
    impersonatorId: currentUser.impersonatorId,
    impersonationExpiration: currentUser.impersonationExpiration,
    attributes: {
      firstName: currentUser.profile?.firstName,
      lastName: currentUser.profile?.lastName,
    },
    riIndividualId: currentUser?.riIndividualId,
  });
};

import { useMutation } from '@apollo/client';
import { createIndividualMutation } from '@repositories/clubs';

// Types
import {
  CreateIndividualMutation,
  CreateIndividualMutationVariables,
} from '@typings/operations';

export const useCreateIndividual = () =>
  useMutation<CreateIndividualMutation, CreateIndividualMutationVariables>(
    createIndividualMutation
  );

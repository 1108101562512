// Types
import { Group, Item } from '@components/DragAndDropContainers/types';

// Libs
import { pipe } from 'fp-ts/lib/function';
import * as A from 'fp-ts/Array';
import * as R from 'ramda';
import { some, flatten, curry } from 'lodash';

const getMainGroupItems = (mainGroups: Group[]) =>
  pipe(mainGroups, A.map(R.prop('items')), flatten);

const isEqualsIds = curry((mainItem: Item, item: Item) =>
  pipe(mainItem, R.prop('id'), R.equals(item.id))
);

const isNotMatchedGroupItems = curry(
  (mainGroups: Group[], item: Item) =>
    !some(getMainGroupItems(mainGroups), isEqualsIds(item))
);

export const excludePrevAGCurrentAgClubs = (
  mainGroups: Group[],
  groupsWithItemsToExclude: Group[]
) =>
  pipe(
    groupsWithItemsToExclude,
    A.map(group => ({
      ...group,
      items: pipe(
        group,
        R.prop('items'),
        A.filter(isNotMatchedGroupItems(mainGroups))
      ),
    }))
  );

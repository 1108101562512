import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { TFunction } from 'i18next';

import { constructFullName } from '@utils/constructFullName';
import { getPhysicalLocation } from '@utils/getPhysicalLocation';

import {
  ConferenceAddress,
  FetchConferenceQuery,
  FormattedLocation,
  MeetingType,
  PresidentialRepresentative,
} from '@typings/operations';

export const getPresRepFullName = (
  presRep?: PresidentialRepresentative | null
): string | null =>
  pipe(presRep, O.fromNullable, O.mapNullable(constructFullName), O.toNullable);

export const getConferenceLocation = (
  t: TFunction,
  venueType: MeetingType,
  conferenceAddress?: ConferenceAddress | FormattedLocation | null
) =>
  venueType === MeetingType.Online
    ? t('global.meetingtype.online', 'Online Only')
    : getPhysicalLocation(conferenceAddress);

export const mapConferenceToConferenceSummary = (
  t: TFunction,
  conferenceData: FetchConferenceQuery | undefined
) => {
  return pipe(
    O.fromNullable(conferenceData?.conference),
    O.map(
      ({
        startDate,
        endDate,
        presidentialRepresentative: presRep,
        conferenceAddress,
        venueType,
        districtId,
      }) => ({
        location: getConferenceLocation(t, venueType, conferenceAddress),
        presidentialRepresentative: getPresRepFullName(presRep),
        districtId,
        startDate,
        endDate,
      })
    ),
    O.toNullable
  );
};

import { Maybe } from '@typings/graphql';

export const getPhysicalLocation = (
  physicalLocation?: {
    country?: Maybe<string>;
    city?: Maybe<string>;
    lineOne?: Maybe<string>;
    lineTwo?: Maybe<string>;
    lineThree?: Maybe<string>;
    state?: Maybe<string>;
    internationalProvince?: Maybe<string>;
    postalCode?: Maybe<string>;
    countryId?: Maybe<string>;
    stateId?: Maybe<string>;
    province?: Maybe<string>;
  } | null,
  isShortened?: boolean
): string | null | undefined =>
  physicalLocation &&
  [
    physicalLocation.state ||
      physicalLocation.internationalProvince ||
      physicalLocation.province,
    physicalLocation.city,
    isShortened ? '' : physicalLocation.country,
  ]
    .filter(loc => !!loc)
    .join(', ');

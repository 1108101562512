// Types
import { Club } from '@typings/graphql';
import { OfficerIndividual } from '@typings/operations';
import { CommitteeDistrictLeadership, CommitteeMemberData } from '../types';

export const mapDOToCommitteeMemberData = (
  officer: OfficerIndividual,
  fullName: string,
  roleInfo: CommitteeDistrictLeadership
): CommitteeMemberData => ({
  id: officer.id,
  photoUri: officer.photoUri,
  phoneNumber: officer.primaryPhone,
  email: officer.primaryEmail,
  nameWithPrefixSuffix: fullName,
  localizedName: officer.localizedName,
  associatedClubsInfo:
    // check clubId & clubType for rotaractors
    officer.clubId && officer.clubType
      ? [
          {
            clubId: officer.clubId,
            clubName: officer.clubName,
            clubType: officer.clubType,
            physicalLocation: officer.clubLocation,
          } as Club,
        ]
      : [],
  membershipType: officer.membership.type,
  activeMemberships: [
    {
      type: officer.membership.type,
      admissionDate: officer.membership.admissionDate || '',
      terminationDate: officer.membership.terminationDate,
    },
  ],
  thisDistrictLeadership: [
    {
      ...roleInfo,
    },
  ],
  __typename: 'CommitteeMemberData',
});

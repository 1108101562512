import {
  DONATE_WIDGET_URL_COMPOSE,
  DONATE_WIDGET_URL_PROD,
  DONATE_WIDGET_URL_UAT,
} from '@constants/index';

export default () => {
  switch (process.env.GATSBY_ENV) {
    case 'prod':
      return DONATE_WIDGET_URL_PROD;
    case 'compose':
      return DONATE_WIDGET_URL_COMPOSE;
    default:
      return DONATE_WIDGET_URL_UAT;
  }
};

import { gql, useLazyQuery } from '@apollo/client';
import {
  GetDistrictMembersStatisticsQuery,
  GetDistrictMembersStatisticsQueryVariables,
} from '@typings/operations';

export const fetchMembersStatisticsQuery = gql`
  query getDistrictMembersStatistics(
    $riDistrictId: String!
    $types: [String!]!
  ) {
    statistics: getDistrictMembersStatistics(
      riDistrictId: $riDistrictId
      types: $types
    ) {
      totalCount
      memberType
    }
  }
`;

export const useFetchMembersStatictics = () =>
  useLazyQuery<
    GetDistrictMembersStatisticsQuery,
    GetDistrictMembersStatisticsQueryVariables
  >(fetchMembersStatisticsQuery);

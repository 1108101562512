import { FC, useEffect } from 'react';

import ReactDOM from 'react-dom';

interface Props {
  children: React.ReactNode;
  rootSelectorId: string;
  onRemove?: () => void;
}

const Portal: FC<Props> = ({ children, rootSelectorId, onRemove }) => {
  const portalRoot =
    typeof document !== `undefined`
      ? document.getElementById(rootSelectorId)
      : null;

  const el =
    typeof document !== `undefined` ? document.createElement('div') : null;

  useEffect(() => {
    if (portalRoot && el) {
      portalRoot.appendChild(el);
      return () => {
        onRemove?.();
        portalRoot.removeChild(el);
      };
    }
    return undefined;
  }, [el, portalRoot]);

  if (el) {
    return ReactDOM.createPortal(children, el);
  }
  return null;
};

export default Portal;

import moment from 'moment';
import { DistrictOfficer } from '@typings/operations';
import { CommitteeDistrictLeadership } from '@domain/districts';

export const areSomeAssigmentAfter = (
  officers: DistrictOfficer[],
  role: Pick<CommitteeDistrictLeadership, 'roleId' | 'endDate'>
) =>
  officers.some(
    officer =>
      officer.roleId === role.roleId &&
      moment(officer.startDate).isAfter(role.endDate, 'd')
  );

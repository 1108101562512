// Libs
import moment from 'moment';

export const getMinDateForRemoveReplace = (startDate: string) => {
  if (moment().isBefore(startDate, 'd')) {
    return moment(startDate).toDate();
  }

  return moment().toDate();
};

import { useMutation } from '@apollo/client';
import {
  DeletePhotoMutation,
  DeletePhotoMutationVariables,
} from '@typings/operations';
import { deletePhotoMutation } from '../queries';

export const useDeletePhoto = () =>
  useMutation<DeletePhotoMutation, DeletePhotoMutationVariables>(
    deletePhotoMutation
  );

import { delocalizedPath } from '@utils/localized-path';

export const getDestinationPath = (pathsToCompare: string[]) => {
  const path = delocalizedPath(window.location.pathname);
  const destination = path.replace('/', '');
  const isDesiredPath = pathsToCompare.includes(destination);

  const isProd = process.env.GATSBY_ENV === 'prod';

  return {
    destination,
    isDesiredPath,
    isProd,
  };
};

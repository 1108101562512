import { ApolloUser } from '@domain/auth';

import { queryGQLString } from '@repositories/utils/dataFetching/apolloFetcher';

export const authenticationQuery = queryGQLString<ApolloUser>(`
  query AuthGetUser {
    currentUser {
      login
      oktaId
      individualId
      currentSecurityQuestion
      impersonatorId
      impersonationExpiration
      nfKey
      profile {
        firstName
        lastName
      }
      riIndividualId
    }
  }
`);

import { useQuery } from '@apollo/client';
import {
  FetchResumeQuery,
  FetchResumeQueryVariables,
} from '@typings/operations';
import { fetchResumeQuery } from '../queries';

export const useFetchResume = (individualId: string) =>
  useQuery<FetchResumeQuery, FetchResumeQueryVariables>(fetchResumeQuery, {
    variables: {
      individualId,
    },
  });

import { gql } from '@apollo/client';

export const areasOfExpertiseQuery = gql`
  query fetchAreasOfExpertise($individualId: String!) {
    areasOfExpertise: getIndividual(id: $individualId) {
      primaryExpertise {
        areaId
        area
        levelId
        level
      }
      otherExpertise {
        areaId
        area
        levelId
        level
      }
      sharingPermissionsExtended {
        expertise {
          id
        }
      }
    }
    expertiseAreas {
      id
      label
    }
    expertiseLevels {
      id
      label
    }
  }
`;

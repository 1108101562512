import { gql } from '@apollo/client';

export const replaceAgClubAffiliationsQuery = gql`
  mutation replaceAgClubAffiliations(
    $districtId: String!
    $individualId: String!
    $newIndividualId: String!
    $startDate: String!
  ) {
    replaceAgClubAffiliations(
      districtId: $districtId
      individualId: $individualId
      newIndividualId: $newIndividualId
      startDate: $startDate
    ) {
      id
      error
    }
  }
`;

import { useMutation } from '@apollo/client';
import { terminateMembershipMutation } from '@repositories/clubs';

// Types
import {
  TerminateMembershipMutation,
  TerminateMembershipMutationVariables,
} from '@typings/operations';

export const useTerminateMembership = () => {
  const [terminateMembership, { error }] = useMutation<
    TerminateMembershipMutation,
    TerminateMembershipMutationVariables
  >(terminateMembershipMutation, {
    refetchQueries: ['fetchEditClubContact'],
  });

  return { terminateMembership, error };
};

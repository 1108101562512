import { ApolloClient } from '@apollo/client';
import { debounce, uniq } from 'lodash';

import { ParticipantOption } from '@domain/districts';

import { fetchConferencesQuery } from '@repositories/districts';

import { GetConferencesResults } from '@typings/graphql';

export default debounce(
  (
    value: ParticipantOption[],
    client: ApolloClient<object>,
    setInvalidParticipants: (ids: string[]) => void,
    setErrorCalback: () => void,
    excludeIdsFromValidation?: string[],
    conferenceRY?: string,
    isValidating?: (isValidating: boolean) => void
  ) => {
    const RY = Number(conferenceRY);
    let selectedIds = value.map(({ id }) => id);

    if (excludeIdsFromValidation?.length) {
      selectedIds = selectedIds.filter(
        id => !excludeIdsFromValidation.includes(id)
      );
    }

    return client
      .query({
        query: fetchConferencesQuery,
        variables: {
          districtIds: selectedIds,
          minStartDate: `${RY - 1}-07-01`,
          maxStartDate: `${RY}-06-30`,
          isExtraData: true,
        },
      })
      .then(res => {
        const { data } = res;

        const activeConferences = (data?.getConferences as GetConferencesResults[]).filter(
          ({ relatedRotaryYear }) => relatedRotaryYear === RY
        );

        const invalidIds = activeConferences.reduce((acc, conf) => {
          const { districtId, participatingDistricts } = conf;
          if (selectedIds.includes(districtId)) {
            acc.push(districtId);
          }

          participatingDistricts?.forEach(({ id }) => {
            if (selectedIds.includes(id)) {
              acc.push(id);
            }
          });

          return acc;
        }, [] as string[]);

        setInvalidParticipants(uniq(invalidIds));

        if (invalidIds.length) {
          setErrorCalback();
        }

        isValidating && isValidating(false);
      });
  },
  500
);

import { gql, useLazyQuery } from '@apollo/client';

import {
  GetPreviousDesQuery,
  GetPreviousDesQueryVariables,
} from '@typings/operations';

export const fetchPreviousDESQuery = gql`
  query getPreviousDES($districtId: String!, $riDistrictId: Int!, $year: Int!) {
    previousDES: getPreviousDES(
      districtId: $districtId
      riDistrictId: $riDistrictId
      year: $year
    ) {
      id
      startDate
      endDate
      role
      roleId
      individual {
        id
        firstName
        lastName
        middleName
        prefix
        suffix
        localizedName
        photoUri
        primaryEmail
        primaryPhone
        clubId
        clubType
        clubName
        clubLocation {
          state
          city
          country
        }
        membership {
          type
          admissionDate
          terminationDate
        }
      }
    }
  }
`;

export const useFetchPreviousDES = () =>
  useLazyQuery<GetPreviousDesQuery, GetPreviousDesQueryVariables>(
    fetchPreviousDESQuery
  );

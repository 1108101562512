import { Executor } from './types';

export class LazyPromise<T> implements Promise<T> {
  [Symbol.toStringTag]: 'LazyPromise';
  executor: Executor<T>;
  promise: Promise<T | undefined> | null = null;
  constructor(executor: Executor<T>) {
    this.executor = executor;
  }
  wake() {
    if (this.promise === null) {
      this.promise = new Promise(this.executor);
    }
  }
  catch(onRejected: ((reason: any) => PromiseLike<never>) | null | undefined) {
    this.wake();
    return (<Promise<T>>this.promise).catch(onRejected);
  }
  finally(onFinally: () => void | null | undefined) {
    this.wake();
    return (<Promise<T>>this.promise).finally(onFinally);
  }
  then<TResult1 = T>(
    onFulfilled?:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | null
      | undefined,
    onRejected?: ((reason: any) => PromiseLike<never>) | null | undefined
  ) {
    this.wake();
    return (<Promise<T>>this.promise).then(onFulfilled, onRejected);
  }
}

import { find } from 'lodash';

import { Option } from '@components/Formik/MultiSelectFilter/types';
import { MemberFilter } from '@presenters/web/components/DistrictMembersFilters/types';

import { ClubRosterFilter } from '@domain/clubs';
import { ClubFilter } from '@domain/districts';

export default (
  initialFilters: MemberFilter | ClubFilter | ClubRosterFilter,
  options?: Option[]
) => {
  const [searchName, searchValue] = window.location.search
    .substring(1)
    .split('=');
  const predefinedFilterValue = find(options, ['value', searchValue]);

  return predefinedFilterValue
    ? {
        ...initialFilters,
        [searchName as string]: [predefinedFilterValue],
      }
    : initialFilters;
};

import { sortBy, toNumber } from 'lodash';
import moment from 'moment';

import { getRotaryYear } from '@utils/datetime';

import {
  FetchIndividualLeadershipsQuery,
  LeadershipResponseResult,
} from '@typings/operations';

const getRotaryYearRange = () => {
  const rotaryYear = toNumber(getRotaryYear());
  return {
    startYear: `${rotaryYear - 1}-06-30T00:00:00.000Z`,
    endYear: `${rotaryYear}-07-01T00:00:00.000Z`,
  };
};

const getRotaryFutureYearRange = () => {
  const rotaryYear = toNumber(getRotaryYear());
  return {
    startYear: `${rotaryYear}-06-30T00:00:00.000Z`,
    endYear: `${rotaryYear + 1}-07-01T00:00:00.000Z`,
  };
};

const getRotaryPreviousYearRange = () => {
  const rotaryYear = toNumber(getRotaryYear());
  return {
    startYear: `${rotaryYear - 2}-06-30T00:00:00.000Z`,
    endYear: `${rotaryYear - 1}-07-01T00:00:00.000Z`,
  };
};

// Sort the leadership history based on type- Rotary/Rotaract or District
// Returs sorted array interms of endDate to get latest leadership affiliation
export const sortIndividualLeadershipAffiliationType = (
  value: FetchIndividualLeadershipsQuery | undefined,
  type: Array<string>
): Array<LeadershipResponseResult> => {
  if (value === undefined || value.getLeadershipHistory?.length === 0)
    return [];

  const filteredValue = value.getLeadershipHistory?.filter(
    item =>
      item._embedded?.organization &&
      type.includes(item._embedded.organization.type)
  );

  const sortedArrayBasedOnEndDate: Array<LeadershipResponseResult> = sortBy(
    filteredValue,
    value => {
      if (value.endDate) return new Date(value.endDate);
      return null;
    }
  ).reverse();
  return sortedArrayBasedOnEndDate;
};

// This function validates the endDate of latest leadership role
// If endDate is between PreviousRY then its Immediate Past Officer
// It endDate is between FutureRY then its Elect Officer
export const isClubImmediatePastOrFutureOfficer = (endDate: string) => {
  if (endDate === '') return false;
  const { startYear, endYear } = getRotaryYearRange();
  const {
    startYear: FutureStartYear,
    endYear: FutureEndYear,
  } = getRotaryFutureYearRange();
  const {
    startYear: PreviousStartYear,
    endYear: PreviousEndYear,
  } = getRotaryPreviousYearRange();
  return (
    moment(endDate).isBetween(startYear, endYear) ||
    moment(endDate).isBetween(FutureStartYear, FutureEndYear) ||
    moment(endDate).isBetween(PreviousStartYear, PreviousEndYear)
  );
};

export const isDistrictImmediatePastOrFutureOfficer = (
  districtLeadershipAffiliation: LeadershipResponseResult[]
) => {
  if (districtLeadershipAffiliation.length === 0) return false;
  const { startYear } = getRotaryPreviousYearRange();
  const { endYear } = getRotaryFutureYearRange();
  for (let i = 0; i < districtLeadershipAffiliation.length; i++) {
    if (
      moment(districtLeadershipAffiliation[i].endDate).isBetween(
        startYear,
        endYear
      ) ||
      moment(districtLeadershipAffiliation[i].startDate).isBetween(
        startYear,
        endYear
      )
    ) {
      return true;
    }
  }
  return false;
};

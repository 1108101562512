import axios from 'axios';
import useSWR from 'swr';

import { defaultLanguage, languages } from '../../languages';

import { Drupal } from '@typings/drupal';

// Convert an absolute URL to the production website into a relative URL.
const productionServer = 'https://my.rotary.org';
export const makeRelativeLinks = (item: Drupal.MenuItem): void => {
  if (item.path.indexOf(productionServer) === 0) {
    // eslint-disable-next-line no-param-reassign
    item.path = item.path.slice(productionServer.length);
  }
  item.children?.forEach(makeRelativeLinks);
};

const fetcher = async (
  menuName: string,
  language: string,
  restrictPath: string,
  getFromStorage: boolean
) => {
  const storage = window.sessionStorage;
  const menuKey = `${menuName}-${language}`;
  const menuData = storage.getItem(menuKey);
  if (menuData && getFromStorage) {
    return JSON.parse(menuData);
  }

  const url = `${process.env.GATSBY_BACKEND_APP_BASE_URL}/${
    language === 'dev' ? defaultLanguage : language
  }/restapi/menu/${menuName}${restrictPath}`;

  const response = await axios.get(url, { withCredentials: true });

  if (response.data && getFromStorage) {
    response.data.forEach(makeRelativeLinks);
    Object.keys(languages).forEach(lang =>
      storage.removeItem(`${menuName}-${lang}`)
    );
    storage.setItem(menuKey, JSON.stringify(response.data));
  }

  return response.data;
};

export const useMenu = (
  menuName: string,
  language: string,
  restricted = false,
  getFromStorage = true
): Drupal.CustomResponseValues<Drupal.MenuResponse> => {
  const restrictPath = restricted ? '?restrict-menu=true' : '';
  const { data, error } = useSWR(
    [menuName, language, restrictPath, getFromStorage],
    fetcher
  );

  return {
    data,
    error,
    loading: !data,
  };
};

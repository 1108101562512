import { some } from 'lodash';

import { Option } from '@components/Formik/MultiSelectFilter/types';
import { MemberFilter } from '@presenters/web/components/DistrictMembersFilters/types';

import { ClubRosterFilter } from '@domain/clubs';

export type FilterProps = MemberFilter | ClubRosterFilter;

export default (filters: FilterProps) =>
  some(filters, filter => (filter as Option[])?.length > 0);

import {
  FetchEditProgramsQuery,
  FetchEditProgramsQueryVariables,
} from '@typings/operations';
import { useQuery } from '@apollo/client';
import { fetchIndividualProgramsQuery } from '../queries';

export const useFetchIndividualPrograms = (individualId: string) => {
  return useQuery<FetchEditProgramsQuery, FetchEditProgramsQueryVariables>(
    fetchIndividualProgramsQuery,
    {
      variables: {
        individualId,
      },
    }
  );
};

import { uniqBy } from 'lodash';

import { ClubAffiliation, DistrictType } from '@typings/operations';

export const getDistrictsList = (clubs?: ClubAffiliation[]) => {
  const districtsList: DistrictType[] = [];

  if (!clubs) return [];

  clubs.forEach(item => {
    if (item.district) districtsList.push(item.district);
  });

  return uniqBy(districtsList, 'id');
};

// Districts order should be: home District on top and the rest in number order
// Home District is the District associated to the club that I am a member of
export const getDistrictsOrder = (districts: DistrictType[]) =>
  [...districts].sort((a, b) => {
    if (a.isHomeDistrict === b.isHomeDistrict) {
      return a.riDistrictId - b.riDistrictId;
    }
    if (a.isHomeDistrict) {
      return -1;
    }

    return 1;
  });

export const getDistrictDetailsPath = (districtId: string) =>
  `/district/${districtId}/details`;

import React from 'react';
import { Text, RichText, withSitecoreContext, Image } from '@sitecore-jss/sitecore-jss-react';
import { MediaCenter as MediaCenterNPM } from '@rotaryintl/component-two-column-grey-wrapper';
import { GetFormattedDate } from '../../utilities/date-helper-functions';
import { createLink } from '../../utilities/link-helpers';

const RotaryMedia = ({ sitecoreContext, params: { ComponentId } = {}, fields: { Title, Link, LinkText, ShowExternalLinkIcon, MediaCallouts } = {} }) => (
  <MediaCenterNPM
    id={ComponentId}
    title={<Text field={Title} />}
    link={createLink(Link, LinkText, ShowExternalLinkIcon)}
    mediaCallouts={MediaCallouts?.map(({ fields: { Title, Description, Date: DateField, Image: ImageField, Link, LinkText } }) => ({
      title: <Text field={Title} />,
      description: <RichText field={Description} />,
      date: GetFormattedDate(sitecoreContext?.language?.toLowerCase(), new Date(DateField?.value)),
      image: <Image field={ImageField} />,
      link: createLink(Link, LinkText),
    }))}
  />
);

export default withSitecoreContext()(RotaryMedia);

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as TE from 'fp-ts/lib/TaskEither';

import { apolloDataToUser, User } from '@domain/auth';

import { authenticationQuery } from '@repositories/auth/queries';

export const fetchUser = (
  client: ApolloClient<InMemoryCache>
): TE.TaskEither<Error, O.Option<User>> =>
  pipe(authenticationQuery(client), TE.map(apolloDataToUser));

import { TFunction } from 'i18next';

import { constructMenuItem } from './constructMenuItem';
import { getClubsSubItems } from './getClubsSubItems';
import { getDistrictsSubItems } from './getDistrictsSubItems';
import { getMenuItemsNewArr } from './getMenuItemsNewArr';

import { getDistrictsOrder } from '@utils/districts';

import { Drupal } from '@typings/drupal';
import { ClubLeadership, DistrictType } from '@typings/operations';

const constructHomeMenuItems = (
  t: TFunction,
  isLoggedIn: boolean,
  menuItems: Array<Drupal.MenuItem>
): Drupal.MenuItem => {
  const menuItemsNewArr = getMenuItemsNewArr(menuItems);

  return constructMenuItem(
    'home-menu-item',
    '/',
    t('header.main-menu.home-label', 'Home'),
    isLoggedIn ? menuItemsNewArr : []
  );
};

const getClubsMenuItems = (
  clubs: ClubLeadership[],
  t: TFunction
): Drupal.MenuItem => {
  const clubsCount = clubs.length;
  const cutClubsArr = clubs.slice(0, 5) || [];

  const myClubsSubItems: Array<Drupal.MenuItem> = getClubsSubItems(
    t,
    cutClubsArr,
    clubsCount
  );

  return constructMenuItem(
    'clubs',
    '/my-clubs',
    t('header.main-menu.my-clubs-label', 'Clubs'),
    [...myClubsSubItems]
  );
};

const getDistrictsMenuItems = (
  districts: DistrictType[],
  t: TFunction
): Drupal.MenuItem => {
  const districtsCount = districts.length;
  const cutDistrictsArr = districts.slice(0, 5) || [];

  const myDistrictsSubItems: Array<Drupal.MenuItem> = getDistrictsSubItems(
    t,
    cutDistrictsArr,
    districtsCount
  );

  return constructMenuItem(
    'districts',
    '/my-districts',
    t('header.main-menu.districts-menu-label', 'Districts'),
    [...myDistrictsSubItems]
  );
};

export const getHomeMenuItems = (
  t: TFunction,
  isLoggedIn: boolean,
  districts: DistrictType[],
  clubs: ClubLeadership[]
): Drupal.MenuItem => {
  const clubsItems = getClubsMenuItems(clubs, t);

  // Should be an empty array if there are no children for clubs or districts menu item
  let menuItems = clubsItems.children?.length ? [clubsItems] : [];

  if (districts.length) {
    const districtItems = getDistrictsMenuItems(
      getDistrictsOrder(districts),
      t
    );

    menuItems = districtItems.children?.length
      ? [...menuItems, districtItems]
      : [...menuItems];
  }

  return constructHomeMenuItems(
    t,
    isLoggedIn,
    menuItems.filter(item => !!item)
  );
};

// Types
import { Meeting, MeetingInput, AddressInput } from '@typings/operations';

export default (meeting: Meeting): MeetingInput => {
  const input = {
    ...meeting,
    address: {
      countryId: meeting.address?.countryId,
      city: meeting.address?.city,
      postalCode: meeting.address?.postalCode,
      lineOne: meeting.address?.lineOne || '', // required in input but not query result
      lineTwo: meeting.address?.lineTwo,
      lineThree: meeting.address?.lineThree,
      state: meeting.address?.state,
      stateId: meeting.address?.stateId,
      internationalProvince: meeting.address?.internationalProvince,
    } as AddressInput,
  };

  delete input.__typename;
  delete input.longitude;
  delete input.latitude;

  return input;
};

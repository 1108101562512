// Libs
import { useContext, useEffect } from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';

// Types
import { CommitteeSelectedInfo, Step } from '@domain/districts';
import { FORM_STATUSES } from '@domain/storage';

// Utils
import {
  defaultContextState,
  DistrictContext,
  filterDistrictLeadershipByRoles,
  isCurrentTerm,
} from '@use-cases/districts';

import { isToday } from '@utils/datetime';

export const useCAReplaceRemoveContext = () => {
  const [context, setContext] = useContext(DistrictContext);

  const {
    selectedInfo: { unassignFrom, role, term, dateToRemoveRole },
  } = context;

  // we need to filter roles assigned to individual as
  // we can not remove District Leadership Roles
  const districtLeadership = filterDistrictLeadershipByRoles(
    unassignFrom?.thisDistrictLeadership || []
  );
  const isOneRole = districtLeadership.length === 1;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resetContextValues = (callback?: (data: any) => void) => {
    const newValues = {
      ...context,
      step: 1 as Step,
      operationType: null,
      managedRYs: { ...context.managedRYs },
      selectedInfo: {
        ...context.selectedInfo,
        role: defaultContextState.selectedInfo.role,
        assignee: defaultContextState.selectedInfo.assignee,
        unassignFrom: defaultContextState.selectedInfo.unassignFrom,
        dateToRemoveRole: defaultContextState.selectedInfo.dateToRemoveRole,
        term: context.termsToAssign[0],
      },
    };
    callback?.({ ...newValues, status: FORM_STATUSES.FINISHED });
  };

  useEffect(() => {
    const updatedContextValues = {} as Partial<CommitteeSelectedInfo>;

    if (role === null) {
      if (isOneRole) {
        const { role: name, roleId: id, termYears } = districtLeadership[0];
        updatedContextValues.role = {
          id,
          name,
          termYears,
        };
      } else {
        const leaderships = districtLeadership.filter(
          leadership => !isToday(leadership.startDate)
        );
        if (leaderships.length === 1) {
          const { role: name, roleId: id, termYears } = leaderships[0];
          updatedContextValues.role = {
            id,
            name,
            termYears,
          };
        }
      }
    }

    if (dateToRemoveRole === null) {
      // If member has one districtLeadership and its startDate is after Today
      // dateToRemoveRole should be the same as startDate
      const today = moment().format('YYYY-MM-DD');
      const leadership = districtLeadership.find(
        ({ roleId }) => roleId === updatedContextValues.role?.id
      );
      const { startDate: leadershipStartDate } =
        leadership || districtLeadership[0] || {};
      const isLeadershipInFuture = moment(leadershipStartDate).isAfter(today);

      const isOneRoleAndFuture = isOneRole && isLeadershipInFuture;
      const date = isOneRoleAndFuture ? leadershipStartDate : today;
      updatedContextValues.dateToRemoveRole = moment(date).toDate();
    }

    if (!isEmpty(updatedContextValues)) {
      setContext(prevState => ({
        ...prevState,
        selectedInfo: {
          ...prevState.selectedInfo,
          ...updatedContextValues,
        },
      }));
    }
  }, [dateToRemoveRole, role, districtLeadership]);

  return {
    districtLeadership,
    isOneRole,
    isCurrentTerm: isCurrentTerm(term),
    resetContextValues,
    selectedLeadership: districtLeadership.find(
      leadership => leadership.roleId === role?.id
    ),
  };
};

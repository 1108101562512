// Libs
import React from 'react';

// Components
import NotificationItem from './NotificationItem';
import { OneColumn } from '@components/Layouts/OneColumn';

// Hooks
import {
  useNotifications,
  useStateNotifications,
} from '@use-cases/notifications';

const Notifications: React.FC = () => {
  const { clearNotification } = useNotifications();
  const state = useStateNotifications();
  const { notifications } = state;

  if (!notifications.length) {
    return null;
  }

  return (
    <OneColumn className="py-5">
      {notifications.map(notification => (
        <NotificationItem
          dataTestId={notification.id}
          key={notification.id}
          clearNotification={clearNotification}
          notification={notification}
        />
      ))}
    </OneColumn>
  );
};

export default Notifications;

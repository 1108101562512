import * as React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import classNames from 'classnames';
import { useTranslation } from '@external/react-i18next';
import { isUrlAbsolute, isUrlFragment } from '@utils/url';
import { argsFromPath } from '@utils/string-helpers';
import { uiLanguages } from '../../../languages';

/**
 * Override of the standard Gatsby Link component.
 *
 * Adds support for multilingual links by automatically adding the current
 * language as a prefix.
 *
 * To be used identically to Gatsby Link.
 */
export function LinkWithState<TState>({
  to,
  showModal,
  lang,
  useExternalLink,
  children,
  ...rest
}: GatsbyLinkProps<TState> & {
  lang?: string;
  useExternalLink?: boolean;
  showModal?: () => void;
}) {
  const languages = uiLanguages();
  const pathParts = argsFromPath(to);
  const { i18n } = useTranslation();
  let languagePrefix = '';

  // Only add language prefix if there isn't one already in the given path.
  if (pathParts[0] in languages === false) {
    // There's no language prefix in the path, so let's add one.
    const language = lang || i18n.language;
    languagePrefix = `/${language}`;
  }

  const newTarget = `${languagePrefix ? `${languagePrefix}${to}` : to}`;
  if (showModal) {
    return (
      <button
        onClick={showModal}
        className="text-small font-bold link-styles inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      >
        {children}
      </button>
    );
  }

  if (useExternalLink) {
    return (
      <a href={newTarget} {...rest}>
        {children}
      </a>
    );
  }
  return (
    // @ts-ignore: Typing issue with Gatsby's link props.
    <GatsbyLink to={newTarget} {...rest}>
      {children}
    </GatsbyLink>
  );
}

const Link: React.FC<GatsbyLinkProps<any> & {
  noLinkStyles?: boolean;
  lang?: string;
  useExternalLink?: boolean;
  showModal?: () => void;
}> = ({
  to,
  noLinkStyles,
  className,
  children,
  useExternalLink,
  showModal,
  ...props
}) => {
  const classes = classNames(className, { 'link-styles-off': noLinkStyles });

  // Handle non-local links or fragment links if given.
  if (isUrlAbsolute(to) || isUrlFragment(to) || to.includes('mailto')) {
    return (
      <a href={to} className={classes} {...props}>
        {children}
      </a>
    );
  }

  return (
    <LinkWithState
      to={to}
      className={classes}
      useExternalLink={useExternalLink}
      showModal={showModal}
      {...props}
    >
      {children}
    </LinkWithState>
  );
};

export default Link;

import { useQuery } from '@apollo/client';
import {
  FetchOfficersForRemovingQuery,
  FetchOfficersForRemovingQueryVariables,
} from '@typings/operations';
import { fetchOfficersForRemoving } from '@repositories/clubs';

export const useFetchClubOfficersForRemoving = (
  variables: FetchOfficersForRemovingQueryVariables
) =>
  useQuery<
    FetchOfficersForRemovingQuery,
    FetchOfficersForRemovingQueryVariables
  >(fetchOfficersForRemoving, {
    variables,
  });

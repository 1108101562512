import { useMutation } from '@apollo/client';
import {
  UpdatePersonalDetailsMutation,
  UpdatePersonalDetailsMutationVariables,
} from '@typings/operations';
import { updatePersonalDetailsMutation } from '../queries';

export const useUpdatePersonalDetails = () =>
  useMutation<
    UpdatePersonalDetailsMutation,
    UpdatePersonalDetailsMutationVariables
  >(updatePersonalDetailsMutation);

import { IORef } from 'fp-ts/lib/IORef';
import * as O from 'fp-ts/Option';
import { i18n } from 'i18next';

import { isIE } from '@utils/browser-detection';
import { addLocalisationToPath } from '@utils/localized-navigate';
import getOktaWidgetTranslations from '@utils/okta-widget-i18n';

const apiUrl = process.env.OKTA_API_URL ?? '';
const apiIssuer = process.env.OKTA_API_ISSUER ?? '';
const clientId = process.env.OKTA_APP_CLIENT_TOKEN ?? '';
const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
const oktaHttpSupport = process.env.OKTA_WIDGET_HTTP_SUPPORT === 'true';

type OktaConfiguration = {
  i18n: i18n;
  usernameUsedForLogin: IORef<O.Option<string>>;
};

export const getOktaConfiguration = ({
  i18n,
  usernameUsedForLogin,
}: OktaConfiguration) => ({
  baseUrl: apiUrl,
  clientId,
  redirectUri: `${appUrl}${addLocalisationToPath('/login')}`,
  authParams: {
    issuer: apiIssuer,
    responseType: ['token', 'id_token'],
    responseMode: 'query',
    scopes: ['openid', 'profile', 'email'],
    display: 'page',
    tokenManager: {
      autoRenew: false,
      autoRemove: false,
    },
    ...(oktaHttpSupport
      ? {
          pkce: false,
          cookies: {
            secure: false,
          },
        }
      : { pkce: !isIE }),
  },
  transformUsername: (
    username: string,
    operation: 'PRIMARY_AUTH' | 'FORGOT_PASSWORD' | 'UNLOCK_ACCOUNT'
  ) => {
    usernameUsedForLogin.write(
      operation === 'PRIMARY_AUTH' ? O.some(username) : O.none
    )();

    return username;
  },
  helpLinks: {
    forgotPassword: `${appUrl}${addLocalisationToPath('/reset-password')}`,
    help: `${appUrl}${addLocalisationToPath('/contact')}`,
  },
  language: i18n.language,
  i18n: getOktaWidgetTranslations(),
});

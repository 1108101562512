export const parseUrl = (url: string) => {
  const urlObj = new URL(url);
  const subPaths = urlObj.pathname.split('/').filter(path => path);
  const params: Record<string, string> = {};

  urlObj.searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return { subPaths, params };
};

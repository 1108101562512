import { gql } from '@apollo/client';

export const updateMembershipQuery = gql`
  mutation UpdateMembership(
    $clubId: String!
    $membershipId: String!
    $type: String!
    $admissionDate: String!
  ) {
    updateMembershipType(
      clubId: $clubId
      membershipId: $membershipId
      type: $type
      admissionDate: $admissionDate
    ) {
      membership {
        id
        type
        admissionDate
      }
    }
  }
`;

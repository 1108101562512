import { TFunction } from 'i18next';

import {
  MEETING_TYPE_HYBRID,
  MEETING_TYPE_ONLINE,
  MEETING_TYPE_PHYSICAL,
} from '@domain/search';

export const getMeetingTypeLabel = (categories: string[]): string | null => {
  if (categories.includes('ONLINE')) {
    return categories.includes('PHYSICAL')
      ? MEETING_TYPE_HYBRID
      : MEETING_TYPE_ONLINE;
  }

  if (categories.includes('PHYSICAL')) {
    return MEETING_TYPE_PHYSICAL;
  }

  return null;
};

export const getMeetingTypeTranslation = (
  t: TFunction,
  meetingType: string
) => {
  switch (meetingType) {
    case 'Online':
      return t('club-search.meetings.online', 'Online');
    case 'Physical':
      return t('club-search.meetings.physical', 'In Person');
    default:
      return t('club-search.meetings.hybrid', 'In Person and Online');
  }
};

import * as React from 'react';
import { ReactNode, useState } from 'react';

import Link from '@components/Link';

import { useTranslation } from '@external/react-i18next';

import { Drupal } from '@typings/drupal';

export interface ShowChildrenProps {
  item: Drupal.MenuItem;
  link: ReactNode;
  show: boolean;
  toggleChildren: () => void;
  toggleChildrenLabel: string;
  children: ReactNode;
}

const ShowChildrenHelper: React.FC<{
  ShowChildrenComponent: React.FC<ShowChildrenProps>;
  item: Drupal.MenuItem;
  link: ReactNode;
}> = ({ ShowChildrenComponent, item, link, children }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const toggleChildrenLabel = show
    ? t('global.menus-toggle-child.hide', 'Hide child links')
    : t('global.menus-toggle-child.show', 'Show child links');

  const toggleChildren = () => {
    setShow(!show);
  };

  return (
    <ShowChildrenComponent
      item={item}
      link={link}
      show={show}
      toggleChildren={toggleChildren}
      toggleChildrenLabel={toggleChildrenLabel}
    >
      {children}
    </ShowChildrenComponent>
  );
};

const MenuLinks: React.FC<{
  menuItems: Drupal.MenuResponse;
  className?: string;
  itemClassName?: string;
  LabelComponent?: React.FC<{ label: string; id?: string }>;
  linkClassName?: string;
  ShowChildrenComponent?: React.FC<ShowChildrenProps>;
  depth?: number;
  maxDepth?: number;
  toggleMenu?: () => void;
}> = ({
  menuItems,
  className,
  itemClassName,
  LabelComponent,
  linkClassName,
  ShowChildrenComponent,
  depth = 1,
  maxDepth = 4,
  toggleMenu,
}) => {
  if (menuItems.length === 0) {
    return null;
  }
  return (
    <ul className={className}>
      {menuItems.map(item => {
        // Render the link with the optional LabelComponent.
        const link = (
          <Link
            useExternalLink={Boolean(item.path.match(/donate/g))}
            to={item.path}
            className={linkClassName}
            onClick={() => (toggleMenu ? toggleMenu() : null)}
          >
            {LabelComponent ? (
              <LabelComponent label={item.label} id={item.id} />
            ) : (
              item.label
            )}
          </Link>
        );
        return (
          <li key={item.id} className={itemClassName}>
            {/* Render the link with the optional ShowChildrenComponent. */}
            {item.children?.length &&
            ShowChildrenComponent &&
            depth < maxDepth ? (
              <ShowChildrenHelper
                ShowChildrenComponent={ShowChildrenComponent}
                item={item}
                link={link}
              >
                <MenuLinks
                  menuItems={item.children || []}
                  className={className}
                  itemClassName={itemClassName}
                  LabelComponent={LabelComponent}
                  linkClassName={linkClassName}
                  ShowChildrenComponent={ShowChildrenComponent}
                  depth={depth + 1}
                  maxDepth={maxDepth}
                  toggleMenu={toggleMenu}
                />
              </ShowChildrenHelper>
            ) : (
              link
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default MenuLinks;

import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import moment from 'moment';
import * as R from 'ramda';

import { getToday, isSameOrAfter, isSameOrBefore } from '@utils/datetime';

export type MembershipDate = {
  admissionDate: string;
  terminationDate: string | null | undefined;
};

export const isAfterAdmission = (item: MembershipDate) =>
  pipe(item, R.prop('admissionDate'), isSameOrBefore(getToday()));

export const isNotTerminated = (item: MembershipDate) =>
  pipe(item, R.prop('terminationDate'), O.fromNullable, O.isNone);

export const isBeforeTermination = (item: MembershipDate) =>
  pipe(
    item,
    O.fromNullable,
    O.mapNullable(R.prop('terminationDate')),
    O.fold(() => false, isSameOrAfter(getToday()))
  );

export const isActiveMembership = (item: MembershipDate): boolean =>
  isAfterAdmission(item) &&
  (isNotTerminated(item) || isBeforeTermination(item));

export const isTerminationBeforeCurrentDate = (
  terminationDate: string | null | undefined
): boolean =>
  !!terminationDate && moment(terminationDate).isBefore(moment(), 'd');

import { useLazyQuery } from '@apollo/client';
import { fetchOneFeedbackQuery } from '../queries';
import {
  GetOneFeedbackQuery,
  GetOneFeedbackQueryVariables,
} from '@typings/operations';

export const useFetchOneFeedback = () =>
  useLazyQuery<GetOneFeedbackQuery, GetOneFeedbackQueryVariables>(
    fetchOneFeedbackQuery
  );

import { useQuery } from '@apollo/client';

import { defaultLanguage } from '../../../../languages';

import { SiteSearchFormValues } from '@domain/search';

import { fetchSiteSearch } from '@repositories/clubs';

import { SearchSolrQuery, SearchSolrQueryVariables } from '@typings/operations';

export const useSiteSearch = (
  params: SiteSearchFormValues,
  page: number,
  pageSize: number
) =>
  useQuery<SearchSolrQuery, SearchSolrQueryVariables>(fetchSiteSearch, {
    variables: {
      page,
      per_page: pageSize,
      ...params,
      langcode: params.langcode === 'dev' ? defaultLanguage : params.langcode,
    },
    skip: !params.keywords,
  });

import {
  getAvailableLangCodes,
  getBrowserLangs,
  Lang,
} from '@domain/language-negotiation';
import { languages } from '../../../../languages';

import { pipe } from 'fp-ts/lib/function';
import * as IO from 'fp-ts/lib/IO';
import * as A from 'fp-ts/lib/Array';
import * as Eq from 'fp-ts/lib/Eq';

// filterBrowserLang :: Navigator -> Lang[]
export const filterBrowserLang = (nav: Navigator): IO.IO<Lang[]> =>
  pipe(
    getBrowserLangs(nav),
    IO.map(A.intersection(Eq.eqString)(getAvailableLangCodes(languages)))
  );

// Libs
import { findIndex } from 'lodash';

// Types
import { Action, State, ActionType } from '@domain/notifications';

export const reducer = (state: State, action: Action) => {
  switch (action.actionType) {
    case ActionType.PURGE:
      return {
        ...state,
        notifications: state.notifications
          .filter(notification => notification.nextPage)
          .map(notification => ({ ...notification, nextPage: false })),
      };

    case ActionType.CLEAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload.idToRemove
        ),
      };

    case ActionType.ADD_SUCCESS:
    case ActionType.ADD_INFO:
    case ActionType.ADD_ERROR: {
      const foundIndex = findIndex(state.notifications, {
        id: action.payload.notification.id,
      });

      if (foundIndex === -1) {
        return {
          ...state,
          notifications: [
            ...state.notifications,
            {
              ...action.payload.notification,
            },
          ],
        };
      }

      return {
        ...state,
        notifications: state.notifications.map((item, index) => {
          if (index === foundIndex) {
            return action.payload.notification;
          }
          return item;
        }),
      };
    }

    default: {
      return state;
    }
  }
};

import { useMutation } from '@apollo/client';
import {
  CreateProgramMutation,
  CreateProgramMutationVariables,
} from '@typings/operations';
import { createProgramMutation } from '../queries';

export const useCreateProgram = () => {
  return useMutation<CreateProgramMutation, CreateProgramMutationVariables>(
    createProgramMutation
  );
};

import { gql, ApolloClient, InMemoryCache } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';

import { curry } from '@utils/fp-ts';

import { ApolloFuncSignature } from './types';
import { extractResponseData } from './extractResponseData';

// toGQL :: string -> DocumentNode
export const toGQL = (query: string): DocumentNode =>
  gql`
    ${query}
  `;

// lazyToGQL :: string -> _ -> DocumentNode
export const lazyToGQL = (query: string) => (): DocumentNode => toGQL(query);

// queryOverClient :: ApolloClient -> ( any any -> DocumentNode ) -> TaskEither Error Promise<ApolloData>
const queryOverClient = (query: () => DocumentNode) => (variables?: any) => (
  client: ApolloClient<InMemoryCache>
): TE.TaskEither<Error, any> => {
  return pipe(
    TE.tryCatch(
      () => client.query({ query: query(), variables: variables }),
      E.toError
    ),
    TE.map(extractResponseData)
  );
};

// mutateOverClient :: ApolloClient -> ( any any -> DocumentNode ) -> TaskEither Error Promise<ApolloData>
export const mutateOverClient = curry(
  (
    mutation: () => DocumentNode,
    variables: any = undefined,
    client: ApolloClient<InMemoryCache>
  ): TE.TaskEither<Error, any> =>
    pipe(
      TE.tryCatch(
        () => client.mutate({ mutation: mutation(), variables: variables }),
        E.toError
      ),
      TE.map(data => {
        return data;
      })
    )
);

// lazyQueryGQL :: ApolloClient, DocumentNode -> variables -> TaskEither Error Promise<data>
// export const lazyQueryGQL = <V, R>(
//   query: DocumentNode
// ): ((variables: V) => ApolloFuncSignature<R>) => queryOverClient(() => query);

// lazyQueryString :: ApolloClient, string -> variables -> TaskEither Error Promise<data>
export const lazyQueryString = <V, R>(
  queryString: string
): ((variables: V) => ApolloFuncSignature<R>) =>
  queryOverClient(lazyToGQL(queryString));

// queryGQL :: ApolloClient, DocumentNode -> TaskEither Error Promise<data>
export const queryGQL = <R>(query: DocumentNode): ApolloFuncSignature<R> =>
  queryOverClient(() => query)();

// queryString :: ApolloClient, string -> TaskEither Error Promise<data>
export const queryGQLString = <R>(
  queryString: string
): ApolloFuncSignature<R> => queryOverClient(lazyToGQL(queryString))();

// mutateQueryString :: ApolloClient, string -> variables -> TaskEither Error Promise<data>
export const mutateQueryString = <V, R>(
  queryString: string
): ((variables: V) => ApolloFuncSignature<R>) =>
  mutateOverClient(lazyToGQL(queryString));

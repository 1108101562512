import { gql } from '@apollo/client';

export const fetchPotentialVendorsQuery = gql`
  query fetchPotentialVendors {
    vendors {
      Name
      Key
    }
  }
`;

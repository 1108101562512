import React from 'react';

import classNames from 'classnames';
import { Suggestion } from 'react-places-autocomplete';

import { ElementProps } from '@components/Formik/Element';
import Icon from '@components/Icon';

interface SuggestionOptionProps {
  itemProps: (
    suggestion: Suggestion,
    otherProps: Partial<ElementProps>
  ) => Partial<ElementProps>;
  suggestion: Suggestion;
}

const SuggestionOption: React.FC<SuggestionOptionProps> = ({
  itemProps,
  suggestion,
}) => (
  <div
    className={classNames(
      'bg-transparent cursor-default block w-full select-none box-border py-3 pr-2 flex items-center',
      {
        'bg-bright-blue-200': suggestion.active,
      }
    )}
    {...itemProps(suggestion, {})}
  >
    <div className="px-3">
      <Icon name="ui/map-pin" size="20" color="gray-300" />
    </div>
    <div>
      <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
      <small className="text-muted">
        {suggestion.formattedSuggestion.secondaryText}
      </small>
    </div>
  </div>
);

export default SuggestionOption;

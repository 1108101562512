import { range } from 'lodash';

import { getRotaryYear } from '@utils/datetime';

import tailwind from '@styles/tailwind.js';

// Colors from the past years to the current
export const YEAR_COLORS = [
  tailwind.theme.colors.green['300'],
  tailwind.theme.colors.red['300'],
  tailwind.theme.colors.gold['600'],
  tailwind.theme.colors['bright-blue']['500'],
  tailwind.theme.colors['dark-blue']['400'],
];

export const getYearLabel = (year: number) => `${year - 1}-${year}`;

export default (lastYear?: number, years?: Array<number | undefined>) => {
  // Current Year is always the 1st element
  const currentYear = years?.[0] || Number(getRotaryYear());
  const year = lastYear || 4;

  // Years from the past to the current
  return range(currentYear - year, currentYear + 1).map(
    year => `${year - 1}-${year}`
  );
};

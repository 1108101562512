import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { TFunction } from 'i18next';
import { curry, some } from 'lodash';

import { getClubTypeName } from './getClubTypeName';

import { ROTARACT_ADVISOR } from '@domain/clubs';
import {
  CLUB_EXECUTIVE_SECRETARY,
  DISTRICT_EXECUTIVE_SECRETARY,
  MULTI_DISTRICT_EXECUTIVE_SECRETARY,
  SPECIAL_REPRESENTATIVE,
} from '@domain/districts';

import {
  Affiliation,
  ClubAffiliation,
  ClubLeadership,
  LeadershipAffiliation,
  MembershipAffiliation,
} from '@typings/operations';

const isLeadershipAffiliation = (
  affiliation: Affiliation
): affiliation is LeadershipAffiliation =>
  (affiliation as LeadershipAffiliation).leadershipRole !== undefined;

const isMembershipAffiliation = (
  affiliation: Affiliation
): affiliation is MembershipAffiliation =>
  (affiliation as MembershipAffiliation).membershipType !== undefined;

const getLeadershipAffiliations = curry(
  (role: string, affiliations: Affiliation[]): LeadershipAffiliation[] =>
    pipe(
      affiliations,
      A.filter(isLeadershipAffiliation),
      A.filter(({ leadershipRole }) => leadershipRole === role)
    )
);

const areLeadershipAffiliations = (
  affiliations: Affiliation[],
  role: string
): boolean => pipe(affiliations, getLeadershipAffiliations(role), some);

const areMembershipAffiliations = (affiliations: Affiliation[]): boolean =>
  pipe(affiliations, A.filter(isMembershipAffiliation), some);

export const getUserClubs = (
  clubAffiliations: ClubAffiliation[],
  isDistrictView?: boolean
): ClubAffiliation[] =>
  pipe(
    clubAffiliations,
    A.filter(({ affiliations }) => {
      let areSpecRepClubs = false;
      let areCesUserClubs = false;
      let areDESClubs = false;

      areCesUserClubs = pipe(
        affiliations,
        getLeadershipAffiliations(CLUB_EXECUTIVE_SECRETARY),
        A.head,
        O.isSome
      );

      const areMemberships = areMembershipAffiliations(affiliations);

      const areRotaractAdvisorClubs = areLeadershipAffiliations(
        affiliations,
        ROTARACT_ADVISOR
      );

      // Get clubs associated with Multi District Executive Secretary
      // for both /my-districts page and /my-clubs page.
      const areMemberOrMultiDESUserClubs = areLeadershipAffiliations(
        affiliations,
        MULTI_DISTRICT_EXECUTIVE_SECRETARY
      );

      // Get clubs associated with for Special Representative.
      // Only for districts menu items and /my-districts page.
      // Not for clubs menu items and /my-clubs page
      if (isDistrictView) {
        areSpecRepClubs = areLeadershipAffiliations(
          affiliations,
          SPECIAL_REPRESENTATIVE
        );

        areDESClubs = areLeadershipAffiliations(
          affiliations,
          DISTRICT_EXECUTIVE_SECRETARY
        );
      }

      return (
        areCesUserClubs ||
        areMemberOrMultiDESUserClubs ||
        areSpecRepClubs ||
        areMemberships ||
        areRotaractAdvisorClubs ||
        areDESClubs
      );
    })
  );

export const getClubLeadership = (
  clubs: ClubLeadership[],
  t: TFunction
): ClubLeadership[] =>
  clubs.map(club => ({
    ...club,
    formattedClubType: getClubTypeName(t, club.clubType),
  }));

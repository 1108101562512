import { QueryResult, useQuery } from '@apollo/client';

import { ClubSearchFormValues } from '@domain/clubs';

import {
  getMeaningfulFilters,
  isLocationFilterComplete,
} from '@use-cases/clubs';

import { fetchClubSearch } from '@repositories/clubs';

import { SearchClubQuery, SearchClubQueryVariables } from '@typings/operations';

export type SearchClubQueryResult = Pick<
  QueryResult<SearchClubQuery>,
  'data' | 'error' | 'loading'
>;

export const useClubSearch = (
  params: ClubSearchFormValues,
  page: number
): SearchClubQueryResult => {
  const meaningfulFilters = getMeaningfulFilters(params);

  return useQuery<SearchClubQuery, SearchClubQueryVariables>(fetchClubSearch, {
    variables: {
      page,
      ...(isLocationFilterComplete(params)
        ? {
            ...meaningfulFilters,
            meetingLocationLatitude: params.meetingLocationLatitude,
            meetingLocationLongitude: params.meetingLocationLongitude,
            meetingLocationRange: params.meetingLocationRange,
          }
        : meaningfulFilters),
    },
    skip: Object.values(meaningfulFilters).length === 0,
  });
};

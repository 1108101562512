import { gql } from '@apollo/client';

export const fetchClubVendorsQuery = gql`
  query fetchVendors($clubId: String!) {
    clubById(clubId: $clubId) {
      vendors {
        vendorName
        startDate
        isPreferred
        vendorKey
        organizationType
        requestors {
          get
          edit
          organizationName
          organizationType
          key
        }
      }
      operationsAccess {
        club
      }
    }
  }
`;

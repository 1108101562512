import { useQuery } from '@apollo/client';

import { fetchSitecoreReportQuery } from '../queries/fetchSitecoreReports';

import {
  GetSitecoreReportQuery,
  GetSitecoreReportQueryVariables,
} from '@typings/operations';
import { SitecoreReportTypeEnum } from '@typings/sitecore';

export const useFetchSiteCoreReport = (reportType: SitecoreReportTypeEnum) =>
  useQuery<GetSitecoreReportQuery, GetSitecoreReportQueryVariables>(
    fetchSitecoreReportQuery,
    {
      variables: { reportType },
    }
  );

import { useMutation } from '@apollo/client';

import { changePasswordQuery } from '../queries';

import {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from '@typings/operations';

export const useChangePassword = () => {
  return useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    changePasswordQuery
  );
};

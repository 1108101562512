import { MemberURLParams } from '@domain/districts';

export const getMemberFromURL = (
  URLParamsObj: MemberURLParams,
  paramsToExclude: string[]
) =>
  Object.keys(URLParamsObj)
    .filter(key => paramsToExclude.indexOf(key) === -1)
    .reduce((result: any, key: string) => {
      result[key] = URLParamsObj[key as keyof typeof URLParamsObj];
      return result;
    }, {});

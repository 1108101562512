import { getTermByYear } from '@use-cases/districts';

export const getFutureTermByTermYears = (
  currentRY: number,
  termYears: number
) => {
  if (termYears < 2) {
    return getTermByYear(currentRY + 1);
  }

  return {
    startDate: String(currentRY),
    endDate: String(currentRY + termYears),
  };
};

export const getCurrentTermByTermYears = (
  currentRY: number,
  termYears: number
) => {
  if (termYears < 2) {
    return getTermByYear(currentRY);
  }

  const currentStartYear = currentRY - 1;
  return {
    startDate: String(currentStartYear),
    endDate: String(currentStartYear + termYears || 0),
  };
};

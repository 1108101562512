import { gql } from '@apollo/client';

export const fetchConferencesQuery = gql`
  query getConferences(
    $districtIds: [String!]!
    $presRepresentativeId: String
    $maxStartDate: String
    $minStartDate: String
    $isExtraData: Boolean
    $skipFetchingRiDistrictId: Boolean
  ) {
    getConferences(
      districtIds: $districtIds
      presRepresentativeId: $presRepresentativeId
      maxStartDate: $maxStartDate
      minStartDate: $minStartDate
      isExtraData: $isExtraData
      skipFetchingRiDistrictId: $skipFetchingRiDistrictId
    ) {
      id
      districtId
      relatedRotaryYear
      venueType
      startDate
      endDate
      presidentialRepresentative {
        id
        firstName
        middleName
        lastName
      }
      participatingDistricts {
        id
        riDistrictId
      }
      conferenceAddress {
        lineOne
        lineTwo
        lineThree
        country
        city
        state
        postalCode
        internationalProvince
      }
      createdByRiDistrictId
      feedbacks {
        id
        type
      }
    }
  }
`;

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import moment from 'moment';

const dataLayerName = 'rotaryDDO';
const routeChangeEventName = 'gatsbyRouteChange';

export const useAnalytics = () => {
  const dataLayerExists = () => {
    return (
      typeof window !== 'undefined' &&
      typeof window[dataLayerName] !== 'undefined' &&
      typeof window._satellite !== 'undefined'
    );
  };
  const resetAnalyticsUser = () => {
    if (!dataLayerExists()) {
      return;
    }
    // Anonymous
    window.__gatsby_user = '';
    window[dataLayerName].userData = { loginStatus: 'not logged in' };
  };

  const updateAnalyticsUser = (currentUser: any) => {
    if (!dataLayerExists()) {
      return;
    }
    // Assign current user data to global, initially used by analytics.
    window.__gatsby_user = currentUser;
    window[dataLayerName].userData.loginStatus = 'logged in';
    window[dataLayerName].userData.userProfileID =
      window.__gatsby_user.nfKey ?? null;
    window[dataLayerName].userData.userAge = currentUser.age;
  };

  const updateAnalyticsPageData = (pageContext: any) => {
    if (!dataLayerExists()) {
      return;
    }
    window.rotaryDDO.excludePageView =
      pageContext.excludeFromAnalytics ?? false;
  };

  const addRuntimeData = () => {
    window[dataLayerName].siteData.date = moment().format('YYYY-MM-DD');
    window[dataLayerName].pageData.pageUrl = window.location.href;
  };

  const updateAnalyticsMetadata = (props: any) => {
    // If we have the default data layer loaded.
    addRuntimeData();
    if (typeof window.rotaryDDO !== 'undefined') {
      const splitTitle = props.title.split('|');
      // Convert page title {{pageName}} | My Rotary to My Rotary - {{pageName}} for analytics.
      window.rotaryDDO.pageData.pageName = splitTitle
        .reverse()
        .join(' - ')
        .trim();
      window.rotaryDDO.pageData.title = props.title;
      window.rotaryDDO.pageData.pageID = splitTitle[1]
        .trim()
        .replace(/ /g, '-')
        .toLowerCase();

      // User-related analytics data that's retrieved asynchronously.
      if (
        typeof window.__gatsby_user !== 'undefined' &&
        window.__gatsby_user !== ''
      ) {
        window.rotaryDDO.userData.loginStatus = 'logged in';
        window.rotaryDDO.userData.userProfileID =
          window.__gatsby_user.nfKey ?? 'n/a';
      } else if (
        typeof window.__gatsby_user !== 'undefined' &&
        window.__gatsby_user === ''
      ) {
        // Anon
        resetAnalyticsUser();
      }
    }
  };

  const fireTrackingEvent = (eventName?: string, payload?: any) => {
    eventName =
      typeof eventName === 'undefined' ? routeChangeEventName : eventName;
    payload = typeof payload === 'undefined' ? {} : payload;
    if (!dataLayerExists()) {
      return;
    }
    // Don't try and track if we don't have the Launch plugin or we are have a pageview exclusion.
    if (
      typeof window._satellite !== 'undefined' &&
      !(
        eventName === routeChangeEventName &&
        window[dataLayerName].excludePageView === true
      )
    ) {
      Object.keys(payload).forEach(key => {
        // Property-specific formatting
        switch (key) {
          case 'searchFilters':
            // @TODO confirm with Rotary what format these need to be in.
            payload[key] = JSON.stringify(payload[key]);
            break;
          default:
            break;
        }
      });

      // Promisify so that we can use await before any redirects (eg on sign in).
      return new Promise(resolve => {
        // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
        // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
        setTimeout(() => {
          // Dispatch route change event to target in a rule.
          window._satellite.track(eventName, payload);
          resolve(null);
        }, 32);
      });
    }
  };

  return {
    fireTrackingEvent,
    updateAnalyticsUser,
    resetAnalyticsUser,
    updateAnalyticsMetadata,
    updateAnalyticsPageData,
  };
};

import { useMutation } from '@apollo/client';
import {
  DeleteConferenceMutation,
  DeleteConferenceMutationVariables,
} from '@typings/operations';
import { deleteConferenceQuery } from '../queries';

export const useDeleteConference = () =>
  useMutation<DeleteConferenceMutation, DeleteConferenceMutationVariables>(
    deleteConferenceQuery,
    { refetchQueries: ['getConferences'] }
  );

// Libs
import * as R from 'ramda';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';
import { find } from 'lodash';

// Types
import { CreateConferenceFormResult } from '@domain/districts/types';
import {
  InputCreateConference,
  Country,
  TimeZone,
  PhoneType,
} from '@typings/graphql';
import { MeetingType } from '@typings/resolvers';

// Utils
import { getFormattedDate } from '@utils/datetime';

// Constants
import { EMPTY_CONFERENCE_ADDRESS } from '@domain/districts/conferences/constants';

const getCountryNameByCode = (
  countryCode: string | null,
  countries: Country[]
) =>
  pipe(
    countries,
    A.filter(({ id }) => id === countryCode),
    A.head,
    O.mapNullable(R.prop('name')),
    O.getOrElse(() => '')
  );

export const mapFormResultToCreateConferenceInput = (
  {
    districtId,
    venueType,
    dateTime: { startDate, startTime, endDate, endTime, timeZone },
    venueName,
    conferenceAddress: {
      city,
      country: conferenceCountryCode,
      lineOne,
      lineTwo,
      lineThree,
      postalCode,
      internationalProvince,
      state,
    },
    language,
    comments,
    participatingDistricts,
    onlineLocation,
    detailForJoiningRemotely,
    isPresRepRequested,
    prAddress: {
      city: prCity,
      country: prCountry,
      internationalProvince: prInternationalProvince,
      lineOne: prLineOne,
      lineThree: prLineThree,
      lineTwo: prLineTwo,
      postalCode: prPostalCode,
      state: prState,
    },
    accomodationPhone: { number, countryId },
    prAccommodation,
  }: CreateConferenceFormResult,
  formattedCountries: Country[],
  timeZones?: TimeZone[],
  originalTimeZone?: string
): InputCreateConference => {
  const isOnline = venueType === MeetingType.Online;
  const isPhysical = venueType === MeetingType.Physical;
  const conferenceCountryValue = getCountryNameByCode(
    conferenceCountryCode || null,
    formattedCountries
  );
  const presRepCountryValue = getCountryNameByCode(
    prCountry || '',
    formattedCountries
  );

  // If user deletes startTime & endTime and changes timeZone value
  // We should save the same value that was before editing
  let tz = timeZone;
  if (originalTimeZone && !(startTime && endTime)) {
    tz = originalTimeZone;
  }

  const timeZoneValue =
    find(timeZones, ({ id, value }) => id === tz || value === tz)
      ?.timeZoneName || null;

  return {
    districtId,
    venueType,
    startDate: startDate ? getFormattedDate(startDate) : '',
    startTime,
    endDate: endDate ? getFormattedDate(endDate) : '',
    endTime,
    timeZone: timeZoneValue,
    venueName: !isOnline ? venueName : '',
    language,
    comments,
    conferenceAddress: !isOnline
      ? {
          city,
          country: conferenceCountryValue,
          lineOne,
          lineTwo,
          lineThree,
          postalCode,
          internationalProvince,
          state,
        }
      : EMPTY_CONFERENCE_ADDRESS,
    prAddress:
      !isOnline && isPresRepRequested
        ? {
            city: prCity,
            country: presRepCountryValue,
            lineOne: prLineOne,
            lineTwo: prLineTwo,
            lineThree: prLineThree,
            postalCode: prPostalCode,
            internationalProvince: prInternationalProvince,
            state: prState,
          }
        : null,
    isPresRepRequested,
    presRepAccPhone:
      !isOnline && isPresRepRequested && number
        ? {
            number: number,
            type: PhoneType.Mobile,
            countryId: countryId || '',
          }
        : null,
    prAccommodation: isPresRepRequested && !isOnline ? prAccommodation : '',
    participatingDistricts: participatingDistricts?.map(({ id, value }) => ({
      id,
      riDistrictId: value,
    })),
    // Used for Online & Hybrid type
    ...(isPhysical
      ? {
          onlineLocation: '',
          detailForJoiningRemotely: '',
        }
      : { onlineLocation, detailForJoiningRemotely }),
  };
};

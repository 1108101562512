// Libs
import { gql, useLazyQuery } from '@apollo/client';

// Types
import {
  GetAllDistrictAGsByDateQuery,
  GetAllDistrictAGsByDateQueryVariables,
} from '@typings/operations';

export const fetchAllDistrictAGsByDate = gql`
  query getAllDistrictAGsByDate($districtId: String!, $date: String) {
    result: getAllDistrictAGsByDate(districtId: $districtId, date: $date) {
      totalCount
      ags {
        agId
        firstName
        lastName
        middleName
        prefix
        suffix
        localizedName
        clubsAffiliations {
          id
          clubId
          clubName
          clubType
          startDate
          location {
            country
            city
            internationalProvince
            state
          }
        }
      }
    }
  }
`;

export const useFetchAllDistrictAGsByDate = () =>
  useLazyQuery<
    GetAllDistrictAGsByDateQuery,
    GetAllDistrictAGsByDateQueryVariables
  >(fetchAllDistrictAGsByDate);

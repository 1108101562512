import { gql } from '@apollo/client';

export const updateClubMeetingsQuery = gql`
  mutation updateClubMeetings(
    $clubId: String!
    $dominoId: String
    $meetings: [MeetingInput!]!
  ) {
    updateClub(
      input: { clubId: $clubId, dominoId: $dominoId, meetings: $meetings }
    ) {
      clubId
    }
  }
`;

import { useLazyQuery } from '@apollo/client';

import { fetchDistrictsForLeadershipQuery } from '@repositories/districts';

import {
  GetDistrictsForLeadershipQuery,
  GetDistrictsForLeadershipQueryVariables,
} from '@typings/operations';

export const useFetchDistrictsForLeadership = () =>
  useLazyQuery<
    GetDistrictsForLeadershipQuery,
    GetDistrictsForLeadershipQueryVariables
  >(fetchDistrictsForLeadershipQuery);

import { useMutation } from '@apollo/client';

import { createIndividualFromSessionQuery } from '../queries';

import {
  CreateIndividualFromSessionMutation,
  CreateIndividualFromSessionMutationVariables,
} from '@typings/operations';

export const useCreateIndividualFromSession = () =>
  useMutation<
    CreateIndividualFromSessionMutation,
    CreateIndividualFromSessionMutationVariables
  >(createIndividualFromSessionQuery);

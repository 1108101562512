// Types
import { GetAccessLevelsQuery } from '@typings/operations';
import { AccessLevel } from '@typings/graphql';

export const mapConferencesFeedbackAccessLevels = (
  accessLevelsData?: GetAccessLevelsQuery
) =>
  accessLevelsData?.results.reduce(
    (acc: Record<string, AccessLevel>, { target, level }) => {
      const conferenceId = target.match(/conferences\/(.+)\/feedback/)?.[1];
      if (conferenceId) acc[conferenceId] = level;
      return acc;
    },
    {}
  );

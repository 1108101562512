import { Lang } from '@domain/language-negotiation';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { pipe } from 'fp-ts/lib/function';
import * as T from 'fp-ts/lib/Task';
import * as E from 'fp-ts/lib/Either';
import { User } from '@domain/auth';
import {
  negotiateLangByIndividualId,
  getBrowserPreferredLanguage,
} from '@repositories/language-negotiation/pipelines';

// getBrowserOrPreferredLang :: (pageContextLanguage: Lang, client: ApolloClient<InMemoryCache>, { isLoggedIn, individualId }: Partial<User>) -> Lang
export const getBrowserOrPreferredLang = (
  pageContextLanguage: Lang,
  client: ApolloClient<InMemoryCache>,
  { isLoggedIn, individualId }: Partial<User>
) =>
  pipe(
    T.of(pageContextLanguage || getBrowserPreferredLanguage(navigator)()),
    E.fromPredicate(
      () => !isLoggedIn || !individualId,
      () => negotiateLangByIndividualId(client, individualId || '')
    ),
    E.getOrElse((a: T.Task<Lang>) => a)
  );

import moment from 'moment';

import { ActiveMembership, MembershipForAddMember } from '@typings/operations';

export const getActiveMemberships = <
  T extends ActiveMembership | MembershipForAddMember
>(
  activeMemberships: T[]
) =>
  activeMemberships.filter(
    ({ terminationDate }) =>
      !terminationDate ||
      moment(terminationDate).isSameOrAfter(new Date(), 'day')
  );

// Libs
import moment from 'moment';

export const activeRangeToCurrentYear = (
  startDate: string,
  endDate: string
): string | null => {
  const yearFormat = 'YYYY';
  const currentYear = moment().format(yearFormat);
  if (!startDate && !endDate) {
    return null;
  }

  const startYear = moment(startDate).format(yearFormat);
  if (!endDate && startDate) {
    return `${startYear} - ${currentYear}`;
  }

  const endYear = moment(endDate).format(yearFormat);
  return `${startYear} - ${endYear}`;
};

import { useMutation } from '@apollo/client';

import { deleteClubMeetingQuery } from '@repositories/clubs';

import {
  DeleteClubMeetingMutation,
  DeleteClubMeetingMutationVariables,
} from '@typings/operations';

export const useDeleteClubMeeting = () =>
  useMutation<DeleteClubMeetingMutation, DeleteClubMeetingMutationVariables>(
    deleteClubMeetingQuery,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        'fetchClubDetails',
        'fetchAddMeeting',
        'fetchEditMeeting',
      ],
    }
  );

import React from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const FullWidthSublayout = (props) => (
  <>
    <Placeholder name="rotary-fullwidthsublayout" rendering={props.rendering} />
  </>
);

export default withSitecoreContext()(FullWidthSublayout);

import { useQuery } from '@apollo/client';

import { fetchMembershipInfoQuery } from '@repositories/clubs';

import {
  MembershipInfoQuery,
  MembershipInfoQueryVariables,
} from '@typings/operations';

export const useFetchMembershipInfo = (clubId: string, membershipId: string) =>
  useQuery<MembershipInfoQuery, MembershipInfoQueryVariables>(
    fetchMembershipInfoQuery,
    {
      variables: {
        clubId,
        membershipId,
      },
    }
  );

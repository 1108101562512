import moment from 'moment';

import { getActiveMembershipsByClubType } from '@use-cases/clubs';

import { AddressType, Gender } from '@typings/operations';

import { ClubMembersList, MemberDetailsInput } from '../types';

export default (
  individual?: ClubMembersList,
  filtersFirstName?: string,
  filtersLastName?: string,
  filtersEmail?: string,
  isRotaractClub?: boolean
): MemberDetailsInput => {
  const { individual: selectedIndividual, membershipList } = individual || {};
  return {
    name: selectedIndividual?.name || '',
    firstName: selectedIndividual?.firstName || filtersFirstName || '',
    lastName: selectedIndividual?.lastName || filtersLastName || '',
    middleName: selectedIndividual?.middleName || '',
    localizedName:
      selectedIndividual?.localizedName || selectedIndividual?.name || '',
    email: selectedIndividual?.email || filtersEmail || '',
    prefix: selectedIndividual?.prefix || '',
    suffix: selectedIndividual?.suffix || '',
    primaryLanguage: selectedIndividual?.primaryLanguage || '',
    gender: selectedIndividual?.gender || Gender.NotGiven,
    yearOfBirth: selectedIndividual?.yearOfBirth
      ? String(selectedIndividual.yearOfBirth)
      : '',
    dateOfBirth: selectedIndividual?.dateOfBirth || null,
    primaryPhone: {
      number: selectedIndividual?.primaryPhone?.number || '',
      countryId: selectedIndividual?.primaryPhone?.countryId || '',
      country: selectedIndividual?.primaryPhone?.country || '',
    },
    primaryAddress: {
      lineOne: selectedIndividual?.primaryAddress?.lineOne || '',
      lineTwo: selectedIndividual?.primaryAddress?.lineTwo || '',
      lineThree: selectedIndividual?.primaryAddress?.lineThree || '',
      countryId: selectedIndividual?.primaryAddress?.countryId || '',
      city: selectedIndividual?.primaryAddress?.city || '',
      type: selectedIndividual?.primaryAddress?.type || AddressType.Home,
      postalCode: selectedIndividual?.primaryAddress?.postalCode || '',
      state: selectedIndividual?.primaryAddress?.state || '',
      stateId: selectedIndividual?.primaryAddress?.stateId || '',
      hasStates: false,
    },
    hasActiveMembership: membershipList
      ? getActiveMembershipsByClubType(membershipList, isRotaractClub).length >
        0
      : false,
    recentMemberships:
      membershipList?.filter(membersip =>
        moment(membersip.terminationDate).isSameOrAfter(
          moment().subtract(30, 'd')
        )
      ) || [],
  };
};

import { gql } from '@apollo/client';

export const updateProfileInformationMutation = gql`
  mutation updateProfileInformation(
    $id: String!
    $firstName: String!
    $lastName: String!
    $primaryEmail: EmailInput!
    $otherEmails: [EmailInput!]
    $primaryAddress: AddressInput
    $otherAddresses: [AddressInput!]
    $primaryPhone: IndividualPhoneInput
    $otherPhones: [IndividualPhoneInput!]
    $sharingPermissionsExtended: SharingPermissionsInput
  ) {
    updateIndividual(
      input: {
        individualId: $id
        firstName: $firstName
        lastName: $lastName
        primaryEmail: $primaryEmail
        otherEmails: $otherEmails
        primaryAddress: $primaryAddress
        otherAddresses: $otherAddresses
        primaryPhone: $primaryPhone
        otherPhones: $otherPhones
        sharingPermissionsExtended: $sharingPermissionsExtended
      }
    ) {
      individualId
    }
  }
`;

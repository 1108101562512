import { gql } from '@apollo/client';

export const searchDESByIndividualIdQuery = gql`
  query searchDESByIndividualId($individualId: String!) {
    searchDESByIndividualId(individualId: $individualId) {
      affiliationInfo {
        clubs {
          clubId
          clubName
          clubType
          meetings {
            weekday
            time
          }
        }
        districts {
          riDistrictId
          districtId
          zone
        }
      }
    }
  }
`;

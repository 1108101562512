import React from 'react';

import { Button } from '@components/Button';

import { useTranslation } from '@external/react-i18next';

type Props = {
  clickHandler?: () => void;
  isButtonShown?: boolean;
  customDescription?: string;
};

const LocationDescription: React.FC<Props> = ({
  clickHandler,
  isButtonShown,
  customDescription,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-baseline">
      <span className="flex-auto text-xs text-gray-500 mb-4 mt-3 ml-px">
        {customDescription ||
          t(
            'club-search.form.meetingLocation.description',
            'Example: “Tokyo, Japan”'
          )}
      </span>
      {isButtonShown && clickHandler && (
        <Button
          textSearch
          capitalize
          className="mt-3 flex-auto text-right"
          clickHandler={clickHandler}
          type="button"
        >
          {t('search.clubs.use-current-location', 'Use Current Location')}
        </Button>
      )}
    </div>
  );
};

export default LocationDescription;

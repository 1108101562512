// Libs
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';
import { replace, includes, get } from 'lodash/fp';

import {
  AccessLevelsArguments,
  GetAccessLevelsQuery,
  GetAccessLevelsResults,
} from '@typings/operations';
import { ConferencesAccessLvl } from '../types';

const CONFERENCES_URL = '/conferences/';

const isConferenceTarget = (item: GetAccessLevelsResults) =>
  pipe(item, get('target'), includes(CONFERENCES_URL));

const getTargetTermYear = (args?: AccessLevelsArguments | null) =>
  pipe(
    O.fromNullable(args?.targetTermYear),
    O.getOrElse(() => '')
  );

export const mapConferencesAccessLevel = (
  accessLevelsData?: GetAccessLevelsQuery
): ConferencesAccessLvl[] | null => {
  return pipe(
    O.fromNullable(accessLevelsData?.results),
    O.map(item => item || []),
    O.map(arr =>
      pipe(
        arr,
        A.filter(isConferenceTarget),
        A.map(({ target, level, arguments: args }) => ({
          id: replace(CONFERENCES_URL, '', target),
          level,
          targetTermYear: getTargetTermYear(args),
        }))
      )
    ),
    O.toNullable
  );
};

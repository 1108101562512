import { useQuery } from '@apollo/client';

import { fetchClubDetailsQuery } from '@repositories/clubs';

import {
  FetchClubDetailsQuery,
  FetchClubDetailsQueryVariables,
} from '@typings/operations';

export const useFetchClubDetails = (
  clubId: string,
  termStartYear?: number,
  dominoId?: string
) =>
  useQuery<FetchClubDetailsQuery, FetchClubDetailsQueryVariables>(
    fetchClubDetailsQuery,
    {
      variables: { clubId, termStartYear, dominoId },
      fetchPolicy: 'cache-and-network',
    }
  );

import { getYearsRange } from '@use-cases/districts';

import { getRotaryYear } from '@utils/datetime';

// Types
import { OptionTypeBase } from '@external/react-select';

export default ({
  useRYEndForValue = true,
}: Record<string, boolean> = {}): OptionTypeBase[] => {
  const currentYear = Number(getRotaryYear());
  const yearsCount = 7;

  return getYearsRange(currentYear, yearsCount).map((year: number) => ({
    value: String(useRYEndForValue ? year + 1 : year),
    label: `${year}-${year + 1}`,
  }));
};

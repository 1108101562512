import { useMutation } from '@apollo/client';
import {
  UpdateProfessionalExperienceMutation,
  UpdateProfessionalExperienceMutationVariables,
} from '@typings/operations';
import { updateProfessionalExperienceMutation } from '../queries';

export const useUpdateProfessionalExperience = () => {
  return useMutation<
    UpdateProfessionalExperienceMutation,
    UpdateProfessionalExperienceMutationVariables
  >(updateProfessionalExperienceMutation);
};

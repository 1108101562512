// Libs
import { sortBy, zip } from 'lodash';

const fieldsToCompareBy = ['id', 'startDate', 'endDate'] as const;

type ComparableDO = Record<typeof fieldsToCompareBy[number], string>;

const compareDOs = <T extends ComparableDO>(obj?: T, other?: T): boolean => {
  if (!obj || !other) return false;

  return fieldsToCompareBy
    .map(field => [obj[field], other[field]])
    .every(([left, right]) => left === right);
};

export const areDOsArraysEqual = <T extends ComparableDO>(
  leftArray: T[],
  rightArray: T[]
): boolean =>
  zip(
    sortBy(leftArray, fieldsToCompareBy),
    sortBy(rightArray, fieldsToCompareBy)
  ).every(([left, right]) => compareDOs(left, right));

import { gql } from '@apollo/client';

export const updateAGClubAffiliationsQuery = gql`
  mutation updateAgClubAffiliations(
    $districtId: String!
    $individualId: String!
    $newIndividualId: String!
    $endDate: String
    $startDate: String
  ) {
    updateAgClubAffiliations(
      districtId: $districtId
      individualId: $individualId
      newIndividualId: $newIndividualId
      endDate: $endDate
      startDate: $startDate
    ) {
      id
      error
    }
  }
`;

import React, { useContext, useState } from 'react';

import { useField } from 'formik';
import { max, min } from 'lodash';
import { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import Element, { ElementProps } from '@components/Formik/Element';
import MapsContext from '@components/Google/GoogleMapsJsApiContext';
import PlacesAutocomplete from '@components/Google/PlacesAutocomplete';

import LocationDescription from './LocationDescription';
import LocationInput from './LocationInput';
import LocationReset from './LocationReset';
import LocationSuggestions from './LocationSuggestions';

import { EarthCoordinates, earthDistanceKm } from '@utils/geography';
import { Rejecter, Resolver } from '@utils/promise/types';

import { useLogger } from '@hooks/logger';

import { LogLevel } from '@typings/operations';

export type GeocoderResult = google.maps.GeocoderResult;
type GeocoderStatus = google.maps.GeocoderStatus;
type LatLngBounds = google.maps.LatLngBounds;

export interface GeoCoordinatePair {
  lat: number;
  lng: number;
}

interface ErrorWithIrritant extends Error {
  irritant?: string | number | null;
}

export const placeTypePreferences = [
  'locality',
  'administrative_area_level_5',
  'administrative_area_level_4',
  'administrative_area_level_3',
  'administrative_area_level_2',
  'administrative_area_level_1',
  'country',
];

const componentClass = 'club-location-select';

interface ClubLocationSelectProps extends ElementProps {
  name: string;
  disabled?: boolean;
  placeholder?: string;
  resettable?: boolean;
}

type LineSegment = [EarthCoordinates, EarthCoordinates];

const getPlaceBounds = (place: GeocoderResult): LatLngBounds =>
  place.geometry.bounds ||
  place.geometry.viewport ||
  new google.maps.LatLngBounds(
    place.geometry.location,
    place.geometry.location
  );
const getEdges = (bounds: LatLngBounds): LineSegment[] => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const north = ne.lat();
  const east = ne.lng();
  const south = sw.lat();
  const west = sw.lng();
  return [
    [
      { latitude: north, longitude: east },
      { latitude: north, longitude: west },
    ],
    [
      { latitude: north, longitude: west },
      { latitude: south, longitude: west },
    ],
    [
      { latitude: south, longitude: west },
      { latitude: south, longitude: east },
    ],
    [
      { latitude: south, longitude: east },
      { latitude: north, longitude: east },
    ],
  ];
};
const getCenterlines = (bounds: LatLngBounds): LineSegment[] => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const north = ne.lat();
  const east = ne.lng();
  const south = sw.lat();
  const west = sw.lng();
  return [
    [
      { latitude: (north + south) / 2, longitude: east },
      { latitude: (north + south) / 2, longitude: west },
    ],
    [
      { latitude: north, longitude: (east + west) / 2 },
      { latitude: south, longitude: (east + west) / 2 },
    ],
  ];
};
export const getInnerRadiusKmFromBounds = (bounds: LatLngBounds): number =>
  min(
    getEdges(bounds).map(Function.prototype.apply.bind(earthDistanceKm, null))
  ) / 2;
export const getCrossRadiusKmFromBounds = (bounds: LatLngBounds): number =>
  max(
    getCenterlines(bounds).map(
      Function.prototype.apply.bind(earthDistanceKm, null)
    )
  );
const guaranteeNumber = (x: string | number | null): number => {
  const number = Number(x);
  if (number) {
    return number;
  }
  const error: ErrorWithIrritant = new Error(
    `Can't turn this ${typeof x} value into a number: ${x}`
  );
  error.irritant = x;
  throw error;
};

interface NarrowPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}
const handleGeocoderResults = (
  resolve: Resolver<GeocoderResult[]>,
  reject: Rejecter,
  results: GeocoderResult[],
  status: GeocoderStatus
): void => {
  if (status === google.maps.GeocoderStatus.OK) {
    resolve(results);
  } else {
    reject(status);
  }
};
const executeGetPlaces = (
  position: NarrowPosition,
  resolve: Resolver<GeocoderResult[]>,
  reject: Rejecter
): void => {
  new google.maps.Geocoder().geocode(
    {
      location: new google.maps.LatLng(
        position.coords.latitude,
        position.coords.longitude
      ),
    },
    handleGeocoderResults.bind(null, resolve, reject)
  );
};
const getPlaces = (position: NarrowPosition) => {
  return new Promise(executeGetPlaces.bind(null, position));
};
const hasType = (type: string, place: GeocoderResult): boolean =>
  place.types.includes(type);
const choosePlace = (
  typePreferences: string[],
  places: GeocoderResult[] | undefined
): GeocoderResult => {
  if (places?.length === 0) {
    throw new Error('Tried to choose a GeocoderResult from an empty list.');
  }

  if (places?.length === 1) {
    return places[0];
  }

  let types = [...typePreferences];

  while (types.length > 0) {
    const matches = places?.filter(hasType.bind(null, types[0]));
    if (matches?.length) {
      return matches[0];
    }
    types = types.slice(1);
  }

  return places?.length ? places[0] : ({} as GeocoderResult);
};
const getPlace = (place: GeocoderResult): GeocoderResult => place;

export const getPlaceFromPosition = (
  position: NarrowPosition,
  typePreferences: string[] = placeTypePreferences
): Promise<GeocoderResult> =>
  getPlaces(position)
    .then(choosePlace.bind(null, typePreferences))
    .then(getPlace);

const ClubLocationSelect: React.FC<ClubLocationSelectProps> = props => {
  const { name, disabled, placeholder, resettable, label } = props;

  const mapsPromise = useContext(MapsContext);
  // Field state is separated from input state, since the input that is
  // displayed to the user isn't actually what GraphQL needs.
  const [, latitudeMeta, latitudeHelpers] = useField<number | null>(
    `${name}Latitude`
  );
  const [, longitudeMeta, longitudeHelpers] = useField<number | null>(
    `${name}Longitude`
  );
  const [, rangeMeta, rangeHelpers] = useField<number | null>(`${name}Range`);
  const [field, meta, helpers] = useField(name);
  const [pin, setPin] = useState(false);
  const [enableSuggestions, setEnableSuggestions] = useState(false);
  const { addLog } = useLogger();

  const setRangeForLocation = async (
    placePromise: Promise<google.maps.GeocoderResult>
  ) => {
    try {
      const place = await placePromise;
      const coords = await getLatLng(place);
      const bounds = getPlaceBounds(place);
      const radius = guaranteeNumber(getInnerRadiusKmFromBounds(bounds));

      latitudeHelpers.setValue(coords.lat);
      longitudeHelpers.setValue(coords.lng);
      rangeHelpers.setValue(radius);
    } catch (err) {
      addLog({
        level: LogLevel.Error,
        message: (err as Error).toString(),
      });
    }
  };

  const loadCurrentLocation = () => {
    const geo = navigator.geolocation;
    if (navigator.geolocation) {
      geo.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          latitudeHelpers.setValue(latitude);
          longitudeHelpers.setValue(longitude);
          const placePromise = mapsPromise.then(
            getPlaceFromPosition.bind(null, position, placeTypePreferences)
          );
          placePromise.then(place => helpers.setValue(place.formatted_address));
          setRangeForLocation(placePromise);
        },
        err =>
          addLog({
            level: LogLevel.Error,
            message: err.toString(),
          })
      );
    }
  };

  const setCurrentLocationCoordinates = () => {
    mapsPromise.then(loadCurrentLocation);
    setPin(true);
  };

  const clearCoordinates = (): void => {
    latitudeHelpers.setValue(null);
    longitudeHelpers.setValue(null);
  };

  const handleSelect = (value: string, placeId: string) => {
    if (value) {
      helpers.setValue(value);
      setPin(true);
    }
    const placePromise = geocodeByPlaceId(placeId).then(results => results[0]);

    setRangeForLocation(placePromise);
    setEnableSuggestions(true);
  };

  const handleClick = () => {
    helpers.setValue('');
    clearCoordinates();
    setPin(false);
    setEnableSuggestions(true);
  };

  const handleChange = (value: string): void => {
    if (!value) {
      clearCoordinates();
      setPin(false);
    }
    helpers.setValue(value);
    setEnableSuggestions(true);
  };

  return (
    <Element {...props}>
      {typeof window !== 'undefined' && (
        <PlacesAutocomplete
          value={field.value || ''}
          onChange={handleChange}
          onSelect={handleSelect}
          googleCallbackName="initPlacesAutocompleteForClubLocationSelect"
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <>
              <LocationInput
                name={name}
                placeholder={placeholder}
                pin={pin}
                isDisabled={disabled}
                isResettable={resettable}
                defaultClassNames={componentClass}
                defaultInputProps={getInputProps({ disabled })}
                metaFields={[meta, latitudeMeta, longitudeMeta, rangeMeta]}
              />
              {navigator.geolocation && (
                <LocationDescription
                  clickHandler={setCurrentLocationCoordinates}
                  isButtonShown
                />
              )}

              {enableSuggestions && (
                <LocationSuggestions
                  suggestions={suggestions}
                  getSuggestionItemProps={getSuggestionItemProps}
                />
              )}
            </>
          )}
        </PlacesAutocomplete>
      )}
      {resettable && field.value && (
        <LocationReset handleClick={handleClick} label={label} />
      )}
    </Element>
  );
};

export default ClubLocationSelect;

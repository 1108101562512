import React from 'react';

import { Placeholder, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import componentFactory from 'src/sitecore/ComponentFactory';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const SitecoreFooter: React.FC = () => {
  const { sitecoreContent } = useAppConfig();
  const { t } = useTranslation();
  const sitecoreContext = sitecoreContent?.sitecore?.context;

  if (sitecoreContext) {
    sitecoreContext.route = sitecoreContent?.sitecore?.route;
    sitecoreContext.itemId = sitecoreContent?.sitecore?.route?.itemId;
  }

  if (sitecoreContent?.error) {
    return <>{t('sitecore.footer.unavailable', 'Footer Unavailable')}</>;
  }

  return sitecoreContent?.sitecore?.context &&
    sitecoreContent?.sitecore?.route ? (
    <SitecoreContext
      componentFactory={componentFactory}
      context={sitecoreContext}
    >
      <Placeholder
        name="rotary-footer"
        rendering={sitecoreContent.sitecore.route}
      />
    </SitecoreContext>
  ) : (
    <div />
  );
};
export default SitecoreFooter;

import { gql } from '@apollo/client';

export const updateConferenceQuery = gql`
  mutation editConference(
    $conferenceId: String!
    $input: InputCreateConference!
  ) {
    editConference(conferenceId: $conferenceId, input: $input)
  }
`;

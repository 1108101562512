// Libs
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';
import { join } from 'lodash';
import { get } from 'lodash/fp';

// Types
import {
  Feedback,
  InputCreateFeedback,
  InputQuestion,
  InputQuestionResponse,
  Question,
} from '@typings/operations';
import { CreateFeedbackFormResult } from '../types';

// Utils
import { parseControlValuesFromDISFormat } from '@utils/parseControlValuesFromDISFormat';

export const formattedQuestionsResponse = (
  formResult: CreateFeedbackFormResult,
  questions?: Question[]
): InputQuestionResponse[] => {
  const inputQuestionResponses: InputQuestionResponse[] = [];
  Object.entries(formResult).forEach(([questionId, value]) => {
    // for radiobutton/textarea value
    if (typeof value === 'string' || typeof value === 'boolean') {
      inputQuestionResponses.push({ questionId, value: String(value) });
    }

    // for checkboxes values
    if (Array.isArray(value)) {
      const allCheckboxValues = pipe(
        O.fromNullable(questions),
        O.map(item => item || []),
        O.map(A.filter(({ id }) => id === questionId)),
        O.chain(A.head),
        O.map(get('optionDetail.optionValue')),
        O.map(parseControlValuesFromDISFormat),
        O.map(A.filterWithIndex(index => Boolean(value[index]))),
        O.map(join),
        O.map(str => `{${str}}`),
        O.getOrElse(() => '')
      );

      inputQuestionResponses.push({ questionId, value: allCheckboxValues });
    }
  });
  return inputQuestionResponses;
};

export const mapDataToFeedback = (
  feedbackType: string,
  questions: Question[]
): Feedback => {
  return {
    id: '',
    feedbackType,
    questions: questions,
    responses: [],
  };
};

export const getFormattedInputQuestions = (
  questions: Question[]
): InputQuestion[] =>
  pipe(
    questions,
    A.map(
      ({
        id,
        applicationId,
        applicationFeature,
        option,
        optionSeq,
        optionDataType,
        optionType: { id: optionTypeId, optionType: optionTypeValue },
        optionDetail: { id: optionDetailId, optionValue = null },
        feedbackSection: { id: feedbackSectionId, sectionName },
      }: Question) => ({
        id,
        applicationId,
        applicationFeature,
        option,
        optionSeq,
        optionDataType,
        optionType: {
          id: optionTypeId,
          optionType: optionTypeValue,
        },
        optionDetail: {
          id: optionDetailId,
          optionValue: optionValue || '',
        },
        feedbackSection: {
          id: feedbackSectionId,
          sectionName,
        },
      })
    )
  );

export const mapDataFeedbackInput = (
  feedbackType: string,
  conferenceId: string,
  formResult: CreateFeedbackFormResult,
  questions: Question[]
): InputCreateFeedback => {
  const formattedResponse = formattedQuestionsResponse(formResult, questions);
  return {
    conferenceId,
    feedbackType,
    questions: getFormattedInputQuestions(questions).filter(question =>
      formattedResponse.some(response => response.questionId === question.id)
    ),
    questionsResponse: formattedResponse,
  };
};

import {
  FetchAreasOfExpertiseQuery,
  FetchAreasOfExpertiseQueryVariables,
} from '@typings/operations';
import { useQuery, QueryResult } from '@apollo/client';
import { areasOfExpertiseQuery } from '../queries';

export type FetchAreasOfExpertiseQueryResult = Pick<
  QueryResult<FetchAreasOfExpertiseQuery>,
  'data' | 'error' | 'loading'
> & {
  refetch: () => Promise<any>;
};

export const useFetchAreaOfExpertise = (
  individualId: string
): FetchAreasOfExpertiseQueryResult => {
  return useQuery<
    FetchAreasOfExpertiseQuery,
    FetchAreasOfExpertiseQueryVariables
  >(areasOfExpertiseQuery, {
    variables: {
      individualId,
    },
  });
};

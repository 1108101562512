import { gql } from '@apollo/client';

export const fetchMembershipInfoQuery = gql`
  query MembershipInfo($clubId: String!, $membershipId: String!) {
    membership: getMembership(clubId: $clubId, membershipId: $membershipId) {
      type
      admissionDate
      club {
        riClubId
        clubType
        clubName
        physicalLocation {
          country
          city
          state
          internationalProvince
        }
      }
      individual {
        id
        name
        photoUri
      }
    }
  }
`;

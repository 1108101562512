import { useQuery } from '@apollo/client';
import {
  FetchPersonalDetailsQuery,
  FetchProfileQueryVariables,
} from '@typings/operations';
import { fetchPersonalDetailsQuery } from '../queries';

export const useFetchPersonalDetails = (individualId: string) =>
  useQuery<FetchPersonalDetailsQuery, FetchProfileQueryVariables>(
    fetchPersonalDetailsQuery,
    {
      variables: { id: individualId },
    }
  );

import { IsManager } from '@domain/districts';

import { getRotaryYear } from '@utils/datetime';

export const getTermByYear = (year: number) => ({
  startDate: `${year - 1}`,
  endDate: `${year}`,
});

export const getTermsByRoles = (
  currentRotaryYear: number,
  isManager: IsManager
) => {
  const { currentTerm, nextTerm } = isManager;
  const termForCurrentYear = getTermByYear(currentRotaryYear);
  const termForElect = getTermByYear(currentRotaryYear + 1);

  if (currentTerm && nextTerm) {
    return [termForCurrentYear, termForElect];
  }

  if (
    Number(getRotaryYear()) + 1 !== currentRotaryYear &&
    !currentTerm &&
    nextTerm
  ) {
    return [termForElect];
  }

  return [termForCurrentYear];
};

import { useMutation } from '@apollo/client';

import { updateMembershipQuery } from '@repositories/clubs';

import {
  UpdateMembershipMutation,
  UpdateMembershipMutationVariables,
} from '@typings/operations';

export const useUpdateMembership = () => {
  const [updateMembershipType, { error }] = useMutation<
    UpdateMembershipMutation,
    UpdateMembershipMutationVariables
  >(updateMembershipQuery, {
    awaitRefetchQueries: true,
    refetchQueries: ['fetchClubDetails', 'fetchAddMeeting', 'fetchEditMeeting'],
  });

  return { updateMembershipType, error };
};

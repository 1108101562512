import { gql } from '@apollo/client';

export const fetchFeedbackQuestionsQuery = gql`
  query getFeedbackQuestions($feedbackType: String!, $conferenceYear: Int!) {
    getFeedbackQuestions(
      feedbackType: $feedbackType
      conferenceYear: $conferenceYear
    ) {
      id
      sectionName
      questions {
        id
        applicationId
        applicationFeature
        option
        optionSeq
        optionDataType
        optionType {
          id
          optionType
        }
        optionDetail {
          id
          optionValue
        }
        feedbackSection {
          id
          sectionName
        }
      }
    }
  }
`;

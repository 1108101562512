import { gql } from '@apollo/client';

export const fetchUserAccountQuery = gql`
  query fetchUserAccount($individualId: String!) {
    getIndividual(id: $individualId) {
      prefix
      firstName
      middleName
      lastName
      localizedName
      suffix
      photoUri
      email
      primaryPhone {
        country
        countryId
        extension
        number
      }
      primaryAddress {
        type
        lineOne
        lineTwo
        lineThree
        country
        countryId
        city
        stateId
        state
        postalCode
        internationalProvince
      }
      clubAffiliations {
        clubId
        clubName
        clubType
        district {
          id
          riDistrictId
          isHomeDistrict
          zone
        }
        meetings {
          weekday
          time
        }
        affiliations {
          ... on LeadershipAffiliation {
            leadershipRole
          }
          ... on MembershipAffiliation {
            membershipType
          }
        }
      }
    }
  }
`;

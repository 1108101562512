import * as React from 'react';
import i18n from 'i18next';
import sanitizeHtml from 'sanitize-html';

// Limit HTML output to the tags below.
const allowedTags = [
  'a',
  'b',
  'br',
  'div',
  'em',
  'i',
  'li',
  'p',
  'strong',
  'ul',
];

const allowedAttributes = {
  b: ['class'],
  strong: ['class'],
  i: ['class'],
  em: ['class'],
  a: ['href', 'class', 'target'],
  p: ['class'],
};

/**
 * Parse the HTML before rendering.
 * @param html
 */
function parseHtml(html: string) {
  const parsedHtml = sanitizeHtml(html, {
    allowedTags: allowedTags,
    allowedAttributes: allowedAttributes,
  });
  return parsedHtml;
}

/**
 * Component to output allowed HTML in translated strings,
 * uses dangerouslySetInnerHTML as React escapes HTML tags.
 * @param tkey
 * @param options
 * @constructor
 */
export const TransHtml: React.FC<{
  tkey: string;
  options: any;
}> = ({ tkey, options }) => {
  // @TODO extend i18n type.
  // @ts-ignore
  const translatedString = i18n.originalT(tkey, options);
  const parsedHtml = parseHtml(translatedString);
  return <span dangerouslySetInnerHTML={{ __html: parsedHtml }} />;
};

import { groupBy, isArray, isEmpty } from 'lodash';

import { SITECORE_INDIVIDUAL_TOKEN } from '../constants';

import { addLocalisationToPath } from '@utils/localized-navigate';

import { MembershipType } from '@typings/operations';

export function getSiteCoreIndividualToken() {
  const individual = window.localStorage.getItem(SITECORE_INDIVIDUAL_TOKEN);
  return individual !== 'undefined' && !!individual
    ? JSON.parse(individual)
    : {};
}

// eslint-disable-next-line camelcase
export async function MyRotary_getTokens() {
  let clubName;
  let clubGUID;
  let districtID;
  let districtName;

  // Fetching individual id from local storage.
  const individual = getSiteCoreIndividualToken();

  const {
    individualId = '',
    clubs = [],
    districts = [],
    profileInfo = {},
    logOutUrl,
    districtLabel = '',
  } = individual;

  // Get Club Info
  if (!isEmpty(clubs) && isArray(clubs)) {
    const { clubId, clubName: firstClubName, formattedClubType } = clubs[0];
    clubName = `${firstClubName} (${formattedClubType})`;
    clubGUID = clubId;
  }

  // Get District Info
  if (individualId && !isEmpty(districts) && isArray(districts)) {
    const { id, riDistrictId } = districts[0];
    districtID = id;
    districtName = `${districtLabel} ${riDistrictId}`;
  }

  // Get Individual Profile
  const { firstName, lastName, middleName, fullName, userRole } = profileInfo;

  let formattedLogoutURL = null;
  if (logOutUrl) {
    const isHttpURL =
      logOutUrl.indexOf('http://') === 0 || logOutUrl.indexOf('https://') === 0;
    formattedLogoutURL = isHttpURL ? logOutUrl.split('//')[1] : logOutUrl;
  }

  const individualToken = individualId
    ? {
        'primary-club-guid': clubGUID || '',
        'primary-club-text': clubName || '',
        'primary-district-guid': districtID || '',
        'primary-district-text': districtName || '',
        'member-id': individualId,
        'user-firstname': firstName || '',
        'user-lastname': lastName || '',
        'user-middlename': middleName || '',
        'user-fullname': fullName || '',
        'user-role': userRole || '',
        'logout-url': formattedLogoutURL || '',
      }
    : {};

  return individualToken;
}

// eslint-disable-next-line camelcase
export async function MyRotary_getAllClubs() {
  const individual = getSiteCoreIndividualToken();
  const { clubs = [], weekDayLabels } = individual;
  if (!isEmpty(clubs) && isArray(clubs)) {
    return clubs.map(club => {
      const {
        clubId,
        clubName: firstClubName,
        formattedClubType,
        district = {},
        affiliations = [],
        meetings = [],
      } = club;
      const { id } = district;
      const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
      const clubUrl = `${appUrl}${addLocalisationToPath(
        `/club/${clubId}/details`
      )}`;
      const isPrimary = affiliations.some(
        a =>
          a.__typename === 'LeadershipAffiliation' ||
          (a.__typename === 'MembershipAffiliation' &&
            (a.membershipType === MembershipType.Member ||
              a.membershipType === MembershipType.Rotaractor))
      );
      const meetingDetails = meetings[0];

      const clubToken = {
        'club-guid': clubId,
        'club-text': `${firstClubName} (${formattedClubType})`,
        'club-url': clubUrl,
        'is-primary': isPrimary,
        'district-guid': id,
      };

      if (
        !isEmpty(meetingDetails) &&
        !!meetingDetails.weekday &&
        !!meetingDetails.time
      ) {
        clubToken['next-meeting-details'] = [
          `${weekDayLabels[meetingDetails.weekday]} ${meetingDetails.time}`,
        ];
      }
      return clubToken;
    });
  }
}
// eslint-disable-next-line camelcase
export async function MyRotary_getAllDistrcits() {
  const individual = getSiteCoreIndividualToken();
  const { districts = [], clubs = [], districtLabel = '' } = individual;
  const groupedDistricts =
    !isEmpty(clubs) && isArray(clubs) ? groupBy(clubs, 'district.id') : [];

  if (!isEmpty(districts) && isArray(districts)) {
    return districts.map(district => {
      const { id, riDistrictId, isHomeDistrict, zone } = district;
      const appUrl = process.env.MRX_APP_PUBLIC_URL ?? '';
      const districtUrl = `${appUrl}${addLocalisationToPath(
        `/district/${id}/details`
      )}`;
      const clubsByDistrict = !isEmpty(groupedDistricts)
        ? groupedDistricts[id]?.map(club => club.clubId)
        : [];

      const districtToken = {
        'district-guid': id,
        'district-text': `${districtLabel} ${riDistrictId}`,
        'district-url': districtUrl,
        'is-primary': isHomeDistrict,
        'district-number': riDistrictId,
        'zone-number': zone,
        clubs: clubsByDistrict,
      };
      return districtToken;
    });
  }
}

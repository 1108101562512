import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { last, range } from 'lodash';
import moment from 'moment';

import { TermToAssign } from '@domain/districts';

import { getRotaryYear, getYear } from '@utils/datetime';

export const getTermStringFromObject = (item: TermToAssign | null): string => {
  if (item) {
    return `${getYear(item.startDate)}-${getYear(item.endDate)}`;
  }
  return '';
};

export const isPriorTerm = (
  endYear: string,
  yearsFromCurrent: {
    past: number;
    future: number;
  }
) => {
  const rangeArr = range(
    Number(getRotaryYear()) + yearsFromCurrent.past,
    Number(getRotaryYear()) + yearsFromCurrent.future
  );

  return rangeArr.includes(Number(endYear));
};

export const isCurrentOrFutureTerm = (endYear: string) =>
  parseInt(endYear, 10) >= parseInt(getRotaryYear(), 10);

export const getFormattedStartDate = (date: string) => `${getYear(date)}-07-01`;

interface QueryableByEndDate {
  endDate: string;
}

export const getUnassignedTermStartDate = <T extends QueryableByEndDate>(
  terms: T[],
  targetYear: string = getRotaryYear()
): Date | null => {
  if (!terms.length) return null;

  const lastAssignedTerm = last(
    terms.sort((prv, nxt) => moment(prv.endDate).diff(moment(nxt.endDate)))
  );

  if (!lastAssignedTerm) return null;

  const hasUnassignedDateRange = moment(lastAssignedTerm.endDate).isBefore(
    moment(`${targetYear}-06-30`),
    'd'
  );

  return hasUnassignedDateRange
    ? moment(lastAssignedTerm.endDate)
        .add(1, 'd')
        .toDate()
    : null;
};

export const isRoleAvailableInManagedRY = <
  T extends { value: string },
  U extends QueryableByEndDate & { role: string }
>(
  role: string,
  roles: T[],
  terms: U[]
) => {
  if (!roles.map(({ value }) => value).includes(role)) return false;

  const roleTerms = terms.filter(term => term.role === role);

  if (!roleTerms.length) return true;

  return !!getUnassignedTermStartDate(roleTerms);
};

export const getPriorToLatestManagedYear = (year?: string) =>
  pipe(
    O.fromNullable(year),
    O.map(parseInt),
    O.chain(year => (Number.isNaN(year) ? O.none : O.some(year))),
    O.getOrElse(() => Number(getRotaryYear()) - 1)
  );

import { ClubSearchFormValues } from '@domain/clubs';

import { isClubRotaract, isClubRotaractSatellite } from '@use-cases/clubs';

export const getOrganizationBase = ({
  clubType,
  organizationBase,
}: ClubSearchFormValues) => {
  if (
    isClubRotaract(clubType || '') ||
    isClubRotaractSatellite(clubType || '')
  ) {
    return organizationBase;
  }

  return '';
};

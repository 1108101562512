import { languages, Languages } from '../../languages';
import i18n from '../../i18n';

const { t } = i18n;

const oktaWidgetKeys = {
  //labels and links
  'primaryauth.title': t('okta-widget.primaryauth.title', 'Sign In'),
  'primaryauth.username.placeholder': t(
    'okta-widget.primaryauth.username.placeholder',
    'Username'
  ),
  'primaryauth.password.placeholder': t(
    'okta-widget.primaryauth.password.placeholder',
    'Password'
  ),
  'primaryauth.submit': t('okta-widget.primaryauth.submit', 'Sign In'),
  remember: t('okta-widget.remember', 'Remember me'),
  help: t('okta-widget.help', 'Help'),
  needhelp: t('okta-widget.needhelp', 'Need help signing in?'),
  forgotpassword: t('okta-widget.forgotpassword', 'Forgot password?'),

  //errors
  'error.username.required': t(
    'okta-widget.error.username.required',
    'Please enter a username'
  ),
  'error.password.required': t(
    'okta-widget.error.password.required',
    'Please enter a password'
  ),
  'errors.E0000004': t(
    'okta-widget.errors.unable-to-sign-in',
    'Unable to sign in'
  ),
  'oform.errorbanner.title': t(
    'okta-widget.oform.errorbanner.title',
    'We found some errors. Please review the form and make corrections.'
  ),
};

const getOktaWidgetTranslations = () => {
  const translations: Record<keyof Languages, Record<string, string>> = {};
  for (const key in languages) {
    translations[key] = oktaWidgetKeys;
  }
  return translations;
};

export default getOktaWidgetTranslations;

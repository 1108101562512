import { Lang } from '@domain/language-negotiation';
import * as IO from 'fp-ts/lib/IO';
import { pipe } from 'fp-ts/lib/function';
import { defaultLanguage } from '../../../../languages';
import { filterBrowserLang } from '@repositories/language-negotiation/pipelines';

// getBroserPreferredLanguage :: Navigator -> Lang
export const getBrowserPreferredLanguage = (nav: Navigator) =>
  pipe(
    filterBrowserLang(nav),
    IO.map((langs: Lang[]) => (langs.length === 0 ? defaultLanguage : langs[0]))
  );

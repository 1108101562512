import { TFunction } from 'i18next';

export default (
  values: {
    totalValue: string | number;
    deltaValue: string | number;
    hasDelta: boolean;
    isNotCompared: boolean;
  },
  t: TFunction
) => {
  const { totalValue, deltaValue, isNotCompared, hasDelta } = values;
  let deltaLabel = t(
    `dashboards.contribution.zero_delta`,
    `<strong>{{totalValue}}</strong> currently. Equals to the Previous Year`,
    { totalValue }
  );

  if (isNotCompared) {
    deltaLabel = t(
      `dashboards.contribution.not_compared`,
      `<strong>{{totalValue}}</strong> currently.`,
      {
        totalValue,
      }
    );
  } else if (hasDelta) {
    deltaLabel = t(
      `dashboards.contribution.not_zero_delta`,
      `<strong>{{totalValue}}</strong> currently. {{deltaValue}} compared with Previous Year`,
      {
        totalValue,
        deltaValue,
      }
    );
  }

  return deltaLabel;
};

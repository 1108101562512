import { useMutation } from '@apollo/client';

import { removeOfficerMutation } from '@repositories/clubs';

import {
  RemoveOfficerMutation,
  RemoveOfficerMutationVariables,
} from '@typings/operations';

export const useRemoveOfficerMutation = () =>
  useMutation<RemoveOfficerMutation, RemoveOfficerMutationVariables>(
    removeOfficerMutation
  );

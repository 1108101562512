import { TFunction } from 'i18next';
import { isEmpty, orderBy } from 'lodash';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { isActiveLeadership } from '@utils/leadershipUtils';
import { isTerminationBeforeCurrentDate } from '@utils/membershipUtils';
import { specialRoles } from '@utils/roles';

import { DIS } from '@typings/dis';
import {
  MemberSearchResultFragment,
  MembershipType,
} from '@typings/operations';

export const getHighlightedClubAffliation = (
  activeMemberships: MemberSearchResultFragment['activeMemberships'],
  activeLeaderships: MemberSearchResultFragment['activeLeaderships'],
  filterByRole?: string
) => {
  const fullMembership = activeMemberships.find(
    membership => membership.type === MembershipType.Member
  );

  if (fullMembership) {
    return (
      activeLeaderships.find(leadership => {
        const { role, clubId } = leadership;
        const onlyAppropriateRole = filterByRole ? role === filterByRole : true;

        return (
          clubId === fullMembership.clubId &&
          onlyAppropriateRole &&
          isActiveLeadership(leadership)
        );
      }) || fullMembership
    );
  }

  return (
    activeLeaderships.find(
      leadership =>
        leadership.role === specialRoles.clubExecutiveSecretaryDirector.name
    ) ||
    activeMemberships.find(
      membership => membership.type === MembershipType.Honorary
    )
  );
};

export const getDistrictByRole = (
  districtLeadership: MemberSearchResultFragment['thisDistrictLeadership']
): Record<string, string[]> => {
  return orderBy(
    districtLeadership.filter(leadership => isActiveLeadership(leadership)),
    ['riDistrictId']
  ).reduce((districtGroup: Record<string, string[]>, leadership) => {
    const { riDistrictId, role } = leadership;
    const districtGroupRecord = { ...districtGroup };
    const district = `${DIS.OrganizationTypeEnum.District} ${riDistrictId}`;

    if (!districtGroupRecord[district]) {
      districtGroupRecord[district] = [];
    }
    districtGroupRecord[district].push(role);
    return districtGroupRecord;
  }, {});
};

export const getClubsByRoles = (
  activeLeaderships: MemberSearchResultFragment['activeLeaderships'],
  clubType: string,
  t: TFunction
): Record<string, string[]> => {
  return orderBy(
    activeLeaderships.filter(
      leadership =>
        leadership.clubType === clubType && isActiveLeadership(leadership)
    ),
    ['clubName']
  ).reduce((clubNameGroup: Record<string, string[]>, leadership) => {
    const { clubName, role, clubType } = leadership;
    const formattedClubType = clubType.replace(/_/g, ' ');
    const clubGroupRecord = { ...clubNameGroup };
    const club = `${clubName} (${formattedClubType})`;

    if (!clubGroupRecord[club]) {
      clubGroupRecord[club] = [];
    }

    clubGroupRecord[club].push(getClubRoleTValue(t, role));
    return clubGroupRecord;
  }, {});
};

export const getActiveMemberShip = (
  activeMemberships: MemberSearchResultFragment['activeMemberships'],
  memberships: string[],
  terminateLabel: string
): Record<string, string[]> => {
  return orderBy(
    activeMemberships.filter(leadership =>
      memberships.includes(leadership.type)
    ),
    ['clubName']
  ).reduce((memberGroup: Record<string, string[]>, leadership) => {
    const { clubName, type, clubType, terminationDate } = leadership;
    const formattedType = isTerminationBeforeCurrentDate(terminationDate)
      ? terminateLabel
      : type.replace(/_/g, ' ');
    const formattedClubType = clubType?.replace(/_/g, ' ');
    const memberGroupRecord = { ...memberGroup };
    const club = `${clubName} (${formattedClubType})`;

    if (!memberGroupRecord[club]) {
      memberGroupRecord[club] = [];
    }
    memberGroupRecord[club].push(formattedType);
    return memberGroupRecord;
  }, {});
};

export const isMembershipEmpty = (
  el: Record<string, string[]> | boolean | undefined
): boolean => !el || isEmpty(el);

import { useMutation } from '@apollo/client';
import { addSponsorMutation } from '@repositories/clubs';

// Types
import {
  AddSponsorMutation,
  AddSponsorMutationVariables,
} from '@typings/operations';

export const useAddSponsor = () => {
  const [addSponsor, { error }] = useMutation<
    AddSponsorMutation,
    AddSponsorMutationVariables
  >(addSponsorMutation);

  return { addSponsor, error };
};

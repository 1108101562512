import { MutableRefObject, ReactElement } from 'react';

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ActionType {
  PURGE = 'purge',
  CLEAR = 'clear',
  ADD_INFO = 'add-info',
  ADD_SUCCESS = 'add-success',
  ADD_ERROR = 'add-error',
}

export type ActionTypeAddNotification =
  | ActionType.ADD_ERROR
  | ActionType.ADD_INFO
  | ActionType.ADD_SUCCESS;

export interface NotificationOptions {
  nextPage?: boolean;
  element?: MutableRefObject<HTMLElement>;
  id?: string;
  isFixed?: boolean;
  timeout?: number;
  type?: NotificationType;
}

export type Notification = {
  type: NotificationType;
  message: string | ReactElement;
  nextPage?: boolean;
  element?: MutableRefObject<HTMLElement>;
  isFixed?: boolean;
  id: string;
  timeout?: number;
};

export interface PurgeAction {
  actionType: ActionType.PURGE;
}

export interface ClearAction {
  actionType: ActionType.CLEAR;
  payload: {
    idToRemove: string;
  };
}

export interface AddAction {
  actionType: ActionTypeAddNotification;
  payload: {
    notification: Notification;
  };
}

export type Action = PurgeAction | ClearAction | AddAction;

export type State = {
  notifications: Notification[];
};

export type AddCommonNotification = (
  actionType: ActionTypeAddNotification,
  type: NotificationType,
  message: string | ReactElement,
  options?: NotificationOptions
) => void;

export type AddNotification = (
  message: string | ReactElement,
  options?: NotificationOptions
) => void;

export type ClearNotification = (idToRemove: string) => void;

export type NotificationContextType = {
  addInfo: AddNotification;
  addSuccess: AddNotification;
  addError: AddNotification;
  clearNotification: ClearNotification;
  purgeNotifications: () => void;
};

import { TFunction } from 'i18next';

import {
  getClubDetailsPath,
  getClubMembersPath,
  isClubRotaract,
  isClubSatellite,
  isHonoraryClubMember,
} from '.';
import { isClubRotaractSatellite } from './isClubRotaractSatellite';
import { isClubImmediatePastOrFutureOfficer } from './isImmediatePastOrFutureOfficer';

import { CLUB_TABS } from '@domain/clubs';

import { isManagerLevelLead, isViewLevel } from '@utils/access-helpers';
import { FEATURE_LEADS_MML, isEnabled } from '@utils/features';

import { Drupal } from '@typings/drupal';
import { ClubAffiliation, ClubOperationsAccess } from '@typings/graphql';

export default (
  t: TFunction,
  accessLevels: Pick<
    ClubOperationsAccess,
    'memberships' | 'financesGoalsReports'
  >,
  clubType: string,
  clubId?: string,
  clubAffiliations?: ClubAffiliation,
  endDate?: string
): Array<Drupal.MenuItem> => {
  const items: Array<Drupal.MenuItem> = [];

  const isRotaractClub = isClubRotaract(clubType);

  const isRotaractSatellite = isClubRotaractSatellite(clubType);

  const isSatellite = isClubSatellite(clubType);

  const isMembershipCandidatesFeature = isEnabled(FEATURE_LEADS_MML);

  const isHonoraryMemberForCurrentClub = isHonoraryClubMember(clubAffiliations);

  const isLeaderForCurrentClub = isManagerLevelLead(accessLevels.memberships);

  const isAuthenticatedToLeadsDrupal = isClubImmediatePastOrFutureOfficer(
    endDate ?? ''
  );

  if (!clubId) {
    return items;
  }

  items.push({
    id: CLUB_TABS.DETAILS,
    label: t('club-details.menu.details', 'Club Details'),
    path: getClubDetailsPath(clubId),
    language_prefix: 'en',
  });

  if (
    !isSatellite &&
    isViewLevel(accessLevels.memberships) &&
    !isRotaractSatellite
  ) {
    items.push({
      id: CLUB_TABS.MEMBERS,
      label: t('club-details.menu.members', 'Members'),
      path: getClubMembersPath(clubId),
      language_prefix: 'en',
    });

    // check if individual is currentLeader or held previousRY/ has futureRY leadership role
    if (
      (isLeaderForCurrentClub || isAuthenticatedToLeadsDrupal) &&
      isMembershipCandidatesFeature
    ) {
      items.push({
        id: CLUB_TABS.MANAGE_MEMBERSHIP,
        label: t(
          'club-details.menu.administration.membership',
          'Membership Candidates'
        ),
        path: `/club/${clubId}/manage-membership`,
        language_prefix: 'en',
      });
    }

    if (
      (!isHonoraryMemberForCurrentClub || isLeaderForCurrentClub) &&
      isViewLevel(accessLevels.financesGoalsReports)
    ) {
      items.push({
        id: CLUB_TABS.FINANCES,
        label: t('club-details.menu.administration.finances', 'Finances'),
        path: `/club/${clubId}/finances`,
        language_prefix: 'en',
      });
    }
  }

  if (!isHonoraryMemberForCurrentClub || isLeaderForCurrentClub) {
    if (isViewLevel(accessLevels.financesGoalsReports)) {
      if (!isRotaractClub && !isRotaractSatellite) {
        items.push({
          id: CLUB_TABS.REPORTS,
          label: t('club-details.menu.administration.reports', 'Reports'),
          path: `/club/${clubId}/reports`,
          language_prefix: 'en',
        });
      }
      items.push({
        id: CLUB_TABS.GOALS,
        label: t('club-details.menu.administration.goals', 'Goals'),
        path: `/club/${clubId}/goals`,
        language_prefix: 'en',
      });
    }
  }

  return items;
};

import { LanguageExtended } from '@typings/graphql';

export const getLanguageById = (
  languages: Array<LanguageExtended>,
  langId: string
) => languages.filter(({ id }) => langId === id)[0];

export const getDISLanguageFromTwoLetterCode = (
  disLanguages: Array<LanguageExtended>,
  code: string
): LanguageExtended =>
  disLanguages.filter(({ shortCode }) => shortCode === code)[0];

export const uiLanguageToDISLanguageCode: { [key: string]: string } = {
  en: 'eng',
  de: 'ger',
  fr: 'fra',
  es: 'esp',
  it: 'ita',
  ko: 'kor',
  ja: 'jpn',
  pt: 'por',
};

export const DISLanguageCodeToUiLanguage: { [key: string]: string } = {
  eng: 'English',
  der: 'Deutsch',
  fra: 'Français',
  esp: 'Español',
  ita: 'Italiano',
  kor: '한국어',
  jpn: '日本語',
  por: 'Português',
};

export const langLocaleMap = {
  en: 'en-us',
  de: 'de-de',
  fr: 'fr-fr',
  it: 'it-it',
  ja: 'ja-jp',
  ko: 'ko-kr',
  pt: 'pt-br',
  ez: 'es-419',
};

export const disLanguageCodeToUiLanguageCode: { [key: string]: string } = {
  eng: 'en',
  der: 'de',
  fra: 'fr',
  esp: 'es',
  ita: 'it',
  kor: 'ko',
  jpn: 'ja',
  por: 'pt',
};

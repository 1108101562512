import { gql } from '@apollo/client';

export const updateResumeMutation = gql`
  mutation updateResume(
    $id: String!
    $sharingPermissionsExtended: SharingPermissionsInput!
  ) {
    updateIndividual(
      input: {
        individualId: $id
        sharingPermissionsExtended: $sharingPermissionsExtended
      }
    ) {
      individualId
    }
  }
`;

import { Option } from '@components/Formik/MultiSelectFilter/types';
import { FilterProps } from '@presenters/web/components/DistrictMembersFilters/types';

import {
  ANNUAL_FUND,
  POLIO_PLUS,
  PRIOR_MONTH_END,
  SelectDashboardClubTypeOptions,
  TOTAL_FOR_30_JUNE,
} from '@domain/districts';

import {
  DistrictDis3,
  DistrictFax,
  DistrictPhone,
  InputConferenceAddress,
  MembershipType,
  PhoneInput,
} from '@typings/graphql';
import {
  AssistantGovernor,
  BaseAddress,
  DistrictLeadership,
  DistrictOfficer,
  Maybe,
  MemberSearchResult,
} from '@typings/operations';
import { AccessLevel } from '@typings/resolvers';

export type ContextMenuItem = { id: number; label: string; path: string };

export type AccessLevelsByPeriod = {
  currentYearPermissions: AccessLevel;
  nextYearPermissions: AccessLevel;
};

export type DistrictMember = Omit<MemberSearchResult, '__typename'>;

export type CommitteeDistrictLeadership = Omit<
  DistrictLeadership,
  '__typename' | 'riDistrictId'
>;

export type CommitteeMemberData = Pick<
  MemberSearchResult,
  | 'id'
  | 'photoUri'
  | 'localizedName'
  | 'associatedClubsInfo'
  | 'nameWithPrefixSuffix'
  | 'email'
  | 'phoneNumber'
> & {
  activeMemberships: {
    clubId?: string;
    type?: Maybe<string>;
    admissionDate?: string;
    terminationDate?: Maybe<string>;
  }[];
  membershipType?: Maybe<string>;
  name?: string;
  thisDistrictLeadership: CommitteeDistrictLeadership[];
  __typename?: 'CommitteeMemberData';
};

export enum CLUB_FILTERS {
  CLUB_TYPES = 'clubTypes',
}

export enum MEMBER_FILTERS {
  CLUB_TYPES = 'clubTypes',
  CLUB_NAMES = 'clubNames',
  DISTRICT_ROLES = 'districtRoles',
  MEMBER_TYPES = 'memberTypes',
  CLUB_IDS = 'clubIds',
}

export type ClubFilter = {
  clubTypes: Option[];
};

export type MemberFilter = {
  clubNames: Option[];
  districtRoles: Option[];
  memberTypes: Option[];
  clubTypes: Option[];
  clubIds: Option[];
};

export type ClubFilterProps = FilterProps<CLUB_FILTERS, ClubFilter>;

// Committee Appointments context
export enum OperationType {
  // Operations from Members flow
  ASSIGN_TO_ROLE = 'assignToRole',
  REASSIGN_TO_ROLE = 'reassignToRole',
  UNASSIGN_ROLE_FROM_OFFICER = 'unassignRoleFromOfficer',
  REPLACE_ROLE = 'replaceMemberRole',
  // Operations from District Officers flow
  ASSIGN_TO_MEMBER = 'assignToMember',
  REASSIGN_OFFICER = 'reassignOfficer',
  UNASSIGN_OFFICER_FROM_ROLE = 'unassignOfficerFromRole',
  REPLACE_OFFICER = 'replaceOfficer',

  // operations for AG
  ASSIGN_TO_AG = 'assignToAG',
  REPLACE_AG = 'replaceAG',
  REASSIGN_TO_AG = 'reassignToAG',
}

export type Step = 1 | 2 | 3;

export type IsManager = {
  currentTerm: boolean;
  nextTerm: boolean;
  nowTerm: boolean;
  afterNowTerm: boolean;
};

export type CommitteeManagerInfo = {
  isManager: IsManager;
};

export type CommitteeSelectedInfo = {
  role: RoleOption | null;
  term: TermToAssign | null;
  dateToRemoveRole: Date | null;
  assignee: CommitteeMemberData | null;
  unassignFrom?: CommitteeMemberData | null;
  officerToBeReplaced?: DistrictOfficer | null;
};

export type ManagedRY = {
  roles: Role[];
  officers: DistrictOfficer[];
};

export type ManagedRYs = Record<'current' | 'future', ManagedRY>;

export type BackURL = { backUrl: string };

export type CommitteeAppointment = {
  districtId: string;
  operationType: OperationType | null;
  defaultTerm: TermToAssign | null;
  step: Step;
  termsToAssign: TermToAssign[];
  selectedInfo: CommitteeSelectedInfo;
  committeeManager: CommitteeManagerInfo;
  managedRYs: ManagedRYs;
};

export type CommitteeAppointmentPartialData = {
  selectedInfo: Partial<CommitteeSelectedInfo>;
  step?: Step;
} & Pick<CommitteeAppointment, 'operationType'>;

export type TermToAssign = {
  startDate: string;
  endDate: string;
  isAssignable?: boolean;
  isDisabled?: boolean;
};

export type FormattedTermToAssign = {
  label: string;
  value: string;
  disabled: boolean;
  details: TermToAssign;
};

// District Officers
export type UnassignedDistrictOfficerRoleTerm = {
  isAssignable?: boolean;
  startDate: string;
  endDate: string;
};

export type DistrictOfficerRole<T extends DistrictOfficer> = {
  id: string;
  role: string;
  termYears: number;
  terms: Array<T | UnassignedDistrictOfficerRoleTerm>;
};

export interface Role {
  id: string;
  value: string;
  detail: {
    TermLength: number;
    IsOfficer: boolean;
  };
}

export type RoleOption = {
  id: string;
  name: string;
  terms?: TermToAssign[];
  termYears?: Maybe<number>;
};

export interface OfficerAssignedToRole {
  id: string;
  startDate: string;
  endDate: string;
  isDelegated?: boolean;
  role: string;
}

export enum DISTRICT_TABS {
  DETAILS = 'districtDetails',
  CLUBS = 'districtClubs',
  MEMBERS = 'districtMembers',
  MEMBERSHIPCANDIDATE = 'districtMembershipCandidates',
  FINANCE = 'districtFinance',
  CONFERENCES = 'districtConferences',
  REPORTS = 'districtReports',
  DASHBOARD = 'districtDashboard',
}

export type Affiliations = {
  clubId: string;
  clubName: string;
  clubType: string;
  location?: BaseAddress | null;
};

export interface AGMembership extends Affiliations {
  membershipType: MembershipType;
  terminationDate: Maybe<string>;
}

export interface DistrictAG
  extends Omit<AssistantGovernor, 'clubsAffiliations' | 'membershipClubs'> {
  membershipClubs: AGMembership[];
  clubsAffiliations: Affiliations[];
}

export type AGManagerInfo = {
  endTermDate: string;
  isCurrentYearManager: boolean;
  isManagerAfterNow: boolean;
  isManagerNow: boolean;
};

// District Dashboards
export type MonthKeys =
  | 'july1st'
  | 'July'
  | 'August'
  | 'September'
  | 'October'
  | 'November'
  | 'December'
  | 'January'
  | 'February'
  | 'March'
  | 'April'
  | 'May'
  | 'June';

export type ClubTypeSelectOption =
  | SelectDashboardClubTypeOptions.All
  | SelectDashboardClubTypeOptions.Rotary
  | SelectDashboardClubTypeOptions.Rotaract;

export type DeltaValues = {
  deltaStartYear: number | null;
  deltaPrevMonth: number | null;
  deltaPrevYear: number | null;
  prevYear: number | null;
};

export type DeltasObject = {
  [key: string]: DeltaValues;
};

export type ChartPointData = Maybe<string | number | DeltasObject | undefined>;

export type MembersTrendsChartData = {
  //     month: 'Rotary Year Start',
  //     ['2020-2021']: 5100,
  //     ['2019-2020']: 2400,
  //     ...
  //     deltas: {
  //       ['2020-2021']: {
  //         deltaStartYear: 0,
  //         deltaPrevMonth: 0,
  //         deltaPrevYear: 0,
  //       },
  //       [2020-2019] {...}
  //     }

  [key: string]: ChartPointData;
};

export type DemographicsChartData = {
  womenCount: number;
  otherGendersCount: number;
  under40: number;
  over40: number;
  unreported: number;
  period: string;
};

export type MembersTrendsData = {
  totalCount: number;
  delta: number;
  chartData: MembersTrendsChartData[];
};

export type ContributionData = {
  rotaryYear: string;
  [TOTAL_FOR_30_JUNE]?: number;
  [PRIOR_MONTH_END]?: number;
  [ANNUAL_FUND]?: number;
  [POLIO_PLUS]?: number;
};

export type ContributionChartColor = Record<
  'lineKey' | 'stroke' | 'fill',
  string
>;

export type DORole = DistrictOfficerRole<DistrictOfficer>;

export type DORoles = DORole[];

export interface RoleParams {
  roles: Role[];
  officers: DistrictOfficer[];
  year: string;
}

export interface PartTimeStartDate {
  value: Date | null;
  minDate: Date;
}

export type MovedItems = {
  clubName: string;
  destinationId: string;
  destinationName: string;
  clubId: string;
  sourceId: string;
  sourceName: string;
  affiliationId: string;
};

export type ClubsDevelopmentData = {
  totalCount: number;
  delta: number;
  chartData: ClubsDevelopmentChartData[];
};

export type ClubsDevelopmentChartData = {
  year: number;
  totalCount: number;
  july1st: number;
  newClubs: number;
  terminatedClubs: number;
  newMembers: number;
  terminatedMembers: number;
};

export type ModalTextContent = {
  content?: string;
  title?: string;
  text?: string;
  saveBtn?: string;
  continueBtn?: string;
};

export enum ACCESS_LEVEL_TARGETS {
  DISTRICT_LEADERSHIP = 'DistrictLeadership',
  DISTRICT_DASHBOARDS = 'DistrictDashboards',
  DISTRICT_CONFERENCES = 'DistrictConferences',
  DISTRICT_CONFERENCE_FEEDBACK = 'DistrictConferenceFeedback',
}

export enum ConferenceType {
  Physical = 'Physical',
  Online = 'Online',
  Hybrid = 'Hybrid',
}

export type CreateConferenceAddress = {
  city: string | null;
  country: string | null;
  lineOne: string | null;
  lineTwo: string | null;
  lineThree: string | null;
  postalCode: string | null;
  state: string | null;
  internationalProvince: string | null;
};

export type DateTime = {
  startDate: string | Date | null;
  startTime: string | null;
  endDate: string | Date | null;
  endTime: string | null;
  timeZone: string | null;
};

export type CreateConferenceFormResult = {
  districtId: string;
  venueType: string;
  dateTime: DateTime;
  conferenceAddress: InputConferenceAddress;
  venueName: string | null;
  language: string | null;
  comments: string | null;
  participatingDistricts: ParticipantOption[] | null;
  onlineLocation: string;
  detailForJoiningRemotely: string;
  isPresRepRequested?: Maybe<boolean>;
  prAddress: InputConferenceAddress;
  accomodationPhone: PhoneInput;
  prAccommodation: string;
};

export type GetConferenceResult = {
  districtId: string;
  venueType: string;
  dateTime: DateTime;
  conferenceAddress: CreateConferenceAddress;
  venueName: string | null;
  timeZone?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  language: string | null;
  comments: string | null;
  participatingDistricts: ParticipantOption[] | null;
  onlineLocation: string;
  detailForJoiningRemotely: string;
  isPresRepRequested: boolean;
  prAddress: CreateConferenceAddress;
  accomodationPhone: PhoneInput;
  prAccommodation: string | null;
};

export type ParticipantOption = {
  id: string;
  value: number;
};

export type CreateFeedbackFormResult = {
  [key: string]: string | boolean[] | boolean;
};

export type DetailsChartData = {
  name: string;
  totalCount: number;
};

export type GetDetailsChartLinkName = (index: number, value: string) => string;

export type ConferencesAccessLvl = {
  id: string;
  level: AccessLevel;
  targetTermYear: string;
};

export type MemberURLParams = {
  operationType?: OperationType;
  step?: Step;
  role?: string;
  term?: TermToAssign;
  terms?: DistrictOfficer[];
  isDOPage?: boolean;
} & Partial<CommitteeMemberData>;

export type TimeZoneOption = {
  value: string;
  displayValue: string;
  label: string;
  subtitle: string;
};

export type DistrictDetailsValues = Pick<
  DistrictDis3,
  | 'primaryWebsite'
  | 'primaryEmail'
  | 'primaryFax'
  | 'primaryPhone'
  | 'businessAddress'
>;

export type DistrictDetailsFormValues = {
  address?: {
    lineOne?: string;
    lineTwo?: string;
    lineThree?: string;
    countryId?: string;
    city?: string;
    state?: string;
    stateId?: string;
    postalCode?: string;
    internationalProvince?: string;
    hasStates?: boolean;
  } | null;
  primaryEmail?: string;
  primaryWebsite?: string;
  primaryPhone?: DistrictPhone;
  primaryFax?: DistrictFax;
};

export type DistrictDetailsFormValuesWithIds = {
  address?: AddressInputType;
  primaryEmail?: EmailInputType;
  primaryWebsite?: PrimaryWebsiteInputType;
  primaryPhone?: PrimaryPhoneInputType;
  primaryFax?: PrimaryFaxInputType;
};

export type AddressInputType = {
  id?: string;
  action?: string;
  lineOne?: string;
  lineTwo?: string;
  lineThree?: string;
  countryId?: string;
  city?: string;
  state?: string;
  stateId?: string;
  postalCode?: string;
  internationalProvince?: string;
  hasStates?: boolean;
};

export type EmailInputType = {
  id?: string;
  primaryEmail?: string;
  action?: string;
};

export type PrimaryWebsiteInputType = {
  id?: string;
  primaryWebsite?: string;
  action?: string;
};

export type PrimaryPhoneInputType = DistrictPhone & {
  action?: string;
};

export type PrimaryFaxInputType = DistrictPhone & {
  action?: string;
};

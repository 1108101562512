import { ApolloClient, InMemoryCache } from '@apollo/client';

import * as IO from 'fp-ts/IO';
import * as IORef from 'fp-ts/IORef';
import { addLocalisationToPath } from '@utils/localized-navigate';
import { GatsbyEnv, Path, UserWithAnalytics } from '@domain/auth';

import {
  getBackendLogoutUrlCurried,
  getWebappLogoutUrlCurried,
} from '@repositories/auth/utils';
import { fetchUserAndAnalyticsPipe } from '@repositories/auth/pipelines';

export const myEnv: GatsbyEnv = {
  OKTA_APP_CLIENT_TOKEN: process.env.OKTA_APP_CLIENT_TOKEN,
  GATSBY_MRX_API_PUBLIC_URL: process.env.GATSBY_MRX_API_PUBLIC_URL,
  GATSBY_BACKEND_APP_BASE_URL: process.env.GATSBY_BACKEND_APP_BASE_URL,
};

// processify :: Process,  Process, T -> T -> B
export const processify = (env: GatsbyEnv, f: any): ((b: string) => string) =>
  IO.io.chain(
    IORef.newIORef(env),
    (ref: IORef.IORef<GatsbyEnv>): IO.IO<(b: string) => string> =>
      IO.io.chain(ref.read, f)
  )();

const addLocalisationToPathIO = (path: Path): IO.IO<Path> => () =>
  addLocalisationToPath(path);

export const fetchUserAndAnalytics = (
  client: ApolloClient<InMemoryCache>
): Promise<UserWithAnalytics> => fetchUserAndAnalyticsPipe(client)();

// getWebappLogoutUrl :: Destination -> Url
export const getWebappLogoutUrl = getWebappLogoutUrlCurried(myEnv);
// getBackendLogoutUrl :: Destination -> Url
export const getBackendLogoutUrl = (destination: string = '') =>
  getBackendLogoutUrlCurried(myEnv, addLocalisationToPathIO)(destination);

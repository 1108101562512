import { useLazyQuery } from '@apollo/client';
import { fetchFeedbackQuestionsQuery } from '../queries';
import {
  GetFeedbackQuestionsQuery,
  GetFeedbackQuestionsQueryVariables,
} from '@typings/operations';

export const useFetchFeedbackQuestions = () =>
  useLazyQuery<GetFeedbackQuestionsQuery, GetFeedbackQuestionsQueryVariables>(
    fetchFeedbackQuestionsQuery
  );

import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@external/react-i18next';
import Icon from '@components/Icon';

const Loading: React.FC<{
  small?: boolean;
  white?: boolean;
  inline?: boolean;
  noMargins?: boolean;
  className?: string;
}> = ({ small, white, inline, noMargins, className }) => {
  const { t } = useTranslation();
  const size = small ? '32' : '100';
  return (
    <div
      className={classNames(className, 'text-center', {
        'my-8': !noMargins && !small && !inline,
        'my-4': !noMargins && small && !inline,
        'inline-block': inline,
      })}
    >
      <Icon
        name="ui/loading"
        size={size}
        color={classNames({ 'bright-blue-600': !white, white: white })}
        className="inline-block"
      />
      <p className="sr-only">{t('loading-indicator', 'Loading…')}</p>
    </div>
  );
};

export default Loading;

import { gql, useLazyQuery } from '@apollo/client';
import {
  SearchClubsInDistrictQuery,
  SearchClubsInDistrictQueryVariables,
} from '@typings/operations';

export const searchClubsInDistrictQuery = gql`
  query searchClubsInDistrict(
    $clubName: String
    $clubLocation: String
    $clubTypes: [String]
    $riDistrictId: Int!
    $status: String
    $page: Int
    $pageSize: Int
  ) {
    districtClubs: searchClubsInDistrict(
      clubName: $clubName
      clubLocation: $clubLocation
      clubTypes: $clubTypes
      riDistrictId: $riDistrictId
      status: $status
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      clubs {
        id
        riClubId
        name
        type
        status
        location {
          country
          state
          countryId
          city
          stateId
        }
        governor
        activeMembersCount
      }
    }
  }
`;

export const useSearchClubsByDistrictId = () =>
  useLazyQuery<SearchClubsInDistrictQuery, SearchClubsInDistrictQueryVariables>(
    searchClubsInDistrictQuery
  );

import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import { toString } from 'lodash';
import * as R from 'ramda';

import { UNASSIGNED } from '@components/DragAndDropContainers/constants/groupnames';
import { Group, Item } from '@components/DragAndDropContainers/types';

import { byContent } from '../helpers';

import { DIS } from '@typings/dis';
import { ClubType } from '@typings/graphql';
import { DistrictClub, FetchAllDistrictClubsQuery } from '@typings/operations';

export const normalizeUnassignedClub = (
  districtClubsData?: FetchAllDistrictClubsQuery
): Group => {
  const groupId = `groupId-${UNASSIGNED}`;
  const groupName = UNASSIGNED;

  return pipe(
    districtClubsData,
    O.fromNullable,
    O.mapNullable(R.prop('districtClubs')),
    O.mapNullable(R.prop('clubs')),
    O.toNullable,
    item => item || [],
    A.filter(
      ({ status, type }) =>
        !R.equals(status, DIS.ClubStatus.Terminated) &&
        // MRD-2177: remove this line after implementation
        // Satellite Club and Rotaract Club logic by DIS
        R.equals(toString(type), ClubType.RotaryClub)
    ),

    A.map(({ id, name }: DistrictClub) => ({
      id,
      content: name,
      data: '',
    })),
    A.sortBy([byContent]),
    (items: Item[]) => ({
      groupId,
      groupName,
      items,
    })
  );
};

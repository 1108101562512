import { Drupal } from '@typings/drupal';
import { AccessLevel } from '@typings/graphql';
import { FetchAdminAccessRightsQuery } from '@typings/operations';

export const getReportsData = (
  reports: Drupal.MenuResponse,
  accessData?: FetchAdminAccessRightsQuery
) => {
  const accessLevel = accessData?.club.operationsAccess.memberships;

  return (
    reports.filter(
      item =>
        accessLevel === AccessLevel.Manager ||
        item.label.toLowerCase() === 'awards'
    ) || []
  );
};

import { Option } from '@components/Formik/MultiSelectFilter/types';

export enum MEMBER_FILTERS {
  CLUB_NAMES = 'clubNames',
  DISTRICT_ROLES = 'districtRoles',
  MEMBER_TYPES = 'memberTypes',
  CLUB_IDS = 'clubIds',
}

export type MemberFilter = {
  clubNames: Option[];
  districtRoles: Option[];
  memberTypes: Option[];
  clubIds: Option[];
};

export interface FilterProps<T, U> {
  applyFilter: (selectedOptions: Option[], name: T) => void;
  initialFilterValues: U;
  multiSelectOptions: U;
  resetFilter: (filterName?: string) => void;
}

export type MemberFilterProps = FilterProps<MEMBER_FILTERS, MemberFilter>;

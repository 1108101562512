import { Destination, GatsbyEnv, Url } from '@domain/auth';
import { curry, Curry2 } from '@utils/fp-ts';

const curryProc: Curry2<GatsbyEnv, Destination, Url> = curry;

// getWebappLogoutUrlCurried :: NodeJS.ProcessEnv, Destination -> Url
export const getWebappLogoutUrlCurried = curryProc(
  (
    { OKTA_APP_CLIENT_TOKEN, GATSBY_MRX_API_PUBLIC_URL }: GatsbyEnv,
    destination: Destination = ''
  ): Url => {
    let url = OKTA_APP_CLIENT_TOKEN
      ? `${GATSBY_MRX_API_PUBLIC_URL}/user/logout`
      : `${GATSBY_MRX_API_PUBLIC_URL}/logout`;
    if (destination !== '') {
      url = `${url}?destination=${encodeURIComponent(destination)}`;
    }
    return url;
  }
);

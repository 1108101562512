// Libs
import { pipe } from 'fp-ts/function';
import { curry, filter, some, partition } from 'lodash';
import moment from 'moment';

// Utils
import {
  buildUnassignedRoleTerms,
  groupDOsByRole,
  sortRolesByRequired,
} from '@use-cases/districts/helpers';
import { getFormattedRYRange, isFutureRY } from '@utils/datetime';

// Constants
import {
  DISTRICT_OFFICER_GOVERNOR_ROLES,
  DISTRICT_OFFICER_ROLES_TO_OMIT,
  ROLES_REQUIRED_BY_POLICY,
} from '@domain/districts';

// Types
import { DistrictOfficer as DO } from '@typings/graphql';
import {
  DORole,
  DORoles,
  OfficerAssignedToRole,
  Role,
  RoleParams,
  UnassignedDistrictOfficerRoleTerm as Unassigned,
} from '@domain/districts';

const getAssignableTerm = (term: DO | Unassigned): boolean | undefined =>
  (term as Unassigned).isAssignable;

const getRoleWithAssignableTerm = (role: DORole): boolean =>
  some(role.terms, getAssignableTerm);

const filterUnassignedRoles = (result: DORoles): DORole[] =>
  filter(result, getRoleWithAssignableTerm);

const filterRoles = (roles: Role[]): Role[] =>
  roles.filter(
    ({ value, detail: { IsOfficer } }) =>
      IsOfficer && !DISTRICT_OFFICER_ROLES_TO_OMIT.includes(value)
  );

const filterDOsByRole = (officers: DO[], RY: number = 0): DO[] =>
  officers.filter(
    ({ isDelegated, role, endDate }) =>
      !(isDelegated || DISTRICT_OFFICER_ROLES_TO_OMIT.includes(role)) &&
      (isFutureRY(RY)
        ? moment(endDate).isSameOrAfter(
            moment(getFormattedRYRange(RY).startYear),
            'D'
          )
        : true)
  );

const sortRoles = curry(sortRolesByRequired)(ROLES_REQUIRED_BY_POLICY);

const splitRoles = (roles: DORoles): DORoles[] =>
  partition(roles, ({ role }) =>
    DISTRICT_OFFICER_GOVERNOR_ROLES.includes(role)
  );

const filterUnfilledDGRoles = (roles: DORoles): DORoles =>
  roles.filter(({ terms }) => !!terms.length);

const buildUnassignedRoleTermsForCAs = (
  year: number,
  roles: DORoles
): DORoles => roles.map(curry(buildUnassignedRoleTerms)(year));

const processRolesForManager = (year: number, roles: DORoles): DORoles => {
  const [governors, nonGovernors] = splitRoles(roles);

  return [
    ...filterUnfilledDGRoles(governors),
    ...buildUnassignedRoleTermsForCAs(year, nonGovernors),
  ];
};

const filterDORolesForViewer = (officers: DORoles) =>
  officers.filter(officer => officer.terms.length > 0);

const getDORolesForManager = ({ roles, officers, year }: RoleParams): DORoles =>
  pipe(
    groupDOsByRole(
      filterRoles(roles),
      filterDOsByRole(officers) as OfficerAssignedToRole[],
      Number(year)
    ),
    curry(processRolesForManager)(Number(year))
  );

const getDORolesForViewer = ({ roles, officers, year }: RoleParams): DORoles =>
  pipe(
    groupDOsByRole(
      filterRoles(roles),
      filterDOsByRole(officers, Number(year)) as OfficerAssignedToRole[],
      Number(year),
      true
    ),
    filterDORolesForViewer
  );

export const getDORoles = (isManager: boolean, params: RoleParams): DORoles[] =>
  pipe(
    (isManager ? getDORolesForManager : getDORolesForViewer)(params),
    sortRoles,
    splitRoles
  );

export const getDOUnassignedRoles = (params: RoleParams) =>
  pipe(
    getDORolesForManager(params),
    filterUnassignedRoles,
    sortRoles,
    splitRoles
  );

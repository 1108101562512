import * as React from 'react';
import classNames from 'classnames';

type IconType = {
  name: string;
  size?: string;
  color?: string;
  className?: string;
  clickHandler?: () => void;
  dataTestId?: string;
};

export const Icon: React.FC<IconType> = ({
  name,
  size = '24',
  color,
  className,
  clickHandler,
  dataTestId,
}) => {
  const req = require.context('../../../src/assets/icons', true, /\.svg$/);
  const SvgElement = req(`./${name}.svg`).default;

  const styleProps = color ? { style: { color } } : {};

  return (
    <SvgElement
      data-testid={dataTestId}
      onClick={clickHandler}
      width={size}
      height={size}
      className={classNames(className, {
        [`fill-current text-${color}`]: !!color,
      })}
      {...styleProps}
    />
  );
};

export default Icon;

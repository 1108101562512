import { sortBy } from 'lodash';

export const sortItemsByRequiredOrder = <T, K extends keyof T>(
  items: T[],
  requiredOrder: T[K][],
  fieldToCompare: K
) =>
  sortBy(items, item =>
    requiredOrder.indexOf(item[fieldToCompare]) >= 0
      ? requiredOrder.indexOf(item[fieldToCompare])
      : requiredOrder.length
  );

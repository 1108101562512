import { useMemo, useEffect } from 'react';

import { useFetchUserAccount } from '@repositories/auth/hooks';
import { useSessionStorage } from '@repositories/storage/hooks';
import { FetchUserAccountQuery } from '@typings/operations';

export const useUserAccount = (individualId: string | null) => {
  const { getStorageData, updateStorage } = useSessionStorage<
    FetchUserAccountQuery
  >();

  const [
    fetchUserAccount,
    { data: fetchData, loading, error, variables },
  ] = useFetchUserAccount();

  const userCacheId = `user-account-${individualId}`;

  useEffect(() => {
    const storageData = getStorageData(userCacheId);

    if (
      !loading &&
      !storageData &&
      individualId &&
      variables?.individualId !== individualId
    ) {
      fetchUserAccount({
        variables: {
          individualId,
        },
      });
    }
  }, [loading, individualId]);

  const data = useMemo(() => {
    const storageData = getStorageData(userCacheId);

    if (
      !loading &&
      fetchData &&
      !storageData?.data &&
      individualId &&
      variables?.individualId === individualId
    ) {
      updateStorage(fetchData, userCacheId);
      return fetchData;
    }

    return storageData?.data;
  }, [fetchData, variables, individualId]);

  return { data, loading, error };
};

import { useMutation } from '@apollo/client';
import { createMembershipMutation } from '@repositories/clubs';

// Types
import {
  CreateMemberShipMutation,
  CreateMemberShipMutationVariables,
} from '@typings/operations';

export const useCreateMembership = () =>
  useMutation<CreateMemberShipMutation, CreateMemberShipMutationVariables>(
    createMembershipMutation
  );

import { TFunction } from 'i18next';

import { getClubTypeName } from './getClubTypeName';
import { getPhysicalLocation } from './getPhysicalLocation';

import {
  ClubSearchResutLocation,
  ClubType,
  LocationData,
  Maybe,
} from '@typings/graphql';

export const getFormattedClubTypeAndLocation = (
  t: TFunction,
  type: Maybe<ClubType> | undefined,
  physicalLocation:
    | Maybe<ClubSearchResutLocation>
    | Maybe<LocationData>
    | undefined
): string => {
  const clubType = type ? getClubTypeName(t, type) : '';
  const shortClubType = clubType.split(' ')[0];
  const formattedShortClubType =
    shortClubType !== '' ? ` (${shortClubType}) ` : ' ';

  const location = physicalLocation
    ? getPhysicalLocation(physicalLocation)
    : '';
  return `${formattedShortClubType}\n${location}`;
};

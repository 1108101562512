export type MemberFullName = {
  prefix?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  suffix?: string | null;
};

export const constructFullName = ({
  prefix,
  firstName,
  middleName,
  lastName,
  suffix,
}: MemberFullName): string => {
  return [prefix, firstName, middleName, lastName, suffix]
    .filter(item => !!item)
    .join(' ');
};

// Libs
import React, {
  createContext,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { globalHistory } from '@reach/router';

// Types
import {
  Action,
  ActionType,
  AddCommonNotification,
  AddNotification,
  ClearNotification,
  NotificationContextType,
  NotificationOptions,
  NotificationType,
  State,
} from '@domain/notifications/types';

// Constants
import {
  initialState,
  NOTIFICATIONS_WRAPPER_ID,
} from '@domain/notifications/constants';

// Components
import Notifications from '@presenters/web/components/Notifications';
import Portal from '@presenters/web/components/Portal';

// Utils
import { reducer } from './reducer';

import { getFormattedNotification } from '@domain/notifications/mappers';

export const NotificationContext = createContext<NotificationContextType>({
  addInfo: () => {},
  addSuccess: () => {},
  addError: () => {},
  clearNotification: () => {},
  purgeNotifications: () => {},
});

export const NotificationStateContext = createContext<State>(initialState);

export const NotificationProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    initialState
  );

  const clearNotification: ClearNotification = useCallback(
    idToRemove =>
      dispatch({
        actionType: ActionType.CLEAR,
        payload: { idToRemove },
      }),
    []
  );

  const purgeNotifications = useCallback(
    () => dispatch({ actionType: ActionType.PURGE }),
    []
  );

  const clearAfterTimeout = useCallback(
    ({ id, timeout }: NotificationOptions = {}) => {
      timeout && id && setTimeout(() => clearNotification(id), timeout);
    },
    [clearNotification]
  );

  const addNotificationMessage: AddCommonNotification = useCallback(
    (actionType, type, message, options) => {
      dispatch({
        actionType,
        payload: {
          notification: getFormattedNotification(message, {
            ...options,
            type,
          }),
        },
      });
      document.getElementById(NOTIFICATIONS_WRAPPER_ID)?.scrollIntoView({
        behavior: 'smooth',
      });
      clearAfterTimeout(options);
    },
    [clearAfterTimeout]
  );

  const addInfo: AddNotification = useCallback(
    (message, options) => {
      addNotificationMessage(
        ActionType.ADD_INFO,
        NotificationType.INFO,
        message,
        options
      );
    },
    [addNotificationMessage]
  );

  const addSuccess: AddNotification = useCallback(
    (message, options) => {
      addNotificationMessage(
        ActionType.ADD_SUCCESS,
        NotificationType.SUCCESS,
        message,
        { ...options, nextPage: true }
      );
    },
    [addNotificationMessage]
  );

  const addError: AddNotification = useCallback(
    (message, options) => {
      addNotificationMessage(
        ActionType.ADD_ERROR,
        NotificationType.ERROR,
        message,
        options
      );
    },
    [addNotificationMessage]
  );

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      action === 'PUSH' && purgeNotifications();
    });
  }, [purgeNotifications]);

  const getApi = useMemo(
    () => ({
      addInfo,
      addSuccess,
      addError,
      clearNotification,
      purgeNotifications,
    }),
    [addInfo, addSuccess, addError, clearNotification, purgeNotifications]
  );

  // get around rerendering
  // https://reactjs.org/docs/context.html#caveats
  // https://leewarrick.com/blog/the-problem-with-context/
  return (
    <NotificationContext.Provider value={getApi}>
      <NotificationStateContext.Provider value={state}>
        <Portal rootSelectorId={NOTIFICATIONS_WRAPPER_ID}>
          <Notifications />
        </Portal>
        {children}
      </NotificationStateContext.Provider>
    </NotificationContext.Provider>
  );
};

// Utils
import { includes } from 'lodash';

import { getTimeZoneAbbr } from '@utils/datetime';

import { TimeZone } from '@typings/graphql';

export default (timezonesList: TimeZone[]) => {
  const { defaultTimeZoneAbbr, defaultTimeZoneOffset } = getTimeZoneAbbr();
  const offsetWithSign = (value: number) => (value < 0 ? value : `+${value}`);

  const timeZone = timezonesList.find(
    ({ value, offset }) =>
      value === defaultTimeZoneAbbr &&
      includes(offset, offsetWithSign(defaultTimeZoneOffset))
  );

  return timeZone?.id || '';
};

export const uuidRegex = '[0-9a-f]{8}(?:-[0-9a-f]{4}){3}-[0-9a-f]{12}';

export const LANGUAGE = 'language';
export const REGION = 'region';

export const DISPLAY_TYPE = {
  LANGUAGE,
  REGION,
};

export const RIBI_COUNTRY_LIST = [
  'Isle of Man',
  'Guernsey-Channel Islands',
  'Scotland',
  'Jersey-Channel Islands',
  'Ireland',
  'Wales',
  'England',
  'Gibraltar',
  'Northern Ireland',
] as Array<string>;

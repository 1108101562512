// Utils
import { ChartValueFormats } from '@domain/districts';

// Constants
const defaultLocale = 'en-US';

export default (
  value: number,
  locale: string = defaultLocale,
  options: Intl.NumberFormatOptions = {}
) => {
  const isStylePercent = options?.style === ChartValueFormats.percent;

  value = isStylePercent ? value / 100 : value;
  locale = locale || defaultLocale;
  const formatOption = isStylePercent ? { ...options } : {};

  return value.toLocaleString(locale, formatOption);
};

import { useLazyQuery } from '@apollo/client';
import { fetchCommitteeRolesQuery } from '@repositories/districts';
import {
  GetCommitteeRolesQuery,
  GetCommitteeRolesQueryVariables,
} from '@typings/operations';

export const useFetchCommitteeRoles = () =>
  useLazyQuery<GetCommitteeRolesQuery, GetCommitteeRolesQueryVariables>(
    fetchCommitteeRolesQuery
  );

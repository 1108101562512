import React from 'react';

import tailwind from '../../styles/tailwind';
import Icon from '../Icon';

import { useTranslation } from '@external/react-i18next';
import ReactTooltip from '@external/react-tooltip';

interface TooltipProps {
  content: string;
  svgClass?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ content, svgClass = '' }) => {
  const { t } = useTranslation();
  return (
    <>
      <button
        type="button"
        aria-label={t('tooltip.label', 'More information')}
        data-tip={content}
        className="mx-2"
      >
        <Icon name="circles/information" size="16" className={svgClass} />
      </button>
      <ReactTooltip
        place="bottom"
        effect="solid"
        type="light"
        event="click"
        class="shadow text-md-important rounded-none-important border-b border-gray-300"
        textColor={tailwind.theme.colors.gray['600']}
        arrowColor="transparent"
        wrapper="span"
        globalEventOff="click"
        overridePosition={(
          { left, top }: { left: number; top: number },
          _e: Event,
          _t: EventTarget,
          node: Node
        ) => {
          return {
            top,
            left: typeof node === 'string' ? left : Math.max(left, 0),
          };
        }}
      />
    </>
  );
};

export default Tooltip;

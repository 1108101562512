import { gql } from '@apollo/client';

export const useFetchContactInformationQuery = gql`
  query fetchContactInformation($id: String!) {
    contactInformation: getIndividual(id: $id) {
      primaryAddress {
        # Id unavailable from DIS causing issues on profile page (MRC-264)
        # Need to revert on Domino Implementation
        #id
        type
        country
        lineOne
        lineTwo
        lineThree
        countryId
        city
        postalCode
        state
        stateId
        internationalProvince
      }
      otherAddresses {
        # Id unavailable from DIS causing issues on profile page (MRC-264)
        # Need to revert on Domino Implementation
        #id
        type
        country
        lineOne
        lineTwo
        lineThree
        countryId
        city
        postalCode
        state
        stateId
        internationalProvince
      }
      primaryPhone {
        # Id unavailable from DIS causing issues on profile page (MRC-264)
        # Need to revert on Domino Implementation
        #id
        type
        country
        countryId
        extension
        number
      }
      otherPhones {
        # Id unavailable from DIS causing issues on profile page (MRC-264)
        # Need to revert on Domino Implementation
        #id
        type
        country
        countryId
        extension
        number
      }
      primaryEmail {
        # Id unavailable from DIS causing issues on profile page (MRC-264)
        # Need to revert on Domino Implementation
        #id
        type
        address
        isSignin
      }
      otherEmails {
        # Id unavailable from DIS causing issues on profile page (MRC-264)
        # Need to revert on Domino Implementation
        #id
        type
        address
        isSignin
      }
      sharingPermissionsExtended {
        email {
          id
        }
        phone {
          id
        }
        address {
          id
        }
      }
      isClubOfficer
      isClubOrDistrictOfficer
    }
  }
`;

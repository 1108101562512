import { Moment } from 'moment';

import { Option } from '@components/Formik/MultiSelectFilter/types';
import { FilterProps } from '@presenters/web/components/DistrictMembersFilters/types';
import { AssignNonMemberProps } from '@presenters/web/pages/Clubs/ManageClubOfficers/AssignNonMemberToRole';
import { SelectOfficerProps } from '@presenters/web/pages/Clubs/ManageClubOfficers/SelectOfficer/SelectOfficer';

import { TermToAssign } from '@domain/districts';

import { ClubLeadershipItem } from '@typings/graphql';
import {
  Address,
  BaseAddress,
  BasicLanguageInput,
  Club as ClubType,
  ClubTypeFilterValue,
  Country,
  Fax,
  Language,
  Meeting as MeetingOutput,
  MeetingType,
  MemberDetailsQuery,
  MembershipForAddMember,
  MembershipStatusFilter,
  MembershipType,
  OrganizationBase,
  PhoneNumber,
  SearchClubMeetingFragment,
  SearchClubRowFragment,
  UpdateIndividualMutationVariables,
  WeekdayFull,
} from '@typings/operations';

export type MeetingContent = Omit<MeetingOutput, '__typename'>;

export interface ClubMeetingValues {
  name?: string;
  languageId?: string;
  comment?: string;
  weekday?: WeekdayFull;
  frequency?: string;
  time: string;
  type?: MeetingType;
  locationVaries: boolean;
  locationNameOrDetail?: string;
  // flattened address
  // required for dependent validation
  lineOne?: string;
  lineTwo?: string;
  lineThree?: string;
  countryId: string;
  city?: string;
  stateId: string;
  internationalProvince: string;
  postalCode: string;
  // end flattened address
  detailForJoiningRemotely?: string;
  onlineLocation?: string;
  language?: string;
  longitude?: number | null;
  latitude?: number | null;
}

export interface VendorItem {
  name?: string | null;
  startDate?: string | null;
}

export type ContactInformationProps = {
  mailingAddress?: Omit<BaseAddress, 'type'> | null;
  isEditable: boolean;
  editContactInformationLink: string;
  primaryEmail?: string | null;
  primaryPhone?: Pick<PhoneNumber, 'number' | 'extension' | 'countryId'> | null;
  primaryFax?: Pick<Fax, 'number' | 'extension' | 'countryId'> | null;
  primaryWebsite?: string | null;
  officialLanguage?: Pick<Language, 'name'> | null;
  isUserLoggedIn?: boolean;
  countries: Country[];
  individualOperationAccess?: string | null;
};

export type EmailOutput = {
  email: string;
  isPrimary: boolean;
  id?: string;
};
export type WebsiteOutput = {
  primaryWebsite: string;
  otherWebsites?: string;
  id?: string;
};
export type GetClubContactInfoValues = Pick<
  ClubType,
  | 'primaryWebsite'
  | 'primaryEmail'
  | 'primaryFax'
  | 'primaryPhone'
  | 'officialLanguage'
> & { address: Omit<Address, 'type'> | null } & { email: EmailOutput } & {
  website: WebsiteOutput;
};

export type ContactAddress = {
  country?: string;
  lineOne: string;
  lineTwo?: string;
  lineThree?: string;
  countryId: string;
  city: string;
  state?: string;
  stateId?: string;
  postalCode: string;
  internationalProvince: string;
  hasStates?: boolean;
  id?: string | null;
};
export interface ClubContactInfoFormValues {
  address?: ContactAddress | null;
  primaryEmail?: string;
  primaryWebsite?: string;
  primaryPhone?: PhoneNumber;
  primaryFax?: Fax;
  officialLanguage: BasicLanguageInput;
  email?: EmailOutput;
  website?: WebsiteOutput;
}

export enum RolesForSelectClubOfficers {
  ClubFoundationChair = 'Club Foundation Chair',
  ClubMembershipChair = 'Club Membership Chair',
  ClubPublicImageChair = 'Club Public Image Chair',
  ClubServiceProjectsChair = 'Club Service Projects Chair',
  ClubPresident = 'Club President',
  ClubSecretary = 'Club Secretary',
  ClubTreasurer = 'Club Treasurer',
  ClubExecutiveSecretaryOrDirector = 'Club Executive Secretary/Director',
  ClubExecutiveDirectorAssistant = 'Club Executive Director Assistant',
  ClubRotractAdvisor = 'Rotaract Advisor',
}

export enum RolesForSelectRotaractOfficers {
  RotaractPresident = 'Rotaract President',
  RotaractServiceProjectsChair = 'Rotaract Service Projects Chair',
  RotaractMembershipChair = 'Rotaract Membership Chair',
  RotaractPublicImageChair = 'Rotaract Public Image Chair',
  RotaractTreasurer = 'Rotaract Treasurer',
  RotaractSecretary = 'Rotaract Secretary',
  RotaractFoundationChair = 'Rotaract Foundation Chair',
}

export enum ClubsPrefix {
  RotaryPrefix = 'Club',
  RotaractPrefix = 'Rotaract',
}

export type AssignMemberToRoleValues = Partial<
  SelectOfficerProps & AssignNonMemberProps & ConfirmOfficerValues
>;

export type Period = {
  start: Moment;
  end: Moment;
};

export type Entity = {
  id: string;
  name: string;
};

export type Club = {
  id: string;
  name: string;
  clubType: string;
  riClubId: string;
};

export type Individual = Entity & {
  photoUri?: string | null;
  email?: string | null;
  membershipAdmissionDate?: string | null;
  membershipTerminationDate?: string | null;
  otherEmails?: string[] | null;
  onlineId?: string | null;
  riIndividualId?: string | null;
  preferredLanguage?: string | null;
};

export type UpdateIndividual = Entity &
  MemberDetailsOutput & {
    photoUri?: string | null;
    email?: string | null;
    membershipAdmissionDate?: string | null;
    membershipTerminationDate?: string | null;
    otherEmails?: string[] | null;
    onlineId?: string | null;
  };

export type SelectRoleValues = {
  type: MembershipType;
  club: Entity;
  individual: Individual;
  role?: Entity;
  slot?: Period;
  customSlot?: Period | null;
  selectedTerm?: TermToAssign;
  email?: string;
  isMemberRow?: boolean;
  isReplaceNonMemberFlowEligible?: boolean;
};

export type ConfirmOfficerValues = {
  club: Entity;
  role: Entity;
  slot: Period;
  individual: Individual;
  editIndividual?: UpdateIndividual;
  customSlot?: Period | null;
  email?: string;
  individualToReplace?: UpdateIndividual;
  effectiveDate?: Date | Moment | string | null;
};

export type ReplaceRoleToMemberValues = {
  type: MembershipType;
  club: Entity;
  individual: Individual;
  role?: Entity;
  slot?: Period;
  customSlot?: Period | null;
  selectedTerm?: TermToAssign;
  email?: string;
  isMemberRow?: boolean;
  individualToReplace?: (Entity & Pick<Individual, 'email'>) | null;
  effectiveDate?: Date | Moment | string | null;
  leadershipId?: string | null;
  activeLeaders: ActiveLeaders[];
};

/**
 * Properties that are passed down to every wizard page.
 */
export type TerminateMembershipPageProps = {
  clubId: string;
  clubType: string;
  memberName: string;
  membershipType: string;
  admissionDate: string;
  dirty?: boolean;
  initialTouched?: boolean;
};

/**
 * All form properties collected in this wizard.
 */
export type TerminateMembershipValues = {
  terminationDate?: Date;
  terminationReason: string;
  terminationConfirmation: boolean;
};

export type ExtendedMemberDetails = Omit<
  MemberDetailsQuery['addMemberGetIndividual']['individual'],
  'yearOfBirth' | 'activeFullMemberships'
> & { primaryAddress: { hasStates?: boolean } };

export type MemberDetailsInput = ExtendedMemberDetails & {
  yearOfBirth: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  localizedName: string;
  gender: string;
  suffix?: string;
  prefix?: string;
  hasActiveMembership: boolean;
  recentMemberships: MembershipForAddMember[];
  id?: string;
};

export type MemberDetailsFormValues = MemberDetailsInput & {
  dayOfBirth: string;
  monthOfBirth: string;
};

export type MemberDetailsOutput = Omit<
  UpdateIndividualMutationVariables,
  'individualId'
>;

export interface IdentificationParameters {
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  id?: number | null;
  region?: string[];
  riClubId?: string[];
  clubTypes?: string[];
}

export type MembershipStatusOption = {
  value: MembershipStatusFilter;
  label: string;
  id: string;
};

export type ClubMembersList = Omit<
  MemberDetailsQuery['addMemberGetIndividual'],
  ''
>;

export interface MembershipData {
  memberType: string;
  admissionDate: string;
  sponsoredBy?: string[];
}

export interface FormMembershipData {
  memberType?: string;
  admissionDate?: string;
  sponsoredBy?: string[];
  procedureCertification: boolean;
}

export type MemberSearchFormValues = {
  name?: string;
  email?: string;
  location?: string;
  city?: string;
  country?: string;
  stateId?: string;
  district?: string;
  club?: string;
  districtRole?: string;
  districtRoles?: string[];
  clubRole?: string;
  clubRoles?: string[];
  memberClubType?: string;
  memberTypes?: string;
};

export enum MEMBER_SEARCH_FILTERS {
  NAME = 'name',
  EMAIL = 'email',
  LOCATION = 'location',
  CITY = 'city',
  COUNTRY = 'country',
  STATE_ID = 'stateId',
  DISTRICT = 'district',
  CLUB = 'club',
  DISTRICT_ROLE = 'districtRole',
  DISTRICT_ROLES = 'districtRoles',
  CLUB_ROLE = 'clubRole',
  CLUB_ROLES = 'clubRoles',
  MEMBER_CLUB_TYPE = 'memberClubType',
  MEMBER_TYPES = 'memberTypes',
}

export type SiteSearchFormValues = {
  keywords?: string;
  contentType?: string;
  documentMimeType?: string;
  langCode?: string;
  topic?: string;
};

export enum SITE_SEARCH_FILTERS {
  KEYWORDS = 'keywords',
  CONTENT_TYPE = 'contentType',
  DOCUMENT_MIMETYPE = 'documentMimeType',
  LANGCODE = 'langcode',
  TOPIC = 'topic',
}

export type ClubSearchFormValues = {
  clubName?: string;
  clubType?: ClubTypeFilterValue;
  weekday?: string;
  meetingType?: string;
  meetingLang?: string;
  meetingLocation?: string;
  meetingLocationLatitude?: number;
  meetingLocationLongitude?: number;
  meetingLocationRange?: number;
  distance?: string;
  distanceUnits?: string;
  advanced?: boolean;
  organizationBase?: OrganizationBase;
};

export enum CLUB_SEARCH_FILTERS {
  CLUB_NAME = 'clubName',
  CLUB_TYPE = 'clubType',
  WEEK_DAY = 'weekday',
  MEETING_TYPE = 'meetingType',
  MEETING_LANG = 'meetingLang',
  MEETING_LOCATION = 'meetingLocation',
  MEETING_LOCATION_LATITUDE = 'meetingLocationLatitude',
  MEETING_LOCATION_LONGITUDE = 'meetingLocationLongitude',
  MEETING_LOCATION_RANGE = 'meetingLocationRange',
  DISTANCE = 'distance',
  DISTANCE_UNITS = 'distanceUnits',
  ORGANIZATIONBASE = 'organizationBase',
}

export type LatLngLiteral = google.maps.LatLngLiteral;

export interface SearchClubNumberedMeetingFragment
  extends SearchClubMeetingFragment {
  xRiLocationNumber?: number;
  comment?: null | string[];
  url?: null | string;
}

export interface SearchClubRowFragmentWithNumberedMeetings
  extends SearchClubRowFragment {
  meetings: SearchClubNumberedMeetingFragment[];
}

export interface MeetingLocationCounter {
  nextNumber: number;
}

export enum CLUB_ROSTER_FILTERS {
  MEMBER_ROLES = 'roles',
  MEMBERSHIP_STATUSES = 'membershipStatuses',
  COUNTRIES_REGIONS = 'countriesRegions',
  CLUBS = 'clubs',
  CLUB_TYPES = 'clubTypes',
}

export enum AddOfficerSteps {
  IDENTIFY,
  INFO,
  MEMBERSHIP,
}

export enum ReplaceOfficerSteps {
  IDENTIFY,
  INFO,
  MEMBERSHIP,
}

export type ClubRosterFilter = {
  roles?: Option[];
  membershipStatuses?: Option[];
  countriesRegions?: Option[];
  clubs?: Option[];
  clubTypes?: Option[];
};

export type ClubRosterFilterProps = FilterProps<
  CLUB_ROSTER_FILTERS,
  ClubRosterFilter
>;

export type UnassignedRole = {
  id: string;
  name: string;
  mandatory: boolean;
  term?: {
    startDate: string;
    endDate: string;
  };
};

export type SearchFormValues = {
  memberId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type RotaryRotaractRolesMapping = {
  [key: string]: string[];
};

export type ClubTypeMapping = {
  [key: string]: string;
};

export enum CLUB_TABS {
  DETAILS = 'details',
  MEMBERS = 'members',
  FINANCES = 'finances',
  REPORTS = 'reports',
  GOALS = 'goals',
  MANAGE_MEMBERSHIP = 'manageMember',
}

export type ActiveLeaders = {
  __typename?: 'Leadership' | undefined;
  endDate: string;
  id: string;
  role: string;
  roleId: string;
  startDate: string;
};

export enum SITECORE_USER_TYPES {
  UNAUTHENTICATED = 'unauthenticated',
  NON_MEMBER = 'non-member',
  MEMBER = 'member',
  LEADER = 'leader',
  USER_LOADING = 'unavailable',
}

export type ClubLeadersContextType = Array<ClubLeadershipItem>;

import { useEffect, useState } from 'react';

export const useModal = (state: boolean) => {
  const [isShowing, show] = useState<boolean>(state || false);

  useEffect(() => {
    show(state);
  }, [state]);

  const toggle = () => show(!isShowing);
  const hide = () => show(false);

  return {
    isShowing,
    toggle,
    hide,
    show,
  };
};

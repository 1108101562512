// Libs
import { gql } from '@apollo/client';

// Query
import { ClubMemberSearchRow } from '@repositories/districts/queries';

export const fetchPreviousOfficersQuery = gql`
  query fetchPreviousOfficers(
    $riClubId: String!
    $yearRange: [Int!]!
    $clubRole: String!
    $pageSize: Int = 10
    $page: Int = 1
  ) {
    previousOfficers: searchPreviousOfficers(
      riClubId: $riClubId
      yearRange: $yearRange
      clubRole: $clubRole
      pageSize: $pageSize
      page: $page
    ) {
      totalCount
      results {
        ...ClubMemberSearchRow
      }
    }
  }
  ${ClubMemberSearchRow}
`;

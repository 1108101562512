import { useEffect } from 'react';

import mapValues from 'lodash/mapValues';

import { Option } from '@components/Formik/MultiSelectFilter/types';
import { SearchFormValues } from '@components/Formik/SearchMembersForm/types';

import { ClubRosterFilter } from '@domain/clubs';

import { getValuesFromOptions } from '@use-cases/districts';

import { useFetchClubMembersByFilter } from '@repositories/clubs';

import { ClubRosterQueryVariables } from '@typings/operations';

export const useFetchClubMembersWithFilters = (
  clubId: string,
  pageSize: number,
  currentPage: number,
  searchValues: SearchFormValues,
  filterValues: ClubRosterQueryVariables | ClubRosterFilter,
  isMultifilterAllowed?: boolean
) => {
  const [
    fetchClubMembersByFilter,
    { data, loading },
  ] = useFetchClubMembersByFilter(
    {
      ...filterValues,
      clubId,
    } as ClubRosterQueryVariables,
    currentPage,
    pageSize,
    isMultifilterAllowed
  );

  const filters = mapValues(filterValues, item =>
    getValuesFromOptions(item as Option[])
  );

  useEffect(() => {
    if (clubId) {
      fetchClubMembersByFilter({
        variables: {
          ...searchValues,
          ...(filters as ClubRosterQueryVariables),
          clubId,
          page: currentPage,
          pageSize,
          isMultifilterAllowed,
        },
      });
    }
  }, [pageSize, currentPage, searchValues, filterValues]);

  return {
    data,
    loading,
    filters,
  };
};

import { useQuery } from '@apollo/client';

import { fetchTerminationReasonQuery } from '@repositories/clubs';

import {
  TerminationReasonQuery,
  TerminationReasonQueryVariables,
} from '@typings/operations';

export const useTerminationReasons = (clubType: string) =>
  useQuery<TerminationReasonQuery, TerminationReasonQueryVariables>(
    fetchTerminationReasonQuery,
    {
      variables: { clubType },
    }
  );

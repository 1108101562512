import { AccessLevelsByPeriod, IsManager } from '@domain/districts';
import { AccessLevel } from '@typings/graphql';

export const getRolesByAccessLevels = ({
  currentYearPermissions,
  nextYearPermissions,
}: AccessLevelsByPeriod): Pick<IsManager, 'currentTerm' | 'nextTerm'> => ({
  currentTerm: currentYearPermissions === AccessLevel.Manager,
  nextTerm: nextYearPermissions === AccessLevel.Manager,
});

import * as React from 'react';

import { AccountData } from '@components/Header/types';
import Link from '@components/Link';

import UserMenu from '../UserMenu';

import { useMaintenanceModeCheck } from '@repositories/maintenance-mode/get-maintenance-mode';

import { delocalizedPath } from '@utils/localized-path';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const NON_DESTINATION_PATHS = ['login', 'signup', 'error'];

const UserLinks: React.FC<{
  className?: string;
  linkClassName?: string;
  buttonClassName?: string;
  accountInfo: AccountData;
  toggleMenu?: () => void;
}> = ({
  className,
  linkClassName,
  buttonClassName,
  accountInfo,
  toggleMenu,
}) => {
  const { user, login } = useAppConfig();
  const { t } = useTranslation();
  const { mutate } = useMaintenanceModeCheck();

  const shouldSetDestination = () => {
    if (typeof window !== 'undefined') {
      const path = delocalizedPath(window.location.pathname);
      const destination = path.replace('/', '');
      const hasDestination =
        Boolean(destination) && !NON_DESTINATION_PATHS.includes(destination);

      return hasDestination;
    }

    return false;
  };

  const handleLogin = async () => {
    // Trigger swr to make a new call for getMaintenanceMode call
    mutate();
    login(shouldSetDestination());
    if (toggleMenu) {
      toggleMenu();
    }
  };

  const handleRegister = async () => {
    mutate();
    if (toggleMenu) {
      toggleMenu();
    }
  };

  if (user?.login === '') {
    return (
      <div className={`ml-3 ${className}`}>
        <Link to="/signup" className={linkClassName} onClick={handleRegister}>
          {t('global.user.register-button-label', 'Register')}
        </Link>
        <button
          type="button"
          className={buttonClassName}
          onClick={handleLogin}
          disabled={false}
        >
          {t('global.user.login-button-label', 'Sign In')}
        </button>
      </div>
    );
  }

  return accountInfo ? (
    <div className={`ml-3 ${className}`}>
      <UserMenu toggleMenu={toggleMenu} accountInfo={accountInfo} />
    </div>
  ) : null;
};

export default UserLinks;

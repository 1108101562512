import { gql } from '@apollo/client';

export const updateAreasOfExpertiseMutation = gql`
  mutation updateAreasOfExpertise(
    $individualId: String!
    $primaryExpertise: ExpertiseInput
    $otherExpertise: [ExpertiseInput!]
    $sharingPermissionsExtended: SharingPermissionsInput!
  ) {
    updateIndividual(
      input: {
        individualId: $individualId
        primaryExpertise: $primaryExpertise
        otherExpertise: $otherExpertise
        sharingPermissionsExtended: $sharingPermissionsExtended
      }
    ) {
      individualId
    }
  }
`;

exports.onRouteUpdate = (props, pluginOptions) => {
  // Avoid pageview event if the auth check hasn't taken place yet.
  // The initial pageview is triggered after auth check in  src/hooks/auth.tsx
  if (
    (process.env.NODE_ENV === 'production' ||
      pluginOptions.includeInDevelopment) &&
    typeof window._satellite !== 'undefined' &&
    typeof window.__gatsby_user !== 'undefined' &&
    window.rotaryDDO.excludePageView !== true
  ) {
    const routeChangeEventName =
      pluginOptions.routeChangeEventName || 'gatsbyRouteChange';

    // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
    // reactHelmet is using requestAnimationFrame: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
    setTimeout(() => {
      // Dispatch route change event to target in a rule.
      window._satellite.track(routeChangeEventName);
    }, 32);
  }
};

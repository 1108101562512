import { gql, useLazyQuery, useQuery } from '@apollo/client';

import {
  FetchAllDistrictClubsQuery,
  FetchAllDistrictClubsQueryVariables,
} from '@typings/operations';

export const fetchAllDistrictClubsQuery = gql`
  query fetchAllDistrictClubs(
    $districtId: String!
    $status: String
    $date: String
    $hasAGAffiliation: Boolean
    $isExtraData: Boolean
  ) {
    districtClubs: getAllDistrictClubs(
      districtId: $districtId
      status: $status
      date: $date
      hasAGAffiliation: $hasAGAffiliation
      isExtraData: $isExtraData
    ) {
      totalCount
      clubs {
        id
        riClubId
        name
        type
        status
        location {
          country
          state
          countryId
          city
          stateId
          internationalProvince
        }
      }
    }
  }
`;

export const useFetchAllDistrictClubs = () =>
  useLazyQuery<FetchAllDistrictClubsQuery, FetchAllDistrictClubsQueryVariables>(
    fetchAllDistrictClubsQuery
  );

export const useFetchAllDistrictClubsOnLoad = (districtId: string) =>
  useQuery<FetchAllDistrictClubsQuery, FetchAllDistrictClubsQueryVariables>(
    fetchAllDistrictClubsQuery,
    {
      variables: {
        districtId,
      },
    }
  );

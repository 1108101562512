import { gql, useLazyQuery } from '@apollo/client';

import {
  GetDistrictClubsQuery,
  GetDistrictClubsQueryVariables,
} from '@typings/operations';

export const fetchDistrictClubsQuery = gql`
  query getDistrictClubs(
    $districtId: String!
    $rotaryYear: String
    $status: [String]
    $type: String
    $page: Int
    $pageSize: Int
  ) {
    districtClubs: getDistrictClubs(
      districtId: $districtId
      rotaryYear: $rotaryYear
      status: $status
      type: $type
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      clubs {
        id
        riClubId
        name
        type
        status
        location {
          country
          state
          countryId
          city
          stateId
          internationalProvince
        }
        activeMembersCount
      }
    }
  }
`;

export const useFetchDistrictClubs = () =>
  useLazyQuery<GetDistrictClubsQuery, GetDistrictClubsQueryVariables>(
    fetchDistrictClubsQuery
  );

import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import FooterV2NPM from '@rotaryintl/component-footer-v2';
import { createLink } from '../../utilities/link-helpers';

const Footer = ({
  fields: {
    children: DataFolders,
    FooterLinksFolder: FooterLinksFolderField,
    UtilityLinksFolder: UtilityLinksFolderField,
    SocialLinksFolder: SocialLinksFolderField,
    FooterLogo,
    FooterLogoText,
    CopyrightText,
  } = {},
}) => {
  const footerLinkItems = DataFolders?.find((e) => e.id === FooterLinksFolderField?.id)?.fields?.children;
  const footerLinks = footerLinkItems?.map(({ fields: { Link, LinkText, ShowExternalLinkIcon } = {} }) => createLink(Link, LinkText, ShowExternalLinkIcon));

  const utilityLinkItems = DataFolders?.find((e) => e.id === UtilityLinksFolderField?.id)?.fields?.children;
  const utilityLinks = utilityLinkItems?.map(({ fields: { UtilityLink, UtilityLinkText } = {} }) => createLink(UtilityLink, UtilityLinkText));

  const socialLinkItems = DataFolders?.find((e) => e.id === SocialLinksFolderField?.id)?.fields?.children;
  const socialLinks = socialLinkItems?.map(({ fields: { Link, LinkText, Icon } = {} }) => ({
    link: createLink(Link, LinkText),
    image: {
      imageUrl: Icon?.value?.src,
      imageAlt: Icon?.value?.alt,
    },
  }));

  return (
    <FooterV2NPM
      logoImage={<Image field={FooterLogo} />}
      logoText={<Text field={FooterLogoText} />}
      logoLink={{ linkUrl: '/', linkText: '', linkTarget: '_self' }}
      copyrightText={CopyrightText?.value}
      footerLinks={footerLinks}
      utilityLinks={utilityLinks}
      socialLinks={socialLinks}
    />
  );
};

export default withSitecoreContext()(Footer);

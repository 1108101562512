import React from 'react';
import { Text, RichText, withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import GenericHeroNPM from '@rotaryintl/component-generic-hero';
import { createLink } from '../../utilities/link-helpers';

const GenericHero = ({
  rendering,
  fields: {
    Title,
    Description,
    BackgroundImageDesktop,
    BackgroundImageMobile,
    PrimaryLink,
    PrimaryLinkText,
    SecondaryLink,
    SecondaryLinkText,
    LinkJoinerText,
  } = {},
}) => (
  <GenericHeroNPM
    title={<Text field={Title} />}
    description={<RichText field={Description} />}
    backgroundImageDesktop={BackgroundImageDesktop?.value?.src}
    backgroundImageMobile={BackgroundImageMobile?.value?.src}
    backgroundImageAlt={BackgroundImageDesktop?.value?.alt}
    primaryLink={createLink(PrimaryLink, PrimaryLinkText)}
    secondaryLink={createLink(SecondaryLink, SecondaryLinkText)}
    linkJoinerText={<Text field={LinkJoinerText} />}
    placeholder={<Placeholder name="generic-hero-bottom" rendering={rendering} />}
  />
);

export default withSitecoreContext()(GenericHero);

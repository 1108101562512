// Libs
import * as R from 'ramda';
import { pipe } from 'fp-ts/lib/function';

// Utils
import {
  isSameOrBefore,
  isSameOrAfter,
  getCurrentDate,
  getFormattedDate,
} from '@utils/datetime';

export type LeadershipDate = {
  startDate: string;
  endDate: string;
};

export const getFormattedStartDate = (item: LeadershipDate) =>
  pipe(item, R.prop('startDate'), getFormattedDate);

export const getFormattedEndDate = (item: LeadershipDate) =>
  pipe(item, R.prop('endDate'), getFormattedDate);

export const isAfterStartDate = (item: LeadershipDate) =>
  pipe(item, getFormattedStartDate, isSameOrBefore(getCurrentDate()));

export const isBeforeEndDate = (item: LeadershipDate) =>
  pipe(item, getFormattedEndDate, isSameOrAfter(getCurrentDate()));

export const isActiveLeadership = (item: LeadershipDate): boolean =>
  isAfterStartDate(item) && isBeforeEndDate(item);

export const isActiveLeadershipInFuturetRY = (
  startDate: string,
  item: LeadershipDate
): boolean => pipe(item, getFormattedStartDate, isSameOrAfter(startDate));

import { getRotaryYear } from '@utils/datetime';
import { AccessLevel, GetAccessLevelsQuery } from '@typings/operations';

export const getManagementYear = (accessLevelData?: GetAccessLevelsQuery) =>
  accessLevelData?.results.find(({ level }) => level === AccessLevel.Manager)
    ?.arguments?.targetTermYear;

export const getFutureManagementYear = (
  accessLevelData?: GetAccessLevelsQuery
) =>
  accessLevelData?.results.find(
    ({ level, arguments: args }) =>
      level === AccessLevel.Manager &&
      args?.targetTermYear === `${Number(getRotaryYear()) + 1}`
  )?.arguments?.targetTermYear;

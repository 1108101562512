import { gql } from '@apollo/client';

export const addSponsorMutation = gql`
  mutation AddSponsor(
    $clubId: String!
    $membershipId: String!
    $sponsorIndividualId: [String!]
  ) {
    addSponsor(
      clubId: $clubId
      membershipId: $membershipId
      sponsorIndividualId: $sponsorIndividualId
    )
  }
`;

import { useQuery } from '@apollo/client';

import { fetchMemberDetailsQuery } from '@repositories/clubs';

import {
  MemberDetailsQuery,
  MemberDetailsQueryVariables,
} from '@typings/operations';

export const useFetchMemberDetails = (
  id: string,
  clubId?: string,
  access?: string
) =>
  useQuery<MemberDetailsQuery, MemberDetailsQueryVariables>(
    fetchMemberDetailsQuery,
    {
      variables: {
        id,
        clubId,
        access,
      },
      // Don't attempt to run this query if no data has been selected.
      skip: (id || '') === '',
    }
  );

import { gql, useLazyQuery } from '@apollo/client';
import {
  GetAllAssistantGovernorsQuery,
  GetAllAssistantGovernorsQueryVariables,
} from '@typings/operations';

export const useFetchAllDistrictAGs = () =>
  useLazyQuery<
    GetAllAssistantGovernorsQuery,
    GetAllAssistantGovernorsQueryVariables
  >(
    gql`
      query getAllAssistantGovernors($districtId: String!) {
        ags: getAllAssistantGovernors(districtId: $districtId) {
          agId
          firstName
          lastName
          middleName
          prefix
          suffix
          localizedName
          clubsAffiliations {
            clubId
            clubName
            clubType
          }
        }
      }
    `
  );

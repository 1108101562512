import { gql } from '@apollo/client';

export const fetchDistrictRolesQuery = gql`
  query getDistrictRoles(
    $districtId: String!
    $userId: String!
    $date: String!
    $orgType: [String!]
  ) {
    district: getDistrictDetails(districtId: $districtId, userId: $userId) {
      id
      riDistrictId
    }
    roles(organizationType: $orgType, validOn: $date) {
      id
      name
    }
  }
`;

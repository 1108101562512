// Types
import { Role } from '@domain/districts';
import { GetRolesQuery } from '@typings/operations';

export const getFormattedRoles = (rolesData?: GetRolesQuery): Role[] =>
  rolesData?.roles?.map(({ id, name, termLength, isOfficer }) => ({
    id,
    value: name,
    detail: {
      TermLength: termLength!,
      IsOfficer: isOfficer!,
    },
  })) || [];

// Libs
import moment, { Moment } from 'moment';
import { last, zip } from 'lodash';

// Types
import { DORole } from '@domain/districts';

const sortTerms = <T extends { startDate: string }>(terms: T[]) => {
  terms.sort((prv, nxt) => moment(prv.startDate).diff(moment(nxt.startDate)));
};

export const buildUnassignedRoleTerms = (
  year: number,
  role: DORole
): DORole => {
  const { terms } = role;
  const dateFormat = 'YYYY-MM-DD';
  const RYStart = moment(`${year - 1}-07-01`);
  const RYEnd = moment(`${year}-06-30`);
  sortTerms(terms);

  const starts: Array<Moment | null> = [
    ...[
      terms.length && RYStart.isSame(moment(terms[0]?.startDate), 'day')
        ? null
        : RYStart,
    ],
    ...terms.map(({ endDate }) => moment(endDate).add(1, 'day')),
  ];

  const ends: Array<Moment | null> = [
    ...terms.map(({ startDate }) => moment(startDate).subtract(1, 'day')),
    ...[
      terms.length && RYEnd.isSame(moment(last(terms)?.endDate), 'day')
        ? null
        : RYEnd,
    ],
  ];

  zip(starts, ends).forEach(([start, end]) => {
    // the 3rd check with `isSameOrAfter` is necessary to handle the cases
    // when a RY is fully covered with multiple terms, i.e. no gaps
    if (start && end && moment(end).isSameOrAfter(moment(start), 'day')) {
      terms.push({
        isAssignable:
          RYEnd.isSameOrAfter(moment(), 'day') &&
          end.format('MM-DD') === '06-30',
        startDate: start.format(dateFormat),
        endDate: end.format(dateFormat),
      });
    }
  });

  sortTerms(terms);
  return role;
};

import { gql } from '@apollo/client';

export const editMeetingQuery = gql`
  mutation editMeeting(
    $clubId: String!
    $dominoId: String
    $meetingId: String!
    $meeting: MeetingInput!
  ) {
    editMeeting(
      input: {
        clubId: $clubId
        dominoId: $dominoId
        meetingId: $meetingId
        meeting: $meeting
      }
    ) {
      meetingId
      meeting {
        name
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const fetchClubsByNameQuery = gql`
  query IdentifyMemberAutocompleteClubInput(
    $clubName: String = ""
    $clubTypes: [ClubTypeFilterValue!]
    $advanced: Boolean = true
    $pageSize: Int = 100
  ) {
    clubs: searchClub(
      clubNamePartial: $clubName
      clubTypes: $clubTypes
      advanced: $advanced
      pageSize: $pageSize
    ) {
      totalCount
      results {
        riClubId
        name
        type
        physicalLocation {
          country
          state
          countryId
          city
          stateId
          internationalProvince
        }
      }
    }
  }
`;

import { useMutation } from '@apollo/client';
import {
  ReplaceAgClubAffiliationsMutation,
  ReplaceAgClubAffiliationsMutationVariables,
} from '@typings/operations';
import { replaceAgClubAffiliationsQuery } from '../queries';

export const useReplaceAgClubAffiliations = () =>
  useMutation<
    ReplaceAgClubAffiliationsMutation,
    ReplaceAgClubAffiliationsMutationVariables
  >(replaceAgClubAffiliationsQuery);

// Types
import { MemberURLParams } from '@domain/districts';
import { has } from 'lodash';

export const flattenObject = (
  obj: Object,
  result: MemberURLParams = {},
  parent?: string
) => {
  for (const key in obj) {
    const propName = parent ? parent + '.' + key : key;
    if (typeof obj[key as keyof typeof obj] == 'object') {
      flattenObject(obj[key as keyof typeof obj], result, propName);
    } else {
      (result as Record<string, Object>)[propName] =
        obj[key as keyof typeof obj];
    }
  }
  return result;
};

/** Unflatten back to nested Object */
export const unflattenObject = (flattenedObj?: Object) => {
  const result: Record<string, any> = {};
  for (const i in flattenedObj) {
    if (has(flattenedObj, i)) {
      const keys = i.match(/^\.+[^.]*|[^.]*\.+$|(?:\.{2,}|[^.])+(?:\.+$)?/g);
      keys?.reduce((prevValue, currentValue, index) => {
        return (
          prevValue[currentValue] ||
          (prevValue[currentValue] = Number.isNaN(Number(keys[index + 1]))
            ? keys.length - 1 === index
              ? flattenedObj[i as keyof typeof flattenedObj]
              : {}
            : [])
        );
      }, result);
    }
  }
  return result;
};

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import history from 'history/browser';

// It's implementation for the browser.
// For node.js see https://docs.microsoft.com/en-us/azure/azure-monitor/app/nodejs.

const reactPlugin: ReactPlugin = new ReactPlugin();
const appInsights: ApplicationInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.GATSBY_AZURE_APPLICATION_INSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});

export default appInsights;

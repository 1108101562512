import { gql } from '@apollo/client';

const IndividualResultRow = gql`
  fragment IndividualResultRow on IndividualSearchResult {
    id
    riIndividualId
    name
    email
    phoneNumber
    activeMemberships {
      clubName
      type
      terminationDate
      clubType
    }
    location
    address {
      lineOne
      lineTwo
      lineThree
      postalCode
      city
      stateId
      state
      country
      type
    }
    photoUri
    sharingPermission {
      address
      phone
      name
      email
      memberId
    }
  }
`;

export default gql`
  query SearchIndividual(
    $firstName: String
    $lastName: String
    $email: String
    $riIndividualId: String
    $riClubId: [String] = []
    $clubName: String
    $countryId: [String] = []
    $clubTypes: [String] = []
    $pageSize: Int
    $page: Int = 1
    $advanced: Boolean = false
    $searchNonMembers: Boolean = false
    $emailExactMatch: Boolean = false
  ) {
    searchIndividual(
      firstName: $firstName
      lastName: $lastName
      email: $email
      riIndividualId: $riIndividualId
      riClubId: $riClubId
      clubName: $clubName
      countryId: $countryId
      clubTypes: $clubTypes
      pageSize: $pageSize
      page: $page
      advanced: $advanced
      searchNonMembers: $searchNonMembers
      emailExactMatch: $emailExactMatch
    ) {
      totalCount
      results {
        ...IndividualResultRow
      }
    }
  }
  ${IndividualResultRow}
`;

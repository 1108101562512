import { getAge } from '@utils/datetime';
import { UserWithAnalytics } from '@domain/auth';

export const getAnalyticsUser = (data: UserWithAnalytics) => {
  const { user, analytics } = data;
  if (!user || !analytics) {
    return data;
  }
  return {
    user: user,
    analytics: {
      nfKey: user.nfKey,
      age: analytics.yearOfBirth && getAge(analytics.yearOfBirth),
      roles: analytics.resumeRoles,
    },
  };
};

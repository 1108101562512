import { gql } from '@apollo/client';

export const fetchIndividualInformationQuery = gql`
  query fetchIndividualInformation($riIndividualId: Int!) {
    individual: getIndividual(riIndividualId: $riIndividualId) {
      id
      firstName
      middleName
      lastName
      riIndividualId
      localizedName
      gender
      genderDescription
      yearOfBirth
      suffix
      prefix
      dateOfBirth
      youthFlag
      preferredLanguage
      primaryEmail {
        type
        address
        isSignin
      }
    }
  }
`;

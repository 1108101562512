import { useMutation } from '@apollo/client';
import { addClubVendorMutation } from '@repositories/clubs';

// Types
import {
  AddClubVendorMutation,
  AddClubVendorMutationVariables,
} from '@typings/operations';

export const useAddClubVendor = () => {
  return useMutation<AddClubVendorMutation, AddClubVendorMutationVariables>(
    addClubVendorMutation
  );
};

import { useEffect } from 'react';

import { isNil } from 'lodash';

type StopBrowserNavigateProps = {
  showModal?: (state: boolean) => void;
  setIsBack?: (state: boolean) => void;
  isStepMax?: boolean;
  backUrl?: string;
  isNextStepVisited?: boolean;
  onNavigate?: () => void;
};

export const useStopBrowserNavigate = ({
  showModal,
  setIsBack,
  isStepMax,
  backUrl,
  isNextStepVisited,
  onNavigate,
}: StopBrowserNavigateProps) => {
  /** Hide Modal pop-up called on browser navigate */
  const globalHide = () => {
    window.stopBrowserNavigate = false;
    showModal?.(false);
  };

  const setGlobal = (flag?: boolean) => {
    window.stopBrowserNavigate = isNil(flag) || flag;
  };

  const eventListener = () => {
    isNextStepVisited ? onNavigate?.() : setGlobal();
    window.history.pushState(backUrl || null, '');
  };

  useEffect(() => {
    if (!isStepMax && !isNextStepVisited) {
      return;
    }

    setIsBack?.(true);

    window.history.pushState(backUrl || null, '');
    window.addEventListener('popstate', eventListener);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('popstate', eventListener);
  }, [isStepMax, isNextStepVisited, onNavigate]);

  return {
    globalHide,
    setGlobal,
  };
};

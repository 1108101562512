// Utils
import { getYearsRange } from '@use-cases/districts';

// Types
import { OptionTypeBase } from '@external/react-select';
import { TFunction } from 'i18next';

// Constants
import { TERM_ALL } from '@domain/districts';

export default (year: number, t: TFunction): OptionTypeBase[] => {
  const yearsCount = 5;
  const allOption = [{ value: TERM_ALL, label: t('select-term.all', 'All') }];

  const options = getYearsRange(year, yearsCount).map((year: number) => ({
    value: String(year),
    label: `${year - 1}-${year}`,
  }));

  return [...allOption, ...options];
};

import { gql } from '@apollo/client';

export const fetchClubSearch = gql`
  fragment SearchClubRow on ClubSearchResult {
    id
    type
    name
    fullName
    meetings {
      ...SearchClubMeeting
    }
  }

  fragment SearchClubMeeting on ClubSearchResultMeeting {
    geo {
      latitude
      longitude
    }
    xRiAddress {
      city
      state
      country
    }
    description
    xRiLanguageId
    xRiLanguageName
    xRiTime
    xRiWeekday
    categories
    comment
    url
  }

  query SearchClub(
    $clubName: String = ""
    $clubType: ClubTypeFilterValue
    $weekday: String = ""
    $meetingType: MeetingTypeFilterValue
    $meetingLang: String = ""
    $meetingLocationLatitude: Float
    $meetingLocationLongitude: Float
    $meetingLocationRange: Float
    $page: Int = 1
    $pageSize: Int = 10
    $status: String
    $advanced: Boolean = true
    $organizationBase: String = ""
  ) {
    clubs: searchClub(
      keyword: $clubName
      clubType: $clubType
      meetingDay: $weekday
      meetingType: $meetingType
      meetingLanguageId: $meetingLang
      meetingLocationLatitude: $meetingLocationLatitude
      meetingLocationLongitude: $meetingLocationLongitude
      meetingLocationRange: $meetingLocationRange
      pageSize: $pageSize
      page: $page
      status: $status
      advanced: $advanced
      organizationBase: $organizationBase
    ) {
      totalCount
      results {
        ...SearchClubRow
      }
    }
  }
`;

// Libs
import { gql, useLazyQuery } from '@apollo/client';

// Types
import {
  GetAssistantGovernorsPaginateQuery,
  GetAssistantGovernorsPaginateQueryVariables,
} from '@typings/operations';

export const AG_FIELDS = gql`
  fragment AGFields on AssistantGovernor {
    id
    agId
    firstName
    lastName
    middleName
    prefix
    suffix
    localizedName
    phone
    email
    photoUri
    isDeceased
    termInfo {
      startDate
      endDate
    }
    districtLeaderships {
      role
      startDate
      endDate
      id
      riDistrictId
    }
    clubsAffiliations {
      clubId
      clubName
      clubType
      location {
        country
        city
        internationalProvince
        state
      }
    }
    membershipClubs {
      id
      clubId
      clubName
      clubType
      membershipType
      terminationDate
      location {
        country
        city
        internationalProvince
        state
      }
    }
  }
`;

export const fetchDistrictAGsPaginateQuery = gql`
  ${AG_FIELDS}
  query getAssistantGovernorsPaginate(
    $districtId: String!
    $date: String
    $currentPage: Int = 1
    $pageSize: Int = 10
  ) {
    result: getAssistantGovernorsPaginate(
      districtId: $districtId
      date: $date
      currentPage: $currentPage
      pageSize: $pageSize
    ) {
      totalCount
      ags {
        ...AGFields
      }
    }
  }
`;

export const useFetchDistrictAGsPaginate = () =>
  useLazyQuery<
    GetAssistantGovernorsPaginateQuery,
    GetAssistantGovernorsPaginateQueryVariables
  >(fetchDistrictAGsPaginateQuery);

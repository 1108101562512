import { gql } from '@apollo/client';

export const editProgramMutation = gql`
  mutation updateProgram(
    $individualId: String!
    $individualProgramId: String!
    $fields: [ProgramFieldInput!]
  ) {
    updateIndividualProgram(
      input: {
        individualId: $individualId
        individualProgramId: $individualProgramId
        fields: $fields
      }
    ) {
      individualProgramId
    }
  }
`;

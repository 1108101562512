import { getCountryCode } from '@utils/getCountryCode';

import { AccPhone, Country } from '@typings/graphql';

export default (
  phone: AccPhone,
  countries?: Pick<Country, 'id' | 'name' | 'shortCode'>[]
) => {
  const country = countries?.find(country => country.id === phone.countryId);
  const countryPhoneCode = getCountryCode(country?.shortCode);

  return `${countryPhoneCode && `+${countryPhoneCode}`} ${phone.number}`;
};

import { useEffect } from 'react';

import { useFetchAllDistrictOfficers } from '@repositories/districts';

import { DistrictOfficer } from '@typings/graphql';

export const useFetchDistrictOfficersData = (
  districtId: string,
  riDistrictId?: number | null,
  rotaryYear?: string
) => {
  const [
    getAllDistrictOfficers,
    { data: officersData, loading: officersLoading },
  ] = useFetchAllDistrictOfficers();

  useEffect(() => {
    if (districtId && rotaryYear && riDistrictId) {
      getAllDistrictOfficers({
        variables: {
          districtId,
          riDistrictId,
          rotaryYear,
        },
      });
    }
  }, [districtId, rotaryYear, riDistrictId]);

  return {
    officersData:
      (officersData?.districtOfficers.leadership as DistrictOfficer[]) || [],
    officersLoading,
  };
};

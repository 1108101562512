import { useEffect, useState } from 'react';

import { isEqual } from 'lodash';

import {
  ConfirmOfficerValues,
  FormMembershipData,
  MemberDetailsFormValues,
} from '@domain/clubs';

type FormValuesType =
  | MemberDetailsFormValues
  | FormMembershipData
  | Pick<ConfirmOfficerValues, 'customSlot' | 'email'>;

export const useTouchedFormFields = (
  handleFormFieldsTouched?: (isTouched: boolean) => void
) => {
  const [isFormFieldsTouched, setIsFormFieldsTouched] = useState<boolean>(
    false
  );

  useEffect(() => {
    handleFormFieldsTouched?.(isFormFieldsTouched);
  }, [isFormFieldsTouched]);

  const checkTouchedFields = (
    initialValues: FormValuesType,
    values: FormValuesType
  ) => {
    if (!isFormFieldsTouched && !isEqual(initialValues, values)) {
      setTimeout(() => setIsFormFieldsTouched(true), 0);
    }
    if (isFormFieldsTouched && isEqual(initialValues, values)) {
      setTimeout(() => setIsFormFieldsTouched(false), 0);
    }
  };

  return {
    checkTouchedFields,
  };
};

import { useQuery } from '@apollo/client';
import { fetchClubVendorsQuery } from '@repositories/clubs';

// Types
import {
  FetchVendorsQuery,
  FetchVendorsQueryVariables,
} from '@typings/operations';

export const useFetchClubVendors = (clubId: string) =>
  useQuery<FetchVendorsQuery, FetchVendorsQueryVariables>(
    fetchClubVendorsQuery,
    {
      variables: { clubId },
      fetchPolicy: 'cache-and-network',
    }
  );

import { useMutation } from '@apollo/client';

import { assignClubLeadershipQuery } from '@repositories/clubs';

import {
  AssignLeadershipRoleMutation,
  AssignLeadershipRoleMutationVariables,
} from '@typings/operations';

export const useAssignClubLeadership = () =>
  useMutation<
    AssignLeadershipRoleMutation,
    AssignLeadershipRoleMutationVariables
  >(assignClubLeadershipQuery);

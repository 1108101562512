import { ApolloClient, InMemoryCache } from '@apollo/client';
import { defaultLanguage } from '../../../../languages';
import { Lang } from '@domain/language-negotiation';

import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as T from 'fp-ts/lib/Task';

import { lazyPreferredLangs } from '@repositories/language-negotiation/queries';
import { extractUserPreferredLang } from '@repositories/language-negotiation/pipelines';

// negotiateLangByIndividualId :: (client: ApolloClient<InMemoryCache>, individualId: string) -> Task<Lang>
export const negotiateLangByIndividualId = (
  client: ApolloClient<InMemoryCache>,
  individualId: string
): T.Task<Lang> =>
  pipe(
    lazyPreferredLangs({ individualId })(client),
    TE.map(extractUserPreferredLang),
    TE.getOrElse(
      (): T.Task<Lang> => {
        return T.of(defaultLanguage);
      }
    )
  );

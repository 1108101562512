import { ceil } from 'lodash';

export default (chartDataMaxValue: number) => {
  let roundedPrecision = -1;

  if (chartDataMaxValue >= 1000) {
    roundedPrecision = -2;
  }

  return ceil(chartDataMaxValue, roundedPrecision);
};

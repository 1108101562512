import { RIBI_COUNTRY_LIST } from '@domain/common';
import { log } from '@hooks/logger';
import { DIS } from '@typings/dis';
import { LogLevel } from '@typings/operations';
import axios from 'axios';

export const getClubInvoiceReport = async (clubId: string) => {
  // Parse query parameters
  const urlParams = new URLSearchParams(window.location.search);
  const docType = urlParams.get('docType') || 'I';
  const isCurrentInvoice = urlParams.get('isCurrentInvoice') === 'true';

  const fetchClubInvoiceDataQuery = `
  query fetchClubInvoiceData($clubId: String!) {
    club: clubById(clubId: $clubId) {
      clubType
      riClubId
      operationsAccess {
        leaderships
      }
      physicalLocation {       
        country
      }
    }
  }
  `;

  const graphqlQuery = `
  query fetchClubInvoiceLink($clubId: Int!, $clubType: Int!, $docType: String!, $isCurrentInvoice: Boolean!) {
    getClubInvoiceReport(clubId: $clubId, clubType: $clubType, docType: $docType, isCurrentInvoice: $isCurrentInvoice, ) {
      fileContent
    }
  }
  `;

  const graphqlEndpoint = `${process.env.GATSBY_MRX_API_BUILD_URL}/graphql`;

  try {
    // Fetch club invoice data
    const response = await axios.post(
      graphqlEndpoint,
      {
        query: fetchClubInvoiceDataQuery,
        variables: { clubId },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    const data = response?.data?.data?.club;
    const isManager = data.operationsAccess.leaderships === 'Manager';
    const clubCountry = data?.physicalLocation?.country || '';
    const isRibiCountry =
      RIBI_COUNTRY_LIST.includes(clubCountry) &&
      data.clubType === DIS.ClubTypeEnum.RotaryClub;

    let clubType;

    if (data?.clubType === 'Rotary_Club') {
      clubType = 3;
    } else if (data?.clubType === 'Rotaract_Club') {
      clubType = 4;
    }

    if (isManager && !isRibiCountry) {
      const pdfVariables = {
        clubType,
        clubId: Number(data?.riClubId),
        docType,
        isCurrentInvoice,
      };

      try {
        const pdfResponse = await axios.post(
          graphqlEndpoint,
          {
            query: graphqlQuery,
            variables: pdfVariables,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );

        const pdfData = pdfResponse?.data?.data?.getClubInvoiceReport;

        if (pdfData) {
          const pdfWindow = window.open('', '_blank');
          if (pdfWindow) {
            pdfWindow.document.write(
              `<iframe width="100%" height="100%" src="data:application/pdf;base64,${encodeURIComponent(
                pdfData.fileContent ?? ''
              )}"></iframe>`
            );
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          log({
            level: LogLevel.Debug,
            message: `Gatsby: getClubInvoiceReport, 'GraphQL Request Failed:', ${error.message}`,
          });
        } else {
          log({
            level: LogLevel.Debug,
            message: `Gatsby: getClubInvoiceReport, 'Unknown error occurred:', ${error}`,
          });
        }
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchClubInvoiceData, 'GraphQL Request Failed:', ${error.message}`,
      });
    } else {
      log({
        level: LogLevel.Debug,
        message: `Gatsby: fetchClubInvoiceData, 'Unknown error occurred:', ${error}`,
      });
    }
  }
};

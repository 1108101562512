import React, { ErrorInfo } from 'react';
import { OneColumn } from '@components/Layouts/OneColumn';
import { log } from '@hooks/logger';
import { LogLevel } from '@typings/operations';

interface ErrorProps {}
interface ErrorState {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log({
      level: LogLevel.Error,
      message: error.toString(),
      other: {
        errorMessage: error.message,
        errorName: error.name,
        errorStack: error.stack,
        componentStack: errorInfo.componentStack,
      },
    });
  }
  render() {
    if (this.state.hasError) {
      return <OneColumn>an error has occurred</OneColumn>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

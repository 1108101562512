// Types
import { CommitteeMemberData, DistrictAG } from '../types';

// Constants
import { ASSISTANT_GOVERNOR, ASSISTANT_GOVERNOR_ROLE_ID } from '../constants';

export const mapAGToCommitteeMemberData = (
  ag: DistrictAG,
  fullName: string
): CommitteeMemberData => ({
  id: ag.agId,
  photoUri: ag.photoUri,
  phoneNumber: ag.phone,
  email: ag.email,
  nameWithPrefixSuffix: fullName,
  localizedName: ag.localizedName,
  associatedClubsInfo: [],
  activeMemberships: [],
  thisDistrictLeadership: [
    {
      id: ag.id,
      roleId: ASSISTANT_GOVERNOR_ROLE_ID,
      role: ASSISTANT_GOVERNOR,
      startDate: ag.termInfo!.startDate,
      endDate: ag.termInfo!.endDate,
    },
  ],
});

import moment, { Moment } from 'moment';

import {
  useAssignClubLeadership,
  useRemoveOfficerMutation,
} from '@repositories/clubs';
import { useUpdateContactInformation } from '@repositories/profile/hooks';

import { EmailType } from '@typings/resolvers';

export type Props = {
  clubId: string;
};

export type ReplaceClubLeadership = {
  individualId: string;
  roleId: string;
  leadershipId: string;
  terminationDate: Date | string | Moment;
  startDate: Date | string | Moment;
  endDate: Date | string | Moment;
  newEmail?: string | null;
};

export const useManageClubLeadership = ({ clubId }: Props) => {
  const [
    assignClubLeadership,
    { loading: assignClubLeadershipLoading, error: assignClubLeadershipError },
  ] = useAssignClubLeadership();

  const [
    deleteClubLeadership,
    { loading: deleteClubLeadershipLoading, error: deleteClubLeadershipError },
  ] = useRemoveOfficerMutation();

  const [
    updateContactInformation,
    {
      loading: updateContactInformationLoading,
      error: updateContactInformationError,
    },
  ] = useUpdateContactInformation();

  const dateFormat = 'YYYY-MM-DD';

  const isLoading =
    assignClubLeadershipLoading ||
    deleteClubLeadershipLoading ||
    updateContactInformationLoading;

  const isError = Boolean(
    assignClubLeadershipError ||
      deleteClubLeadershipError ||
      updateContactInformationError
  );

  const replaceClubLeadership = async ({
    individualId,
    roleId,
    leadershipId,
    terminationDate,
    startDate,
    endDate,
    newEmail,
  }: ReplaceClubLeadership) => {
    await deleteClubLeadership({
      variables: {
        clubId,
        leadershipId,
        terminationDate: moment(terminationDate).format(dateFormat),
      },
    });

    await assignClubLeadership({
      variables: {
        clubId,
        individualId,
        roleId,
        startDate: moment(startDate).format(dateFormat),
        endDate: moment(endDate).format(dateFormat),
      },
    });

    if (newEmail) {
      await updateContactInformation({
        variables: {
          id: individualId,
          primaryEmail: {
            address: newEmail,
            type: EmailType.Business,
          },
        },
      });
    }
  };

  return {
    replaceClubLeadership,
    isLoading,
    isError,
  };
};

import React, { useEffect } from 'react';

import { isEqual, toInteger } from 'lodash';

import {
  AccessLevelsByPeriod,
  CommitteeAppointment,
  IsManager,
} from '@domain/districts';

import { getRolesByAccessLevels, getTermsByRoles } from '@use-cases/districts';

import { AccessLevel } from '@typings/graphql';
import { GetAccessLevelsQuery } from '@typings/operations';

export const useCalculateAccessLevel = (
  accessLevelData: GetAccessLevelsQuery | undefined,
  contextValues: CommitteeAppointment,
  setContextValues: React.Dispatch<React.SetStateAction<CommitteeAppointment>>,
  currentRotaryYear: number
) => {
  useEffect(() => {
    if (accessLevelData?.results.length) {
      const [
        currentYearAccessData,
        nextYearAccessData,
      ] = accessLevelData.results;

      const accessLevels: AccessLevelsByPeriod = {
        currentYearPermissions: currentYearAccessData.level,
        nextYearPermissions: nextYearAccessData.level,
      };
      const populateManagerWithNowData = (
        manager: Pick<IsManager, 'currentTerm' | 'nextTerm'>
      ) => {
        if (
          currentYearAccessData.arguments?.targetTermYear ===
          `${currentRotaryYear}`
        ) {
          return {
            ...manager,
            nowTerm: currentYearAccessData.level === AccessLevel.Manager,
            afterNowTerm: nextYearAccessData.level === AccessLevel.Manager,
          };
        }
        const {
          nowTerm,
          afterNowTerm,
        } = contextValues.committeeManager.isManager;

        return {
          ...manager,
          nowTerm,
          afterNowTerm,
        };
      };

      const isManager = populateManagerWithNowData(
        getRolesByAccessLevels(accessLevels)
      );

      const selectedTerm = contextValues.selectedInfo.term;

      const endDate = selectedTerm?.endDate
        ? toInteger(selectedTerm?.endDate)
        : null;
      const termsToAssign = getTermsByRoles(
        endDate || currentRotaryYear,
        isManager
      );

      const isManagerChanged =
        !isEqual(isManager, contextValues.committeeManager.isManager) &&
        selectedTerm;

      const isTermsNotDefined =
        selectedTerm === null &&
        currentYearAccessData.arguments?.targetTermYear ===
          `${currentRotaryYear}`;

      // To prevent setting data from the previous context (AG or CA), it is necessary to define the initial data
      // only from accessLevel of current and next year
      if (isManagerChanged || isTermsNotDefined) {
        setContextValues(prevState => ({
          ...prevState,
          defaultTerm: prevState.defaultTerm || termsToAssign[0],
          termsToAssign,
          selectedInfo: {
            ...prevState.selectedInfo,
            term: prevState.selectedInfo.term || termsToAssign[0],
          },
          committeeManager: {
            ...prevState.committeeManager,
            isManager,
          },
        }));
      }
    }
  }, [accessLevelData, contextValues]);
};

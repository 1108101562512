export interface Languages {
  [key: string]: string;
}

export const languages: Languages = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  ko: '한국어',
  ja: '日本語',
  pt: 'Português',
  dev: 'Dev',
};

export const languagePrefix: Languages = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  ko: '한국어',
  ja: '日本語',
  pt: 'Português',
};

export const defaultLanguage = 'en';

export const uiLanguages = () => {
  const uiLanguages = languages;
  delete uiLanguages.dev;
  return uiLanguages;
};

// DIS provides three types of documentation for endpoints input/output data
// structures:
// - correct
// - incorrect
// - none
// To get proper types the following was used:
// 1. For endpoints having no documentation for the output data:
//    - data was taken from DIS (the more, the better)
//    - https://www.npmjs.com/package/json-ts was used to create TS types
// 2. For endpoints that have docs, https://app.quicktype.io/ was used to
//    convert JSON Schema to TS types.
// 3. Both above methods in combination, plus manual analysis.

import { DistrictDis3 } from '@typings/graphql';

export namespace DIS {
  export type BooleanString = 'True' | 'False';

  export type OrganizationType =
    | 'District'
    | 'Rotary_Club'
    | 'Rotaract_Club'
    | 'Rotaract_Satellite_Club';

  export type ClubType =
    | 'Rotary_Club'
    | 'Rotaract_Club'
    | 'Satellite_Club'
    | 'Rotaract_Satellite_Club';

  export type OrganizationBase = 'School' | 'Community' | 'University';

  export type Reason =
    | 'Terminated - Non-payment of club dues'
    | 'Terminated - Personal'
    | 'Terminated - Relocation'
    | 'Terminated - Family Obligations'
    | 'Terminated - Business Obligations'
    | 'Terminated - Joining New Club'
    | 'Terminated - By club for cause'
    | 'Terminated - Deceased'
    | 'Terminated - Non-Attendance'
    | 'Terminated - Health'
    | 'Terminated - Lack of participation'
    | 'Terminated - No Contact Update'
    | 'Terminated - Age'
    | 'Terminated - on SAR'
    | 'Terminated - Health/Personal'
    | 'Terminated - Pilot Participation'
    | 'Terminated - Other'
    | 'Terminated - Merged'
    | 'Club Terminated'
    | 'Terminated - Joining Rotary Club'
    | 'Terminated - School Graduation';

  export type MembershipType =
    | 'Member'
    | 'Honorary'
    | 'Rotaractor'
    | 'Rotaract_Honorary';

  export type EmailType = 'Personal' | 'Business';

  export const genders = [
    'Male',
    'Female',
    'Not Given',
    'DNI',
    'Self',
  ] as const;

  export type Gender = typeof genders[number];

  export type AddressType =
    | 'Business'
    | 'Home'
    | 'Localized'
    | 'Project'
    | 'HostCountry'
    | 'Unknown';

  export type FaxType = 'Business' | 'Home';

  export type PhoneType = 'Business' | 'Home' | 'Mobile';

  export enum DistrictStatus {
    Active = 'active',
    Inactive = 'inactive',
  }

  export enum ClubStatus {
    Active = 'Active',
    Terminated = 'Terminated',
    Suspended = 'Suspended',
  }

  export enum ResumeRolesOrganizationType {
    District = 'District',
    Club = 'Club',
    'Rotaract Club' = 'Rotaract Club',
    'Rotary Office' = 'Rotary Office',
  }

  export enum OrganizationTypeEnum {
    District = 'District',
    Rotary_Club = 'Rotary_Club',
    Rotaract_Club = 'Rotaract_Club',
  }

  export enum ClubTypeEnum {
    RotaryClub = 'Rotary_Club',
    RotaractClub = 'Rotaract_Club',
    SatelliteClub = 'Satellite_Club',
    RotaractSatelliteClub = 'Rotaract_Satellite_Club',
  }

  export type MeetingType = 'Physical' | 'Online' | 'Hybrid';

  export interface CreateDistrictDetailsInput {
    id?: string;
  }

  export interface DistrictClubsQuery {
    districtId?: string;
    rotaryYear?: string;
    status?: string;
    type?: string;
    page?: number;
    pageSize?: number;
  }

  export interface GetDistrictDashboardsQuery {
    districtId: string;
    rotaryYear?: string;
    category?: string;
  }

  export interface CreateIndividualInput {
    aboutMe?: string;
    classification?: string;
    /**
     * Date of Birth must be a valid date with format yyyy-MM-dd
     * Date of Birth must be before today
     * Date of Birth can not be earlier than 100 years
     */
    dateOfBirth?: string;
    /**
     * And cannot start with a space ( ),dash or dot (.)
     */
    firstName: string;
    /**
     * Gender must be one of Male, Female, Not Given, Do not Identify, Self Identify
     */
    gender: Gender;
    genderDescription?: string;
    /**
     * And cannot start with a space ( ),dash or dot (.)
     */
    lastName: string;
    localizedName?: string;
    /**
     * And cannot start with a space ( ),dash or dot (.)
     */
    middleName?: string;
    /**
     * Must be Empty when primaryAddress is empty
     */
    otherAddresses?: AddressInput[];
    /**
     * Must be Empty when primaryEmail is empty
     */
    otherEmails?: Email[];
    /**
     * Must be Empty when primaryExpertise is empty
     */
    otherExpertise?: ExpertiseInput[];
    /**
     * Must be Empty when primaryFax is empty
     */
    otherFaxes?: FaxInput[];
    /**
     * Other languages must be one of codes in link: /metadata/languages
     */
    otherLanguages?: LanguageInput[];
    /**
     * Must be Empty when primaryPhone is empty
     */
    otherPhones?: IndividualPhoneInput[];
    /**
     * Must be Empty when primaryProfession is empty, At least one of the following fields must
     * have a value: Occupation, Employer, Position
     */
    otherProfessions?: ProfessionInput[];
    /**
     * Preferred Language must be one of Rotary official languages.
     */
    preferredLanguage?: LanguageInput;
    /**
     * Accept Latin Characters and diacritics.
     * Accept space ( ), dash , comma (,) , dot (.) or any symbol (e.g. &,%,^...)
     */
    prefix?: string;
    primaryAddress?: AddressInput;
    primaryEmail?: Email;
    primaryExpertise?: ExpertiseInput;
    primaryFax?: FaxInput;
    /**
     * Primary language must be one of codes in link: /metadata/languages
     */
    primaryLanguage: LanguageInput;
    primaryPhone?: IndividualPhoneInput;
    /**
     * At least one of the following fields must have a value: Occupation, Employer, Position
     */
    primaryProfession?: ProfessionInput;
    sharingPermission?: SharingPermissionsInput;
    /**
     * Suffix must be one of Jr., Sr., II, III, IV, V, VI, VII, Filho, Hija, Hijo, Neto, Padre,
     * Sobrinho
     */
    suffix?: string | null;
    /**
     * If YouthFlag is true,this field is required. YearOfBirth must be a valid year with format
     * yyyy
     */
    yearOfBirth?: number;
    /**
     * True means you are younger than 18 (<18). YearOfBirth must be provided when this value is
     * true.
     */
    youthFlag: boolean;

    /**
     * True means you allow share your sponsorship info
     */
    recognitionOptOutFlag?: boolean;
  }

  export interface IndividualPhoneInput {
    countryId: string;
    extension?: string;
    number: string;
    type: PhoneType;
  }

  export interface IndividualPhoneOutput {
    country: string;
    countryId: string;
    extension: string | null;
    number: string;
    type: PhoneType;
    id?: string;
  }

  export type IndividualOutput = {
    id: string;
    riIndividualId: number | null;
    photoUri: string | null;
    primaryAddress: AddressOutput | null;
    otherAddresses: AddressOutput[];
    primaryExpertise: ExpertiseOutput | null;
    otherExpertise: ExpertiseOutput[];
    primaryPhone: IndividualPhoneOutput | null;
    otherPhones: IndividualPhoneOutput[];
    primaryFax: FaxOutput | null;
    otherFaxes: FaxOutput[];
    primaryLanguage: LanguageOutput;
    otherLanguages: LanguageOutput[];
    preferredLanguage: LanguageOutput | null;
    sharingPermission: SharingPermissionsOutput;
    aboutMe: string | null;
    classification: string | null;
    dateOfBirth: string | null;
    firstName: string;
    gender: Gender | null;
    lastName: string;
    localizedName: string | null;
    middleName: string | null;
    otherEmails: Email[];
    otherProfessions: ProfessionOutput[];
    prefix: string | null;
    primaryEmail: Email | null;
    primaryProfession: ProfessionOutput | null;
    suffix: string | null;
    yearOfBirth: number | null;
    youthFlag: boolean;
    _links?: {
      self: Link;
      photo: Link;
      onlineid: Link;
      programs: Link;
      membership_history: Link;
      sponsorship_history: Link;
      leadership_history: Link;
      feedbackList: Links;
      conferenceList: Links;
    };
    // A special field telling that the user has no OKTA account.
    // TODO: is this field really exist in DIS?
    noOktaAccount?: boolean;
  };

  export interface AddressBaseInput {
    /**
     * Required
     * Latin characters only
     * Cannot start with a space ( ), dot (.), comma (,) or dash
     * Maximum length is 40
     */
    city: string;
    /**
     * Required
     * Country must be one of codes in link: /metadata/countries
     */
    countryId: string;
    /**
     * Required if the country IS NOT one of USA, CAN
     * Must be empty if country IS one of above
     * Maximum length is 40
     */
    internationalProvince?: string;
    /**
     * Required
     * Should not start with a space ( ), dot (.), comma (,) or dash
     * Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only
     * Length between 2 and 40
     */
    lineOne: string;
    /**
     * Must be empty if LineTwo is empty
     * Should not start with a space ( ), dot (.), comma (,) or dash
     * Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only
     * Maximum length is 40
     */
    lineThree?: string | null;
    /**
     * Should not start with a space ( ), dot (.), comma (,) or dash
     * Must be Latin characters (Aa-Zz) and numbers, underscores, periods, and hyphens only
     * Maximum length is 40
     */
    lineTwo?: string | null;
    /**
     * Required if the country IS one of USA, CAN, GUM, MSM, PRI, VIR, ASM, MHL, MNP, PLW
     * Otherwise, it's optional
     * Maximum length is 20
     */
    postalCode?: string | null;
    /**
     * Required if the country IS one of USA, CAN, and it must belongs to the country;
     * Must be empty if country IS NOT one of above
     * State must be one of codes in link: /metadata/states
     */
    stateId?: string | null;
  }

  export interface AddressInput extends AddressBaseInput {
    /**
     * Address Type must be one of Business, Home, Localized, Project, HostCountry
     */
    type: AddressType;
  }

  export interface AddressBaseOutput {
    id?: string | null;
    state: string | null;
    country: string | null;
    lineOne: string | null;
    city: string | null;
    postalCode: string | null;
    countryId: string;
    internationalProvince: string | null;
    lineThree: string | null;
    lineTwo: string | null;
    stateId: string | null;
  }

  export interface AddressOutput extends AddressBaseOutput {
    type: AddressType;
    id?: string;
  }

  export interface Email {
    /**
     * Valid email address should be: {local-part}@{domain}
     * The "local-part" length should be 1 to 64 characters
     * The "domain" length should not exceed 255 characters
     */
    address: string;
    /**
     * Email Type must be one of Personal, Business
     */
    type: EmailType;

    id?: string;
  }

  export interface ProfessionInput {
    position?: string;
    employer?: string;
    occupation?: string;
    id?: string;
  }

  export interface ProfessionOutput {
    position: string | null;
    employer: string | null;
    occupation: string | null;
  }

  export interface ExpertiseInput {
    /**
     * Must be Guid
     * areaId must be one of codes in link: /metadata/expertise-areas
     */
    areaId: string;
    /**
     * Must be Guid
     * levelId must be one of codes in link: /metadata/expertise-levels
     */
    levelId: string;
  }

  export interface ExpertiseOutput {
    areaId: string;
    area: string;
    levelId: string;
    level: string;
  }

  export interface FaxInput {
    /**
     * Required
     * Country must be one of codes in link: /metadata/countries
     */
    countryId: string;
    /**
     * Only allow numeric, space, dash, slash (/), comma (,) parentheses () or dot (.)
     * Can ONLY start with either a numeric or open parentheses (
     * Maximum length is 10
     */
    extension?: string | null;
    /**
     * Required
     * Only allow numeric, space, dash, slash (/), comma (,) parentheses () or dot (.)
     * Can ONLY start with either a numeric or open parentheses (
     * Number/extension/type combination must not already exist for this individual. Duplicate
     * checking must remove all non-numeric characters.
     * Length limit is between 1 and 30
     */
    number: string;
    /**
     * Type must be one of Business, Home
     */
    type?: FaxType | null;
  }

  export interface FaxOutput {
    country?: string | null;
    countryId: string;
    extension?: string | null;
    number: string;
    type?: FaxType | null;
    id?: string | null;
  }

  export interface EmailOutput {
    email: string;
    isPrimary: boolean;
    id?: string;
  }

  export interface WebsiteOutput {
    primaryWebsite: string;
    otherWebsites?: string;
    id?: string;
  }

  export interface DistrictFaxOutput {
    id?: string | null;
    country?: string | null;
    countryId?: string | null;
    extension?: string | null;
    number?: string | null;
  }

  export interface PhoneInput {
    /**
     * Required
     * Country must be one of codes in link: /metadata/countries
     */
    countryId: string;
    /**
     * Only allow numeric, space, dash, slash (/), comma (,) parentheses () or dot (.)
     * Can ONLY start with either a numeric or open parentheses (
     * Maximum length is 10
     */
    extension?: string | null;
    /**
     * Required
     * Only allow numeric, space, dash, slash (/), comma (,) parentheses () or dot (.)
     * Can ONLY start with either a numeric or open parentheses (
     * Number/extension/type combination must not already exist for this individual. Duplicate
     * checking must remove all non-numeric characters.
     * Length limit is between 1 and 30
     */
    number: string;
  }

  export interface PhoneOutput {
    country: string;
    countryId: string;
    extension: string | null;
    number: string;
    id?: string | null;
  }

  export interface LanguageInput {
    languageId: string;
  }

  export interface LanguageOutput {
    languageId: string;
    language: string;
    id?: string | null;
  }

  export interface SharingPermissionsInput {
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    aboutMe?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    address?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    email?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    expertise?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    fax?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    gender?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    language?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    phone?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    photo?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    profession?: SharingPermissionInput;
    /**
     * Must be Guid
     * field value must be one of codes in link: /metadata/sharing-permission)
     */
    program?: SharingPermissionInput;
  }

  export type SharingPermissionsOutput =
    | SharingPermissionsOutputRegular
    | SharingPermissionsOutputExtended;

  export interface SharingPermissionsOutputExtended {
    aboutMe: SharingPermissionOutput;
    address: SharingPermissionOutput;
    email: SharingPermissionOutput;
    expertise: SharingPermissionOutput;
    fax: SharingPermissionOutput;
    gender: SharingPermissionOutput;
    language: SharingPermissionOutput;
    phone: SharingPermissionOutput;
    photo: SharingPermissionOutput;
    profession: SharingPermissionOutput;
    name: SharingPermissionOutput;
    dateOfBirth: SharingPermissionOutput;
    program: SharingPermissionOutput;
    sponsors: SharingPermissionOutput;
    recognition: SharingPermissionOutput;
  }

  export interface SharingPermissionsOutputRegular {
    aboutMe: boolean;
    address: boolean;
    email: boolean;
    expertise: boolean;
    fax: boolean;
    gender: boolean;
    language: boolean;
    phone: boolean;
    photo: boolean;
    profession: boolean;
    name: boolean;
    dateOfBirth: boolean;
    // program, sponsors, and recognition are missing in real DIS responses.
  }

  export interface SharingPermissionInput {
    levelId: string;
  }

  export interface SharingPermissionOutput {
    levelId: string;
    level: string;
  }

  export interface SharingPermission {
    id: string;
    value: string;
    // There is also "detail" property which is always an empty object.
  }

  export enum SharingPermissionLevelId {
    ClubOfficersDistrictOfficersId = '2b85eddc-bae7-48c1-bca6-ad93a81cace8',
    ClubMembersDistrictOfficersId = 'd9649eca-06fc-4cfd-81a5-89f13a562fc4',
    DistrictMembersId = '9354a4b3-2f93-45eb-9259-1cb8cc8511d1',
    AllMembersId = 'b163bf39-0cf0-4bdf-9bb3-4936c11393a8',
  }

  export type OptionallyStringifiedNumber = number | string;

  export interface SearchIndividualInput {
    city?: {
      value: string;
      operator: SearchFilterOperator;
    };
    clubName?: {
      value: string;
      operator: SearchFilterOperator;
    };
    clubRole?: {
      value: string;
      operator: SearchFilterOperator;
    };
    clubType?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    countryId?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    districtRole?: {
      value: string;
      operator: SearchFilterOperator;
    };
    email?: {
      value: string;
      operator: SearchFilterOperator;
    };
    firstName?: {
      value: string;
      operator: SearchFilterOperator;
    };
    internationalProvince?: {
      value: string;
      operator: SearchFilterOperator;
    };
    lastName?: {
      value: string;
      operator: SearchFilterOperator;
    };
    location?: {
      value: string;
      operator?: SearchFilterOperator.Partial;
    };
    name?: {
      value: string;
      operator?: SearchFilterOperator.Partial;
    };
    postalCode?: {
      value: string;
      operator: SearchFilterOperator;
    };
    riClubId?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    riDistrictId?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    stateId?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
  }

  export interface SearchMemberInput extends SearchIndividualInput {}

  export interface SearchMemberAdvancedInput {
    searchSyntax: string;
    sortSyntax: string;
  }

  export interface SearchClubAdvancedInput {
    searchSyntax: string;
    sortSyntax?: string;
  }

  export interface SearchIndividualAdvancedInput
    extends SearchMemberAdvancedInput {}

  export enum SearchFilterOperator {
    Equal = '=',
    Partial = '~',
    LessOrEqual = '<=',
  }

  export interface SearchIndividualOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      individuals: SearchIndividualRow[];
    };
  }

  export interface SearchMemberOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      individuals: SearchMemberRow[];
    };
  }

  export interface MemberSharingPermissions {
    Name: boolean;
    Email: boolean;
    Phone: boolean;
    Address: boolean;
    Photo: boolean;
  }

  export interface SearchIndividualRow {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    localizedName: string | null;
    prefix: string | null;
    suffix: string | null;
    photoUri: string | null;
    riIndividualId: string | null;
    isDeceased: boolean;
    deceasedDate: string | null;
    primaryEmail: Email | null;
    otherEmails: Email[];
    primaryAddress: AddressOutput | null;
    otherAddresses: AddressOutput[];
    primaryPhone: PhoneOutput | null;
    otherPhones: PhoneOutput[];
    clubLeadership: ClubLeadershipFromSearch[];
    districtLeadership: DistrictLeadershipFromSearch[];
    /**
     * The type=Member memberships goes first in the list.
     *
     * @see sortMemberships
     */
    membership: MembershipFromSearch[];
    youthFlag: boolean;
    preferredLanguage?: LanguageOutput | null;
  }

  export interface SearchMemberRow extends SearchIndividualRow {
    sharingPermission: MemberSharingPermissions;
  }

  export interface MembershipFromSearch {
    id: string;
    clubId: string;
    clubName: string;
    riClubId: string;
    clubType: ClubType;
    type: MembershipType;
    districtId: string;
    riDistrictId: string;
    admissionDate: string;
    terminationDate: string | null;
  }

  export interface DistrictLeadershipFromSearch {
    id: string;
    roleId: string;
    role: string;
    startDate: string;
    endDate: string;
    riDistrictId: string;
    districtId: string;
    zone?: string;
    isDelegated: boolean;
  }

  export interface DistrictLeadership {
    id: string;
    roleId: string;
    role: string;
    startDate: string;
    endDate: string;
    _embedded: {
      individual: PartialIndividualData;
    };
  }

  export interface ClubLeadershipFromSearch {
    id: string;
    clubId: string;
    clubName: string;
    riClubId: string;
    clubType: ClubType;
    roleId: string;
    role: string;
    startDate: string;
    endDate: string;
    districtId: string;
    riDistrictId: string;
    isDelegated: boolean;
    meetings: Meeting[];
  }

  export interface MembershipBase {
    id: string;
    type: MembershipType;
    admissionDate: string;
    terminationDate: string | null;
    terminationReasonId: string | null;
    terminationReason: string | null;
    _embedded: MembershipEmbeddedDataBase;
    _links: {
      individual: Link;
    };
  }

  export interface Membership extends MembershipBase {
    _embedded: MembershipEmbeddedData;
  }

  export interface MembershipEmbeddedDataBase {
    sponsors:
      | {
          id: string;
        }[]
      | null;
  }

  export interface MembershipEmbeddedData extends MembershipEmbeddedDataBase {
    individual: PartialIndividualData;
  }

  export interface MembershipHistoryRow extends Membership {
    _embedded: MembershipEmbeddedData & {
      club: {
        id: string;
        name: string;
        type: ClubType;
      };
    };
  }

  export interface RecognitionDataRow {
    id: string;
    riIndividualId: number;
    prefix: string | null;
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string | null;
    localizedName: string | null;
    photoURI: null;
    riClubId: number;
    clubId: string;
    clubName: string;
    clubType: string;
    clubLocation: {
      country: string;
      state: string | null;
    };
    riDistrictId: number;
    districtId: string;
    sponsorships: number;
    membershipStatus: string;
    membershipType: string;
    _links: Links;
  }

  export interface RecognitionData {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      individual: RecognitionDataRow[];
    };
  }

  export interface Leadership {
    id: string;
    endDate: string;
    startDate: string;
    isDelegated: boolean;
    roleId: string;
    role: string;
    _embedded: {
      individual: PartialIndividualData;
    };
  }

  export interface LeadershipHistoryRow extends Omit<Leadership, '_embedded'> {
    _embedded: {
      organization: {
        id: string;
        name: string;
        type: OrganizationType;
      };
    };
  }

  export type Weekday =
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';

  export interface Meeting {
    address: AddressBaseOutput | null;
    latitude: number | null;
    longitude: number | null;
    weekday: Weekday;
    time: string;
    comment: string | null;
    type: MeetingType;
    onlineLocation: string | null;
    languageId: string | null;
    language: string | null;
    frequency: string | null;
    name: string | null;
    locationNameOrDetail: string | null;
    locationVaries: boolean;
    detailForJoiningRemotely: string | null;
    meetingId?: string | null;
  }

  export interface MeetingInput {
    address?: AddressBaseInput | null;
    weekday?:
      | 'Monday'
      | 'Tuesday'
      | 'Wednesday'
      | 'Thursday'
      | 'Friday'
      | 'Saturday'
      | 'Sunday'
      | null;
    time?: string | null;
    comment?: string | null;
    type?: MeetingType | null;
    onlineLocation?: string | null;
    languageId?: string | null;
    language?: string | null;
    frequency?: string | null;
    name?: string | null;
    locationNameOrDetail?: string | null;
    locationVaries?: boolean | null;
    detailForJoiningRemotely?: string | null;
  }

  export interface SearchClubDistrict {
    id: string;
    riDistrictId: number;
    isHomeDistrict: boolean;
  }

  export interface SearchClubMeeting {
    address: SearchClubMeetingAddress | null;
    latitude: number | null;
    longitude: number | null;
    day:
      | 'Monday'
      | 'Tuesday'
      | 'Wednesday'
      | 'Thursday'
      | 'Friday'
      | 'Saturday'
      | 'Sunday';
    time: string;
    comment: string | null;
    type: MeetingType;
    onlineLocation: string | null;
    languageId: string | null;
    language: string | null;
    frequency: string | null;
    name: string;
    locationNameOrDetail: string | null;
    locationVaries: boolean;
  }

  export interface SearchClubMeetingAddress {
    state: string | null;
    country: string | null;
    lineOne: string | null;
    city: string | null;
    postalCode: string | null;
    countryId: string;
    internationalProvince: string | null;
    lineThree: string | null;
    lineTwo: string | null;
    stateId: string | null;
  }

  export interface SearchClubRow {
    id: string;
    riClubId: string;
    name: string;
    fullName: string;
    type: ClubType;
    district: SearchClubDistrict | null;
    mailingAddress: AddressOutput | null;
    status: 'active' | 'terminated';
    meetings: SearchClubMeeting[];
    primaryWebsite: string | null;
    otherWebsites: string[];
    sponsorClubs: {
      id: string;
      clubName: string;
      type: string;
      startDate: string;
      endDate: string;
    }[];
    sponsoredClubs: {
      id: string;
      clubName: string;
      type: string;
      startDate: string;
      endDate: string;
    }[];
    physicalLocation: SearchClubMeetingAddress | null;
  }

  export interface SearchClubInput {
    geoLocation?: {
      range: OptionallyStringifiedNumber;
      value: string;
      operator?: SearchFilterOperator.LessOrEqual;
    };
    riDistrictId?: {
      value: OptionallyStringifiedNumber;
      operator?: SearchFilterOperator.Equal;
    };
    clubName?: {
      value: string;
      operator?: SearchFilterOperator.Equal | SearchFilterOperator.Partial;
    };
    riClubId?: {
      value: OptionallyStringifiedNumber;
      operator?: SearchFilterOperator.Equal;
    };
    clubType?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    clubFullName?: {
      value: string;
      operator?: SearchFilterOperator.Partial;
    };
    meetingType?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    meetingLanguageId?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    meetingDay?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
    meetingTime?: {
      value: string;
      operator?: never;
    };
    keyword?: {
      value: string;
      operator?: SearchFilterOperator.Partial;
    };
    status?: {
      value: string;
      operator?: SearchFilterOperator.Equal;
    };
  }

  export interface SearchClubOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      clubs: SearchClubRow[];
    };
  }

  export interface GetClubsByQueryParams {
    districtId?: string;
    riClubId?: number | null;
    rotaryYear?: string | null;
    status?: string | null;
    type?: string | null;
    date?: string | null;
    hasAGAffiliation?: boolean | null;
  }

  export interface DistrictClubsOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      clubs: Club[];
    };
  }

  export interface DistrictAGsQuery {
    districtId: string;
    date?: string;
    page?: number;
    pageSize?: number;
  }

  export interface DistrictAGsOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      ags: AssistantGovernor[];
    };
  }

  export interface AssistantGovernor {
    id: string;
    endDate: string;
    startDate: string;
    _embedded: {
      individual: PartialIndividualData;
      agClubAffiliations: AgClubAffiliation[];
    };
  }

  export interface AgClubAffiliation {
    id: string;
    endDate: string;
    startDate: string;
    _embedded: {
      club: AgClubPartial;
    };
  }

  export interface AgClubPartial {
    id: string;
    name: string;
    type: string;
    physicalLocation: AddressBaseOutput | null;
  }

  export interface ClubRelationship {
    id: string;
    type: ClubType;
    startDate: string;
    endDate: string;
    childClubId?: string;
    childDisId?: string;
    parentClubId?: string;
    parentDisId?: string;
    clubName: string;
  }

  export interface Club {
    status: ClubStatus;
    primaryPhone: PhoneOutput | null;
    otherPhones: PhoneOutput[];
    mailingAddress: AddressBaseOutput | null;
    localizedAddress: AddressBaseOutput | null;
    businessAddresses: AddressBaseOutput[];
    subscriptionMailingAddresses: AddressBaseOutput[];
    riClubId: string;
    id: string;
    name: string;
    charteredDate: string;
    officialLanguage: LanguageOutput;
    meetings: Meeting[];
    primaryEmail: string | null;
    otherEmails: string[];
    primaryWebsite: string | null;
    otherWebsites?: string[];
    primaryFax: FaxOutput | null;
    otherFaxes: FaxOutput[];
    email?: EmailOutput | null;
    website?: WebsiteOutput | null;
    district: District | null;
    physicalLocation: AddressBaseOutput | null;
    type: ClubType;
    organizationBase: OrganizationBase | null;
    childClubRelationships: ClubRelationship[];
    parentClubRelationships: ClubRelationship[];
  }

  export interface UpdateClubInput {
    status: string;
    primaryPhone: PhoneInput | null;
    otherPhones: PhoneInput[];
    mailingAddress: AddressBaseInput | null;
    localizedAddress: AddressBaseInput | null;
    businessAddresses: AddressBaseInput[];
    subscriptionMailingAddresses: AddressBaseInput[];
    riClubId: number;
    name: string;
    charteredDate: string;
    // officialLanguage: LanguageInput;
    // apparantly just a string?
    // https://ri-u-usc-dis-am.portal.azure-api.net/docs/services/memberclub/operations/testAddClub?
    officialLanguage: string;
    meetings: MeetingInput[];
    primaryEmail: string | null;
    otherEmails: string[];
    primaryWebsite: string | null;
    otherWebsites: string[];
    primaryFax: FaxInput;
    otherFaxes: FaxOutput[];
    district: FaxInput;
    physicalLocation: AddressBaseInput | null;
    type: ClubType;
    childClubRelationships: ClubRelationship[];
    parentClubRelationships: ClubRelationship[];
  }

  export interface District {
    id: string;
    riDistrictId: number;
  }

  export interface DistrictByQueryParams {
    status: string;
    riDistrictId?: number;
    page: number;
    pageSize: number;
  }

  export interface DistrictsByQuery {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      districts: DistrictDis3[];
    };
  }

  export interface DistrictInfo {
    id: string;
    riDistrictId: number;
    zone: number | null;
    status: string;
    primaryPhone: PhoneOutput | null;
    otherPhones: PhoneOutput[];
    mailingAddress: AddressBaseOutput | null;
    localizedAddress: AddressBaseOutput | null;
    businessAddress: AddressBaseOutput[] | null;
    primaryWebsite: string | null;
    otherWebsites: string[] | null;
    primaryEmail: string | null;
    otherEmails: string[];
    primaryFax?: DistrictFaxOutput | null;
    otherFaxes: DistrictFaxOutput[] | null;
    districtLegalName: string | null;
    websites?: WebsiteOutput | null;
    emails?: EmailOutput[] | null;
  }

  export interface EmailOutput {
    id?: string;
    email: string;
    isPrimary: boolean;
  }

  export interface WebsiteOutput {
    id?: string;
    primaryWebsite: string;
    otherWebsites?: string;
  }

  export interface Language {
    id: string;
    value: string;
    detail: {
      ShortCode: string;
      IsRiOfficialLanguage: boolean;
    };
  }

  export interface Country {
    id: string;
    value: string;
    detail: {
      ShortCode: string;
    };
  }

  export interface DisCountry {
    id: string;
    name: string;
    shortCode: string;
  }

  export interface State {
    id: string;
    value: string;
    detail: {
      CountryCode: string;
    };
  }

  export interface DisState {
    id: string;
    countryId: string;
    name: string;
  }

  export interface TimeZone {
    id: string;
    value: string;
    detail: {
      TimeZoneName: string;
      Location: string;
      Offset: string;
    };
  }

  export interface TerminationReason {
    id: string;
    value: Reason;
    detail: {
      Type: string;
      DoNotAllowUserEntry: null | boolean;
    };
  }

  export interface AreaOfExpertise {
    id: string;
    value: string;
    detail: object;
  }

  export interface LevelOfExpertise {
    id: string;
    value: string;
    detail: object;
  }

  export interface GetOnlineIdOutput {
    value: string;
    _links: Links;
  }

  export type LeadershipRole = {
    id: string;
    value: string;
    detail: {
      OrganizationType: string | null;
      TermLength: number;
      TermOfAccessBefore: number;
      TermOfAccessAfter: number;
      StartDate: string;
      EndDate: string | null;
      MustBeMembership: boolean | string[];
      IsOfficer: boolean;
      RestrictPerOrganizationFlag: boolean;
      DISUseFlag: boolean;
      OfficerManagement: string;
    };
  };

  export enum IndividualOperation {
    assignRole = 'assignRole',
    editMember = 'editMember',
    editMembershipType = 'editMembershipType',
    terminateMembership = 'terminateMembership',
  }

  export enum SharingPermissions {
    noSharing = '2b85eddc-bae7-48c1-bca6-ad93a81cace8',
    shareWithClubAndDistrict = 'd9649eca-06fc-4cfd-81a5-89f13a562fc4',
    shareWithDisctrictOfficer = '9354a4b3-2f93-45eb-9259-1cb8cc8511d1',
    shareWithAll = 'b163bf39-0cf0-4bdf-9bb3-4936c11393a8',
  }

  /**
   * Access level defines whether a individual can view or an entity.
   */
  export type AccessLevel =
    | 'No Permission'
    | 'Limited Viewer'
    | 'Viewer'
    | 'Contributor'
    | 'Manager';

  export interface GetAccessLevelsInputRow {
    target: string;
    arguments?: { [key: string]: string };
  }

  export interface GetAccessLevelsInput {
    data: GetAccessLevelsInputRow[];
  }

  export interface GetAccessLevelsOutput {
    data: (GetAccessLevelsInputRow & { level: AccessLevel })[];
  }

  export interface ImpersonationAuthOutput {
    expiration: string;
    legacySessionDetail: string;
  }

  export interface CreateMembershipInput {
    /**
     * admissionDate must be a valid date with format yyyy-MM-dd
     * admissionDate cannot be more than 1 day in the future (current date + 1)
     * admissionDate must be within 30 days
     */
    admissionDate: string;
    individualId: string;
    /**
     * Can only add sponsor to member in rotary club
     * Can't add more than two sponsors
     * Sponsor can't be duplicated in the request
     * SponsorId must be active member not honorary member
     */
    sponsorIds?: string[];
    /**
     * type must be one of Member, Honorary
     */
    type: MembershipType;
  }

  export interface PatchRequestOperation<InputValue = any> {
    op: 'replace' | 'add' | 'remove';
    path: string;
    value?: InputValue;
  }

  export interface GetIndividualByRiIndividualIdOutput {
    _embedded: {
      individuals: IndividualOutput[];
    };
  }

  export interface Link {
    href: string;
    method: string;
  }

  export interface Links {
    [key: string]: Link | [any];
  }

  export interface GetAllLeadershipsOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      leadership: Leadership[];
    };
  }

  export interface GetMembershipsOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      membership: Membership[];
    };
  }

  export interface PartialIndividualData {
    id: string;
    firstName: string;
    lastName: string;
    middleName?: string | null;
    prefix?: string | null;
    suffix?: string | null;
    localizedName?: string | null;
  }

  export type IndividualOperationsAccess = {
    [key in IndividualOperation]: AccessLevel | null;
  };

  export interface UpdateMembershipTypeRequestBody {
    type: string;
    admissionDate: string;
  }

  export interface CreateLeadershipInput {
    /**
     * Can only add active member in this club as officer (Club Foundation Chair, Club
     * Membership Chair, Club President, Club Secretary, Club Treasurer)
     * Can add any non deceased individual as club executive secretary
     */
    individualId: string;
    /**
     * Must be Guid
     * roleId must be one of codes in link: /metadata/leadership-roles
     * Not allow to add club officer with the role whose don't show online flag is yes
     * Role should be active in club officer's whole assignment period
     */
    roleId: string;
    /**
     * Start date should later than member admission date and club chartered date
     * Start date format must be yyyy-MM-dd
     * Start date must be in current or next term year
     * If start date is in next term year, the date must be 01 JUL
     */
    startDate: string;
  }

  export interface UpdateMembershipTypeRequest {
    /**
     * admissionDate must be a valid date with format yyyy-MM-dd
     * admissionDate cannot be more than 1 day in the future (current date + 1)
     * Cannot update member type when membership has termination date
     * admissionDate must be within 30 days
     */
    admissionDate: string;
    /**
     * type must be one of Member, Honorary
     */
    type: MembershipType;
  }

  export interface RecognitionsOutput {
    _embedded: {
      individual: {
        riIndividualId: number;
        recognitions: Recognition[] | null;
      }[];
    };
  }

  export interface Recognition {
    type: string;
    subType: string;
    levelDescription: string;
    level: number;
    achievedDate: string;
  }

  export interface ResumeRolesOutput {
    _embedded: {
      individuals: {
        riIndividualId: number;
        originalJoinDate: string;
        currentClubJoinDate: string;
        yearsOfService: number;
        type: ResumeRolesItem[];
      }[];
    };
  }

  export interface ResumeRolesItem {
    /**
     * So far we only saw "Role" here.
     */
    name: string;
    organizations: ResumeRolesOrganization[];
  }

  export interface ResumeRolesOrganization {
    id: number | null;
    /**
     * Example values: Club, District, Satellite Club, Rotary Office, Regional
     * Group, etc.
     */
    type: string;
    name: string;
    roles: ResumeRolesRole[];
    address: ResumeRolesAddress;
  }

  export interface ResumeRolesRole {
    /**
     * Example values: Member, Club President, Club Secretary, etc.
     */
    name: string;
    title: null;
    rank: number | null;
    startDate: string;
    endDate: string | null;
    isShowOnline: 'True' | 'False';
    committeeName: string | null;
  }

  export interface ResumeRolesAddress {
    city: string | null;
    state: string | null;
    stateCode: string | null;
    province: string | null;
    country: string | null;
  }

  export interface MembershipHistoryOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      membership: MembershipHistoryRow[];
    };
  }

  export interface LeadershipHistoryOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      leadership: LeadershipHistoryRow[];
    };
  }

  export interface Program {
    id: string;
    value: string;
    detail: {
      Fields: ProgramField[];
    };
  }

  export interface ProgramField {
    id: string;
    value: string;
    detail: {
      Description: string;
    };
  }

  export interface IndividualProgram {
    fields: IndividualProgramField[];
    isVerified: BooleanString;
    isShownOnline: BooleanString;
    // This is ID of the individual-program connection.
    id: string;
    programId: string;
    program: string;
  }

  export interface IndividualProgramField {
    fieldId: string;
    field: string;
    value: string;
  }

  export interface IndividualProgramsOutput {
    _embedded: {
      programs: IndividualProgram[];
    };
  }

  export interface CreateIndividualProgramInput {
    /**
     * Required
     * programId must be one of codes in link: /metadata/programs
     * If you create a individualProgram with 'other' programId, you must use 'description'
     * field, and it's value should not be empty
     */
    programId: string;
    fields?: IndividualProgramFieldInput[];
  }

  export interface IndividualProgramFieldInput {
    /**
     * Must be Guid
     */
    fieldId: string;
    /**
     * Field id must belong to selected program's fields
     * When field is startDate, endDate, value format must be `yyyy-MM-dd`
     * When field is hostCountry, sponsorCountry, Country must be one of codes in link:
     * /metadata/countries
     * When field is institution, hostIndividual, sponsorIndividual, sponsorClub, memberClub,
     * studySubject, program, hostMultiDistrict, sponsorMultiDistrict, value maximum is 150
     * When field is hostDistrict, sponsorDistrict, value maximum is 255
     * When field is studySubject, program, hostMultiDistrict, sponsorMultiDistrict value must
     * be Latin character set only
     * When field is areaOfFocus, field value must be one of codes in link:
     * /metadata/area-of-focus
     *
     * More validation rules can be found at
     * https://confluenceprd.rotaryintl.org/pages/viewpage.action?spaceKey=IST&title=Validation+Rules
     */
    value: string;
  }

  // This type does not fully meets DIS structure, but it is a format that
  // works.
  export interface UpdateIndividualProgramInputItem {
    op: 'replace';
    path: '/fields';
    // Please note that ALL EXISTING FIELDS WILL BE DELETED and replaces with
    // the given ones.
    value: IndividualProgramFieldInput[];
  }

  // For some reason this is an array, but it should have ONLY ONE item.
  export type UpdateIndividualProgramInput = UpdateIndividualProgramInputItem[];

  export interface AreaOfFocus {
    id: string;
    value: string;
    detail: {
      Acronym: string;
      IsActive: boolean;
    };
  }

  export interface GetNFKeyOutput {
    nfKey: string;
  }

  export interface GetRiEmail {
    links: Links;
    _embedded: {
      'rotary:emailAddresses': Array<RotaryEmailAddress | undefined>;
    };
  }

  export type RotaryEmailAddress = {
    address: string;
    eml_cst_key?: string | null;
  };

  export interface ValidationError {
    code: ValidationCode;
    description: string;
    source: string;
    arguments: {
      regex?: string;
    };
  }

  export type ErrorCode = typeof errorCodes[number];
  export const errorCodes = [
    'ValidationError',
    'NotFound',
    'BadRequest',
    'InvalidRequestFormat',
    'Club_CannotUpdateATerminatedClub',
    'Individual_CannotModifyEmailUsedAsOnlineId',
    'Email_Duplicate',
    'Language_Duplicate',
    'Expertise_Duplicate',
    'Individual_DuplicatePhone',
    'Individual_DuplicateFax',
    'Leadership_CannotAssignToActiveRoleForClub',
    'Leadership_CannotAssignToActiveRoleForDistrict',
    'Club_DuplicatePhone',
    'Club_DuplicateFax',
    'ResourceExists',
    'Forbidden',
    'Program_CannotUpdateOrDeleteVerifiedProgram',
    'Club_CannotUpdatePhysicalLocation',
    'Club_CannotUpdateGeoLocation',
    'Individual_ContributorCannotUpdate',
    'Unauthorized',
    'BadData',
    'ConversionError',
    'ServiceUnavailable',
    'ImpersonateFail',
    'InvalidSearchCondition',
  ] as const;

  export type ValidationCode = typeof validationCodes[number];
  export const validationCodes = [
    'Field_MustBeEmpty',
    'Field_Required',
    'Field_LessThanMinLength',
    'Field_ExceededMaxLength',
    'Field_LengthOutOfRange',
    'Field_InvalidField',
    'InvalidValue_NotInAcceptedValues',
    'InvalidValue_NotInMetadata',
    'ParameterConflict',
    'Field_InvalidStartCharacter',
    'Date_InvalidTimespan',
    'Date_InvalidDate',
    'Date_DateMustBeAfter',
    'InvalidValue_InvalidUri',
    'InvalidValue_OutOfRange',
    'PrimaryIsRequiredBeforeOther',
    'Date_DateMustBeWithinDaysRange',
    'Email_InvalidLength',
    'Date_MustBeEarlierThanToday',
    'Date_ExceededFutureDaysLimit',
    'Leadership_MustBeMember',
    'Leadership_MemberNotActiveForFullTerm',
    'Leadership_ClubMustBeActive',
    'Leadership_CannotEditOfficersForTerminatedClub',
    'Club_CannotAddMembertoTerminatedClub',
    'Club_CannotGetMemberForTerminatedClub',
    'Club_Inactive',
    'Member_TerminationInfoCannotBeUpdate',
    'Leadership_CannotAssignForPastTerm',
    'Date_CannotBeInThePast',
    'Club_ClubOfficerCannotBeHonMember',
    'Individual_IsDeceased',
    'InvalidOperation',
    'Date_MustNotBeBeforeBaseDate',
    'Date_MustBeAfterBaseDate',
    'Date_MustNotBeAfterBaseDate',
    'Field_MustBeLatinCharacters',
    'Member_CanOnlyBelongToOneClub',
    'Member_CanHaveOnlyTwoSponsors',
    'Member_MemberIsASponsor',
    'Member_CannotSponsorSelf',
    'Member_CanOnlySponsorMember',
    'Member_HasAnActiveMembership',
    'Member_HasAnRotaractorMembership',
    'Member_MembershipTypeAssigned',
    'Member_TerminationReasonCannotBeNull',
    'Member_InvalidTerminationReason',
    'Individual_PrimaryAddressRequired',
    'Member_SponsorIsNotAnActiveMemberAtTimeOfSponsorship',
    'Member_SponsorMustBeInSameClub',
    'Member_CannotUpdateMemberType',
    'Member_CannotTerminateHonoraryMemberWithDeceasedReason',
    'Date_EndDateMoreThan10YearsAfterStartDate',
    'Date_MustStartOnRIYear',
    'Leadership_MustBeInCurrentTerm',
    'Leadership_CannotDeleteActiveOfficer',
    'Leadership_InvalidRole',
    'Leadership_MembershipNotActiveForFullTerm',
    'Club_InvalidClubType',
    'Club_MustBeARotaryClub',
    'Program_FieldDoesNotBelongToProgram',
    'Program_FieldExists',
    'Field_MustNotBeNullOrEmpty',
    'File_UnsupportedFileExtension',
    'File_UnsupportedFileContent',
    'File_MaxFileSizeExceeded',
    'Leadership_CannotDeleteActiveDistrictOfficer',
    'Leadership_MembershipIsNotActiveForDistrictOfficerTerm',
    'InvalidOperator',
    'InvalidValueOutOfAccepted',
    'InvalidTimespan',
    'InvalidGeoLocation',
    'MustBe',
    'MustBetween',
    'NeedSearchField',
    'OperationNotSupportForLeadershipRole',
    'Individual_IsYouth',
    'CannotUpdateIndividualYouth',
    'Field_Required_When_Related_Provided',
    'Date_InvalidYear',
    'MustBePositiveNumber',
  ] as const;

  export interface GetImpersonateSessionOutput {
    legacySessionDetail: string;
    expiration: string;
  }

  export interface DistrictLeadershipIndividualOutput {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string;
    prefix: string;
    suffix: string;
    localizedName: string;
  }

  export interface DistrictLeadershipOrganizationOutput {
    id: string;
    name: string;
    type: string;
  }

  export interface AssignDistrictLeadershipOutput {
    id: string;
    startDate: string;
    endDate: string;
    isDelegated: boolean;
    role: string;
    roleId: string;
    _embedded: {
      individual: DistrictLeadershipIndividualOutput;
      organization: DistrictLeadershipOrganizationOutput;
    };
  }

  export interface AssignClubToAGIndividualOutput
    extends DistrictLeadershipIndividualOutput {}

  export interface AssignClubToAGOrganizationOutput
    extends DistrictLeadershipOrganizationOutput {}

  export interface AssignClubToAGOutput {
    message: string;
    startDate: string;
    endDate: string;
    id: string;
    _embedded: {
      individual: AssignClubToAGIndividualOutput;
      organization: DistrictLeadershipOrganizationOutput;
    };
  }

  export interface AgClubAffiliationPartial
    extends Omit<AgClubAffiliation, '_embedded'> {
    _embedded: {
      club: {
        id: string;
        name: string;
      };
    };
  }

  export interface GetAgClubAffiliationsOutput
    extends DistrictLeadershipIndividualOutput {
    agClubAffiliations: AgClubAffiliationPartial[];
  }

  export interface PutClubFromAgToAgOutput {}

  export interface UpdateAgClubAffiliationOutput {}

  export interface DeleteAgClubAffiliationOutput {}

  export type UpdateDistrictValue =
    | string
    | PhoneInput
    | PhoneInput[]
    | FaxInput
    | FaxInput[]
    | AddressBaseInput
    | AddressBaseInput[]
    | Email
    | null;

  export interface UpdateDistrictInput
    extends PatchRequestOperation<UpdateDistrictValue> {}

  export interface AssignClubToAGInput {
    individualId: string;
    clubId: string;
    startDate: string;
  }

  export enum OperationMethod {
    POST = 'POST',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
  }

  export type InvokeBatchBody = {
    id: string;
    method: OperationMethod;
    RelativeUrl: string;
    body?:
      | AssignClubToAGInput
      | PutClubFromAgToAgInput
      | UpdateAgClubAffiliationInput[];
  } | null;

  export type BatchPostResponseBody = {
    message: string;
    startDate: string;
    endDate: string;
    id: string;
  };

  export type ErrorBatch = {
    code: string;
    description: string;
    source: string;
  };

  export type ErrorBatchResponse = {
    status: number;
    type: string;
    title: string;
    detail: string;
    errors: ErrorBatch[];
  };

  export type InvokeBatchResponse = {
    Id: number;
    StatusCode: number;
    Body: BatchPostResponseBody | ErrorBatchResponse | null;
  };

  export interface PutClubFromAgToAgInput {
    individualId: string;
    newIndividualId: string;
    endDate?: null | string;
    startDate?: null | string;
  }

  export interface UpdateAgClubAffiliationInput {
    op: string;
    path: string;
    value: string;
  }

  export interface BodyAssignDistrictLeadership {
    individualId: string;
    startDate: string;
    roleId: string;
  }

  export type MonthlyDataItem = {
    month: string;
    fiscalMonth: number;
    count: number | null;
  };

  export type DistrictDashboardMembers = {
    currentMembers: number;
    newMembers: number;
    terminatedMembers: number;
    terminatedClubTerminatedMembers: number;
    terminatedMembersNoRejoin: number;
    charteredMembers: number;
    july1st: number;
    monthly: MonthlyDataItem[];
  };

  export type DistrictDashboardClubs = {
    currentClubs: number;
    newClubs: number;
    terminatedClubs: number;
    july1st: number;
    monthly: MonthlyDataItem[];
  };

  export type DemographicsItem = {
    male: number;
    female: number;
    selfdescribe: number;
    notgiven: number;
  };

  export type AgeCategoriesKeys = 'under40' | 'over40' | 'unreported';

  export type DemographicsAgeCatgory = {
    under40: DemographicsItem;
    over40: DemographicsItem;
    unreported: DemographicsItem;
  };

  export type DistrictDashboardDemographics = {
    current: DemographicsAgeCatgory;
    july1st: DemographicsAgeCatgory;
  };

  export type ClubCreditsKey = 'clubCredits';
  export type ContributionKeys = 'annualFund' | 'polioPlus';

  export type PrevYearContributionDeltas = {
    totalFor30June: number;
    priorMonthEnd: number;
    prevYearAsOfDate: string;
  };

  export type PrevYearCLubCreditsDeltas = {
    prevYearAsOfDate: string;
    polioPlus: number;
    annualFund: number;
  };

  export type ClubCreditsChartData = {
    rotaryYear: number;
    asOfDate: string;
    polioPlus: number | null;
    annualFund: number | null;
    prevYearDeltas: PrevYearCLubCreditsDeltas;
  };

  export type ContributionChartItem = {
    rotaryYear: number;
    asOfDate: string;
    totalFor30June: number | null;
    priorMonthEnd: number | null;
    prevYearDeltas: PrevYearContributionDeltas;
  };

  export type ContributionChartData = {
    annualFund: Array<ContributionChartItem | null>;
    polioPlus: Array<ContributionChartItem | null>;
    clubCredits: Array<ClubCreditsChartData | null>;
  };

  export type ProspectiveRotarian = {
    assignedtoDistrict: number;
    inprocessDistrict: number;
    assignedtoClub: number;
    inprocessClub: number;
    assignedtoWhq: number;
    closedNotconverted: number;
    converted: number;
  };

  export type ContributionItem = {
    current: number;
    yearTodate: number | null;
    monthly: MonthlyDataItem[];
  };

  export type DistrictDashboard = {
    member: DistrictDashboardMembers | null;
    club: DistrictDashboardClubs | null;
    demographic: DistrictDashboardDemographics | null;
    prospectiveRotarian: ProspectiveRotarian | null;
    contribution: {
      priorfiscalmonth: string;
      annualFund: ContributionItem;
      polioPlus: ContributionItem;
      other: ContributionItem;
      clubCredits: {
        annualFundPct: number;
        polioPlusPct: number;
        otherPct: number;
      };
    } | null;
  };

  export type CategoryType<Type> = {
    [Property in keyof Type]: Type[Property];
  };

  export type CategoryOrganization = {
    district?: DistrictDashboard | null;
    rotaryClub?: DistrictDashboard | null;
    rotaractClub?: DistrictDashboard | null;
  };

  export type DistrictDashboardItem = CategoryType<CategoryOrganization> & {
    riYear: number;
  };

  export interface DistrictDashboardsOutput {
    id: string;
    riDistrictId: number;
    asofDate: string;
    _embedded: {
      counts: DistrictDashboardItem[];
    };
  }

  export interface DistrictConferencesRequestParams {
    associatedDistrictId: string;
    presidentialRepresentativeId?: string;
    MaxStartDate?: string;
    MinStartDate?: string;
  }

  export interface CreateDistrictConferenceRequestBody {}

  export interface CreateDistrictConferenceOutput {}

  export interface OptionType {
    id: string;
    optionType: string;
  }

  export interface OptionDetail {
    id: string;
    optionValue?: string | null;
  }

  export interface FeedbackSection {
    id: string;
    sectionName: string;
  }

  export interface Question {
    id: string;
    applicationId: string;
    applicationFeature: string;
    option: string;
    optionSeq: number;
    optionDataType: string;
    optionType: OptionType;
    optionDetail: OptionDetail;
    feedbackSection: FeedbackSection;
  }

  export interface QuestionResponse {
    questionId: string;
    value: string;
  }

  export interface Feedback {
    id: string;
    feedbackType: string;
    questions: Question[];
    responses: QuestionResponse[];
  }
  export interface GetAllFeedbackOutput {
    feedback: Feedback[];
  }

  export interface FeedbackLayoutQuestion {
    id: string;
    applicationId: string;
    applicationFeature: string;
    option: string;
    optionSeq: number;
    optionDataType: string;
    optionType: OptionType;
    optionDetail: OptionDetail;
    feedbackSection: FeedbackSection;
    startYear: number;
    endYear: number;
  }

  export interface GetFeedbackLayoutOutput
    extends Array<FeedbackLayoutQuestion> {}

  export interface CreateFeedbackOutput {
    id: string;
    feedbackType: string;
    questions: Question[];
    responses: QuestionResponse[];
  }

  export interface CreateFeedbackQuestion extends Question {}

  export interface CreateFeedbackRequestBody {
    feedbackType: string;
    questions: CreateFeedbackQuestion[];
    responses: QuestionResponse[];
  }

  export interface ParticipatingDistrict {
    id: string;
    riDistrictId: number;
  }

  export interface PresidentialRepresentative {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
  }

  export interface DistrictConferencesOutput {
    page: number;
    pageSize: number;
    totalCount: number;
    _embedded: {
      conferences: DistrictConference[];
    };
  }

  export interface DistrictConference {
    id: string;
    districtId: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    timeZone: string;
    relatedRotaryYear: number;
    venueName: string;
    language: string;
    prAccommodation: string;
    comments: string;
    participatingDistricts: ParticipatingDistrict[];
    venueType: MeetingType;
    onlineLocation: string;
    locationNameOrDetail: string;
    detailForJoiningRemotely: string;
    conferenceAddress: AddressBaseOutput;
    prAddress: AddressBaseOutput;
    presidentialRepresentative: PresidentialRepresentative;
    isPresRepRequested: boolean;
    presRepAccPhone: PhoneInput;
    _embedded: {
      feedbackList: ConferenceFeedbackList[];
    };
  }

  export enum FeedbackType {
    DG = 'DG',
    PR = 'PR',
  }

  export type ConferenceFeedbackList = {
    id: string;
    feedbackType: FeedbackType;
  };

  export interface CommitteeRolesResponse {
    committeeRole: CommitteeRoles[];
  }

  export type CommitteeRoles = {
    id: string;
    type: string;
    startDate: string;
    endDate: string;
    _embedded: {
      committee: {
        id: string;
        name: string;
        startDate: string;
        endDate: string;
      };
      conference: {
        id: string;
        districtId: string;
        riDistrictId: string;
        startDate: string;
        startTime: string;
        endDate: string;
        endTime: string;
        rotaryYear: string;
      };
    };
  };

  export interface OktaProfileData {
    firstName: string;
    lastName: string;
    emailAddress: string;
    username: string;
    mobileNumber?: string;
    registrationType?: string;
    registrationForm?: string;
  }

  export interface CrmIndividualInfo {
    individualKey: string;
    preferredLanguage: string;
    memberId: string;
    userSortName: string;
  }

  export interface Role {
    roleName: string;
    roleKey: string;
  }

  export interface Application {
    name: string;
    appInstanceId: string;
    linkUrl: string;
  }

  export interface GroupApplicationRolesRelationship {
    name: string;
    id: string;
    roles: Role[];
    applications: Application[];
    accessFlag?: any;
    errorCode: number;
    errorId: number;
    message?: string;
    containErrors: boolean;
  }

  export interface RolesAuthenticationTicketData {
    clubId?: string;
    districtId: string;
    districtKey: string;
    individualKey: string;
    memberId: string;
    rotaryClubKey?: string;
    userSortName: string;
    firstName: string;
    lastName: string;
    role: string;
    preferredLanguage: string;
    positionType: string;
    termBeforeDuration: number;
    termAfterDuration: number;
    termStartDate: Date;
    termEndDate: Date;
    roleKey: string;
    delegation?: any;
    delegationStartDate?: string;
    delegationEndDate?: string;
    delegatorSortName?: string;
    delegatorIndividualKey?: string;
    delegatorMemberId?: string;
    rotaractClubId?: string;
    rotaractClubKey?: string;
    clubGroupId?: string;
    clubGroupKey?: string;
    regionalGroupId?: string;
    regionalGroupKey?: string;
    regionalGroupName?: string;
    clubGroupName?: string;
  }

  export interface SSOTicketResponse {
    oktaProfileData: OktaProfileData;
    crmIndividualInfo: CrmIndividualInfo;
    groupApplicationRolesRelationship: GroupApplicationRolesRelationship[];
    rolesAuthenticationTicketData: RolesAuthenticationTicketData[];
  }
}

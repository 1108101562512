// Utils
import { mapDOToCommitteeMemberData } from './mapDOToCommitteeMemberData';
import { constructFullName } from '@utils/constructFullName';

// Types
import { DistrictOfficer } from '@typings/operations';
import { CommitteeMemberData } from '@domain/districts';

export const mapAndFormatDOToCommitteeMemberData = (
  DESList: DistrictOfficer[] = []
): CommitteeMemberData[] => {
  const mappedDESList = DESList.map(
    ({
      id,
      startDate,
      endDate,
      role,
      roleId,
      individual,
    }: DistrictOfficer): CommitteeMemberData =>
      mapDOToCommitteeMemberData(individual, constructFullName(individual), {
        id,
        startDate,
        endDate,
        role,
        roleId,
      })
  );

  const formattedDESList = mappedDESList.reduce(
    (result: CommitteeMemberData[], des: CommitteeMemberData) => {
      const isDESAlreadyProcessed = result.find(
        ({ id }: CommitteeMemberData) => id === des.id
      );

      if (isDESAlreadyProcessed) {
        return result.map((individual: CommitteeMemberData) => {
          const { id, thisDistrictLeadership } = individual;

          if (id === des.id) {
            return {
              ...individual,
              thisDistrictLeadership: [
                ...thisDistrictLeadership,
                des.thisDistrictLeadership[0],
              ],
            };
          }

          return individual;
        });
      }

      return [...result, des];
    },
    []
  );

  return formattedDESList;
};

import { getRotaryYear } from '@utils/datetime';
import moment from 'moment';

export const getMaxDateForRemoveReplace = (
  endDate: string,
  isReplace?: boolean
) => {
  const endDateYear = moment(endDate).year();
  const endYearDate = `${endDateYear}-06-29`;

  let result = endYearDate;

  // Partial term
  // For remove the max date should be the end of the role term
  if (moment(endDate).isBefore(endYearDate) && !isReplace) {
    result = endDate;
  }

  // Partial term
  // For replace the max date should be the end of the RY
  const rotaryYear = Number(getRotaryYear());
  if (isReplace && rotaryYear > endDateYear) {
    result = `${rotaryYear}-06-29`;
  }

  return moment(result).toDate();
};

import { RadioOption } from '@components/Formik/RadioField';
import { TFunction } from 'i18next';

export const optionsAccessFor = (t: TFunction): RadioOption[] => [
  {
    value: 'club',
    label: t(
      'club-editmanagementsystems.add-vendor.authorization-form.accessfor-option1',
      'Club intends to use vendor’s services to manage club data'
    ),
  },
  {
    value: 'district',
    label: t(
      'club-editmanagementsystems.add-vendor.authorization-form.accessfor-option2',
      'District intends to use vendor’s services to manage club data'
    ),
  },
];

export const optionsAccessType = (t: TFunction): RadioOption[] => [
  {
    value: 'edit',
    label: t(
      'club-editmanagementsystems.add-vendor.authorization-form.accesstype-option1',
      'View & Edit Access. Allow the vendor to view and edit your club data.'
    ),
  },
  {
    value: 'view',
    label: t(
      'club-editmanagementsystems.add-vendor.authorization-form.accesstype-option2',
      'View-Only Access. Allow the vendor view-only access to your club data.'
    ),
  },
];

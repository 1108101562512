import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { pipe } from 'fp-ts/lib/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as E from 'fp-ts/lib/Either';
import * as T from 'fp-ts/lib/Task';

import { FetchUrl } from './types';
import { extractResponseData } from './extractResponseData';

const defaultAxiosConfig = { withCredentials: true };

//restGet :: FetchUrl -> TaskEither Error AxiosResponse
export const axiosGet = <R>(
  url: FetchUrl,
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
): TE.TaskEither<Error, AxiosResponse<R>> =>
  TE.tryCatch(() => axios.get(url, config), E.toError);

// postTE :: FetchUrl, anu, config -> TaskEither<Error, RestData>
export const axiosPost = <D, R>(
  url: FetchUrl,
  data: D,
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
): TE.TaskEither<Error, AxiosResponse<R>> =>
  TE.tryCatch(() => axios.post(url, data, config), E.toError);

export const axiosCall = <R>(
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
): TE.TaskEither<AxiosError, AxiosResponse<R>> =>
  TE.tryCatch(
    () => axios(config),
    reason => reason as AxiosError
  );

// getTE :: FetchUrl, config -> TaskEither<unknown, RestData>
export const getTE = <R extends any>(
  fetcher: FetchUrl,
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
): TE.TaskEither<Error, R | any> =>
  pipe(axiosGet<R>(fetcher, config), TE.map(extractResponseData));

// postTE :: FetchUrl, anu, config -> TaskEither<unknown, RestData>
export const postTE = <D, R>(
  fetcher: FetchUrl,
  data: D,
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
) =>
  pipe(
    axiosPost<D, R>(fetcher, data, config),
    TE.map((data: AxiosResponse<R>) =>
      extractResponseData<AxiosResponse<R>, R>(data)
    )
  );

export const axiosTE = <R>(
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
) =>
  pipe(
    config,
    options => axiosCall<R>(options),
    TE.map((data: AxiosResponse<R>) =>
      extractResponseData<AxiosResponse<R>, R>(data)
    )
  );

// get :: FetchUrl, config -> Task<RestData>
export const get = <R>(
  fetcher: FetchUrl,
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
): T.Task<R> => pipe(getTE(fetcher, config), TE.fold(T.of, T.of));

// post :: FetchUrl, any, AxiosRequestConfig -> Task<RestData>
export const post = <D, R>(
  fetcher: FetchUrl,
  data: D,
  config: AxiosRequestConfig | undefined = defaultAxiosConfig
) => pipe(postTE<D, R | Error>(fetcher, data, config), TE.fold(T.of, T.of));

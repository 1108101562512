// @TODO load translations dynamically based on /languages.tsx
import { default as enJsonTranslation } from './en.json';
import { default as deJsonTranslation } from './de.json';
import { default as frJsonTranslation } from './fr.json';
import { default as esJsonTranslation } from './es.json';
import { default as itJsonTranslation } from './it.json';
import { default as jaJsonTranslation } from './ja.json';
import { default as ptJsonTranslation } from './pt.json';
import { default as koJsonTranslation } from './ko.json';

export const resources = {
  en: {
    translation: enJsonTranslation,
  },
  de: {
    translation: deJsonTranslation,
  },
  fr: {
    translation: frJsonTranslation,
  },
  es: {
    translation: esJsonTranslation,
  },
  it: {
    translation: itJsonTranslation,
  },
  ja: {
    translation: jaJsonTranslation,
  },
  pt: {
    translation: ptJsonTranslation,
  },
  ko: {
    translation: koJsonTranslation,
  },
};

import { FetchDgByRyQuery } from '@typings/operations';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as R from 'ramda';
import { DISTRICT_GOVERNOR, SPECIAL_REPRESENTATIVE } from '@domain/districts';
import { useFetchDGByRY } from '@repositories/memberSearch';

export const useFetchFormattedDGByRY = (
  conferenceDistrictId: string,
  minStartDate: string,
  maxStartDate: string,
  relatedRY: number | string
) => {
  const { loading: loadingDGByRY, data: dgByRYData } = useFetchDGByRY({
    conferenceDistrictId,
    conferenceStartDate: minStartDate,
    conferenceEndDate: maxStartDate,
    relatedRY: `${relatedRY}`,
  });

  const getRoleName = (dgByRYData?: FetchDgByRyQuery): string =>
    pipe(
      O.fromNullable(dgByRYData?.searchDGByRY),
      O.map(R.prop('roleName')),
      O.getOrElse(() => '')
    );

  const getFullName = (dgByRYData?: FetchDgByRyQuery): string =>
    pipe(
      O.fromNullable(dgByRYData?.searchDGByRY),
      O.map(R.prop('fullName')),
      O.getOrElse(() => '')
    );

  const getDistrictNumber = (dgByRYData?: FetchDgByRyQuery): string =>
    pipe(
      O.fromNullable(dgByRYData?.searchDGByRY),
      O.map(R.prop('riDistrictId')),
      O.getOrElse(() => '')
    );

  const isSpRep = (dgByRYData?: FetchDgByRyQuery) =>
    pipe(getRoleName(dgByRYData), R.equals(SPECIAL_REPRESENTATIVE));

  const isDG = (dgByRYData?: FetchDgByRyQuery) =>
    pipe(getRoleName(dgByRYData), R.equals(DISTRICT_GOVERNOR));

  return {
    loadingDGByRY,
    fullName: getFullName(dgByRYData),
    districtNumber: getDistrictNumber(dgByRYData),
    isSpRep: isSpRep(dgByRYData),
    isDG: isDG(dgByRYData),
  };
};

import moment from 'moment';

import { ClubLeadersContextType } from '@domain/clubs/types';

/* CDA-762-Added below method to get correct default date in the calendar while replacing an officer. 
From Club Leaders list, filtering by role and identifying replacing slot role's start-date. Identifying previous leaders enddate + 1, 
which is next available replacing role start date as compared with todays date. */

export const getEffectiveMinDate = (
  clubLeaders: ClubLeadersContextType,
  roleId: string,
  startDate: string
) => {
  const todayDate = new Date();

  const filteredClubLeaders = clubLeaders?.filter(
    leader => leader.role.id === roleId
  );

  const prevLeaderSlotIndex =
    filteredClubLeaders.findIndex(leader => leader.startDate === startDate) - 1;

  const replaceRoleStartDate =
    prevLeaderSlotIndex !== -1
      ? moment(filteredClubLeaders[prevLeaderSlotIndex]?.endDate)
          .add(1, 'days')
          .toDate()
      : todayDate;

  const defaultStartDate = moment(replaceRoleStartDate).isAfter(todayDate)
    ? replaceRoleStartDate
    : todayDate;

  const effectiveMinDate = moment(startDate).isAfter(todayDate)
    ? defaultStartDate
    : todayDate;

  return effectiveMinDate;
};

import { TFunction } from 'i18next';

import { WeekdayFull } from '@typings/graphql';

export const getAllDayLabel = (t: TFunction) => {
  const translations: Record<string, string> = {
    [WeekdayFull.Monday]: `${t('global.weekdayfull.monday', 'Monday')} ${t(
      'club-details.meetings.when-connector',
      'at'
    )}`,
    [WeekdayFull.Tuesday]: `${t('global.weekdayfull.tuesday', 'Tuesday')} ${t(
      'club-details.meetings.when-connector',
      'at'
    )}`,
    [WeekdayFull.Wednesday]: `${t(
      'global.weekdayfull.wednesday',
      'Wednesday'
    )} ${t('club-details.meetings.when-connector', 'at')}`,
    [WeekdayFull.Thursday]: `${t(
      'global.weekdayfull.thursday',
      'Thursday'
    )} ${t('club-details.meetings.when-connector', 'at')}`,
    [WeekdayFull.Friday]: `${t('global.weekdayfull.friday', 'Friday')} ${t(
      'club-details.meetings.when-connector',
      'at'
    )}`,
    [WeekdayFull.Saturday]: `${t(
      'global.weekdayfull.saturday',
      'Saturday'
    )} ${t('club-details.meetings.when-connector', 'at')}`,
    [WeekdayFull.Sunday]: `${t('global.weekdayfull.sunday', 'Sunday')} ${t(
      'club-details.meetings.when-connector',
      'at'
    )}`,
  };
  return translations;
};

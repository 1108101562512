import { useLazyQuery } from '@apollo/client';

import { fetchConferenceDetailsQuery } from '../queries';

import {
  FetchConferenceQuery,
  FetchConferenceQueryVariables,
} from '@typings/operations';

export const useFetchConferenceDetails = () =>
  useLazyQuery<FetchConferenceQuery, FetchConferenceQueryVariables>(
    fetchConferenceDetailsQuery
  );

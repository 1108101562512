import { ClubAffiliation, MembershipAffiliation } from '@typings/graphql';
import { MembershipType } from '@typings/operations';

export const isHonoraryMember = (membershipType: string | undefined) =>
  membershipType === MembershipType.Honorary ||
  membershipType === MembershipType.RotaractHonorary;

const findMembershipAffiliation = (
  clubAffiliation: ClubAffiliation,
  membershipTypes?: MembershipType[]
) => {
  return clubAffiliation.affiliations.find(
    item =>
      item.__typename === 'MembershipAffiliation' &&
      (!membershipTypes || membershipTypes.includes(item.membershipType))
  ) as MembershipAffiliation;
};

export const isHonoraryClubMember = (
  clubAffiliation: ClubAffiliation | undefined
) => {
  if (clubAffiliation) {
    const honoraryFound = findMembershipAffiliation(clubAffiliation, [
      MembershipType.Honorary,
      MembershipType.RotaractHonorary,
    ]);
    if (honoraryFound) {
      return true;
    }
  }

  return false;
};

import { useQuery } from '@apollo/client';
import moment from 'moment';

import { RolesForSelectRotaractOfficers } from '@domain/clubs/types';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { isRotaractClub } from '@utils/isRotaractClub';

import { fetchRolesQuery } from '@queries/fetchRolesQuery';

import { useTranslation } from '@external/react-i18next';

import { GetRolesQuery, GetRolesQueryVariables } from '@typings/operations';

export const useRoleOptions = (
  organizationType?: string[],
  validOn?: Date | string,
  isGlobalMemberSearch?: boolean
) => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery<
    GetRolesQuery,
    GetRolesQueryVariables
  >(fetchRolesQuery, {
    variables: {
      organizationType: organizationType || [],
      validOn: validOn ? moment(validOn).format('YYYY-MM-DD') : undefined,
    },
  });
  const rotaractOfficerRoles = Object.values(RolesForSelectRotaractOfficers);

  return {
    options:
      data?.roles
        ?.filter(({ name, type }) => {
          if (isGlobalMemberSearch) {
            return !rotaractOfficerRoles.includes(
              name.trim() as RolesForSelectRotaractOfficers
            );
          }
          return !isRotaractClub(type) || isRotaractClub(type);
        })
        .map(({ id, name, type }) => ({
          id,
          label: getClubRoleTValue(t, name),
          value: name,
          type,
        })) || [],
    loading,
    error: error?.message,
  };
};

import { gql } from '@apollo/client';

export const fetchAccessLevelsQuery = gql`
  query getAccessLevels($options: [AccessLevelsOptions!]!) {
    results: getAccessLevels(options: $options) {
      target
      level
      arguments {
        targetTermYear
        type
      }
    }
  }
`;

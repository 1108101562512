import { TFunction } from 'i18next';
import moment from 'moment';

import { ROTARACT_ADVISOR } from '@domain/clubs/constants';
import {
  RolesForSelectClubOfficers,
  UnassignedRole,
} from '@domain/clubs/types';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import { MembershipType } from '@typings/operations';

const getRolesForHonoraryMember = (
  name: string,
  membershipType: Omit<MembershipType, 'Rotaractor'>
) => {
  if (membershipType === MembershipType.Honorary) {
    return name === RolesForSelectClubOfficers.ClubExecutiveSecretaryOrDirector;
  }
  if (membershipType === MembershipType.RotaractHonorary) {
    return name === ROTARACT_ADVISOR;
  }
  return name !== RolesForSelectClubOfficers.ClubExecutiveDirectorAssistant;
};

export const processRoles = (
  unassignedRoles: UnassignedRole[],
  selectedTerm: string,
  membershipType: Omit<MembershipType, 'Rotaractor'>,
  t: TFunction
) => {
  return unassignedRoles
    ?.filter(
      ({ name, term }) =>
        moment(term?.endDate).isSame(moment(`${selectedTerm}-06-30`)) &&
        getRolesForHonoraryMember(name, membershipType)
    )
    .map(({ name, id, mandatory }) => ({
      label: `${getClubRoleTValue(t, name)}${mandatory ? '*' : ''}`,
      value: `${id}:${name}`,
    }));
};

import { useMutation } from '@apollo/client';
import { updateClubContactsMutation } from '@repositories/clubs';

// Types
import {
  UpdateClubContactInfoMutation,
  UpdateClubContactInfoMutationVariables,
} from '@typings/operations';

export const useUpdateClubContacts = () =>
  useMutation<
    UpdateClubContactInfoMutation,
    UpdateClubContactInfoMutationVariables
  >(updateClubContactsMutation, {
    refetchQueries: ['fetchEditClubContact'],
  });

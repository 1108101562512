import { ApolloClient, InMemoryCache } from '@apollo/client';
import { IORef } from 'fp-ts/lib/IORef';
import { Option } from 'fp-ts/lib/Option';
import * as O from 'fp-ts/lib/Option';
import { i18n } from 'i18next';

import { Lang } from '@domain/language-negotiation';

import { AddLog } from '@hooks/logger';

import { Okta } from '@typings/okta';

export type Destination = string;
export type Url = string;
export type Path = string;
export type GatsbyEnv = {
  OKTA_APP_CLIENT_TOKEN?: string;
  GATSBY_MRX_API_PUBLIC_URL?: string;
  GATSBY_BACKEND_APP_BASE_URL?: string;
};

export type User = {
  isLoggedIn: boolean;
  individualId?: string | null;
  prerferredLanguage?: Lang;

  // Merged with AuthData
  nfKey?: string;
  login: string;
  // eslint-disable-next-line camelcase
  session_index?: string;
  attributes?: {
    firstName?: string | null;
    lastName?: string | null;
  };
  id?: string;
  currentSecurityQuestion?: string | null;
  impersonatorId?: string | null;
  impersonationExpiration?: string | null;
  riIndividualId?: string | null;
};

export type Member = {
  isLoggedIn: boolean;
  individualId?: string;
};

export type AuthUser = User | Member | undefined;

export enum ErrorUserStatuses {
  NOT_FOUND = 'NOT_FOUND',
  UNKNOWN_STATUS = 'UNKNOWN_STATUS',
}
export type UserStatuses = Okta.Account['status'] | ErrorUserStatuses;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UserAnalytics = any | never;
export type UserWithAnalytics = {
  user?: User;
  analytics?: UserAnalytics;
};

export type ApolloUser = {
  currentUser?: {
    riIndividualId?: string | null;
    nfKey: string;
    login: string;
    oktaId: string;
    individualId: string | null;
    currentSecurityQuestion: string | null;
    impersonatorId: string | null;
    impersonationExpiration: string | null;
    profile?: {
      firstName?: string | null;
      lastName?: string | null;
    };
  };
};

export type UserWithAnalyticsOptions = {
  user: Option<User>;
  analytics: Option<UserAnalytics>;
};

export type Dependencies = {
  client: ApolloClient<InMemoryCache>;
  addLog: AddLog;
};

export type Deps = Dependencies & {
  purgeNotifications: Function;
  addError: Function;
  t: Function;
  i18n: i18n;
  usernameUsedForLogin: IORef<O.Option<string>>;
};

export type ErrorMessages = { [key: string]: string };

export enum HasAccessToEmail {
  Yes = 'yes',
  No = 'no',
}

export interface AbstractToken {
  expiresAt: number;
  authorizeUrl: string;
  scopes: string[];
  pendingRemove?: boolean;
  claims: {
    name: string;
    email: string;
  };
}

export type TokenManager = {
  on: (event: string, callback: Function) => void;
  off: (event: string, callback: Function) => void;
  add: (key: string, value: Token) => void;
  clear: () => void;
  renew: (key: string) => Promise<Token>;
  getTokens: () => Promise<Tokens>;
  getExpireTime: (token: Token) => number;
};

export interface AccessToken extends AbstractToken {
  accessToken: string;
  tokenType: string;
  userinfoUrl: string;
}

export interface RefreshToken extends AbstractToken {
  refreshToken: string;
  tokenUrl: string;
  issuer: string;
}

export interface IDToken extends AbstractToken {
  idToken: string;
  issuer: string;
  clientId: string;
}

export type AuthClient = {
  authStateManager: Object;
  session: {
    get: () => Promise<{ status: string }>;
  };
  storageManager: Object;
  token: Object;
  tokenManager: TokenManager;
  transactionManager: Object;
  options: Object;
  emitter: Object;
  start: () => void;
  stop: () => void;
};

export type Token = AccessToken | IDToken | RefreshToken;

export interface Tokens {
  accessToken?: AccessToken;
  idToken?: IDToken;
  refreshToken?: RefreshToken;
}

export interface OktaError {
  name: string;
  message: string;
  statusCode?: number;
  xhr?: object;
}

export type OktaWidget = {
  authClient: AuthClient;
  remove: () => void;
  on: (event: string, callback: Function) => void;
  showSignInToGetTokens: ({ el }: { el: HTMLElement }) => Promise<SuccessData>;
};

export type SuccessData = NonNullableKeys<
  Pick<Tokens, 'accessToken' | 'idToken'>
>;

export type NonNullableKeys<Type> = {
  [Key in keyof Type]-?: NonNullable<Type[Key]>;
};

import { useLazyQuery } from '@apollo/client';
import { fetchConferencesQuery } from '../queries';
import {
  GetConferencesQuery,
  GetConferencesQueryVariables,
} from '@typings/operations';

export const useFetchConferences = () =>
  useLazyQuery<GetConferencesQuery, GetConferencesQueryVariables>(
    fetchConferencesQuery
  );

import { QueryResult, useQuery } from '@apollo/client';
import { fetchIndividualForAssignClubRoleQuery } from '@repositories/clubs';

//Types
import {
  GetIndividualForAssignClubRoleQuery,
  GetIndividualForAssignClubRoleQueryVariables,
} from '@typings/operations';

export const useFetchIndividualForAssignClubRole = (
  clubId: string,
  individualId: string
): Pick<
  QueryResult<GetIndividualForAssignClubRoleQuery>,
  'loading' | 'error' | 'data'
> =>
  useQuery<
    GetIndividualForAssignClubRoleQuery,
    GetIndividualForAssignClubRoleQueryVariables
  >(fetchIndividualForAssignClubRoleQuery, {
    variables: { clubId, individualId },
  });

import { gql } from '@apollo/client';

export const updateDistrictDetailsQuery = gql`
  mutation updateDistrictDetails(
    $districtId: String!
    $businessAddress: [DistrictAddressInput]
    $primaryWebsite: String
    $primaryPhone: DistrictPhoneInput
    $primaryFax: DistrictFaxInput
    $primaryEmail: DistrictEmailInput
    $emails: DistrictEmailsInput
    $websites: DistrictWebsiteInput
  ) {
    updateDistrictDetails(
      district: {
        districtId: $districtId
        businessAddress: $businessAddress
        primaryWebsite: $primaryWebsite
        primaryPhone: $primaryPhone
        primaryFax: $primaryFax
        primaryEmail: $primaryEmail
        emails: $emails
        websites: $websites
      }
    )
  }
`;

import {
  CLUB_INVOICE,
  DISASTER_RESPONSE_FUND,
  DONATE_WIDGET_MODES,
  POLIO_PLUS_FUND,
  ROTARY_DIRECT,
} from '@constants/index';

export default (destination: string): DONATE_WIDGET_MODES => {
  let destinationMode = destination;
  if (destinationMode.includes(CLUB_INVOICE)) {
    destinationMode = CLUB_INVOICE;
  }
  switch (destinationMode) {
    case DISASTER_RESPONSE_FUND:
      return DONATE_WIDGET_MODES.DRF;
    case ROTARY_DIRECT:
      return DONATE_WIDGET_MODES.RD;
    case POLIO_PLUS_FUND:
      return DONATE_WIDGET_MODES.EP;
    case CLUB_INVOICE:
      return DONATE_WIDGET_MODES.INVOICE;
    default:
      return DONATE_WIDGET_MODES.GIV;
  }
};

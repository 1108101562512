import { useMutation } from '@apollo/client';
import {
  UploadPhotoMutation,
  UploadPhotoMutationVariables,
} from '@typings/operations';
import { uploadPhotoMutation } from '../queries';

export const useUploadPhoto = () =>
  useMutation<UploadPhotoMutation, UploadPhotoMutationVariables>(
    uploadPhotoMutation
  );

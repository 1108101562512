import { ApolloError } from '@apollo/client';
import { TFunction } from 'i18next';

import { useOptions } from '@components/Formik/Select';

import { UnassignedRole } from '@domain/clubs/types';

import { processRoles } from '@use-cases/clubs';

import { MembershipType } from '@typings/operations';

export const useSelectableRoleOptions: (
  unassignedRoles: UnassignedRole[],
  selectedTerm: string,
  type: Omit<MembershipType, 'Rotaractor'>,
  loading: boolean,
  error: ApolloError | undefined,
  t: TFunction
) => useOptions = (
  unassignedRoles,
  selectedTerm,
  type,
  loading,
  error,
  t
) => () => {
  return {
    options: unassignedRoles.length
      ? processRoles(unassignedRoles, selectedTerm, type, t)
      : undefined,
    loading,
    error: error?.message,
  };
};

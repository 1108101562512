import * as React from 'react';
import classNames from 'classnames';

export const OneColumn: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <div data-label="OneColumn" className={classNames('OneColumn', className)}>
    {children}
  </div>
);

import { AssignMemberToRoleValues } from '../types';

export default (
  values: AssignMemberToRoleValues,
  clubId: string,
  roleId: string
) => {
  const { individual, customSlot, editIndividual } = values;
  return {
    clubId,
    roleId,
    individualId: individual?.id || editIndividual?.id || '',
    startDate: customSlot?.start.format('YYYY-MM-DD') || '',
    endDate: customSlot?.end.format('YYYY-MM-DD') || '',
  };
};

// Utils
import { round } from 'lodash';
import { getSign } from '@use-cases/districts';

export default (value: number, withSign: boolean = false) => {
  const sign = withSign ? getSign(value) : '';
  const absoluteValue = Math.abs(round(value));

  return absoluteValue > 999
    ? `${sign}${(absoluteValue / 1000).toFixed(2)} K`
    : `${sign}${absoluteValue}`;
};

// Utils
import { getRotaryYear } from '@utils/datetime';
import { getIsManagerByYear } from './getIsManagerByYear';
import getConferencesByRY from './getConferencesByRY';

// Types
import { GetConferencesResults } from '@typings/graphql';
import { GetAccessLevelsQuery } from '@typings/operations';

export const isCreateConferenceBtn = (
  conferences: GetConferencesResults[],
  districtAccessLvl: GetAccessLevelsQuery
): boolean => {
  const currentRY = Number(getRotaryYear());
  const nextRY = currentRY + 1;

  const isAddConferenceForCurrentRY =
    getIsManagerByYear(districtAccessLvl, currentRY) &&
    !getConferencesByRY(conferences, currentRY).length;

  const isAddConferenceForNextRY =
    getIsManagerByYear(districtAccessLvl, nextRY) &&
    !getConferencesByRY(conferences, nextRY).length;

  return isAddConferenceForCurrentRY || isAddConferenceForNextRY;
};

import { gql } from '@apollo/client';

export const moveAgClubAffiliations = gql`
  mutation moveAgClubAffiliations(
    $movedItems: [MovedItem!]!
    $districtId: String!
    $startDate: String!
  ) {
    moveAgClubAffiliations(
      movedItems: $movedItems
      districtId: $districtId
      startDate: $startDate
    ) {
      errorBatch
      finalResult {
        successResults {
          id
          body {
            message
            startDate
            endDate
            id
          }
          statusCode
        }
        errorResults {
          id
          body {
            status
            type
            title
            detail
            errors {
              code
              description
              source
            }
          }
          statusCode
        }
      }
    }
  }
`;

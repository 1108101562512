import React from 'react';

import classNames from 'classnames';
import { Suggestion } from 'react-places-autocomplete';

import { ElementProps } from '../Element';
import SuggestionOption from './SuggestionOption';

type Props = {
  suggestions: readonly Suggestion[];
  getSuggestionItemProps: (
    suggestion: Suggestion,
    otherProps: Partial<ElementProps>
  ) => Partial<ElementProps>;
};

const LocationSuggestions: React.FC<Props> = ({
  suggestions,
  getSuggestionItemProps,
}) => {
  const handleScrollbarClick = (e: React.MouseEvent) => {
    if (
      e.clientX >= (e.target as HTMLInputElement).clientWidth ||
      e.clientY >= (e.target as HTMLInputElement).clientHeight
    )
      e.preventDefault();
  };

  return (
    <div
      onMouseDown={handleScrollbarClick}
      className={classNames(
        'cursor-pointer w-full z-10 mb-1 bg-white absolute box-border top-autocomplete rounded-sm',
        {
          'dropdown-shadow': suggestions.length > 0,
        }
      )}
    >
      <div className="max-h-75 overflow-y-auto">
        <div className="overflow-y-auto max-h-none box-border relative">
          {suggestions.map(suggestion => (
            <SuggestionOption
              key={suggestion.placeId}
              itemProps={getSuggestionItemProps}
              suggestion={suggestion}
            />
          ))}
          {!!suggestions.length && (
            <div className="flex justify-end p-3">
              <img
                src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationSuggestions;

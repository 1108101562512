// Libs
import { useEffect, useRef, useContext } from 'react';

// Types
import { NotificationContextType, State } from '@domain/notifications';

// Context
import {
  NotificationContext,
  NotificationStateContext,
} from './NotificationProvider';

export const useNotifications: () => NotificationContextType = () => {
  return useContext(NotificationContext);
};

export const useStateNotifications: () => State = () => {
  return useContext(NotificationStateContext);
};

export const useErrorHandling = (
  error?: string,
  condition?: boolean,
  id?: string
) => {
  const { addError, clearNotification } = useNotifications();
  const addErrorRef = useRef(addError);
  const clearNotificationRef = useRef(clearNotification);
  useEffect(() => {
    if (condition && error) {
      addErrorRef.current(error, { id });
    } else if (id) {
      clearNotificationRef.current(id);
    }
  }, [error, condition, id]);
};

import { gql } from '@apollo/client';

export const fetchSiteSearch = gql`
  fragment SearchSiteResultRow on SolrResultItem {
    id
    title_fulltext
    source_id
    source_path
    content_fulltext
    date_modified
    date_created
    content_type
    document_file_size
    item_id
    langcode
    site
    bundle
    _version_
  }

  query SearchSolr(
    $keywords: String = ""
    $content_type: String = ""
    $document_mimetype: String = ""
    $langcode: String = ""
    $topic: String = ""
    $page: Int = 1
    $per_page: Int = 4
  ) {
    searchSolr(
      keywords: $keywords
      content_type: $content_type
      document_mimetype: $document_mimetype
      langcode: $langcode
      topic: $topic
      page: $page
      per_page: $per_page
    ) {
      responseHeader {
        QTime
        status
        zkConnected
      }
      response {
        docs {
          ...SearchSiteResultRow
        }
        numFound
        start
      }
      spellcheckSuggestion
      facet_counts {
        facet_fields {
          content_type {
            value
            count
          }
          topic {
            value
            count
          }
          langcode {
            value
            count
          }
          document_mimetype {
            value
            count
          }
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const deleteClubMeetingQuery = gql`
  mutation deleteClubMeeting(
    $clubId: String!
    $dominoId: String
    $meetingId: String!
  ) {
    deleteClubMeeting(
      input: { clubId: $clubId, dominoId: $dominoId, meetingId: $meetingId }
    ) {
      isMeetingDeleted
    }
  }
`;

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '@components/Icon';
import { OneColumn } from '@components/Layouts/OneColumn';
import { useTranslation } from '@external/react-i18next';
import { useAlerts } from '@hooks/alerts';
import { useAppConfig } from '@hooks/appConfig';
import { log } from '@hooks/logger';

import { Drupal } from '@typings/drupal';
import { LogLevel } from '@typings/graphql';

interface ConfirmedAlerts {
  [alertId: string]: boolean;
}

export const AlertItem: React.FC<{
  alert: Drupal.ScheduledAlert;
  onDismiss: (id: string) => void;
}> = ({
  alert: { header, message, vid, alert_style: alertStyle },
  onDismiss,
}) => {
  const { t } = useTranslation();
  const htmlValue = message;
  const newMessage = htmlValue.replace(
    'href',
    'class="text-gold-600 font-bold underline hover:no-underline hover:shadow-none" href'
  );
  return (
    <div
      className={classNames(
        'mb-4 text-sm py-6 px-5 flex items-start justify-between border-b-6',
        alertStyle !== 'default'
          ? 'bg-gray-100 border-red-600 text-red-600'
          : 'bg-dark-blue-500  border-gold-600 text-gold-600'
      )}
      key={vid}
    >
      <div>
        <p className="font-bold">{header}</p>
        <div dangerouslySetInnerHTML={{ __html: newMessage }} />
      </div>
      <button
        type="button"
        onClick={() => onDismiss(vid)}
        className="ml-5"
        data-testid="close-button"
      >
        <Icon
          name="ui/close"
          size="20"
          className={
            alertStyle !== 'default' ? 'text-red-600' : 'text-gold-600'
          }
        />
        <span className="sr-only">
          {t('notification.close-button', 'Close')}
        </span>
      </button>
    </div>
  );
};

const Alerts = () => {
  const [confirmedAlerts, setConfirmedAlerts] = useState<ConfirmedAlerts>({});
  const { i18n } = useTranslation();
  const [{ data, loading }] = useAlerts(i18n.language);
  const { isProcessingLogin } = useAppConfig();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const readValue = window.localStorage.getItem('confirmedAlerts');
      if (readValue !== null) {
        try {
          setConfirmedAlerts(JSON.parse(readValue));
        } catch (e) {
          setConfirmedAlerts({});
        }
      }
    }
  }, []);

  const handleDismissAlert = (id: string) => {
    const newConfirmedAlerts = { ...confirmedAlerts, [id]: true };
    setConfirmedAlerts(newConfirmedAlerts);
    window.localStorage.setItem(
      'confirmedAlerts',
      JSON.stringify(newConfirmedAlerts)
    );
  };

  const unConfirmedAlerts = data
    ? data.filter(({ vid: alertId }) => !(alertId in confirmedAlerts))
    : [];

  if (isProcessingLogin || loading || !unConfirmedAlerts.length) {
    return null;
  }

  log({
    level: LogLevel.Debug,
    message: `Drupal: getAlerts, request to ${process.env.GATSBY_BACKEND_APP_BASE_URL}/${i18n.language}/restapi/alerts`,
    other: {
      requestData: {
        withCredentials: true,
      },
      response: data,
    },
  });

  return (
    <OneColumn>
      {unConfirmedAlerts.map(alert => (
        <AlertItem
          key={alert.vid}
          alert={alert}
          onDismiss={handleDismissAlert}
        />
      ))}
    </OneColumn>
  );
};

export default Alerts;

import { gql, useLazyQuery } from '@apollo/client';

import {
  FetchDistrictMembersQuery,
  FetchDistrictMembersQueryVariables,
} from '@typings/operations';

const MemberSearchRow = gql`
  fragment MemberSearchRow on MemberSearchResult {
    id
    name
    localizedName
    email
    location
    photoUri
    phoneNumber
    onlineId
    riIndividualId
    activeMemberships(riClubId: "") {
      type
      clubName
      clubId
      admissionDate
      terminationDate
    }
    activeLeaderships(riClubId: "") {
      role
      clubName
      clubId
    }
    nameWithPrefixSuffix
    thisDistrictLeadership(
      district: $district
      includeDLHistory: $includeDLHistory
    ) {
      roleId
      role
      startDate
      endDate
      id
      riDistrictId
      termYears
    }
    sharingPermissionsResult {
      email
      phone
    }
    associatedClubsInfo(district: $district) {
      clubId
      clubName
      clubType
      physicalLocation {
        country
        city
        state
        internationalProvince
      }
    }
    preferredLanguage {
      id
      name
    }
  }
`;

export const fetchDistrictMembersQuery = gql`
  query fetchDistrictMembers(
    $district: String
    $page: Int = 1
    $pageSize: Int = 10
    $name: String = ""
    $email: String = ""
    $isIncludedDistrictLeadership: Boolean = true
    $isIncludedDistrictMembership: Boolean = true
    $clubNames: [String] = []
    $clubIds: [String] = []
    $districtRoles: [String] = []
    $memberTypes: [String] = []
    $allowRotaractors: Boolean
    $rolesToExclude: [String] = []
    $rotaryYear: String
    $individualId: String
    $yearRange: [Int!]
    $includeDLHistory: Boolean
    $isDeceased: Boolean
  ) {
    members: memberSearchAdvanced(
      district: $district
      pageSize: $pageSize
      page: $page
      name: $name
      email: $email
      isIncludedDistrictLeadership: $isIncludedDistrictLeadership
      isIncludedDistrictMembership: $isIncludedDistrictMembership
      clubNames: $clubNames
      clubIds: $clubIds
      districtRoles: $districtRoles
      memberTypes: $memberTypes
      allowRotaractors: $allowRotaractors
      rolesToExclude: $rolesToExclude
      rotaryYear: $rotaryYear
      individualId: $individualId
      yearRange: $yearRange
      includeDLHistory: $includeDLHistory
      isDeceased: $isDeceased
    ) {
      totalCount
      results {
        ...MemberSearchRow
      }
    }
  }
  ${MemberSearchRow}
`;

export const useFetchDistrictMembers = () =>
  useLazyQuery<FetchDistrictMembersQuery, FetchDistrictMembersQueryVariables>(
    fetchDistrictMembersQuery
  );

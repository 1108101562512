import { useLazyQuery } from '@apollo/client';

// Types
import { fetchPreviousOfficersQuery } from '../queries';

import {
  FetchPreviousOfficersQuery,
  FetchPreviousOfficersQueryVariables,
} from '@typings/operations';

export const useFetchPreviousOfficers = () => {
  return useLazyQuery<
    FetchPreviousOfficersQuery,
    FetchPreviousOfficersQueryVariables
  >(fetchPreviousOfficersQuery);
};

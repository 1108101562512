import { DISTRICT_OFFICER_GOVERNOR_ROLES } from '@domain/districts';

import { DistrictOfficer } from '@typings/graphql';
import { FetchAllDistrictOfficersQuery } from '@typings/operations';

export const buildRolesQueryVars = (year: number) => ({
  organizationType: ['District'],
  validOn: `${year}-07-01`,
  officerManagement: 'District',
  rolesToInclude: DISTRICT_OFFICER_GOVERNOR_ROLES,
});

export const buildOfficersQueryVars = (
  districtId: string,
  riDistrictId: number,
  year: number,
  // eslint-disable-next-line default-param-last
  shouldGetMembershipsFromOtherDistricts: boolean = false,
  districtRoles?: string[]
) => ({
  districtId,
  riDistrictId,
  rotaryYear: String(year),
  districtRoles,
  options: {
    shouldFetchNonMemberDES: true,
    shouldGetMembershipsFromOtherDistricts,
  },
});

export const extractOfficersData = (
  data: FetchAllDistrictOfficersQuery | undefined
) => (data?.districtOfficers.leadership as DistrictOfficer[]) || [];

import { TFunction } from 'i18next';

import { constructMenuItem } from './constructMenuItem';

import { getClubDetailsPath } from '@use-cases/clubs';

import { getClubTypeName } from '@utils/getClubTypeName';

import { Drupal } from '@typings/drupal';
import { ClubLeadership } from '@typings/operations';

export const getClubsSubItems = (
  t: TFunction,
  cutClubsArr: ClubLeadership[],
  clubsCount: number = 0,
  maxSubItemsCount: number = 5
): Array<Drupal.MenuItem> => {
  const cutClubsSubItems = cutClubsArr.map(({ clubId, clubName, clubType }) => {
    const clubsType = clubType ? getClubTypeName(t, clubType) : '';

    const nameWithType = `${clubName} (${clubsType})`;

    const clubRow = nameWithType;

    return constructMenuItem(
      `club-${clubId}`,
      getClubDetailsPath(clubId),
      clubRow
    );
  });

  if (clubsCount > maxSubItemsCount) {
    return [
      ...cutClubsSubItems,
      constructMenuItem(
        'all-my-clubs',
        '/my-clubs',
        t('header.main-menu.all-my-clubs-label', 'All my clubs')
      ),
    ];
  }

  return cutClubsSubItems;
};

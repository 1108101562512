import { useQuery, QueryResult } from '@apollo/client';
import {
  FetchBackgroundQuery,
  FetchProfileQueryVariables,
} from '@typings/operations';
import { fetchBackgroundQuery } from '../queries';

export type FetchBackgroundQueryResult = Pick<
  QueryResult<FetchBackgroundQuery>,
  'data' | 'error' | 'loading'
> & {
  refetch: () => Promise<any>;
};

export const useFetchBackground = (
  individualId: string
): FetchBackgroundQueryResult => {
  return useQuery<FetchBackgroundQuery, FetchProfileQueryVariables>(
    fetchBackgroundQuery,
    {
      variables: {
        id: individualId!,
      },
    }
  );
};

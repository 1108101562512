// Libs
import React, { useContext, useState } from 'react';

// Components
import CheckboxPrompt from '@presenters/web/components/CheckboxPrompt';

// Hooks
import { ROLE_3YR_COMMUNICATION_CONFIRMATION } from '@domain/districts';

import { DistrictContext } from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';

export const use3yrRoleConfirmation = () => {
  const { t } = useTranslation();
  const [context] = useContext(DistrictContext);
  const {
    selectedInfo: { role },
  } = context;
  const is3yrRole = role?.termYears === 3;
  const [isCommunicationConfirmed, setIsCommunicationConfirmed] = useState(
    false
  );

  const renderConfirmation = () =>
    is3yrRole ? (
      <CheckboxPrompt
        isChecked={isCommunicationConfirmed}
        toggleIsChecked={() =>
          setIsCommunicationConfirmed(!isCommunicationConfirmed)
        }
        prompt={t(
          'district-officers.3yr-role-communication-confirmation',
          ROLE_3YR_COMMUNICATION_CONFIRMATION
        )}
      />
    ) : null;

  return { isCommunicationConfirmed, renderConfirmation };
};

import { useMutation } from '@apollo/client';
import { updateIndividualMutation } from '@repositories/clubs';

// Types
import {
  UpdateIndividualMutation,
  UpdateIndividualMutationVariables,
} from '@typings/operations';

export const useUpdateIndividual = () =>
  useMutation<UpdateIndividualMutation, UpdateIndividualMutationVariables>(
    updateIndividualMutation
  );

import { useLazyQuery } from '@apollo/client';

import { fetchClubMembersQuery } from '@repositories/clubs';

import { ClubRosterQuery, ClubRosterQueryVariables } from '@typings/operations';

export const useFetchClubMembersByFilter = (
  filters: ClubRosterQueryVariables,
  currentPage: number,
  pageSize: number,
  isMultifilterAllowed?: boolean
) =>
  useLazyQuery<ClubRosterQuery, ClubRosterQueryVariables>(
    fetchClubMembersQuery,
    {
      variables: {
        ...filters,
        pageSize,
        page: currentPage,
        isMultifilterAllowed,
      },
    }
  );

// Libs
import { pipe } from 'fp-ts/lib/function';
import { some, toNumber } from 'lodash/fp';
import * as O from 'fp-ts/Option';
import { AccessLevel, GetAccessLevelsQuery } from '@typings/operations';

export const getIsManagerByYear = (
  accessLevelData: GetAccessLevelsQuery | undefined,
  year: number
) =>
  pipe(
    O.fromNullable(accessLevelData?.results),
    O.map(
      some(
        item =>
          toNumber(item.arguments?.targetTermYear) === year &&
          item.level === AccessLevel.Manager
      )
    ),
    O.getOrElse(() => false)
  );

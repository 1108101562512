import { decode } from 'html-entities';
import { delocalizedPath } from '@utils/localized-path';

type EscapeQueryParams = {
  [key: string]: any;
};

export const capitalizeFirstLetter = (word: String): string => {
  const lower = word.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.substring(1);
};

/**
 * Left trims a string by a charlist.
 */
export const lTrimByCharList = (source: string, charlist: string): string => {
  if (!source) {
    return '';
  }
  let result = source;
  // Remove the charlist from the beginning.
  while (result.substring(0, charlist.length) === charlist) {
    result = result.substring(charlist.length);
  }
  return result;
};

/**
 * Right trims a string by a charlist.
 */
export const rTrimByCharList = (source: string, charlist: string): string => {
  if (!source) {
    return '';
  }
  let result = source;
  // Remove the charlist from the end.
  while (result.substring(result.length - charlist.length) === charlist) {
    result = result.substring(0, result.length - charlist.length);
  }
  return result;
};

/**
 * Trims a string by a charlist.
 */
export const trimByCharList = (source: string, charlist: string): string => {
  if (!source) {
    return '';
  }

  return rTrimByCharList(lTrimByCharList(source, charlist), charlist);
};

/**
 * Splits a path by '/' and returns its components.
 */
export const argsFromPath = (path: string): string[] => {
  return trimByCharList(path, '/').split('/');
};

/**
 * Wrapper on the html5 entities decode method.
 */
export const htmlEntitiesDecode = (source: string) => {
  return decode(source);
};

/**
 * Escape quotes
 * @param str
 */
export const addSlashes = (str: string) => {
  return (str + '').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
};

export const escapeQueryParams = (params: EscapeQueryParams) => {
  Object.keys(params).map(filterName => {
    if (typeof params[filterName] == 'string') {
      params[filterName] = addSlashes(params[filterName]);
    }
  });
  return params;
};

export const matchBypass = (location: string, paths: string[]): boolean => {
  const formattedLocation = delocalizedPath(location);
  const getRegEx = (path: string) =>
    new RegExp(`^/?${path.replace('*', '.*')}$`);

  return paths.some((path: string) => getRegEx(path).test(formattedLocation));
};

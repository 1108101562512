import { useLazyQuery } from '@apollo/client';
import {
  GetAccessLevelsQuery,
  GetAccessLevelsQueryVariables,
} from '@typings/operations';
import { fetchAccessLevelsQuery } from '@queries/fetchAccessLevelsQuery';

export const useFetchAccessLevels = () =>
  useLazyQuery<GetAccessLevelsQuery, GetAccessLevelsQueryVariables>(
    fetchAccessLevelsQuery
  );

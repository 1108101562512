import { useMutation } from '@apollo/client';
import {
  UpdateProgramMutation,
  UpdateProgramMutationVariables,
} from '@typings/operations';
import { editProgramMutation } from '../queries';

export const useUpdateProgram = () => {
  return useMutation<UpdateProgramMutation, UpdateProgramMutationVariables>(
    editProgramMutation
  );
};

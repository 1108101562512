import moment from 'moment';

import {
  CommitteeDistrictLeadership,
  DISTRICT_OFFICER_GOVERNOR_ROLES,
} from '@domain/districts';

export const canBeReplacedRemovedRole = (endDate: string) =>
  moment(endDate).isAfter(moment(), 'd');

// Skipping roles which is
// `District Leadership - DG, DGE, DGN, Spec. Rep.`
export const filterDistrictLeadershipByRoles = (
  districtLeadership: CommitteeDistrictLeadership[]
) =>
  districtLeadership.filter(
    leadership =>
      !DISTRICT_OFFICER_GOVERNOR_ROLES.includes(leadership.role) &&
      canBeReplacedRemovedRole(leadership.endDate)
  );

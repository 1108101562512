import { SITECORE_USER_TYPES } from '@domain/clubs/types';

import {
  Affiliation,
  ClubAffiliation,
  MembershipType,
} from '@typings/operations';

export const getUserType = (
  clubsAffiliations: ClubAffiliation[],
  isDESInfoAvailable: boolean
) => {
  // Treat DES User, Leader by default.
  if (isDESInfoAvailable) {
    return SITECORE_USER_TYPES.LEADER;
  }

  const affiliations = clubsAffiliations?.reduce(
    (acc: Affiliation[], cur: ClubAffiliation) =>
      cur.affiliations ? [...acc, ...cur.affiliations] : [...acc],
    []
  );

  const isMember = (affiliation: Affiliation) =>
    affiliation.__typename === 'LeadershipAffiliation' ||
    (affiliation.__typename === 'MembershipAffiliation' &&
      (affiliation.membershipType === MembershipType.Member ||
        affiliation.membershipType === MembershipType.Rotaractor));
  const member = affiliations.some(isMember);
  // Note: Returning LEADER for member intentionally, member translates to leader behind the scenes.
  return member ? SITECORE_USER_TYPES.LEADER : SITECORE_USER_TYPES.NON_MEMBER;
};

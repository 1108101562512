import { gql } from '@apollo/client';

export const assignClubLeadershipQuery = gql`
  mutation AssignLeadershipRole(
    $clubId: String!
    $individualId: String!
    $roleId: String!
    $startDate: String!
    $endDate: String!
  ) {
    createLeadership(
      clubId: $clubId
      individualId: $individualId
      roleId: $roleId
      startDate: $startDate
      endDate: $endDate
    ) {
      leadership {
        id
      }
    }
  }
`;

import { useEffect, useMemo } from 'react';

import { ASSISTANT_GOVERNOR, IsManager } from '@domain/districts';

import { useFetchDistrictMembers } from '@repositories/districts';

import { getRotaryYear } from '@utils/datetime';

import { FetchDistrictMembersQuery } from '@typings/operations';

export const useFetchIsAgInfoById = (
  individualId: string | undefined,
  districtId: string,
  isManager: IsManager
) => {
  const [
    fetchMembersCurrentYear,
    { data: currentYearData, loading: currentYearLoading },
  ] = useFetchDistrictMembers();

  const [
    fetchMembersNextYear,
    { data: nextYearData, loading: nextYearLoading },
  ] = useFetchDistrictMembers();

  const getRequestVariables = (year: string) => ({
    variables: {
      district: districtId,
      rotaryYear: year,
      individualId,
      rolesToExclude: [ASSISTANT_GOVERNOR],
    },
  });

  const isAssignedToAg = (data?: FetchDistrictMembersQuery) =>
    data?.members.totalCount === 0;

  useEffect(() => {
    if (individualId) {
      const RY = getRotaryYear();
      if (isManager.nowTerm) {
        fetchMembersCurrentYear(getRequestVariables(RY));
      }
      if (isManager.afterNowTerm) {
        fetchMembersNextYear(getRequestVariables(`${Number(RY) + 1}`));
      }
    }
  }, [individualId]);

  return useMemo(
    () => ({
      currentYearAssigned: isAssignedToAg(currentYearData),
      nextYearAssigned: isAssignedToAg(nextYearData),
      loading: currentYearLoading || nextYearLoading,
    }),
    [districtId, individualId, currentYearLoading, nextYearLoading]
  );
};

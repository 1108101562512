import queryString from 'query-string';

export const getQueryParams = () => {
  if (typeof window !== 'undefined') {
    return queryString.parse(window.location.search);
  }
  return {};
};

export const getLanguageFromURL = () => {
  let userLanguage = 'en';
  if (typeof window !== 'undefined') {
    const userLangCode = new URL(window.location.href).pathname.split('/');
    userLanguage = userLangCode.length > 1 ? userLangCode[1] : userLangCode[0];
  }
  return userLanguage;
};

// Libs
import { useContext, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

// Context
import { fetchRolesQuery } from '@queries/fetchRolesQuery';

import { CommitteeAppointment, Role } from '@domain/districts';

// Hooks
import {
  areDOsArraysEqual,
  buildOfficersQueryVars,
  buildRolesQueryVars,
  DistrictContext,
  extractOfficersData,
  getFormattedRoles,
} from '@use-cases/districts';

import { useFetchAllDistrictOfficers } from '@repositories/districts';

// Utils
import { getRotaryYear } from '@utils/datetime';

// Queries

// Types
import { DistrictOfficer } from '@typings/graphql';

export const useSetContextManagedRYs = (
  districtId: string,
  riDistrictId: number | null | undefined
) => {
  const currentRY = Number(getRotaryYear());
  const [currentRYOfficers, setCurrentRYOfficers] = useState<DistrictOfficer[]>(
    []
  );
  const [futureRYOfficers, setFutureRYOfficers] = useState<DistrictOfficer[]>(
    []
  );
  const currentRYRoles = getFormattedRoles(
    useQuery(fetchRolesQuery, {
      variables: buildRolesQueryVars(currentRY - 1),
    }).data
  );
  const futureRYRoles = getFormattedRoles(
    useQuery(fetchRolesQuery, {
      variables: buildRolesQueryVars(currentRY),
    }).data
  );
  const [
    getCurrentRYOfficers,
    { data: currentRYOfficersData, loading: currentOfficersLoading },
  ] = useFetchAllDistrictOfficers();
  const [
    getFutureRYOfficers,
    { data: futureRYOfficersData, loading: futureOfficersLoading },
  ] = useFetchAllDistrictOfficers();
  const [
    {
      managedRYs: {
        current: {
          roles: contextCurrentRYRoles,
          officers: contextCurrentRYOfficers,
        },
        future: {
          roles: contextFutureRYRoles,
          officers: contextFutureRYOfficers,
        },
      },
    },
    setContext,
  ] = useContext(DistrictContext);

  const setEntityOnContext = (
    year: 'current' | 'future',
    entity:
      | { key: 'roles'; data: Role[] }
      | { key: 'officers'; data: DistrictOfficer[] }
  ) =>
    setContext((prevState: CommitteeAppointment) => ({
      ...prevState,
      managedRYs: {
        ...prevState.managedRYs,
        [year]: {
          ...prevState.managedRYs[year],
          [entity.key]: entity.data,
        },
      },
    }));

  useEffect(() => {
    if (!contextCurrentRYRoles.length && currentRYRoles.length) {
      setEntityOnContext('current', {
        key: 'roles',
        data: currentRYRoles,
      });
    }
  }, [contextCurrentRYRoles, currentRYRoles]);

  useEffect(() => {
    if (!contextFutureRYRoles.length && futureRYRoles.length) {
      setEntityOnContext('future', {
        key: 'roles',
        data: futureRYRoles,
      });
    }
  }, [contextFutureRYRoles, futureRYRoles]);

  useEffect(() => {
    if (riDistrictId) {
      getCurrentRYOfficers({
        variables: buildOfficersQueryVars(
          districtId,
          riDistrictId,
          currentRY,
          true
        ),
      });

      getFutureRYOfficers({
        variables: buildOfficersQueryVars(
          districtId,
          riDistrictId,
          currentRY + 1,
          true
        ),
      });
    }
  }, [riDistrictId]);

  useEffect(() => {
    setCurrentRYOfficers(extractOfficersData(currentRYOfficersData));
  }, [currentRYOfficersData]);

  useEffect(() => {
    setFutureRYOfficers(extractOfficersData(futureRYOfficersData));
  }, [futureRYOfficersData]);

  useEffect(() => {
    if (
      currentRYOfficers.length &&
      !areDOsArraysEqual(contextCurrentRYOfficers, currentRYOfficers)
    ) {
      setEntityOnContext('current', {
        key: 'officers',
        data: currentRYOfficers,
      });
    }
  }, [contextCurrentRYOfficers, currentRYOfficers]);

  useEffect(() => {
    if (
      futureRYOfficers.length &&
      !areDOsArraysEqual(contextFutureRYOfficers, futureRYOfficers)
    ) {
      setEntityOnContext('future', { key: 'officers', data: futureRYOfficers });
    }
  }, [contextFutureRYOfficers, futureRYOfficers]);

  return {
    currentOfficersLoading,
    futureOfficersLoading,
  };
};

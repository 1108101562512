import { useQuery } from '@apollo/client';

import { fetchClubInvoiceDataQuery } from '../queries/fetchClubInvoiceData';

import {
  FetchClubInvoiceDataQuery,
  FetchClubInvoiceDataQueryVariables,
} from '@typings/operations';

export const useFetchClubInvoiceData = (clubId: string) => {
  return useQuery<
    FetchClubInvoiceDataQuery,
    FetchClubInvoiceDataQueryVariables
  >(fetchClubInvoiceDataQuery, {
    variables: { clubId },
    fetchPolicy: 'cache-and-network',
  });
};

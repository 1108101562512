import { useEffect, useState } from 'react';

import { useMobileLayout } from '@hooks/useMobileLayout';

export const useHandleStickySelectScroll = (
  stickyRef: React.MutableRefObject<HTMLElement | null>
) => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [defaultTopPosition, setDefaultTopPosition] = useState<number>(0);

  const { isTabletLayout, isMobileLayout } = useMobileLayout();

  const getOffsetTop = (element: HTMLElement | null): number => {
    if (!element) {
      return 0;
    }
    return (
      getOffsetTop(element.offsetParent as HTMLElement) + element.offsetTop
    );
  };

  const onRemove = () => {
    stickyRef.current?.classList.remove('stickySelect');
  };

  useEffect(() => {
    if (document) {
      setDefaultTopPosition(
        getOffsetTop(document.getElementById('relativeSelectContainer'))
      );
    }
  }, []);

  useEffect(() => {
    if (isSticky) {
      const isLessThenTabletWidth = isTabletLayout || isMobileLayout;

      stickyRef.current?.classList.add(
        'stickySelect',
        isLessThenTabletWidth ? 'topMobile' : 'topDesktop'
      );

      stickyRef.current?.classList.remove('hidden');
    } else {
      stickyRef.current?.classList.add('hidden');
    }
  }, [isSticky, defaultTopPosition]);

  const handleScroll = () => {
    const x = defaultTopPosition - (isTabletLayout || isMobileLayout ? 60 : 0);
    const res = x - document.documentElement.scrollTop;

    if (res <= 0 && !isSticky) {
      setIsSticky(true);
    } else if (document.documentElement.scrollTop <= x && isSticky) {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return { isSticky, onRemove };
};

import { TFunction } from 'i18next';

import { Option } from '@components/Formik/MultiSelectFilter/types';

import { CLUB_TYPE_ROTARACT, CLUB_TYPE_ROTARY } from '@domain/districts';

export const getClubTypeFilterOptions = (t: TFunction): Option[] => [
  {
    id: '1',
    value: CLUB_TYPE_ROTARACT,
    label: t('filters.club-type.rotaract', 'Rotaract'),
  },
  {
    id: '2',
    value: CLUB_TYPE_ROTARY,
    label: t('filters.club-type.rotary', 'Rotary'),
  },
];

import { TFunction } from 'i18next';
import { AggregatedFacet } from '@utils/search';

export const getFileTypeFacets = (t: TFunction): AggregatedFacet[] => {
  return [
    {
      value: 'application/pdf',
      label: t('site-search.file-type.pdf-label', 'PDF'),
      count: 0,
    },
    {
      value:
        'application/vnd.openxmlformats-officedocument.presentationml.presentation || application/vnd.ms-powerpoint',
      label: t('site-search.file-type.presentation-label', 'Presentation'),
      count: 0,
    },
    {
      value:
        'text/* || application/msword || application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      label: t('site-search.file-type.text-label', 'Text'),
      count: 0,
    },
    {
      value:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || application/vnd.ms-excel',
      label: t('site-search.file-type.table-label', 'Table'),
      count: 0,
    },
    {
      value: 'image/*',
      label: t('site-search.file-type.image-label', 'Image'),
      count: 0,
    },
    {
      value: 'video/*',
      label: t('site-search.file-type.video-label', 'Video'),
      count: 0,
    },
    // This needs to be the last array item for the logic below to work.
    {
      value:
        "!application/pdf || '' || (text/* || application/msword || application/vnd.openxmlformats-officedocument.wordprocessingml.document || application/vnd.openxmlformats-officedocument.presentationml.presentation) || (application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || application/vnd.ms-excel) || image/* || video/*",
      label: t('site-search.file-type.various-label', 'Various'),
      count: 0,
    },
  ];
};

import { useMutation } from '@apollo/client';

import { editMeetingQuery } from '@repositories/clubs';

import {
  EditMeetingMutation,
  EditMeetingMutationVariables,
} from '@typings/operations';

export const useEditMeeting = () =>
  useMutation<EditMeetingMutation, EditMeetingMutationVariables>(
    editMeetingQuery,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        'fetchClubDetails',
        'fetchAddMeeting',
        'fetchEditMeeting',
      ],
    }
  );

import React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { LazyPromise } from '@utils/promise/LazyPromise';
import { Rejecter, Resolver } from '@utils/promise/types';

declare global {
  interface Window {
    googleMapsJsApiContextResolve?: (args: any[]) => any;
  }
}

// When this function declaration includes its type constraints,
// webpack crashes. Should be:
// (f: (args: any[]) => any, ...args: any[]): any => f(args);
// @ts-ignore
const packArgs = (f, ...args) => f(args);

const executeIncludeGoogleMapsJsApi = function configureGoogleMaps(
  resolve: Resolver<any[]>,
  reject: Rejecter
) {
  if (!window) {
    reject(
      new Error(
        "Can't include the Google Maps JavaScript API library because there isn't a browser window to put it in."
      )
    );
  }

  let userLanguage = 'en';
  if (typeof window !== 'undefined') {
    const getUrl = new URL(window.location.href);
    if (getUrl) {
      const userLangCode = getUrl.pathname.split('/');
      userLanguage =
        userLangCode.length > 1 ? userLangCode[1] : userLangCode[0];
    }
  }
  const script = document.createElement('script');

  const headElements = document.getElementsByTagName('head');
  if (headElements.length === 0) {
    reject(new Error('Document has no head element?!'));
  }
  const headElement = headElements[0];

  window.googleMapsJsApiContextResolve = packArgs.bind(null, resolve);
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_JAVASCRIPT_API_KEY}&language=${userLanguage}&libraries=places&callback=googleMapsJsApiContextResolve`;

  headElement.appendChild(script);
};

const GoogleMapsJsApiContext = React.createContext<Promise<any[]>>(
  new LazyPromise<any[]>(executeIncludeGoogleMapsJsApi)
);

export default GoogleMapsJsApiContext;

import { gql } from '@apollo/client';

export const fetchOfficersForRemoving = gql`
  query fetchOfficersForRemoving($clubId: String!, $termStartYear: Int) {
    club: clubById(clubId: $clubId) {
      leaders(termStartYear: $termStartYear) {
        id
        individualName
        startDate
        endDate
        primaryEmail
        role {
          name
          id
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const fetchAccountSettingsQuery = gql`
  query fetchAccountSettings($individualId: String!) {
    settings: getIndividual(id: $individualId) {
      primaryEmail {
        address
      }
      recognitionOptOutFlag
    }
  }
`;

import { gql } from '@apollo/client';

export const fetchPresRepConferencesQuery = gql`
  query getPresRepConferences($individualId: String!) {
    result: getPresRepConferences(individualId: $individualId) {
      term
      districtId
      riDistrictId
      conferences {
        id
        districtId
        relatedRotaryYear
        venueType
        startDate
        endDate
        presidentialRepresentative {
          id
          firstName
          middleName
          lastName
        }
        participatingDistricts {
          id
          riDistrictId
        }
        conferenceAddress {
          lineOne
          lineTwo
          lineThree
          country
          city
          state
          postalCode
          internationalProvince
        }
        feedbacks {
          id
          type
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const updateProfessionalExperienceMutation = gql`
  mutation updateProfessionalExperience(
    $id: String!
    $primaryProfession: ProfessionInput
    $otherProfessions: [ProfessionInput!]
    $sharingPermissionsExtended: SharingPermissionsInput
  ) {
    updateIndividual(
      input: {
        individualId: $id
        primaryProfession: $primaryProfession
        otherProfessions: $otherProfessions
        sharingPermissionsExtended: $sharingPermissionsExtended
      }
    ) {
      individualId
    }
  }
`;

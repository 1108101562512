import { useLazyQuery } from '@apollo/client';

import { fetchAccountSettingsQuery } from '../queries';

import {
  FetchAccountSettingsQuery,
  FetchAccountSettingsQueryVariables,
} from 'src/types/operations';

export const useFetchAccountSettings = () =>
  useLazyQuery<FetchAccountSettingsQuery, FetchAccountSettingsQueryVariables>(
    fetchAccountSettingsQuery
  );

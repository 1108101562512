import {
  MeetingLocationCounter,
  SearchClubNumberedMeetingFragment,
  SearchClubRowFragmentWithNumberedMeetings,
} from '@domain/clubs';
import {
  SearchClubMeetingFragment,
  SearchClubRowFragment,
} from '@typings/operations';

const withMeetingLocationNumber = function(
  this: MeetingLocationCounter,
  meeting: SearchClubMeetingFragment
): SearchClubNumberedMeetingFragment {
  if (meeting.geo && meeting.geo.latitude && meeting.geo.longitude) {
    return {
      ...meeting,
      xRiLocationNumber: this.nextNumber++,
    };
  }
  return meeting;
};

const withMeetingLocationNumbers = (
  numberer: (
    meeting: SearchClubMeetingFragment
  ) => SearchClubNumberedMeetingFragment,
  club: SearchClubRowFragment
): SearchClubRowFragmentWithNumberedMeetings => ({
  ...club,
  meetings: club.meetings.map(numberer),
});

export const eachWithMeetingLocationNumbers = (
  clubs: SearchClubRowFragment[]
): SearchClubRowFragmentWithNumberedMeetings[] => {
  const locationCounter = {
    nextNumber: 1,
  };
  const clubMapper = withMeetingLocationNumbers.bind(
    null,
    withMeetingLocationNumber.bind(locationCounter)
  );
  return clubs.map(clubMapper);
};

//Types
import { MeetingType } from '@typings/resolvers';
import { TFunction } from 'i18next';

export const getMeetingType = (type: string, t: TFunction) => {
  switch (type) {
    case MeetingType.Physical:
      return t('conference-details.meeting-type.physical', 'In Person Only');
    case MeetingType.Online:
      return t('conference-details.meeting-type.online', 'Online Only');
    case MeetingType.Hybrid:
      return t(
        'conference-details.meeting-type.hybrid',
        'In Person and Online'
      );
    default:
      return '';
  }
};

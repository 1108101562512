import { Notification, NotificationType } from './types';

export const initialState = { notifications: [] };

export const initialNotification: Notification = {
  type: NotificationType.SUCCESS,
  message: '',
  nextPage: false,
  element: undefined,
  isFixed: false,
  id: '',
};

export const NOTIFICATIONS_WRAPPER_ID = 'notifications-wrapper';

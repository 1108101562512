// Libs
import i18n from '@i18n';

// Types
import { SelectOptions } from '@components/Formik/Select';
import { ConferenceType } from '@domain/districts/types';

const { t } = i18n;

export const getConferenceTypeSelectOptions = (): SelectOptions => [
  {
    label: t('create-conference.in-person-only', 'In Person Only'),
    value: ConferenceType.Physical,
  },
  {
    label: t('create-conference.online-only', 'Online Only'),
    value: ConferenceType.Online,
  },
  {
    label: t('create-conference.in-person-and-online', 'In Person and Online'),
    value: ConferenceType.Hybrid,
  },
];

import { useLazyQuery } from '@apollo/client';

import { fetchPresRepConferencesQuery } from '../queries';

import {
  GetPresRepConferencesQuery,
  GetPresRepConferencesQueryVariables,
} from '@typings/operations';

export const useFetchPresRepConferences = () =>
  useLazyQuery<GetPresRepConferencesQuery, GetPresRepConferencesQueryVariables>(
    fetchPresRepConferencesQuery
  );

import { useMutation } from '@apollo/client';

import { mapByDisEmailQuery } from '../queries';

import {
  MapByDisEmailMutation,
  MapByDisEmailMutationVariables,
} from '@typings/operations';

export const useMapUserAccount = () =>
  useMutation<MapByDisEmailMutation, MapByDisEmailMutationVariables>(
    mapByDisEmailQuery,
    {
      refetchQueries: ['AuthGetUser'],
    }
  );

import React, { useCallback } from 'react';

import classNames from 'classnames';

import Icon from '@components/Icon';
import Loading from '@components/Loading';
import MenuLinks from '@components/MenuLinks';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useMenu } from '@hooks/menu';

const Footer: React.FC = () => {
  const { i18n } = useTranslation();
  const {
    data: footerMenu,
    error: errorFooter,
    loading: loadingFooter,
  } = useMenu('menu-my-rotary-footer', i18n.language);
  const {
    data: footerHelpMenu,
    error: errorFooterHelp,
    loading: loadingFooterHelp,
  } = useMenu('menu-my-rotary-footer-help', i18n.language);
  const {
    data: socialMenu,
    error: errorSocial,
    loading: loadingSocial,
  } = useMenu('menu-my-rotary-social', i18n.language);
  const { data: legalMenu, error: errorLegal, loading: loadingLegal } = useMenu(
    'menu-my-rotary-legal',
    i18n.language
  );

  const {
    user = {
      individualId: null,
    },
    isProcessingLogin,
    setProcessingLogin,
  } = useAppConfig();

  const loading =
    loadingFooter || loadingFooterHelp || loadingSocial || loadingLegal;

  if (isProcessingLogin && !loading && user?.individualId) {
    setProcessingLogin(false);
  }

  const footerLabel = useCallback(label => {
    return (
      <>
        <span className="sr-only">{label}</span>
        <Icon
          name={`social/${label.toLowerCase().replace(' ', '')}`}
          color="white"
          size="20"
          className="mx-3"
        />
      </>
    );
  }, []);

  const zIndex = isProcessingLogin ? '-z-1' : '';

  return (
    <footer className={`bg-dark-blue-500 text-white ${zIndex}`}>
      <div
        className={classNames(
          'max-w-7xl mx-auto px-6 desktop:px-32 pb-10',
          loadingFooter ? 'pt-6' : 'pt-10'
        )}
      >
        {footerMenu && !loadingFooter && !errorFooter && (
          <MenuLinks
            menuItems={footerMenu}
            className="text-center tablet:mb-6"
            itemClassName="mb-5 tablet:mb-0 tablet:inline-block tablet:mx-5"
            linkClassName="text-white font-bold"
          />
        )}
        {/* Only show secondary menus when all of them are ready. */}
        {loading && <Loading small white />}
        {footerHelpMenu && !loading && !errorFooterHelp && (
          <MenuLinks
            menuItems={footerHelpMenu}
            className="text-center tablet:mb-6"
            itemClassName="mb-5 tablet:mb-0 tablet:inline-block tablet:mx-3"
            linkClassName="text-white font-bold"
          />
        )}
        {socialMenu && !loading && !errorSocial && (
          <MenuLinks
            menuItems={socialMenu}
            className="text-center mt-8 mb-17 pt-1"
            itemClassName="inline-block"
            linkClassName="text-white"
            LabelComponent={({ label }) => footerLabel(label)}
          />
        )}
        <div className="text-center text-xs">
          <p className="mb-3 desktop:inline-block desktop:mb-0 desktop:mr-2">
            © {new Date().getFullYear()} Rotary International. All Rights
            Reserved.
          </p>
          {legalMenu && !loading && !errorLegal && (
            <MenuLinks
              menuItems={legalMenu}
              className="desktop:inline-block"
              itemClassName="inline-block mx-2 desktop:ml-5 desktop:mr-0"
              linkClassName="text-white link-underline"
            />
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

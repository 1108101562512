import { useMutation, gql } from '@apollo/client';
import { AddLogMutationVariables, LogLevel } from '../types/operations';
import { LogLevels } from '@typings/logger';
import { UnreachableCaseError } from '@typings/util';
import { getUTCTime } from '@utils/datetime';

const loggerQuery = gql`
  mutation AddLog($level: LogLevel!, $message: String!, $other: String) {
    sendLog(level: $level, message: $message, other: $other)
  }
`;

const logLevels: LogLevels = {
  debug: 4,
  info: 3,
  warn: 2,
  error: 1,
};

export type AddLog = (variables: AddLogMutationVariables) => void;

export const useLogger = (): { addLog: AddLog } => {
  const [executeQuery] = useMutation(loggerQuery, {
    // Avoid unnecessary re-renders of React components.
    // But there still will be a re-render if the mutation ends with an error,
    // not sure why.
    ignoreResults: true,
  });
  return {
    addLog: async (variables: AddLogMutationVariables) => {
      try {
        await executeQuery({ variables });
        await log(variables, { consoleOnly: true });
      } catch (e) {
        await log(variables);
      }
    },
  };
};

let r7Initialized = false;
let r7InitializePromise: Promise<void> | null = null;
const initR7 = async (): Promise<void> => {
  if (r7Initialized) {
    return;
  }
  if (r7InitializePromise) {
    return r7InitializePromise;
  }
  r7InitializePromise = new Promise(resolve => {
    loadScript('/r7insight.min.js').then(() => {
      if (typeof R7Insight === 'undefined') {
        // eslint-disable-next-line deprecate/member-expression, no-console
        console.error('R7Insight is not defined after loading its script.');
        return;
      }
      R7Insight.init({
        token: process.env.RAPID7_TOKEN_FRONTEND!,
        region: process.env.RAPID7_REGION!,
      });
      r7InitializePromise = null;
      r7Initialized = true;
      resolve();
    });
  });
  return r7InitializePromise;
};

export const log = async (
  variables: {
    level: LogLevel;
    message: string;
    other?: any;
  },
  options?: { consoleOnly?: boolean }
) => {
  let logLevel: 'log' | 'info' | 'warn' | 'error';

  switch (variables.level) {
    case LogLevel.Error:
      logLevel = 'error';
      break;
    case LogLevel.Warn:
      logLevel = 'warn';
      break;
    case LogLevel.Info:
      logLevel = 'info';
      break;
    case LogLevel.Debug:
      logLevel = 'log';
      break;
    default:
      throw new UnreachableCaseError(variables.level);
  }

  const envLogLevel = process.env.GATSBY_LOG_LEVEL as LogLevel;
  if (logLevels[variables.level] > logLevels[envLogLevel]) return;

  if (options?.consoleOnly) {
    return;
  }

  await initR7();
  if (typeof R7Insight === 'undefined') {
    // eslint-disable-next-line deprecate/member-expression, no-console
    console.error(
      'R7Insight is not defined while it has to be initialized already.'
    );
    return;
  }

  const logData = variables.other
    ? { ...variables, other: JSON.stringify(variables.other, null, 2) }
    : { ...variables };

  try {
    await R7Insight[logLevel]({
      ...logData,
      host: window.location.host,
      time: getUTCTime(),
    });
  } catch (e) {
    // eslint-disable-next-line deprecate/member-expression, no-console
    console.error('Cannot log with R7Insight.', e);
  }
};

// https://gist.github.com/dtestyk/64af16ef15bdee079d1a
const loadScript = (url: string): Promise<void> => {
  return new Promise(function(resolve) {
    if (!window?.document) {
      resolve();
      return;
    }
    const head = window.document.getElementsByTagName('head')[0];
    const script = window.document.createElement('script');
    script.type = 'text/javascript';
    script.addEventListener('load', function() {
      resolve();
    });
    script.src = url;
    head.appendChild(script);
  });
};

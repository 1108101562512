import { useEffect, useState } from 'react';

import OktaSignIn from '@okta/okta-signin-widget';
import { newIORef } from 'fp-ts/lib/IORef';
import * as O from 'fp-ts/Option';

import { getOktaConfiguration } from '../utils';

import { OktaWidget, Token, User } from '@domain/auth';

import { useTranslation } from '@external/react-i18next';

/*  Refreshing logic from PECORE-42 is below =>
import { authBackendApps } from '@use-cases/login';
import { logout } from '@use-cases/auth';
import { DrupalError } from '@repositories/auth/typings';
import { useLogger } from '@hooks/logger';
import { Status } from '@typings/express-responses';
import { LogLevel } from '@typings/graphql';
import { getAuthenticationError } from '@use-cases/auth';
import { AccessToken, IDToken } from '@domain/auth';

const OKTA_RENEW_IN_PROGRESS = 'okta-renew-in-progress';
*/

const usernameUsedForLogin = newIORef<O.Option<string>>(O.none)();

export const useOktaWidget = (user: User) => {
  /*  Refreshing logic from PECORE-42 is below =>
      const { addLog } = useLogger();
  */
  const { i18n } = useTranslation();
  const [oktaWidget, setOktaWidget] = useState<OktaWidget>();

  const authClient = oktaWidget?.authClient || null;

  const clearTokens = () => {
    if (authClient) {
      authClient.tokenManager.clear();
    }
  };

  const setTokens = (tokens: Record<string, Token>) => {
    if (authClient) {
      Object.entries(tokens).forEach(([key, value]) => {
        authClient.tokenManager.add(key, value);
      });
    }
  };

  /*  Refreshing logic from PECORE-42 is below =>
  const authBackend = async (accessToken: AccessToken, idToken: IDToken) => {
    const { backend, status } = await authBackendApps(
      accessToken.accessToken,
      idToken.idToken,
      i18n.language,
      addLog,
      {
        initialCall: 'Refresh tokens',
        email: user?.login,
        id: user?.id,
      }
    );

    if (status !== Status.OK) {
      clearTokens();

      // If login in Node was successful but Drupal response error, need to log out of Node.
      if (backend && 'status' in backend && backend.status === Status.OK) {
        const {
          error_code: errorCode,
          error_message: errorMessage,
        } = status as DrupalError;

        addLog(getAuthenticationError(errorMessage, errorCode));
        localStorage.setItem('sessionExpired', 'set');
        logout('/login');
      }
    }

    localStorage.removeItem(OKTA_RENEW_IN_PROGRESS);
  };
  */

  useEffect(() => {
    const oktaConfig = getOktaConfiguration({ i18n, usernameUsedForLogin });
    const signIn = new OktaSignIn(oktaConfig) as OktaWidget;
    const { tokenManager } = signIn.authClient;

    const expireEarlySeconds = 60;

    tokenManager.getExpireTime = (token: Token) => {
      return token.expiresAt - expireEarlySeconds;
    };

    /*
      Refreshing logic from PECORE-42 is below =>
    
    tokenManager.on('expired', async (key: string) => {
      const delay = (Math.random() + 1) * 1000;

      // Multiple tabs can try to refresh tokens together.
      // Need to prevent this tab from refreshing tokens if another tab is already working on it.
      // If the tabs are not active, localStorage is slower than usual, need to make a delay so that all tabs get the actual storage
      await new Promise(res => {
        setTimeout(res, delay);
      });
      if (localStorage.getItem(OKTA_RENEW_IN_PROGRESS)) {
        return;
      }

      localStorage.setItem(OKTA_RENEW_IN_PROGRESS, 'true');

      try {
        if (!user?.isLoggedIn) {
          throw new Error('User is not logged in');
        }

        const session = await signIn.authClient.session.get();
        if (session.status !== 'ACTIVE') {
          throw new Error('Session is not active');
        }

        await tokenManager.renew(key);
        const tokens = await tokenManager.getTokens();

        addLog({
          level: LogLevel.Info,
          message: `Trying to refresh tokens for ${user.login}`,
          other: JSON.stringify(session),
        });

        if (tokens.accessToken && tokens.idToken) {
          await authBackend(tokens.accessToken, tokens.idToken);
        }
      } catch (err) {
        localStorage.removeItem(OKTA_RENEW_IN_PROGRESS);
        clearTokens();

        addLog({
          level: LogLevel.Error,
          message: `Unable to refresh tokens. ${(err as Error).message}`,
        });
      }
    });

    */

    if (oktaWidget) {
      signIn.authClient.start();
    }

    setOktaWidget(signIn);

    return () => {
      signIn.remove();
      signIn.authClient.stop();
    };
  }, [i18n.language, !!oktaWidget, user.isLoggedIn]);

  return {
    oktaWidget,
    authClient,
    usernameUsedForLogin,
    clearTokens,
    setTokens,
  };
};

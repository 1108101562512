import { gql } from '@apollo/client';

export const fetchClubContactsQuery = gql`
  query fetchEditClubContact($clubId: String!, $dominoId: String) {
    club: clubById(clubId: $clubId, dominoId: $dominoId) {
      address: mailingAddress {
        lineOne
        lineTwo
        lineThree
        countryId
        city
        state
        stateId
        postalCode
        internationalProvince
        id
      }
      primaryEmail
      primaryPhone {
        countryId
        extension
        number
        country
        id
      }
      primaryFax {
        countryId
        extension
        number
        country
        id
      }
      email {
        id
        isPrimary
        email
      }
      website {
        primaryWebsite
        otherWebsites
        id
      }
      primaryWebsite
      officialLanguage {
        id
      }
    }
  }
`;

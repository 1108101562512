import React from 'react';
import { Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ToolsCardsNPM from '@rotaryintl/component-tools-cards';
import { createLink } from '../../utilities/link-helpers';

const ToolsCards = ({ params: { ComponentId } = {}, fields: { Title, Description, ToolCards } }) => (
  <ToolsCardsNPM
    id={ComponentId}
    title={<Text field={Title} />}
    description={<RichText field={Description} />}
    toolCards={ToolCards?.map(({ fields: { Title, Description, Link } }) => ({
      title: <Text field={Title} />,
      description: <RichText field={Description} />,
      link: createLink(Link),
    }))}
  />
);

export default withSitecoreContext()(ToolsCards);

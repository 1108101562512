import { LogLevel } from '@typings/graphql';

export const getAuthenticationError = (
  errorMessage: string,
  errorCode?: string
) => {
  const errorCodeMessage = errorCode ? `Error code: ${errorCode}.` : '';

  return {
    level: LogLevel.Error,
    message: `An error was generated during the backend authentication process. ${errorCodeMessage} Error message: ${errorMessage}`,
  };
};

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getAnalyticsIndividualQuery } from '@repositories/auth/queries';
import { User, UserAnalytics } from '@domain/auth';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/lib/TaskEither';
import * as O from 'fp-ts/lib/Option';

// fetchUserAnalytics :: ApolloClient<InMemoryCache>, User -> TaskEither<Error, Option<User>>
export const fetchUserAnalytics = (
  client: ApolloClient<InMemoryCache>,
  { individualId }: User
): TE.TaskEither<Error, O.Option<UserAnalytics>> => {
  if (individualId === null) {
    return TE.of(O.none);
  }

  return pipe(
    getAnalyticsIndividualQuery({ individualId })(client),
    TE.map(({ getIndividual }: UserAnalytics) => getIndividual),
    TE.map(O.some)
  );
};

import { DistrictType, Maybe } from '@typings/graphql';

export const getFormattedDistricts = <
  T extends {
    districtId?: Maybe<string>;
    riDistrictId?: Maybe<string | number>;
    zone?: Maybe<string>;
  }
>(
  data: T[]
): DistrictType[] =>
  data.map(item => ({
    id: String(item.districtId),
    riDistrictId: Number(item.riDistrictId),
    isHomeDistrict: false,
    zone: String(item.zone),
  }));

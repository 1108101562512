import { STORAGE_PREFIX, FORM_STATUSES } from '@domain/storage';
import { getStorageID } from '@use-cases/storage';
import { useParams } from '@reach/router';

interface SessionStorage<T> {
  getStorageData: (id?: string) => { data: T; status?: string } | null;
  updateStorage: (
    newData: T & { status?: string },
    newSessionStorageId?: string
  ) => void;
  removeFromStorage: (id: string) => void;
  clearStorage: () => void;
}

export const useSessionStorage = <T>(): SessionStorage<T> => {
  const { sessionStorageId } = useParams() || {};

  return {
    getStorageData: (id?: string) => {
      const _id = id || sessionStorageId;
      const storageData = sessionStorage.getItem(getStorageID(_id));

      if (_id && storageData) {
        const { status = FORM_STATUSES.STARTED, ...rest } = JSON.parse(
          storageData
        );
        return {
          data: rest,
          status,
        };
      }
      return null;
    },
    updateStorage: (newData: T, newSessionStorageId?: string) =>
      sessionStorage.setItem(
        getStorageID(newSessionStorageId || sessionStorageId),
        JSON.stringify(newData)
      ),
    removeFromStorage: (id: string) => {
      const _id = id || sessionStorageId;
      sessionStorage.removeItem(getStorageID(_id));
    },
    clearStorage: () => {
      Object.keys(sessionStorage).forEach(storageKey => {
        if (storageKey.includes(STORAGE_PREFIX)) {
          sessionStorage.removeItem(storageKey);
        }
      });
    },
  };
};

import { GraphLangs, Lang } from '@domain/language-negotiation';
import * as E from 'fp-ts/lib/Either';

//preferredUserLang :: GraphLangs[] -> Lang -> Either<null, Lang>
export const getPreferredUserLangCode = (languages: GraphLangs[]) => (
  preferredLanguage?: Lang
) => {
  const prefLang = languages.find(({ id }) => id === preferredLanguage);
  return prefLang && prefLang.shortCode
    ? E.right(prefLang.shortCode)
    : E.left(null);
};

// Libs
import { union, map, flatten, keyBy } from 'lodash';
import * as LFP from 'lodash/fp';
import * as R from 'ramda';
import * as A from 'fp-ts/Array';
import { curry } from '@utils/fp-ts';
import { pipe } from 'fp-ts/lib/function';

// Types
import { MovedItems } from '../types';
import { Group, Item } from '@components/DragAndDropContainers/types';

const getItems = (data: Group) => pipe(data, R.prop('items'));

const getRelatedGroupProps = (item: Group) => ({
  relatedGroupId: item.relatedGroupId,
  relatedGroupName: item.relatedGroupName,
});

const getFormattedSourceItem = curry((item: Group, el: Item[]) => ({
  ...el,
  sourceId: item.groupId,
  sourceName: item.groupName,
  ...getRelatedGroupProps(item),
}));

const getFormattedDestinationItem = curry((item: Group, el: Item[]) => ({
  ...el,
  destinationId: item.groupId,
  destinationName: item.groupName,
  ...getRelatedGroupProps(item),
}));

export const getFormattedData = (
  initialData: Group[],
  updatedData: Group[]
): MovedItems[] => {
  const formattedInitData = pipe(
    initialData,
    A.map(item => map(getItems(item), getFormattedSourceItem(item))),
    flatten,
    union
  );

  const formattedUpdatedData = pipe(
    updatedData,
    A.map(item => map(getItems(item), getFormattedDestinationItem(item))),
    flatten,
    union
  );

  return pipe(
    formattedInitData,
    LFP.keyBy('id'),
    LFP.merge(keyBy(formattedUpdatedData, 'id')),
    LFP.values,
    A.filter(
      item =>
        item.destinationId !== item.sourceId &&
        item.destinationId !== item.relatedGroupId
    ),
    A.map(
      ({
        content: clubName,
        id: clubId,
        destinationId,
        destinationName,
        sourceId,
        sourceName,
        data: affiliationId,
        relatedGroupId,
        relatedGroupName,
        startDate,
      }) => ({
        clubName,
        destinationId,
        destinationName,
        clubId,
        sourceId: relatedGroupId || sourceId,
        sourceName: relatedGroupName || sourceName,
        affiliationId,
        affiliationStartDate: startDate,
      })
    )
  );
};

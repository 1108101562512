import { gql } from '@apollo/client';

export const fetchBackgroundQuery = gql`
  query fetchBackground($id: String!) {
    background: getIndividual(id: $id) {
      aboutMe
      sharingPermissionsExtended {
        aboutMe {
          id
        }
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const setPreferredLanguageQuery = gql`
  mutation setPreferredLanguage(
    $individualId: String!
    $preferredLanguage: String!
  ) {
    updateIndividual(
      input: {
        individualId: $individualId
        preferredLanguage: $preferredLanguage
      }
    ) {
      individualId
    }
  }
`;

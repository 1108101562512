import { QueryResult, useQuery } from '@apollo/client';

import { fetchClubOfficersQuery } from '@repositories/clubs';

import {
  GetClubOfficersQuery,
  GetClubOfficersQueryVariables,
} from '@typings/operations';

export type ClubOfficers = Pick<
  QueryResult<GetClubOfficersQuery>,
  'data' | 'loading' | 'error'
>;

export const useFetchClubOfficers = (
  variables: GetClubOfficersQueryVariables
): ClubOfficers =>
  useQuery<GetClubOfficersQuery, GetClubOfficersQueryVariables>(
    fetchClubOfficersQuery,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    }
  );

import { curry } from '@utils/fp-ts';
import { Destination, GatsbyEnv, Path, Url } from '@domain/auth';
import { pipe } from 'fp-ts/lib/function';
import * as IO from 'fp-ts/lib/IO';

// getBackendLogoutUrlCurried :: NodeJS.ProcessEnv, Destination -> Url
export const getBackendLogoutUrlCurried = curry(
  (
    { GATSBY_BACKEND_APP_BASE_URL }: GatsbyEnv,
    fIO: (a: Path) => IO.IO<Path>,
    destination: Destination = ''
  ): Url => {
    const transformUrl = (localisationToPath: Path): Url => {
      let url = `${GATSBY_BACKEND_APP_BASE_URL}${localisationToPath}`;
      if (destination !== '') {
        url = `${url}?destination=${encodeURIComponent(destination)}`;
      }
      return url;
    };
    return pipe(fIO('/user/logout'), IO.map(transformUrl))();
  }
);

import { pipe } from 'fp-ts/lib/function';
import * as IO from 'fp-ts/lib/IO';
import * as T from 'fp-ts/lib/Task';

import * as Rest from './restFetcher';

export type GatsbyUrl = string;

// toUrl :: string -> GatsbyUrl
export const toUrl = (path: string): IO.IO<GatsbyUrl> =>
  IO.of(`${process.env.GATSBY_MRX_API_PUBLIC_URL}/${path}`);

// get :: string -> IO<Task<R>>
export const get = <R>(path: string): IO.IO<T.Task<R>> =>
  pipe(
    toUrl(path),
    IO.map(url => Rest.get<R>(url))
  );

// post :: string, data -> IO<Task<R>>
export const post = <D, R>(path: string, data: D) =>
  pipe(
    toUrl(path),
    IO.map(gatstbyPath => Rest.post<D, R>(gatstbyPath, data))
  );

import { gql, useLazyQuery } from '@apollo/client';
import {
  GetAllDistrictsQuery,
  GetAllDistrictsQueryVariables,
} from '@typings/operations';

export const fetchAllDistrictsQuery = gql`
  query getAllDistricts($status: String, $page: Int, $pageSize: Int) {
    districts: getAllDistricts(
      status: $status
      page: $page
      pageSize: $pageSize
    ) {
      id
      riDistrictId
    }
  }
`;

export const useFetchAllDistricts = () =>
  useLazyQuery<GetAllDistrictsQuery, GetAllDistrictsQueryVariables>(
    fetchAllDistrictsQuery
  );

import moment from 'moment';

const formatYearOfBirth = (
  dateOfBirth?: string | null,
  yearOfBirth?: number | string | null
) => {
  if (dateOfBirth) return moment(dateOfBirth).format('YYYY');

  if (yearOfBirth) return String(yearOfBirth);

  return '';
};

export const formatDateOfBirth = (
  dateOfBirth?: string | null,
  yearOfBirth?: number | string | null
) => ({
  dayOfBirth: dateOfBirth ? moment(dateOfBirth).format('DD') : '',
  monthOfBirth: dateOfBirth ? moment(dateOfBirth).format('MM') : '',
  yearOfBirth: formatYearOfBirth(dateOfBirth, yearOfBirth),
});

export const getDateOfBirth = (
  dayOfBirth: string,
  monthOfBirth: string,
  yearOfBirth: string
) => {
  if (dayOfBirth && monthOfBirth && yearOfBirth) {
    const date = moment(`${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`);

    return {
      dateOfBirth: date.isValid() ? date.format('YYYY-MM-DD') : null,
      yearOfBirth: yearOfBirth ? parseInt(yearOfBirth) : null,
    };
  }

  return {
    dateOfBirth: null,
    yearOfBirth: yearOfBirth ? parseInt(yearOfBirth) : null,
  };
};

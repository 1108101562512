import { gql, useLazyQuery, WatchQueryFetchPolicy } from '@apollo/client';

import {
  FetchDistrictDetailsQuery,
  FetchDistrictDetailsQueryVariables,
} from '@typings/operations';

export const fetchDistrictDetailsQuery = gql`
  query fetchDistrictDetails($districtId: String!, $userId: String!) {
    districtDetails: getDistrictDetails(
      districtId: $districtId
      userId: $userId
    ) {
      id
      riDistrictId
      zone
      zoneSection
      districtLegalName
      primaryWebsite
      primaryEmail
      primaryFax {
        id
        country
        countryId
        number
        extension
      }
      primaryPhone {
        id
        country
        countryId
        number
        extension
      }
      businessAddress {
        id
        lineOne
        lineTwo
        lineThree
        country
        countryId
        city
        state
        postalCode
        internationalProvince
        stateId
      }
      websites {
        id
        primaryWebsite
        otherWebsites
      }
      emails {
        id
        email
        isPrimary
      }
    }
  }
`;

export const useFetchDistrict = (
  options: {
    fetchPolicy?: WatchQueryFetchPolicy;
  } = {}
) =>
  useLazyQuery<FetchDistrictDetailsQuery, FetchDistrictDetailsQueryVariables>(
    fetchDistrictDetailsQuery,
    options
  );

import React from 'react';

import Icon from '@components/Icon';
import Link from '@components/Link';

import { useTranslation } from '@external/react-i18next';

interface SearchMenuItemProps {
  label: string;
  path: string;
  icon: string;
  clickHandler: () => void;
}

const SearchMenuItem: React.FC<SearchMenuItemProps> = ({
  label,
  path,
  icon,
  clickHandler,
}) => {
  const handleClick = () => {
    clickHandler();
  };
  return (
    <li>
      <Link
        onClick={() => handleClick()}
        to={path}
        className="link-styles-off block py-4 pl-4 pr-10 text-gray-600 hover:bg-dark-blue-400 hover:text-white hover:font-bold focus:bg-dark-blue-400 focus:text-white focus:font-bold"
      >
        <Icon name={icon} className="inline-block mr-4" size="18" />
        {label}
      </Link>
    </li>
  );
};

interface SearchSubmenuProps {
  isExpanded: boolean;
  clickHandler: () => void;
  canUserSearchMembers?: boolean;
}

const SearchSubmenu: React.FC<SearchSubmenuProps> = ({
  isExpanded,
  clickHandler,
  canUserSearchMembers = true,
}) => {
  const { t } = useTranslation();

  return (
    <ul
      hidden={!isExpanded}
      className="absolute right-0 bg-white z-60 min-w-14 whitespace-nowrap"
      style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)' }}
    >
      <SearchMenuItem
        path="/site-search"
        label={t('header.search-form.search-site-label', 'Search the site')}
        icon="ui/pages"
        clickHandler={clickHandler}
      />

      {canUserSearchMembers && (
        <SearchMenuItem
          path="/member-search"
          label={t('header.search-form.search-member-label', 'Find a member')}
          icon="ui/people"
          clickHandler={clickHandler}
        />
      )}

      <SearchMenuItem
        path="/club-search"
        label={t('header.search-form.search-club-label', 'Find a club')}
        icon="ui/map-pin-uncolored"
        clickHandler={clickHandler}
      />
    </ul>
  );
};

export default SearchSubmenu;

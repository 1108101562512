import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAppConfig } from '@hooks/appConfig';
import { useUserAccount } from '@repositories/auth/hooks';

export type ProfilePhotoContext = {
  photoUri: string | null;
  updateProfilePhoto: (photoUri: File | false | null) => void;
};

export const ProfilePhotoContext = createContext<ProfilePhotoContext>({
  photoUri: null,
  updateProfilePhoto: () => {},
});

export const ProfilePhotoProvider: FC = ({ children }) => {
  const { user } = useAppConfig();

  const individualIdForFetch =
    user?.isLoggedIn && user?.individualId ? user.individualId : null;
  const { data } = useUserAccount(individualIdForFetch);

  const accountInfo = data?.getIndividual;
  const [photoUri, setPhotoUri] = useState<ProfilePhotoContext['photoUri']>(
    null
  );

  useEffect(() => {
    if (accountInfo) {
      setPhotoUri(accountInfo.photoUri || null);
    }
  }, [accountInfo]);

  const updateProfilePhoto = (photoUri: File | false | null) => {
    if (photoUri) {
      setPhotoUri(URL.createObjectURL(photoUri));
    } else {
      setPhotoUri(null);
    }
  };

  return (
    <ProfilePhotoContext.Provider
      value={{
        photoUri,
        updateProfilePhoto,
      }}
    >
      {children}
    </ProfilePhotoContext.Provider>
  );
};

export const useProfilePhoto = () => useContext(ProfilePhotoContext);

import { gql } from '@apollo/client';

export const updateBackgroundMutation = gql`
  mutation updateBackground(
    $id: String!
    $aboutMe: String!
    $sharingPermissionsExtended: SharingPermissionsInput!
  ) {
    updateIndividual(
      input: {
        individualId: $id
        aboutMe: $aboutMe
        sharingPermissionsExtended: $sharingPermissionsExtended
      }
    ) {
      individualId
    }
  }
`;

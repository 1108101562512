// Types
import { ManagedRYs } from '@domain/districts';

export default (
  managedRYs: ManagedRYs,
  isCurrentRYManager: boolean,
  roleName?: string
) =>
  managedRYs[isCurrentRYManager ? 'current' : 'future'].officers
    .filter(({ role }) => role === roleName)
    .map(({ startDate, endDate }) => ({ startDate, endDate }));

// Libs
import { gql, useMutation } from '@apollo/client';

// Types
import {
  AssignDistrictLeadershipMutation,
  AssignDistrictLeadershipMutationVariables,
} from '@typings/operations';

export const assignDistrictLeadershipQuery = gql`
  mutation assignDistrictLeadership(
    $districtId: String!
    $riDistrictId: String!
    $individualId: String!
    $roleId: String!
    $startDate: String!
    $email: String
  ) {
    assignDistrictLeadership(
      districtId: $districtId
      riDistrictId: $riDistrictId
      individualId: $individualId
      roleId: $roleId
      startDate: $startDate
      email: $email
    ) {
      id
      error
    }
  }
`;

export const useAssignDistrictLeadership = () =>
  useMutation<
    AssignDistrictLeadershipMutation,
    AssignDistrictLeadershipMutationVariables
  >(assignDistrictLeadershipQuery);

import { lazyQueryString } from '@repositories/utils/dataFetching/apolloFetcher';
import { Individual, LanguageExtended } from '@typings/operations';

export const lazyPreferredLangs = lazyQueryString<
  { individualId?: string | null },
  { getIndividual: Individual; languages: LanguageExtended }
>(`
  query fetchPreferredLanguage($individualId: String!) {
    getIndividual(id: $individualId) {
      preferredLanguage
    }
    languages {
      id
      shortCode
    }
  }
`);

import { GetConferencesResults } from '@typings/graphql';
import { isPastConference } from '@use-cases/districts';
import { partition } from 'lodash';

export const getSplitConferences = (conferences: GetConferencesResults[]) => {
  const [pastConferences, upcomingConferences] = partition(
    conferences,
    ({ endDate }) => isPastConference(endDate)
  );

  return { pastConferences, upcomingConferences };
};

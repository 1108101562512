import * as React from 'react';
import { useAppConfig } from '@hooks/appConfig';
import { useTranslation } from '@external/react-i18next';
import moment from 'moment';

const ImpersonationHeader: React.FC = () => {
  const { user, logout } = useAppConfig();
  const username = `${user?.attributes?.firstName} ${user?.attributes?.lastName}`;

  const { t } = useTranslation();

  const handleLogout = () => logout();

  if (!user?.impersonatorId) {
    return null;
  }

  return (
    <div className="text-small text-center text-dark-blue-600 py-2 px-2 bg-gold-600">
      <div className="font-bold mobile-l:inline">
        {t(
          'global.user.impersonation_message',
          'You are currently impersonating {{ username }}',
          {
            username,
          }
        )}
      </div>
      <>
        <div className="hidden mobile-l:inline"> | </div>
        <div className="mobile-l:inline">
          {t(
            'global.user.impersonation_session_message',
            'Session ends at {{time}}',
            {
              time: moment(
                new Date((user && user.impersonationExpiration) || '')
              ).toDate(),
            }
          )}
        </div>
      </>
      <div className="hidden mobile-l:inline"> - </div>
      <div className="mobile-l:inline">
        <button
          onClick={handleLogout}
          className="text-small font-normal text-dark-blue-600 underline"
        >
          {t('global.user.impersonation_end', 'End session now')}
        </button>
      </div>
    </div>
  );
};

export default ImpersonationHeader;

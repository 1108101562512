import { find, values } from 'lodash';
import moment from 'moment';

import { getYearLabel } from './index';

import {
  DeltasObject,
  DeltaValues,
  JUN_KEY,
  JUNE_MONTH_KEY,
  MAY_KEY,
  MembersTrendsChartData,
  MembersTrendsData,
  MonthKeys,
  YEAR_START_KEY,
} from '@domain/districts';

import { getFormattedMonth, getRotaryYear } from '@utils/datetime';

import { MembersTrends } from '@typings/operations';

type MonthlyData = Record<MonthKeys, MembersTrendsChartData>;

// Go throught yearly MembersTrends data
// For each MembersTrends object return a membersCount value with a year key => [2020-2021]: number
// Group received value by month
//     month: 'Rotary Year Start',
//     ['2020-2021']: 5100,
//     ['2019-2020']: 2400,
//     ...
//     deltas: {
//       ['2020-2021']: {
//         deltaStartYear: 0,
//         deltaPrevMonth: 0,
//         deltaPrevTerm: 0,
//       },
//       [2020-2019] {...}
//     }
export const sortYearDataByMonth = (data: MembersTrends[]) => {
  const monthlyDataAcc: Omit<MonthlyData, 'June'> = {
    [YEAR_START_KEY]: {},
    July: {},
    August: {},
    September: {},
    October: {},
    November: {},
    December: {},
    January: {},
    February: {},
    March: {},
    April: {},
    May: {},
  };
  /* eslint-disable no-param-reassign */
  return data.reduce(
    (acc: { [key: string]: MembersTrendsChartData }, yearData) => {
      const { year, monthlyData, july1st } = yearData;
      const yearKey = getYearLabel(year);

      const prevYearData = find(data, ['year', year - 1]);

      acc[YEAR_START_KEY][yearKey] = july1st || null;
      acc[YEAR_START_KEY].month = YEAR_START_KEY;
      acc[YEAR_START_KEY].prevMonth = MAY_KEY;

      acc[YEAR_START_KEY].deltas = {
        ...(acc[YEAR_START_KEY].deltas as DeltasObject),
        ...getDeltas(yearKey, YEAR_START_KEY, july1st, 0, 0, prevYearData),
      };

      monthlyData.forEach((element, index) => {
        const { month: monthKey, count } = element;

        const isCurrentYear = Number(getRotaryYear()) === year;
        const isJune = monthKey === JUNE_MONTH_KEY;

        // add property with a key `June` to `acc` object only if it is June now and current RY,
        // otherwise, there will be a gap at the end of the graph, even if June has not come yet
        if (moment().format('MMM') === JUN_KEY && isCurrentYear) {
          acc[JUNE_MONTH_KEY] = {};
        }

        const currentMonthData = acc[monthKey as MonthKeys];

        if (currentMonthData) {
          currentMonthData[yearKey] =
            // condition `isJune && !isCurrentYear` is needed in order
            // to show June dot only for current RY line
            count === 0 || (isJune && !isCurrentYear) ? null : count;
          currentMonthData.month = getFormattedMonth(monthKey);

          const prevMonth = monthlyData[index - 1];

          currentMonthData.prevMonth = getFormattedMonth(prevMonth?.month);
          currentMonthData.deltas = {
            ...(currentMonthData.deltas as DeltasObject),
            ...getDeltas(
              yearKey,
              monthKey,
              count || 0,
              prevMonth?.count || 0,
              july1st,
              prevYearData
            ),
          };
        }
      });

      return acc;
    },
    { ...monthlyDataAcc }
  );
  /* eslint-enable no-param-reassign */
};

// Returns an object with delta values
// Compares monthly value with value from previous RY start
// Compares monthly value with value from previous month
// Compares monthly  value with value from previous year for current month
export const getDeltas = (
  yearKey: string,
  monthKey: string,
  currentValue: number,
  prevMonthValue: number,
  july1stValue: number,
  prevYearData?: MembersTrends
): DeltasObject => {
  const deltasResult: DeltaValues = {
    deltaStartYear: null,
    deltaPrevMonth: null,
    deltaPrevYear: null,
    prevYear: null,
  };

  const current = currentValue || 0;

  const getPrevYearMonth = (month: string) =>
    find(prevYearData?.monthlyData, ['month', month]);

  if (monthKey === YEAR_START_KEY) {
    // For YEAR_START_KEY prev month is a May of the prev year
    deltasResult.deltaPrevMonth =
      current - (getPrevYearMonth(MAY_KEY)?.count || 0);
    deltasResult.deltaPrevYear = prevYearData
      ? current - prevYearData.july1st
      : null;
  } else {
    const prevYearMonth = getPrevYearMonth(monthKey);

    deltasResult.deltaStartYear = july1stValue ? current - july1stValue : null;
    // For July compare value only with july1stValue
    deltasResult.deltaPrevMonth =
      monthKey !== 'July' ? current - prevMonthValue : null;
    deltasResult.deltaPrevYear = prevYearMonth
      ? current - (prevYearMonth.count || 0)
      : null;
  }

  return {
    [yearKey]: { ...deltasResult, prevYear: prevYearData?.year || null },
  };
};

// Returns delta members count for current day
// Compares totalCount with value of the Rotary Start Year
export const getTotalDeltaMembers = (
  data: MembersTrends[]
): { totalCount: number; delta: number } => {
  // Current Year is always the 1st element
  const currentYear = data?.[0] || null;

  if (!currentYear) {
    return {
      totalCount: 0,
      delta: 0,
    };
  }

  const { july1st, totalCount } = currentYear;

  return {
    totalCount,
    delta: totalCount - (july1st || 0),
  };
};

export const getMembersTrendsChartData = (
  data: MembersTrends[]
): MembersTrendsData => {
  const { totalCount, delta } = getTotalDeltaMembers(data);

  return {
    totalCount,
    delta,
    chartData: values(sortYearDataByMonth(data)),
  };
};

import { TFunction } from 'i18next';

import { ClubType, ClubTypeFilterValue, Maybe } from '@typings/graphql';

export const getClubTypeName = (
  t: TFunction,
  type: Maybe<string> | undefined
): string => {
  switch (type) {
    case ClubType.RotaryClub:
      return t('club-type.rotary', 'Rotary Club');
    case ClubType.RotaractClub:
      return t('club-type.rotaract', 'Rotaract Club');
    case ClubType.SatelliteClub:
      return t('club-type.satellite', 'Rotary Satellite Club');
    case ClubType.RotaractSatelliteClub:
      return t('club-type.rotaract-satellite', 'Rotaract Satellite Club');
    case ClubTypeFilterValue.RotaryClub:
      return t('club-type.rotary', 'Rotary Club');
    case ClubTypeFilterValue.RotaractClub:
      return t('club-type.rotaract', 'Rotaract Club');
    case ClubTypeFilterValue.SatelliteClub:
      return t('club-type.satellite', 'Rotary Satellite Club');
    case ClubTypeFilterValue.RotaractSatelliteClub:
      return t('club-type.rotaract-satellite', 'Rotaract Satellite Club');
    default:
      return '';
  }
};

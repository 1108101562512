import { gql } from '@apollo/client';

export const terminateMembershipMutation = gql`
  mutation TerminateMembership(
    $clubId: String!
    $membershipId: String!
    $terminationReasonId: String
    $terminationDate: String!
  ) {
    terminateMembership(
      clubId: $clubId
      membershipId: $membershipId
      terminationReasonId: $terminationReasonId
      terminationDate: $terminationDate
    )
  }
`;

import { gql } from '@apollo/client';

export const searchClubOfficersQuery = gql`
  fragment SearchOfficersResult on MemberSearchResult {
    id
    name
    photoUri
    email
    otherEmails
    onlineId
    phoneNumber
    leadershipsFromThisClub: activeLeaderships(clubId: $clubId) {
      role
      endDate
      startDate
    }
    membershipsFromThisClub: activeMemberships(clubId: $clubId) {
      admissionDate
      terminationDate
      type
    }
  }
  query SearchOfficers(
    $name: String
    $email: String
    $clubId: String!
    $page: Int = 1
    $pageSize: Int
    $membershipStatus: MembershipStatusFilter
  ) {
    clubById(clubId: $clubId) {
      members(
        name: $name
        email: $email
        page: $page
        pageSize: $pageSize
        membershipStatus: $membershipStatus
      ) {
        totalCount
        results {
          ...SearchOfficersResult
        }
      }
    }
  }
`;

export const searchDelegationOfficersQuery = gql`
  fragment SearchDelegationOfficersResult on MemberSearchResult {
    id
    name
    photoUri
    email
    otherEmails
    onlineId
    phoneNumber
    riIndividualId
    leadershipsFromThisClub: activeLeaderships(riClubId: $riClubId) {
      role
      endDate
      startDate
    }
    membershipsFromThisClub: activeMemberships(riClubId: $riClubId) {
      admissionDate
      terminationDate
      type
    }
    preferredLanguage {
      id
      name
    }
  }
  query SearchDelegationOfficers(
    $name: String
    $email: String
    $clubId: String!
    $page: Int = 1
    $pageSize: Int
    $membershipStatus: MembershipStatusFilter
    $riClubId: String
  ) {
    clubById(clubId: $clubId) {
      members(
        name: $name
        email: $email
        page: $page
        pageSize: $pageSize
        membershipStatus: $membershipStatus
        riClubId: $riClubId
      ) {
        totalCount
        results {
          ...SearchDelegationOfficersResult
        }
      }
    }
  }
`;

import React, { useState } from 'react';

import { OneColumn } from '@components/Layouts/OneColumn';
import Link from '@components/Link';

import MainSubMenu from './MainSubMenu';

import { Drupal } from '@typings/drupal';

interface Props {
  menuItems: Drupal.MenuResponse;
}
const MainMenu: React.FC<Props> = ({ menuItems }) => {
  const [activeItem, setActiveItem] = useState<null | number>(null);
  const [showNavMenu, setShowNavMenu] = useState(true);
  const subMenuItems: null | Drupal.MenuResponse =
    activeItem !== null && menuItems[activeItem]?.children?.length
      ? menuItems[activeItem].children || []
      : null;

  const menuContainer = React.createRef<HTMLDivElement>();

  const handleShowMenu = () => setShowNavMenu(true);

  const handleCloseMenu = () => {
    if (showNavMenu) {
      setShowNavMenu(false);
    }
  };

  return (
    <div
      className="menuContainer relative max-w-full"
      ref={menuContainer}
      onMouseLeave={() => {
        setActiveItem(null);
      }}
      onMouseOver={handleShowMenu}
      onFocus={handleShowMenu}
    >
      <OneColumn className="pt-6">
        <ul className="flex">
          {menuItems.map(({ path, label, id }, i) => (
            <li key={id}>
              <Link
                key={id}
                to={path}
                className={`uppercase text-white inline-block border-b-4 border-dark-blue-500 pb-4 mr-5 LinkDivider LinkDividerLastChild font-bold text-xs leading-xs ${activeItem ===
                  i && 'text-gold-500 border-gold-500'}`}
                onClick={handleCloseMenu}
                noLinkStyles
                onMouseEnter={() => i !== activeItem && setActiveItem(i)}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>

        {subMenuItems !== null && (
          <MainSubMenu
            container={menuContainer}
            menuItems={subMenuItems}
            showNavMenu={showNavMenu}
            handleCloseMenu={handleCloseMenu}
          />
        )}
      </OneColumn>
    </div>
  );
};

export default MainMenu;

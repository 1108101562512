// Libs
import partition from 'lodash/partition';

// Types
import {
  DORoles,
  UnassignedDistrictOfficerRoleTerm as Unassigned,
} from '@domain/districts';

export const sortRolesByRequired = (
  requiredRoles: string[],
  roles: DORoles
): DORoles => {
  roles.sort((prv, nxt) => prv.role.localeCompare(nxt.role));

  return partition(roles, ({ role }) => requiredRoles.includes(role))
    .map(part =>
      partition(
        part,
        ({ terms }) => !terms.find(term => (term as Unassigned).isAssignable)
      )
    )
    .flat(Infinity) as DORoles;
};

import { pipe } from 'fp-ts/lib/function';
import { curry, range, toNumber } from 'lodash';
import moment, { Moment } from 'moment';
import timezone from 'moment-timezone';

export const getCurrentDate = (dateFormat: string = 'YYYY-MM-DD'): string =>
  moment().format(dateFormat);

export const printYear = (date: string): string =>
  new Date(date).getFullYear().toString();

export const getAge = (yearOfBirth: number): number => {
  // Current DIS logic is: the age is the current year minus the year of
  // birth. So DIS assumes that a person already had a birthday in this year.
  // https://teams.microsoft.com/l/message/19:cf5301bc0c9c4302be1f36d46f11051b@thread.skype/1588837115219?tenantId=67b4e043-0afd-4afb-8b94-bf96370c8e7f&groupId=1ca46938-b411-4741-b831-8f075947278f&parentMessageId=1588837115219&teamName=My%20Rotary%20Refresh%20UX%20Platform%20%26%20Core%20Functiomality&channelName=DIS%203.0-My%20Rotary%20Forum%20(APIs)&createdTime=1588837115219
  return new Date().getFullYear() - yearOfBirth;
};

// Returns the today's date: https://stackoverflow.com/a/4929629
export const getToday = (): string => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = today.getFullYear();

  return `${year}-${month}-${day}`;
};

export const getUTCTime = () => {
  return new Date().toISOString();
};

export const isActiveDateRange = (startDate: string, endDate: string) => {
  const today = new Date();

  return (
    moment(startDate).isSameOrBefore(today, 'd') &&
    moment(endDate).isSameOrAfter(today, 'd')
  );
};

export const getRotaryYear = (date?: string | null) => {
  const defaultDate = date || undefined;
  // Rotary year start from 30 June and ends 1 July
  // If date is less < 07-01 then we will use current year
  // otherwise current year + 1
  return (moment(defaultDate).format('MM-DD') < '07-01'
    ? moment(defaultDate).year()
    : moment(defaultDate).year() + 1
  ).toString();
};

export const getRotaryYearRange = (customRotaryYear?: number) => {
  const rotaryYear = customRotaryYear || toNumber(getRotaryYear());
  // SEE getRotaryYear() for details
  return {
    startYear: `${rotaryYear - 1}-06-30T00:00:00.000Z`,
    endYear: `${rotaryYear}-07-01T00:00:00.000Z`,
  };
};

export const getYear = (date: string, format: string = 'YYYY-MM-DD') =>
  moment(date, format).year();

export const getFormattedDate = (
  date: Date | string | Moment,
  format = 'YYYY-MM-DD'
) => moment(date).format(format);

export const getStartRYYear = (date: string = 'YYYY-MM-DD') => {
  const defaultDate = date || undefined;
  if (
    moment(defaultDate).format('MM-DD') >= '07-01' &&
    moment(defaultDate).format('MM-DD') <= '12-31'
  ) {
    return moment(defaultDate).year();
  }
  return moment(defaultDate).year() - 1;
};

export const getEndRYYear = (date: string = 'YYYY-MM-DD') => {
  const defaultDate = date || undefined;
  if (
    moment(defaultDate).format('MM-DD') >= '07-01' &&
    moment(defaultDate).format('MM-DD') <= '12-31'
  ) {
    return moment(defaultDate).year() + 1;
  }
  return moment(defaultDate).year();
};

export const isSameOrAfter = curry(
  (date: Moment | string, dateToCompareWithm: Moment | string) =>
    moment(dateToCompareWithm).isSameOrAfter(date)
);

export const isSameOrBefore = curry(
  (date: Moment | string, dateToCompareWithm: Moment | string) =>
    moment(dateToCompareWithm).isSameOrBefore(date)
);

export const isAfter = curry(
  (date: Moment | string, dateToCompareWith: Moment | string) =>
    moment(dateToCompareWith).isAfter(date)
);

export const isBefore = curry(
  (date: Moment | string, dateToCompareWith: Moment | string) =>
    moment(dateToCompareWith).isBefore(date)
);

export const getFormattedMonth = (monthKey: string, format: string = 'MMM') =>
  monthKey
    ? moment()
        .month(monthKey)
        .format(format)
    : null;

export const getDaysInMonthByYear = (year: string | number, month: string) =>
  moment(`${year}-${month}`, 'YYYY-MMM').daysInMonth();

export const isValidDate = (date: Moment) => date.isValid;

export const getYearsRange = (currentYear: number, yearsCount: number) =>
  range(currentYear, currentYear - yearsCount);

export const getFutureYearsRange = (currentYear: number, yearsCount: number) =>
  range(currentYear + 1, currentYear + yearsCount);

export const getFormattedRYRange = (rotaryYear: number) => ({
  startYear: getFormattedDate(`${rotaryYear - 1}-07-01`, 'YYYY-MM-DD'),
  endYear: getFormattedDate(`${rotaryYear}-06-30`, 'YYYY-MM-DD'),
});

export const isCurrentRY = (date: string) =>
  pipe(
    getRotaryYear(),
    toNumber,
    getFormattedRYRange,
    ({ startYear, endYear }) =>
      isSameOrAfter(startYear, date) && isSameOrBefore(endYear, date)
  );

export const isToday = (date?: string) =>
  Boolean(date && moment().isSame(moment(date), 'D'));

export const getTimeZoneAbbr = () => {
  const timeZone = timezone.tz.guess();

  return {
    defaultTimeZoneAbbr: timezone.tz(timeZone).zoneAbbr(),
    defaultTimeZoneOffset: timezone.tz(timeZone).utcOffset() / 60,
  };
};

export const isFutureRY = (rotaryYear: number) =>
  Number(getRotaryYear()) < rotaryYear;

export const checkRecordWithinRYRange = (
  delegationStartDate: Moment,
  delegationEndDate: Moment,
  range: number
) => {
  const { startYear, endYear } = getRotaryYearRange(Number(getRotaryYear()));
  const RYRangestartDate = moment(startYear).year(
    Number(getRotaryYear()) - range
  );
  // isAfter(RYRangestartDate, delegationStartDate) is checking start date should be after range start date
  // isBefore(endYear, delegationEndDate) is checking End date should be before the end of the current RY
  // isBefore(moment(getToday()), delegationEndDate) is checking the end Date should be past
  return (
    isAfter(RYRangestartDate, delegationStartDate) &&
    isBefore(endYear, delegationEndDate) &&
    isBefore(moment(getToday()), delegationEndDate)
  );
};

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import {
  GeocoderResult,
  getInnerRadiusKmFromBounds,
} from '@components/Formik/LocationSelector/ClubLocationSelect';

import { ClubSearchFormValues } from '@domain/clubs';

export const withLocationKeywordFallback = async (
  formValues: ClubSearchFormValues
): Promise<ClubSearchFormValues> => {
  const values = { ...formValues };
  // We have a location search but no coordinates set
  if (
    values.meetingLocation &&
    !(values.meetingLocationLatitude && values.meetingLocationLongitude)
  ) {
    // let's try to geocode
    const geocodeResult: GeocoderResult[] = await geocodeByAddress(
      values.meetingLocation
    );

    if (geocodeResult.length) {
      const placeSuggestion = geocodeResult[0];
      const placeLatLng = await getLatLng(placeSuggestion);
      // Override lat/long with suggestion
      values.meetingLocation = placeSuggestion.formatted_address;
      values.meetingLocationLatitude = placeLatLng.lat;
      values.meetingLocationLongitude = placeLatLng.lng;
      values.meetingLocationRange = getInnerRadiusKmFromBounds(
        placeSuggestion.geometry.bounds
      );
    }
  }

  return !values.meetingLocation ||
    (values.meetingLocationLatitude && values.meetingLocationLongitude)
    ? values
    : {
        ...values,
        clubName: values.clubName
          ? `${values.clubName} ${values.meetingLocation}`
          : values.meetingLocation,
        meetingLocationLatitude: 0,
        meetingLocationLongitude: 0,
      };
};

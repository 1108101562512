import { TFunction } from 'i18next';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import {
  AGManagerInfo,
  CommitteeMemberData,
  DistrictAG,
  MemberURLParams,
  OperationType,
  TermToAssign,
} from '@domain/districts';

import {
  addQueryParamsToURL,
  buildAGsManagerUrl,
  canBeReplacedRemovedRole,
  isCurrentOrFutureTerm,
  isDateBetween,
  isPriorTerm,
} from '@use-cases/districts';

import { getRotaryYear, isToday } from '@utils/datetime';
import { flattenObject } from '@utils/objectUtils';

import { Maybe } from '@typings/graphql';

export default (
  t: TFunction,
  handleClickMenu: (
    urlToNavigate: string,
    operationType: OperationType
  ) => void,
  options: {
    districtId: string;
    riDistrictId?: Maybe<number>;
    agItem: DistrictAG;
    managerInfo: AGManagerInfo;
    isActiveMember: boolean;
    getAGMember?: CommitteeMemberData | null;
    term?: TermToAssign | null;
  }
) => {
  const {
    districtId,
    riDistrictId,
    managerInfo: {
      endTermDate,
      isCurrentYearManager,
      isManagerAfterNow,
      isManagerNow,
    },
    agItem: { termInfo, isDeceased, districtLeaderships },
    isActiveMember,
    getAGMember,
    term,
  } = options;

  const isDisabledReplace = isToday(termInfo?.startDate);

  const AGsManagerURL = `${buildAGsManagerUrl(districtId)}/${uuid()}`;

  const areReplaceRemoveAvailable =
    isCurrentOrFutureTerm(endTermDate) &&
    isCurrentYearManager &&
    canBeReplacedRemovedRole(termInfo?.endDate || '');

  const isAgAssignedInPriorYear = (rotaryYear: number, isCurrentTerm = false) =>
    districtLeaderships?.some(
      ({ endDate, riDistrictId: leadershipRiDistrictId }) =>
        // check for the current district
        leadershipRiDistrictId === String(riDistrictId) &&
        // check if role assigned to the term
        isDateBetween(
          endDate,
          { start: `${rotaryYear - 1}-07-01`, end: `${rotaryYear}-06-30` },
          null
        ) &&
        // if current term, we need to check if role is not active anymore
        (!isCurrentTerm || moment().isSameOrBefore(endDate, 'd'))
    );

  const RY = Number(getRotaryYear());

  const isAssignToAgCurrentYear = isAgAssignedInPriorYear(RY, true);
  const isAssignToAgFutureYear = isAgAssignedInPriorYear(RY + 1);

  const currentYearReassignAvailable =
    isManagerNow && isPriorTerm(endTermDate, { past: -5, future: 0 });

  const futureYearReassignAvailable =
    isManagerAfterNow && isPriorTerm(endTermDate, { past: -5, future: 1 });

  const isReassignAvailable =
    !isDeceased &&
    (currentYearReassignAvailable || futureYearReassignAvailable);

  const isReassignDisabled = () => {
    if (!isActiveMember) {
      return true;
    }
    if (isManagerNow && isManagerAfterNow) {
      return isAssignToAgCurrentYear && isAssignToAgFutureYear;
    }
    if (isManagerNow) {
      return isAssignToAgCurrentYear;
    }
    return isManagerAfterNow && isAssignToAgFutureYear;
  };

  return [
    ...(areReplaceRemoveAvailable
      ? [
          {
            id: 1,
            label: t(
              'context-menu.assistant-governor.replace-with-someone-else',
              'Replace with someone Else'
            ),
            isDisabled: isDisabledReplace,
            path: addQueryParamsToURL(AGsManagerURL, {
              ...flattenObject(getAGMember as MemberURLParams),
              ...flattenObject({ term }),
              operationType: OperationType.REPLACE_AG,
            }),
            handleClick: () =>
              handleClickMenu(AGsManagerURL, OperationType.REPLACE_AG),
          },
          {
            id: 2,
            label: t(
              'context-menu.assistant-governor.remove-from-role',
              'Remove from Officer Role'
            ),
            path: addQueryParamsToURL(AGsManagerURL, {
              ...flattenObject(getAGMember as MemberURLParams),
              ...flattenObject({ term }),
              operationType: OperationType.UNASSIGN_OFFICER_FROM_ROLE,
            }),
            handleClick: () =>
              handleClickMenu(
                AGsManagerURL,
                OperationType.UNASSIGN_OFFICER_FROM_ROLE
              ),
          },
        ]
      : []),
    ...(isReassignAvailable
      ? [
          {
            id: 3,
            label: t('context-menu.assistant-governor.reassign', 'Reassign'),
            path: addQueryParamsToURL(AGsManagerURL, {
              ...flattenObject(getAGMember as MemberURLParams),
              ...flattenObject({ term }),
              step: 2,
              operationType: OperationType.REASSIGN_TO_AG,
            }),
            handleClick: () =>
              handleClickMenu(AGsManagerURL, OperationType.REASSIGN_TO_AG),
            isDisabled: isReassignDisabled(),
          },
        ]
      : []),
  ];
};

import { getPreferredUserLangCode } from '@repositories/language-negotiation/utils';
import { Individual } from '@typings/operations';
import { GraphLangs, Lang } from '@domain/language-negotiation';
import { defaultLanguage } from '../../../../languages';
import { pipe } from 'fp-ts/function';
import * as E from 'fp-ts/lib/Either';

// extractUserPreferredLang :: FetchPreferredLanguageQuery -> Lang
export const extractUserPreferredLang = ({
  languages,
  getIndividual,
}: {
  getIndividual: Individual;
  languages: GraphLangs;
}): Lang => {
  const { preferredLanguage } = getIndividual || {};
  return pipe(
    getPreferredUserLangCode(languages)(preferredLanguage),
    E.getOrElse(() => defaultLanguage)
  );
};

import { TFunction } from 'i18next';
import { v4 as uuid } from 'uuid';

import {
  CommitteeMemberData,
  DISTRICT_OFFICER_GOVERNOR_ROLES,
  DORole,
  isDES,
  isDRFC,
  ManagedRY,
  ManagedRYs,
  MemberURLParams,
  OperationType,
  TermToAssign,
} from '@domain/districts';

import {
  addQueryParamsToURL,
  areSomeAssigmentAfter,
  buildCommitteeAppointmentsUrl,
  canBeReplacedRemovedRole,
  isCurrentOrFutureTerm,
  isPriorTerm,
  isRoleAvailableInManagedRY,
} from '@use-cases/districts';

import { getRotaryYear, isToday } from '@utils/datetime';
import { flattenObject } from '@utils/objectUtils';

import { MembershipType } from '@typings/graphql';
import { OfficerMembership } from '@typings/resolvers';

export type ContextMenuProps = {
  districtId: string;
  isManagerSelected: boolean;
  isManagerNow: boolean;
  isManagerAfterNow: boolean;
  endTermDate: string;
  role: DORole;
  roleEndDate: string;
  roleStartDate: string;
  managedRYs: ManagedRYs;
  membershipType: OfficerMembership['type'];
  membershipDistrictId: OfficerMembership['districtId'];
  getOfficerMember?: CommitteeMemberData | null;
  term: TermToAssign;
  terms: TermToAssign[];
  isAssignedInFutureRY?: boolean;
};

export default (
  t: TFunction,
  handleClickMenu: (
    urlToNavigate: string,
    operationType: OperationType
  ) => void,
  {
    districtId,
    isManagerSelected,
    isManagerNow,
    isManagerAfterNow,
    endTermDate,
    role,
    roleEndDate,
    roleStartDate,
    managedRYs: { current, future },
    membershipType,
    membershipDistrictId,
    getOfficerMember,
    term,
    terms,
    isAssignedInFutureRY,
  }: ContextMenuProps
) => {
  const committeeAppointmentsURL = `${buildCommitteeAppointmentsUrl(
    districtId
  )}/${uuid()}`;

  const isOptionAvailable = (...extraChecks: boolean[]): boolean =>
    [
      !DISTRICT_OFFICER_GOVERNOR_ROLES.includes(role.role),
      ...extraChecks,
    ].every(Boolean);

  const areReplaceRemoveAvailable = isOptionAvailable(
    isCurrentOrFutureTerm(endTermDate),
    isManagerSelected,
    canBeReplacedRemovedRole(roleEndDate),
    !isDRFC(role.role)
  );

  const isReassignAvailable = isOptionAvailable(
    role.termYears === 1,
    (isManagerAfterNow && isPriorTerm(endTermDate, { past: -5, future: 1 })) ||
      (isManagerNow && isPriorTerm(endTermDate, { past: -5, future: 0 })),
    Boolean(membershipType) || isDES(role.role)
  );

  const isReplaceDisabled =
    (isManagerNow && isToday(roleStartDate)) ||
    areSomeAssigmentAfter(current.officers, {
      roleId: role.id,
      endDate: roleEndDate,
    }) ||
    isAssignedInFutureRY;

  const isReassignEnabled = () => {
    const isSelectedEqualCurrentRY = endTermDate === getRotaryYear();

    const hasActiveMembershipInCurrentDistrict =
      membershipDistrictId === districtId &&
      [MembershipType.Member, MembershipType.Rotaractor].includes(
        membershipType as MembershipType
      );

    const isRoleAvailable = ({ roles, officers }: ManagedRY): boolean =>
      isRoleAvailableInManagedRY(role.role, roles, officers) &&
      (hasActiveMembershipInCurrentDistrict || isDES(role.role));

    switch (
      String([isSelectedEqualCurrentRY, isManagerNow, isManagerAfterNow])
    ) {
      case 'false,true,false':
        return isRoleAvailable(current);
      case 'false,true,true':
        return isRoleAvailable(current) || isRoleAvailable(future);
      case 'false,false,true':
      case 'true,false,true':
      case 'true,true,true':
        return isRoleAvailable(future);
      default:
        return false;
    }
  };

  return [
    ...(areReplaceRemoveAvailable
      ? [
          {
            id: 1,
            label: t(
              'roster-result.context-menu.replace-with-someone-else',
              'Replace with someone Else'
            ),
            path: addQueryParamsToURL(committeeAppointmentsURL, {
              ...flattenObject(getOfficerMember as MemberURLParams),
              isDOPage: true,
              operationType: OperationType.REPLACE_OFFICER,
            }),
            isDisabled: isReplaceDisabled,
            handleClick: () =>
              handleClickMenu(
                committeeAppointmentsURL,
                OperationType.REPLACE_OFFICER
              ),
          },
          {
            id: 2,
            label: t(
              'roster-result.context-menu.remove-from-officer-role',
              'Remove from Officer Role'
            ),
            path: addQueryParamsToURL(committeeAppointmentsURL, {
              ...flattenObject(getOfficerMember as MemberURLParams),
              ...flattenObject(role),
              ...flattenObject({ term }),
              isDOPage: true,
              operationType: OperationType.UNASSIGN_OFFICER_FROM_ROLE,
            }),
            handleClick: () =>
              handleClickMenu(
                committeeAppointmentsURL,
                OperationType.UNASSIGN_OFFICER_FROM_ROLE
              ),
          },
        ]
      : []),
    ...(isReassignAvailable
      ? [
          {
            id: 3,
            label: t(
              'committee-appointments.context-menu.reassign',
              'Reassign'
            ),
            path: addQueryParamsToURL(committeeAppointmentsURL, {
              ...flattenObject(getOfficerMember as MemberURLParams),
              ...flattenObject(role),
              ...flattenObject({ term }),
              ...flattenObject({ terms }),
              step: 2,
              isDOPage: true,
              operationType: OperationType.REASSIGN_TO_ROLE,
            }),
            handleClick: () =>
              handleClickMenu(
                committeeAppointmentsURL,
                OperationType.REASSIGN_TO_ROLE
              ),
            isDisabled: !isReassignEnabled(),
          },
        ]
      : []),
  ];
};

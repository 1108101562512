import { useQuery, QueryResult } from '@apollo/client';
import {
  FetchContactInformationQuery,
  FetchContactInformationQueryVariables,
} from '@typings/operations';
import { useFetchContactInformationQuery } from '../queries';

type FetchContactInformationResult = Pick<
  QueryResult<FetchContactInformationQuery>,
  'data' | 'error' | 'loading' | 'refetch'
>;

export const useFetchContactInformation = (
  individualId: string
): FetchContactInformationResult => {
  return useQuery<
    FetchContactInformationQuery,
    FetchContactInformationQueryVariables
  >(useFetchContactInformationQuery, {
    variables: { id: individualId },
  });
};

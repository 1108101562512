import { gql, useLazyQuery } from '@apollo/client';

import {
  GetDistrictDashboardsQuery,
  GetDistrictDashboardsQueryVariables,
} from '@typings/operations';

export const fetchDistrictDashboardsQuery = gql`
  query getDistrictDashboards(
    $districtId: String!
    $years: [Int!]!
    $clubType: DashboardClubType
  ) {
    dashboards: getDistrictDashboards(
      districtId: $districtId
      years: $years
      clubType: $clubType
    ) {
      asOfDate
      membersTrends {
        year
        totalCount
        july1st
        monthlyData {
          month
          count
          fiscalMonth
        }
      }
      clubDevelopment {
        year
        totalCount
        july1st
        newClubs
        terminatedClubs
        newMembers
        terminatedMembers
      }
      demographics {
        womenCount
        otherGendersCount
        under40
        over40
        unreported
        period
      }
      contribution {
        annualFund {
          rotaryYear
          asOfDate
          totalFor30June
          priorMonthEnd
          prevYearDeltas {
            totalFor30June
            priorMonthEnd
            prevYearAsOfDate
          }
        }
        polioPlus {
          rotaryYear
          asOfDate
          totalFor30June
          priorMonthEnd
          prevYearDeltas {
            totalFor30June
            priorMonthEnd
            prevYearAsOfDate
          }
        }
        clubCredits {
          rotaryYear
          asOfDate
          annualFund
          polioPlus
          prevYearDeltas {
            polioPlus
            annualFund
            prevYearAsOfDate
          }
        }
      }
    }
  }
`;

export const useFetchDistrictDashboards = () =>
  useLazyQuery<GetDistrictDashboardsQuery, GetDistrictDashboardsQueryVariables>(
    fetchDistrictDashboardsQuery
  );

import { ActiveMembership, Club } from '@typings/operations';

export const getActiveAssociatedClubs = (
  activeMemberships: ActiveMembership[],
  associatedClubsInfo: Club[]
) => {
  return activeMemberships.length
    ? associatedClubsInfo.filter(({ clubId }) =>
        activeMemberships.some(
          activeMembership => activeMembership.clubId === clubId
        )
      )
    : [];
};

import { pipe } from 'fp-ts/lib/function';
import * as A from 'fp-ts/lib/Array';
import { ClubAffiliation } from '@typings/operations';

export const doesUserHaveMembership = (
  clubsAffiliation: ClubAffiliation[]
): boolean =>
  pipe(
    clubsAffiliation,
    A.map(item => item.affiliations),
    A.flatten,
    A.filter(item => 'membershipType' in item),
    A.isNonEmpty
  );

// Libs
import * as R from 'ramda';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/lib/function';

// Types
import { GetAllDistrictAGsByDateQuery } from '@typings/operations';
import { Group } from '@components/DragAndDropContainers/types';

// Utils
import { byContent } from '../helpers';
import { constructFullName } from '@utils/constructFullName';
import { isRotaryClub } from '@utils/isRotaryClub';

export const normalizeAssignedAG = (
  agsResultData?: GetAllDistrictAGsByDateQuery,
  relatedGroupName?: string
): Group[] =>
  pipe(
    agsResultData,
    O.fromNullable,
    O.mapNullable(R.prop('result')),
    O.mapNullable(R.prop('ags')),
    O.toNullable,
    item => item || [],
    A.map(agByDate => {
      const {
        agId = '',
        prefix,
        firstName,
        middleName,
        lastName,
        suffix,
        clubsAffiliations,
      } = agByDate || {};

      const items = pipe(
        clubsAffiliations,
        item => item || [],

        // MRD-2177: remove this line after implementation
        // Satellite Club and Rotaract Club logic by DIS
        A.filter(club => isRotaryClub(club?.clubType)),

        A.map(club => ({
          id: club?.clubId || '',
          content: club?.clubName || '',
          data: club?.id || '',
          startDate: club?.startDate,
        })),
        A.sortBy([byContent])
      );

      return {
        relatedGroupId: `groupId-${relatedGroupName || 'default'}`,
        relatedGroupName,
        groupId: relatedGroupName ? `${agId}-${relatedGroupName}` : agId,
        groupName: constructFullName({
          prefix,
          firstName,
          middleName,
          lastName,
          suffix,
        }),
        items,
      };
    })
  );

import { gql } from '@apollo/client';

export const fetchIndividualForAssignClubRoleQuery = gql`
  query getIndividualForAssignClubRole(
    $clubId: String!
    $individualId: String!
  ) {
    club: clubById(clubId: $clubId) {
      id: clubId
      name: clubName
      clubType
      riClubId
    }
    individual: getIndividual(id: $individualId) {
      id
      name
      email
      photoUri
      onlineId
      clubAffiliations(filters: { clubId: $clubId }) {
        affiliations {
          ... on MembershipAffiliation {
            membershipType
            admissionDate
            terminationDate
          }
        }
      }
    }
  }
`;

import { TFunction } from 'i18next';

import { localizedNavigate } from './localized-navigate';

import { ErrorCode } from '@typings/ErrorCode';
import { FrontendError } from '@typings/errors';
import { UnreachableCaseError } from '@typings/util';

export const getClientErrorNotification = (
  error: FrontendError,
  setErrorNotification: (code: ErrorCode, message: string) => void,
  t: TFunction
) => {
  switch (error.extensions.code) {
    case ErrorCode.ExternalService:
      setErrorNotification(
        ErrorCode.ExternalService,
        t(
          'global.error.exernal-service',
          'An external service has reported an error. Please try again.'
        )
      );
      localizedNavigate('/error');
      break;
    case ErrorCode.Validation:
      setErrorNotification(
        ErrorCode.Validation,
        ([] as Array<string>)
          .concat(error.extensions.validationErrorMessages || [])
          .join('\n')
      );
      break;
    case ErrorCode.General:
      setErrorNotification(
        ErrorCode.General,
        [
          t(
            'global.error.general',
            'There is a problem with this individual’s data. Please contact RI.'
          ),
        ]
          .concat(error.extensions.errorMessages || [])
          .join('\n')
      );
      break;
    case ErrorCode.UserNotMapped:
      localizedNavigate('/account/select-member-status');
      setErrorNotification(
        ErrorCode.UserNotMapped,
        t(
          'global.error.user-not-mapped',
          'Please create an account to continue.'
        )
      );
      break;
    case ErrorCode.UserNotAuthenticated:
      setErrorNotification(
        ErrorCode.UserNotAuthenticated,
        t('global.error.not-authenticated', 'Please log in to continue.')
      );
      // TODO: Use localizedNavigate here instead of
      //  window.location.replace, but make sure we don't have the
      //  following:
      //  - user session ends and ErrorCode.UserNotAuthenticated is
      //    thrown from getApolloContext
      //  - this code calls localizedNavigate('/login?...') and this
      //    initiates a navigation
      //  - another GraphQL request which was working in parallel is
      //    cancelled (probably by Gatsby?)
      //  - this generates a network error which is handled above in the
      //    "if (networkError)" condition
      //  - the handler calls localizedNavigate('/error')
      //  - result => user is redirected to /error
      if (typeof window !== 'undefined' && process.env.MRX_APP_PUBLIC_URL) {
        window.localStorage.setItem('sessionExpired', 'set');
        window.location.replace(window.location.pathname);
      }
      break;
    case ErrorCode.ImpersonationError:
      setErrorNotification(
        ErrorCode.ImpersonationError,
        t(
          'global.error.impersonation_session_end_message',
          'Your impersonation session has been finished.'
        )
      );
      break;
    case ErrorCode.FeatureDisabled:
      setErrorNotification(
        ErrorCode.FeatureDisabled,
        t('global.Error.feature_disabled', 'Feature is currently disabled')
      );
      break;
    default:
      throw new UnreachableCaseError(error.extensions.code as string);
  }
};

import { useMutation } from '@apollo/client';

import { updateDistrictDetailsQuery } from '../queries';

import {
  UpdateDistrictDetailsMutation,
  UpdateDistrictDetailsMutationVariables,
} from '@typings/operations';

export const useUpdateDistrictDetails = () =>
  useMutation<
    UpdateDistrictDetailsMutation,
    UpdateDistrictDetailsMutationVariables
  >(updateDistrictDetailsQuery);

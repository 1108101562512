import { gql, useLazyQuery } from '@apollo/client';
import {
  GetDistrictClubsStatisticsQuery,
  GetDistrictClubsStatisticsQueryVariables,
} from '@typings/operations';

export const fetchClubsStatisticsQuery = gql`
  query getDistrictClubsStatistics(
    $districtId: String!
    $rotaryYear: String
    $status: String
    $types: [String!]!
  ) {
    statistics: getDistrictClubsStatistics(
      districtId: $districtId
      rotaryYear: $rotaryYear
      status: $status
      types: $types
    ) {
      totalCount
      clubType
    }
  }
`;

export const useFetchClubsStatistics = () =>
  useLazyQuery<
    GetDistrictClubsStatisticsQuery,
    GetDistrictClubsStatisticsQueryVariables
  >(fetchClubsStatisticsQuery);

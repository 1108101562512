import { useQuery } from '@apollo/client';
import { fetchPotentialVendorsQuery } from '@repositories/clubs';

// Types
import {
  FetchPotentialVendorsQuery,
  FetchPotentialVendorsQueryVariables,
} from '@typings/operations';

export const useFetchPotentialVendors = () =>
  useQuery<FetchPotentialVendorsQuery, FetchPotentialVendorsQueryVariables>(
    fetchPotentialVendorsQuery
  );

//
// Returns the starting year of the current term. Examples:
// - Before July 2020 we're in 2019/2020 term, so 2019 is returned
// - After June it becomes 2020.
//
import moment from 'moment';

export const getCurrentTermStartYear = (today?: Date) => {
  let date = today;
  if (!date) {
    date = new Date();
  }

  let result = date.getFullYear();
  if (date.getMonth() < 6) {
    // It's not July yet, so we start with one year back.
    result--;
  }

  return result;
};

//
// Generates terms (e.g. 2015/2026): one in the future and 5 in the past.
//
export const getTerms = (startYear: number) => {
  const options = [];

  let tempYear = startYear + 1;
  for (let i = 0; i <= 6; i++) {
    options.push({
      value: tempYear,
      label: `${tempYear}-${tempYear + 1}`,
    });
    tempYear--;
  }

  return options;
};

//
// Generates terms: current and one in the future.
//
export const getCurrentAndNextTerms = (startYear: number) => [
  {
    value: `${startYear}-${startYear + 1}`,
    label: `${startYear}-${startYear + 1}`,
  },
  {
    value: `${startYear + 1}-${startYear + 2}`,
    label: `${startYear + 1}-${startYear + 2}`,
  },
];

//
// Formats the date when the given term started.
//
export const formatRoleValidOnValue = (startYear: number) =>
  `${startYear}-07-01`;

//
// Mandatory roles.
//
export const mandatoryRoles = [
  'Club President',
  'Club Secretary',
  'Club Treasurer',
  'Rotaract President',
  'Rotaract Secretary',
  'Rotaract Treasurer',
];

/**
 * Fill blanks in a term schedule.
 * @param termStart
 * @param termEnd
 * @param items
 */
export const calculateTermCoverage = (
  termStart: string,
  termEnd: string,
  items: ({ startDate: string; endDate?: string | null } & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  })[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ({ startDate: string; endDate: string } & { [key: string]: any })[] => {
  let current = moment(termStart).subtract({ days: 1 });

  const results = [];

  items
    .sort((a, b) => {
      return moment(a.startDate).diff(moment(b.startDate)) > 0 ? 1 : -1;
    })
    .forEach(item => {
      const itemStart = moment(item.startDate);
      if (
        moment(item.startDate)
          .subtract({ days: 1 })
          .diff(current) > 0
      ) {
        results.push({
          startDate: moment(current)
            .add({ days: 1 })
            .format('YYYY-MM-DD'),
          endDate: itemStart.subtract({ days: 1 }).format('YYYY-MM-DD'),
        });
      }
      if (!item.endDate) {
        /* eslint no-param-reassign: ["error", { "props": false }] */
        item.endDate = termEnd;
      }
      results.push(item);
      current = moment(item.endDate);
    });

  if (moment(termEnd).diff(current) > 0) {
    results.push({
      startDate: moment(current)
        .add({ days: 1 })
        .format('YYYY-MM-DD'),
      endDate: termEnd,
    });
  }

  return results;
};

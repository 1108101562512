import { ClubMeetingValues, MeetingContent } from '@domain/clubs';
import { getCountryById } from '@utils/countries';
import { getDisplayNameById } from '@utils/getDisplayNameById';

import { DisplayNames } from '@typings/graphql';
import { Country } from '@typings/operations';

export default (
  displayNames: DisplayNames[],
  countries: Country[],
  {
    countryId,
    city,
    postalCode,
    lineOne,
    lineTwo,
    lineThree,
    stateId,
    languageId,
    language,
    ...values
  }: ClubMeetingValues
): MeetingContent =>
  ({
    ...values,
    address: {
      countryId,
      city,
      postalCode,
      lineOne,
      lineTwo,
      lineThree,
      stateId,
      country: countryId && getCountryById(countries, countryId).name,
    },
    language: getDisplayNameById(displayNames, languageId) || language,
    languageId,
  } as MeetingContent);

import React from 'react';
import { Text, RichText, withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { CardGroupPanel as CardGroupPanelNPM } from '@rotaryintl/component-landing-pages';
import { createLink } from '../../utilities/link-helpers';

const CardGroupPanel = ({ rendering, params: { ComponentId } = {}, fields: { Title, Description, Link, LinkText, ShowExternalLinkIcon } = {} }) => (
  <CardGroupPanelNPM
    id={ComponentId}
    title={<Text field={Title} />}
    description={<RichText field={Description} />}
    link={createLink(Link, LinkText, ShowExternalLinkIcon)}
    cardsPlaceholder={<Placeholder name="cardgrouppanel-cards" rendering={rendering} />}
  />
);

export default withSitecoreContext()(CardGroupPanel);
import { TFunction } from 'i18next';

import { DISTRICT_TABS } from '@domain/districts';

import { Drupal } from '@typings/drupal';

export default (t: TFunction, districtId?: string): Array<Drupal.MenuItem> => [
  {
    id: DISTRICT_TABS.DETAILS,
    label: t('district-details.menu.details', 'Details'),
    path: `/district/${districtId}/details`,
    language_prefix: 'en',
  },
  {
    id: DISTRICT_TABS.CLUBS,
    label: t('district-details.menu.clubs', 'Clubs'),
    path: `/district/${districtId}/clubs`,
    language_prefix: 'en',
  },
  {
    id: DISTRICT_TABS.MEMBERS,
    label: t('district-details.menu.members', 'Members'),
    path: `/district/${districtId}/members`,
    language_prefix: 'en',
  },
  {
    id: DISTRICT_TABS.MEMBERSHIPCANDIDATE,
    label: t('district-details.menu.membership', 'Membership Candidates'),
    path: `/district/${districtId}/manage-membership`,
    language_prefix: 'en',
  },
  {
    id: DISTRICT_TABS.FINANCE,
    label: t('district-details.menu.finance', 'Finance'),
    path: `/district/${districtId}/finance`,
    language_prefix: 'en',
  },
  {
    id: DISTRICT_TABS.CONFERENCES,
    label: t('district-details.menu.conferences', 'Conferences'),
    path: `/district/${districtId}/conferences`,
    language_prefix: 'en',
  },
  {
    id: DISTRICT_TABS.REPORTS,
    label: t('district-details.menu.reports', 'Reports'),
    path: `/district/${districtId}/reports`,
    language_prefix: 'en',
  },
  {
    id: DISTRICT_TABS.DASHBOARD,
    label: t('district-details.menu.dashboard', 'Dashboard'),
    path: `/district/${districtId}/dashboard`,
    language_prefix: 'en',
  },
];

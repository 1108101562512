import { gql } from '@apollo/client';

export const fetchOneFeedbackQuery = gql`
  query getOneFeedback(
    $feedbackType: String!
    $conferenceId: String!
    $isDistrictPresRep: Boolean
  ) {
    getOneFeedback(
      feedbackType: $feedbackType
      conferenceId: $conferenceId
      isDistrictPresRep: $isDistrictPresRep
    ) {
      id
      feedbackType
      responses {
        questionId
        value
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const fetchTerminationReasonQuery = gql`
  query TerminationReason($clubType: String) {
    terminationReasons(clubType: $clubType) {
      id
      reason
      clubType
    }
  }
`;

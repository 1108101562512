import { useNotifications } from '@use-cases/notifications';

import { useAddSponsor, useUpdateMembership } from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

import {
  AddSponsorMutationVariables,
  UpdateMembershipMutationVariables,
} from '@typings/operations';

/**
 * Mutation hook for changing the membership.
 *
 * Combines both changing the membership type as well as adding a sponsor.
 */
export const useChangeMembership = () => {
  const {
    updateMembershipType,
    error: errorUpdateMembership,
  } = useUpdateMembership();
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const { addSponsor, error: errorAddSponsor } = useAddSponsor();

  return async (
    variables: Partial<AddSponsorMutationVariables> &
      Partial<UpdateMembershipMutationVariables>
  ) => {
    if (!variables.clubId || !variables.membershipId) {
      // TODO: Log error.
      return;
    }

    const { data } =
      variables.admissionDate && variables.type
        ? await updateMembershipType({
            variables: {
              clubId: variables.clubId,
              membershipId: variables.membershipId,
              admissionDate: variables.admissionDate,
              type: variables.type,
            },
          })
        : { data: null };

    const sponsorIds = variables.sponsorIndividualId;

    if (sponsorIds) {
      await addSponsor({
        variables: {
          clubId: variables.clubId,
          membershipId:
            data?.updateMembershipType?.membership?.id ||
            variables.membershipId,
          sponsorIndividualId: sponsorIds,
        },
      });
    }

    if (errorUpdateMembership) {
      addError(errorUpdateMembership.message);
    } else if (errorAddSponsor) {
      addError(errorAddSponsor.message);
    } else {
      addSuccess(
        t('edit-membership.submit-edit-membership-form.success', 'Success.'),
        { id: 'form.success' }
      );
      localizedNavigate(`/club/${variables.clubId}/roster`);
    }
  };
};

import { gql } from '@apollo/client';

export const addClubMeetingQuery = gql`
  mutation addClubMeeting(
    $clubId: String!
    $meeting: MeetingInput!
    $dominoId: String
  ) {
    addClubMeeting(
      input: { clubId: $clubId, meeting: $meeting, dominoId: $dominoId }
    ) {
      meetingId
      meeting {
        name
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const fetchResumeQuery = gql`
  query fetchResume($individualId: String!) {
    resume: getIndividual(id: $individualId) {
      resumeRecognitions {
        type
        recognitions {
          description
          level
          date
        }
      }
      sharingPermissionsExtended {
        recognition {
          id
        }
      }
    }
  }
`;

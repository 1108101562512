// Utils
import {
  ceil,
  flatten,
  floor,
  max,
  maxBy,
  min,
  omit,
  round,
  values,
} from 'lodash';
import { getYearsRangeLabels } from './index';

// Types
import { MembersTrendsChartData } from '@domain/districts';
import { DIS } from '@typings/dis';

export default (chartData: MembersTrendsChartData[], tickCount = 4) => {
  // Exclude from MembersTrendsChartData month and deltas fields
  // Also exclude values for last year as we don't display it on the chart
  // Get only members counts
  const yearKeyToExclude = getYearsRangeLabels(5)[0];
  const counts = flatten(
    chartData.map(item =>
      values(omit(item, ['month', 'prevMonth', 'deltas', yearKeyToExclude]))
    )
  );

  // Min value should be decreased on 1% and rounded to 50 down
  // Max value should be increased on 1% and rounded to 50 up
  const minValue = floor((min(counts) as number) * 0.99, -1);
  const maxValue = ceil((max(counts) as number) * 1.01, -1);
  const increment = round((maxValue - minValue) / tickCount);

  const yAxisTicks = [minValue];

  for (let i = 0; i < tickCount; i++) {
    yAxisTicks.push(ceil(yAxisTicks[i] + increment));
  }

  return {
    minValue,
    maxValue,
    yAxisTicks,
  };
};

const getMaxValue = (value1: number | null, value2: number | null) =>
  max([value1, value2]) || 0;

export const getMaxContributionValue = (data: DIS.ContributionChartItem[]) => {
  const { totalFor30June, priorMonthEnd } = maxBy(
    data,
    ({ totalFor30June, priorMonthEnd }) =>
      getMaxValue(totalFor30June, priorMonthEnd)
  ) || { totalFor30June: 0, priorMonthEnd: 0 };

  return getMaxValue(totalFor30June, priorMonthEnd);
};

export const getMaxClubCreditsValue = (data: DIS.ClubCreditsChartData[]) => {
  const { polioPlus, annualFund } = maxBy(data, ({ polioPlus, annualFund }) =>
    getMaxValue(polioPlus, annualFund)
  ) || { polioPlus: 0, annualFund: 0 };

  return getMaxValue(polioPlus, annualFund);
};

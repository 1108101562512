import * as React from 'react';
import OverlaysModal from 'react-overlays/Modal';

const Modal: React.FC<{
  labelledbyID: string;
  show: boolean;
  toggle: () => void;
}> = ({ labelledbyID, show, toggle, children }) => {
  return (
    <OverlaysModal
      container={() =>
        (window && window.document && window.document.body) || null
      }
      show={show}
      aria-labelledby={labelledbyID}
      className="fixed z-50 inset-0 overflow-auto"
    >
      <>
        <div onClick={toggle} className="fixed -z-1 inset-0" />
        {children}
      </>
    </OverlaysModal>
  );
};

export default Modal;

import { useMutation } from '@apollo/client';
import {
  MoveAgClubAffiliationsMutation,
  MoveAgClubAffiliationsMutationVariables,
} from '@typings/operations';
import { moveAgClubAffiliations } from '../queries';

export const useMoveAgClubAffiliations = () =>
  useMutation<
    MoveAgClubAffiliationsMutation,
    MoveAgClubAffiliationsMutationVariables
  >(moveAgClubAffiliations);

import { gql } from '@apollo/client';

export const Profession = gql`
  fragment Profession on Profession {
    occupation
    employer
    position
    # Id unavailable from DIS causing issues on profile page (MRC-264)
    # Need to revert on Domino Implementation
    #id
  }
`;

export const SponsorshipItem = gql`
  fragment SponsorshipItem on SponsorshipItem {
    club {
      location {
        state
        internationalProvince
        city
        country
      }
      type
      name
      clubId
    }
    membershipList {
      individualId
      isTerminated
      startDate
      fullName
      type
    }
  }
`;

const profileViewQuery = gql`
  query fetchProfile($id: String!) {
    individual: getIndividual(id: $id) {
      id
      riIndividualId
      nfKey
      firstName
      lastName
      middleName
      localizedName
      primaryEmail {
        type
        address
      }
      otherEmails {
        type
        address
      }
      primaryPhone {
        country
        countryId
        extension
        number
        type
      }
      otherPhones {
        country
        countryId
        extension
        number
        type
      }
      primaryAddress {
        type
        lineOne
        lineTwo
        lineThree
        country
        countryId
        city
        stateId
        state
        postalCode
      }
      otherAddresses {
        type
        lineOne
        lineTwo
        lineThree
        country
        countryId
        city
        stateId
        state
        postalCode
      }
      resumeRoles {
        originalJoinDate
        currentClubJoinDate
        yearsOfService
        resumeRolesGroups {
          organizationName
          organizationId
          organizationType
          roles {
            role
            startDate
            endDate
            isShowOnline
            committeeName
          }
          address {
            city
            state
            stateCode
            province
            country
          }
        }
      }
      resumeRecognitions {
        type
        recognitions {
          description
          level
          date
        }
      }
      resumeSponsorships(profileIndividualId: $id) {
        sponsoredByMe {
          ...SponsorshipItem
        }
        sponsoredBySomeone {
          ...SponsorshipItem
        }
      }
      dateOfBirth
      preferredLanguage
      photoUri
      email
      location
      restrictPhoto
      restrictInfo
      clubAffiliations {
        affiliations {
          ... on LeadershipAffiliation {
            leadershipRole
            isDelegated
          }
          ... on MembershipAffiliation {
            id
            membershipType
            admissionDate
            terminationDate
          }
        }
        clubName
        clubType
        clubId
        clubLocation {
          city
          state
          internationalProvince
          country
        }
      }
      aboutMe
      groupedExpertise {
        level
        areas {
          label
          primary
        }
      }
      primaryProfession {
        ...Profession
      }
      otherProfessions {
        ...Profession
      }
      programs {
        title
        isVerified
        fields {
          label
          value
        }
      }
      editPermissions {
        photo
        contactInformation
        background
        rotaryPrograms
        professionalExperience
        areasOfExpertise
        recognition
      }
      sharingPermissionsExtended {
        recognition {
          id
        }
      }
      prefix
      suffix
      gender
      youthFlag
    }
  }
  ${Profession}
  ${SponsorshipItem}
`;

export default profileViewQuery;

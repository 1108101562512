import { gql } from '@apollo/client';

export const deleteProgramMutation = gql`
  mutation deleteProgram(
    $individualId: String!
    $individualProgramId: String!
  ) {
    deleteIndividualProgram(
      input: {
        individualId: $individualId
        individualProgramId: $individualProgramId
      }
    )
  }
`;

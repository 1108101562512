import { gql } from '@apollo/client';

export const updatePersonalDetailsMutation = gql`
  mutation updatePersonalDetails(
    $id: String!
    $firstName: String!
    $middleName: String
    $lastName: String!
    $localizedName: String!
    $gender: Gender!
    $genderDescription: String
    $yearOfBirth: Int
    $sharingPermissionsExtended: SharingPermissionsInput
    $suffix: String
    $prefix: String
    $dateOfBirth: String
  ) {
    updateIndividual(
      input: {
        individualId: $id
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        localizedName: $localizedName
        gender: $gender
        genderDescription: $genderDescription
        yearOfBirth: $yearOfBirth
        sharingPermissionsExtended: $sharingPermissionsExtended
        suffix: $suffix
        prefix: $prefix
        dateOfBirth: $dateOfBirth
      }
    ) {
      individualId
    }
  }
`;

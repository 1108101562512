// Constants
import {
  ADDITIONAL_PARTICIPATING_DISTRICTS,
  COMMENTS,
  DATES_AND_TIMES,
  LANGUAGE,
  LOCATION,
  PRESIDENTIAL_REPRESENTATIVE,
} from '@domain/districts/conferences/constants';

// Utils
import { getConferenceHeading } from './getConferenceHeading';

//Types
import { TFunction } from 'i18next';

export default (t: TFunction) => ({
  venueTypeLabel: t('create-conference.meeting-type', 'Meeting Type'),
  languageLabel: t('create-conference.language', 'Language'),
  lineOneLabel: t('create-conference.line-one.label', 'Address'),
  presRepLineOneLabel: t(
    'create-conference.pres-rep-line-one.label',
    'Accommodation Address'
  ),
  accommodationNameLabel: t(
    'create-conference.pres-rep-accomodation-name.label',
    'Accommodation for Presidential Representative'
  ),
  phoneCountryIdLabel: t(
    'create-conference.pres-rep-acc-phone-country-id.label',
    'Phone Code'
  ),
  phoneNumberLabel: t(
    'create-conference.pres-rep-acc-phone-number.label',
    'Accommodation Phone'
  ),
  languagePlaceholder: t(
    'create-conference.language.placeholder',
    '--Select--'
  ),
  countryPlaceholder: t('create-conference.country.placeholder', '--Select--'),
  statePlaceholder: t('create-conference.state.placeholder', '--Select--'),
  locationTitle: getConferenceHeading(t, LOCATION),
  languageTitle: getConferenceHeading(t, LANGUAGE),
  dateTimeTitle: getConferenceHeading(t, DATES_AND_TIMES),
  commentsTitle: getConferenceHeading(t, COMMENTS),
  districtsTitle: getConferenceHeading(t, ADDITIONAL_PARTICIPATING_DISTRICTS),
  presRepTitle: getConferenceHeading(t, PRESIDENTIAL_REPRESENTATIVE),
});

import React from 'react';

import classNames from 'classnames';
import { FieldMetaProps, useField, useFormikContext } from 'formik';

import Icon from '@components/Icon';

type Props = {
  name: string;
  placeholder?: string;
  pin: boolean;
  isDisabled?: boolean;
  isResettable?: boolean;
  defaultClassNames?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultInputProps?: any;
  metaFields: FieldMetaProps<unknown>[];
};

const LocationInput: React.FC<Props> = ({
  pin,
  name,
  placeholder,
  isDisabled,
  isResettable,
  defaultClassNames,
  defaultInputProps,
  metaFields,
}) => {
  const [field] = useField(name);
  const { submitForm } = useFormikContext();

  return (
    <>
      {pin && field.value && (
        <span className="absolute top-0625 left-0 ml-3 pointer-events-none">
          <Icon
            name="ui/map-pin"
            size="22"
            color={isDisabled ? 'gray-300' : 'dark-blue-400'}
          />
        </span>
      )}
      <input
        id={name}
        placeholder={placeholder}
        {...field}
        className={classNames(
          'border border-gray-300 focus:border-dark-blue-400 rounded-sm',
          'w-full py-3 px-4 text-xs leading-loose',
          'focus-visible:outline-0 focus:shadow-none',
          defaultClassNames,
          {
            'pl-10 ': pin,
            'pr-10': isResettable,
            'bg-gray-100 cursor-not-allowed placeholder-gray-300': isDisabled,
            'placeholder-gray-400': !isDisabled,
            'bg-red-100 border-red-600': metaFields.some(
              meta => meta.error && meta.touched
            ),
          }
        )}
        {...defaultInputProps}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            submitForm();
          }
        }}
      />
    </>
  );
};

export default LocationInput;

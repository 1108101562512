import { TFunction } from 'i18next';

import { getClubRoleTkey } from './getClubRoleTkey';

export const getClubRoleTValue = (t: TFunction, clubRole: string) => {
  return t('club-roles.*', '{clubRoles}', {
    clubRoles: {
      key: `${getClubRoleTkey(clubRole)}`,
      default: clubRole,
    },
    context: 'clubRoles',
  });
};

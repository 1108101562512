type SpecialRoles = 'clubExecutiveSecretaryDirector';

export const specialRoles: Record<
  SpecialRoles,
  { id: string; name: string }
> = {
  /**
   * The only role which can be assigned to any individual.
   *
   * E.g. to a member, non member, honorary member, etc.
   */
  clubExecutiveSecretaryDirector: {
    id: '26d6ef7e-f784-4d77-b3f2-aa2c926c2d58',
    name: 'Club Executive Secretary/Director',
  },
};

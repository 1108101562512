import { TFunction } from 'i18next';

import { Option } from '@components/Formik/MultiSelectFilter/types';

import { getFormattedClubTypeAndLocation } from '@utils/getFormattedClubTypeAndLocation';

import { DistrictClub } from '@typings/operations';

export const normalizeClubById = (
  t: TFunction,
  { id, location, name, type }: DistrictClub
): Option => {
  return {
    id,
    value: id,
    label: `${name}${getFormattedClubTypeAndLocation(t, type, location)}`,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { navigate } from '@reach/router';

import { AppConfig } from '@domain/appConfig';

import { useNotifications } from '@use-cases/notifications';

import {
  getBackendLogoutUrl,
  getWebappLogoutUrl,
} from '@repositories/auth/auth';

import {
  addLocalisationToPath,
  localizedNavigate,
} from '@utils/localized-navigate';
import { delocalizedPath } from '@utils/localized-path';

import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';
// necessary to prevent error when compiling translations-index
declare global {
  interface Window {
    // eslint-disable-next-line camelcase
    __gatsby_user: any;
    rotaryDDO: any;
    _satellite: any;
  }
}
const SUCCESS_LOGIN_MESSAGE = 'successLoginMessage';
export const useUserTrackingEvent = (
  { user, userAnalytics: analytics, isMaintenanceMode }: AppConfig,
  isProcessingLogin: boolean
) => {
  const {
    updateAnalyticsUser,
    resetAnalyticsUser,
    fireTrackingEvent,
  } = useAnalytics();
  const { addSuccess, clearNotification } = useNotifications();
  const { t } = useTranslation();
  useEffect(() => {
    const existingUserData = typeof window.__gatsby_user !== 'undefined';
    const hasSuccessLoginMessageShown = window.localStorage.getItem(
      SUCCESS_LOGIN_MESSAGE
    );
    if (user?.isLoggedIn) {
      // If the user is logged in, check if the backend is in maintenance mode.
      // If yes, then log the user out from the webapp.
      if (isMaintenanceMode) {
        navigate(getWebappLogoutUrl(addLocalisationToPath('/')));
      }
      if (!isProcessingLogin && !hasSuccessLoginMessageShown) {
        addSuccess(
          t('auth.success', 'You have been signed in as <em>{{name}}</em>.', {
            name: user.login,
          }),
          { id: 'auth.success', timeout: 5000 }
        );
        window.localStorage.setItem(
          SUCCESS_LOGIN_MESSAGE,
          JSON.stringify(true)
        );
      } else {
        clearNotification('auth.success');
      }
    }
    // We run the analytics if we have the data from the auth query and: we
    // either have a current user and in this case we must also have an
    // individualData (obtained from running the getIndividual query above), or
    // we don't have a current user at all, which means we will just run the
    // analytics for the anonymous user.
    if (user && analytics) {
      if (analytics) {
        // Assign current user data to global user var, used by analytics.
        updateAnalyticsUser(analytics);
      }
    }
    if (!user) {
      // Anonymous.
      resetAnalyticsUser();
    }
    if (!existingUserData) {
      // Fire page view event if we've completed the auth check and there was no existing user data/session.
      fireTrackingEvent();
    }
  }, [user, analytics]);
};
export const login = (
  setCurrentPathAsDestination = false,
  language: string = ''
) => {
  let urlToNavigate = `/${language}/login`;
  const currentLocation = window.location.href.replace(
    window.location.origin,
    ''
  );
  const destination = window.location.pathname;
  if (!process.env.OKTA_APP_CLIENT_TOKEN) {
    urlToNavigate = `${process.env.GATSBY_MRX_API_PUBLIC_URL}/dummy-auth`;
  }
  if (setCurrentPathAsDestination && destination) {
    urlToNavigate += `?destination=${delocalizedPath(destination)}`;
  }
  if (currentLocation !== urlToNavigate) {
    localizedNavigate(urlToNavigate);
  }
  return false;
};
export const logout = (destination?: string) => {
  window.localStorage.removeItem(SUCCESS_LOGIN_MESSAGE);
  const backendLogoutUrl = getBackendLogoutUrl(destination);
  const logOutUrl = getWebappLogoutUrl(backendLogoutUrl);
  navigate(logOutUrl);
  return false;
};

import { lazyQueryString } from '@repositories/utils/dataFetching/apolloFetcher';
import { Individual } from '@typings/operations';

export const getAnalyticsIndividualQuery = lazyQueryString<
  { individualId?: string | null },
  { getIndividual: Individual }
>(`
  query getAnalyticsIndividual($individualId: String!) {
    getIndividual(id: $individualId) {
      yearOfBirth
    }
  }
`);

import moment from 'moment';

import { FIRST_JUL, YEAR_START_KEY } from '@domain/districts';

import { getYearLabel, localizeDayMonth } from '@use-cases/districts';

import { getDaysInMonthByYear, getRotaryYear } from '@utils/datetime';

export default (month: string, year: string, asOfDate: string) => {
  const currentRY = getRotaryYear();
  const currentMonth = moment().format('MMM');

  const RYRange = getYearLabel(Number(year));
  let dayMonth = `${getDaysInMonthByYear(year, month)} ${month}`;

  // If month value is july1st -> always display '1 Jul'
  if (month === YEAR_START_KEY) {
    dayMonth = FIRST_JUL;
  } else {
    const isAsOfDateIsInValidDate = moment(
      asOfDate,
      'D MMM YYYY',
      true
    ).isValid();
    // For current month return as of date from response
    if (
      month === currentMonth &&
      year === currentRY &&
      isAsOfDateIsInValidDate
    ) {
      const lastIndex = asOfDate.lastIndexOf(' ');
      dayMonth = asOfDate.substring(0, lastIndex);
    }
  }

  return `${localizeDayMonth(`${dayMonth} ${RYRange}`, 'D MMM YY')} ${RYRange}`;
};

import { QueryResult, useLazyQuery, useQuery } from '@apollo/client';

import { fetchClubRolesForAssignMemberQuery } from '@repositories/clubs';

import {
  GetClubRolesForAssignMemberQuery,
  GetClubRolesForAssignMemberQueryVariables,
} from '@typings/operations';

export const useFetchClubRolesForAssignMember = (
  clubId: string,
  date: string,
  clubType?: string | string[]
): Pick<
  QueryResult<GetClubRolesForAssignMemberQuery>,
  'loading' | 'error' | 'data'
> =>
  useQuery<
    GetClubRolesForAssignMemberQuery,
    GetClubRolesForAssignMemberQueryVariables
  >(fetchClubRolesForAssignMemberQuery, {
    variables: { clubId, date, clubType },
  });

export const useFetchClubRolesForAssignMemberLazyQuery = () =>
  useLazyQuery<
    GetClubRolesForAssignMemberQuery,
    GetClubRolesForAssignMemberQueryVariables
  >(fetchClubRolesForAssignMemberQuery);

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { IORef } from 'fp-ts/lib/IORef';
import * as O from 'fp-ts/lib/Option';
import { AddLog } from '@hooks/logger';
import { pipe } from 'fp-ts/lib/function';
import {
  LogLevel,
  MutationResendActivationEmailArgs,
} from '@typings/operations';
import { resendActivationEmail } from '@repositories/auth/queries';
import * as TE from 'fp-ts/lib/TaskEither';
import { getUsernameUsedForLoginPipe } from './getUsernameUsedForLoginPipe';

export const resendActivationEmailPipe = ({
  client,
  addLog,
  usernameUsedForLogin,
}: {
  client: ApolloClient<InMemoryCache>;
  addLog: AddLog;
  usernameUsedForLogin: IORef<O.Option<string>>;
}) => (data: { variables: MutationResendActivationEmailArgs }) =>
  pipe(
    resendActivationEmail<MutationResendActivationEmailArgs>(data.variables)(
      client
    ),
    TE.mapLeft((e: Object) =>
      addLog({
        level: LogLevel.Warn,
        message: `Frontend: Cannot resend activation email for "${getUsernameUsedForLoginPipe(
          usernameUsedForLogin,
          'no username available'
        )}": ${e.toString()}`,
      })
    )
  );

import { gql } from '@apollo/client';

export const fetchClubRolesForAssignMemberQuery = gql`
  query getClubRolesForAssignMember(
    $clubId: String!
    $date: String!
    $clubType: [String!] = "Rotary_Club"
  ) {
    club: clubById(clubId: $clubId) {
      id: clubId
      name: clubName
      riClubId
      clubType
    }
    roles(organizationType: $clubType, validOn: $date) {
      id
      name
    }
  }
`;

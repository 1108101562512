import { useQuery } from '@apollo/client';

import { fetchIndividualInformationQuery } from '../queries/fetchIndividualInformation';

import {
  FetchIndividualInformationQuery,
  FetchIndividualInformationQueryVariables,
} from '@typings/operations';

export const useFetchIndividualInformation = (riIndividualId: number) =>
  useQuery<
    FetchIndividualInformationQuery,
    FetchIndividualInformationQueryVariables
  >(fetchIndividualInformationQuery, {
    variables: { riIndividualId },
  });

import { AxiosRequestConfig } from 'axios';
import { FetchUrl } from './types';

import { pipe } from 'fp-ts/lib/function';
import * as Rest from './restFetcher';
import * as T from 'fp-ts/lib/Task';
import * as IO from 'fp-ts/lib/IO';

export type DrupalUrl = FetchUrl;

// toUrl :: string -> DrupalUrl
export const toUrl = (path: string): IO.IO<DrupalUrl> =>
  IO.of(`${process.env.GATSBY_BACKEND_APP_BASE_URL}/${path}`);

//get :: string -> IO.IO<Task<R>>
export const get = <R>(path: string) =>
  pipe(
    toUrl(path),
    IO.map((dPath: string) => Rest.get<R>(dPath))
  );

//post :: string, data -> IO.IO<Task<DrupalData>>
export const post = <D, R extends Error>(path: string, data: D) =>
  pipe(
    toUrl(path),
    IO.map(drupalPath => Rest.post<D, R>(drupalPath, data))
  );

//getData :: any, data -> Task<DrupalData>
export const getData = <R>(path: string): T.Task<R> => get<R>(path)();

// customCall :: AxiosRequestConfig -> TaskEither<Error, DrupalData>
export const customCall = <R>(config: AxiosRequestConfig) =>
  pipe(
    config.url || '',
    toUrl,
    IO.map(url => Rest.axiosTE<R>({ ...config, url }))
  );

import { useMutation } from '@apollo/client';

// Queries
import { updateConferenceQuery } from '../queries';

// Types
import {
  EditConferenceMutation,
  EditConferenceMutationVariables,
} from '@typings/operations';

export const useUpdateConference = () =>
  useMutation<EditConferenceMutation, EditConferenceMutationVariables>(
    updateConferenceQuery
  );

import { RestLayoutService } from '@sitecore-jss/sitecore-jss-react';

import { SITECORE_PRODUCTION_LAYOUT_URL } from './constants';

import { FEATURE_SITECORE_IS_PROD, isEnabled } from '@utils/features';

export const sitecoreConfig = () => {
  const sitecoreHostURL = isEnabled(FEATURE_SITECORE_IS_PROD)
    ? SITECORE_PRODUCTION_LAYOUT_URL
    : process.env.GATSBY_SITECORE_API_HOST;

  return {
    apiHost: sitecoreHostURL,
    apiKey: process.env.GATSBY_SITECORE_API_KEY,
    siteName: process.env.GATSBY_SITECORE_SITE_NAME,
  };
};

export const layoutService = new RestLayoutService(sitecoreConfig());

export class ParameterizedRestLayoutService extends RestLayoutService {
  constructor(config, params) {
    super(config);
    this.config = config;
    this.params = params;
  }

  getFetchParams = language => {
    return {
      sc_apikey: this.config.apiKey,
      sc_site: this.config.siteName,
      sc_lang: language || '',
      tracking: this.config.tracking ?? true,
      ...this.params,
    };
  };
}

export const parameterizedlayoutService = params => {
  const sitecoreConfigInfo = sitecoreConfig();
  return new ParameterizedRestLayoutService(
    { ...sitecoreConfigInfo, configurationName: 'default' },
    params
  );
};

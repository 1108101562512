const queryString = require('query-string');

exports.onClientEntry = () => {
  if (
    typeof window !== 'undefined' &&
    typeof process.env.GATSBY_BACKEND_APP_BASE_URL !== 'undefined' &&
    process.env.GATSBY_LEGACY_REDIRECTS_ENABLED === 'true'
  ) {
    // Check if we have to enable or disable the backendRedirectDisabled flag.
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.disable_legacy_app_redirect === '1') {
      window.localStorage.setItem('backendRedirectDisabled', '1');
    } else if (queryParams.disable_legacy_app_redirect === '0') {
      window.localStorage.removeItem('backendRedirectDisabled');
    }

    // Check if we have the backend redirect flag disabled in the local
    // storage. If the flag is not disabled, then we perform a redirect.
    const redirectDisabled = window.localStorage.getItem(
      'backendRedirectDisabled'
    );
    if (redirectDisabled !== '1') {
      window.location.href = `${process.env.GATSBY_BACKEND_APP_BASE_URL}${window.location.pathname}?show_new_webapp_notification=1`;
    }
  }
};

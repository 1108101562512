import { omit, pickBy } from 'lodash';

import { ClubSearchFormValues } from '@domain/clubs';

import { isLocationFilterComplete } from '@use-cases/clubs';

import { escapeQueryParams } from '@utils/string-helpers';

import { MeetingTypeFilterValue } from '@typings/graphql';

const locationFilterParts = [
  'meetingLocation',
  'meetingLocationLatitude',
  'meetingLocationLongitude',
  'meetingLocationRange',
];

const guaranteeMeetingTypeFilterValue = (
  value: string
): MeetingTypeFilterValue | undefined => {
  switch (value) {
    case MeetingTypeFilterValue.Hybrid:
    case MeetingTypeFilterValue.Online:
    case MeetingTypeFilterValue.Physical:
      return value;
    default:
      return undefined;
  }
};

export const getMeaningfulFilters = (filters: ClubSearchFormValues) => {
  const populatedFilters = pickBy(filters, Boolean);

  const simpleFilters = escapeQueryParams(
    omit(populatedFilters, [...locationFilterParts, 'meetingType'])
  );

  const meetingLocation = isLocationFilterComplete(filters)
    ? {
        meetingLocation: {
          meetingLocationLatitude: filters.meetingLocationLatitude,
          meetingLocationLongitude: filters.meetingLocationLongitude,
          meetingLocationRange: filters.meetingLocationRange,
        },
      }
    : {};

  const meetingType = filters.meetingType
    ? {
        meetingType: guaranteeMeetingTypeFilterValue(filters.meetingType),
      }
    : {};

  return {
    ...simpleFilters,
    ...meetingLocation,
    ...meetingType,
  };
};

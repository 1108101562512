import { isUrlAbsolute } from '@utils/url';
import { lTrimByCharList, rTrimByCharList } from '@utils/string-helpers';

export const getItemSourceUrl = (
  site: string,
  sourceId: string,
  sourcePath: string
) => {
  const sourceUrl =
    sourceId === 'my_rotary_legacy_app' || sourceId === 'my_rotary_web_app'
      ? sourcePath
      : `${rTrimByCharList(site, '/')}/${lTrimByCharList(sourcePath, '/')}`;

  return isUrlAbsolute(sourceUrl)
    ? sourceUrl
    : `/${lTrimByCharList(sourceUrl, '/')}`;
};

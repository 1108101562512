import { gql } from '@apollo/client';

export const fetchClubOfficersQuery = gql`
  query getClubOfficers(
    $clubId: String!
    $termStartYear: Int
    $roleValidOn: String
    $clubType: [String!]
  ) {
    club: clubById(clubId: $clubId) {
      clubName
      leaders(termStartYear: $termStartYear) {
        id
        isDelegated
        individualName
        individualId
        photoUri
        startDate
        endDate
        role {
          name
          id
        }
      }
    }
    roles(organizationType: $clubType, validOn: $roleValidOn) {
      name
      id
    }
  }
`;

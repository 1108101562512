import React, { useState } from 'react';

import classNames from 'classnames';
import OverlaysModal from 'react-overlays/Modal';

import Icon from '@components/Icon';
import { OneColumn } from '@components/Layouts/OneColumn';
import Link from '@components/Link';

import { Drupal } from '@typings/drupal';

interface Props {
  container?: React.RefObject<HTMLDivElement>;
  menuItems: Drupal.MenuResponse;
  showNavMenu: boolean;
  handleCloseMenu: () => void;
}
const MainSubMenu: React.FC<Props> = ({
  container,
  menuItems,
  showNavMenu,
  handleCloseMenu,
}) => {
  const [activeItem, setActiveItem] = useState<null | number>(null);

  return (
    <OverlaysModal
      // BRUTEFORCE: disable scroll on Focus (can have some issue with accessibility)
      // ToDo: Remove react-overlays and create a CSS mega-menu
      autoFocus={false}
      enforceFocus={false}
      restoreFocus={false}
      restoreFocusOptions={{ preventScroll: false }}
      container={() => container || null}
      show={showNavMenu}
      className="absolute z-50 bottom-0 w-full overlayModal"
    >
      <div className="py-4 bg-dark-blue-600">
        <OneColumn className="flex text-sm">
          <div className="w-64">
            {menuItems.map(({ path, id, label, children }, i) => {
              const isActive = i === activeItem;
              return (
                <Link
                  key={id}
                  onMouseEnter={() => !isActive && setActiveItem(i)}
                  to={path}
                  className={classNames([
                    'SubMenuLink',
                    'text-white flex justify-between items-center py-2',
                    'hover:shadow-none focus:shadow-none',
                  ])}
                  onClick={handleCloseMenu}
                >
                  <span className="SubMenuLinkLabel">{label}</span>
                  {children?.length ? (
                    <Icon name="arrows/right" size={isActive ? '14' : '12'} />
                  ) : null}
                </Link>
              );
            })}
          </div>
          <div className="ml-12">
            {activeItem !== null &&
              menuItems[activeItem]?.children?.map(({ path, id, label }) => (
                <div key={id}>
                  <Link
                    to={path}
                    className="text-white inline-block py-2"
                    onClick={handleCloseMenu}
                  >
                    {label}
                  </Link>
                </div>
              ))}
          </div>
        </OneColumn>
      </div>
    </OverlaysModal>
  );
};

export default MainSubMenu;

// Utils
import { last, omit } from 'lodash';

// Types
import { ClubDevelopment } from '@typings/operations';

export const getClubDevelopmentData = (data: ClubDevelopment[]) => {
  if (!data) {
    return {
      totalCount: 0,
      delta: 0,
      chartData: [],
    };
  }

  // Data is sorted from the latest to newest
  const current = last(data);

  return {
    totalCount: current?.totalCount || 0,
    delta: (current?.totalCount || 0) - (current?.july1st || 0),
    chartData: data.map(item => omit(item, '__typename')),
  };
};

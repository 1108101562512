import { gql } from '@apollo/client';

export const fetchIndividualProgramsQuery = gql`
  query fetchEditPrograms($individualId: String!) {
    individual: getIndividual(id: $individualId) {
      programs {
        programId
        title
        individualProgramId
        isVerified
        startDate
        endDate
        fields {
          label
          value
          fieldId
        }
      }
      sharingPermissionsExtended {
        program {
          id
        }
      }
    }
    programs: getPrograms {
      programId
      title
      fieldGroups {
        fields {
          fieldId
          label
          isRequired
          type
          options {
            key
            value
          }
          constraints {
            charSet
            maxLength
          }
        }
        translationKey
        defaultTranslation
      }
    }
  }
`;

import { useLazyQuery } from '@apollo/client';

import { fetchUserAccountQuery } from '../queries';

import {
  FetchUserAccountQuery,
  FetchUserAccountQueryVariables,
} from '@typings/operations';

export const useFetchUserAccount = () =>
  useLazyQuery<FetchUserAccountQuery, FetchUserAccountQueryVariables>(
    fetchUserAccountQuery
  );

import React, { useState, useEffect } from 'react';
import { useTranslation } from '@external/react-i18next';
import { LinkWithState as LocalizedLink } from '@utils/localized-link';
import Icon from '@components/Icon';

const CookieConsent = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.localStorage.getItem('cookieConsent')
    ) {
      setIsVisible(false);
    }
  }, []);

  const handleAccept = () => {
    window.localStorage.setItem('cookieConsent', 'set');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={{ minHeight: '40px' }}
      className="flex align-middle w-full bg-gray-600 text-gold-500 text-center text-sm"
    >
      <div className="mx-auto" style={{ paddingTop: '7px' }}>
        {t(
          'cookie-consent.body',
          'This website uses cookies, by continuing you are agreeing to our'
        )}
        <LocalizedLink
          to="/privacy-policy"
          className="text-gold-500 inline-block ml-1"
        >
          {t('cookie-consent.privacy-policy', 'privacy policy')}
        </LocalizedLink>
      </div>
      <button onClick={handleAccept} className="mr-4">
        <Icon name="ui/close" size="20" />
        <span className="sr-only">
          {t('cookie-consent.button-label', 'Accept')}
        </span>
      </button>
    </div>
  );
};

export default CookieConsent;

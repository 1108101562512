import { useMutation } from '@apollo/client';
import {
  CreateConferenceMutation,
  CreateConferenceMutationVariables,
} from '@typings/operations';
import { createConferenceQuery } from '../queries';

export const useCreateConference = () =>
  useMutation<CreateConferenceMutation, CreateConferenceMutationVariables>(
    createConferenceQuery
  );

import { useEffect } from 'react';

export const useCaptureClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      handler(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [ref, handler]);

  return null;
};

import { createContext } from 'react';

import { CommitteeAppointment } from '@domain/districts';

export const defaultContextState: CommitteeAppointment = {
  districtId: '',
  operationType: null,
  step: 1,
  termsToAssign: [],
  defaultTerm: null,
  managedRYs: {
    current: {
      roles: [],
      officers: [],
    },
    future: {
      roles: [],
      officers: [],
    },
  },
  selectedInfo: {
    assignee: null,
    unassignFrom: null,
    role: null,
    term: null,
    dateToRemoveRole: null,
  },
  committeeManager: {
    isManager: {
      currentTerm: false,
      nextTerm: false,
      nowTerm: false,
      afterNowTerm: false,
    },
  },
};

export const DistrictContext = createContext<
  [
    CommitteeAppointment,
    React.Dispatch<React.SetStateAction<CommitteeAppointment>>
  ]
>([defaultContextState, prevState => ({ ...prevState, defaultContextState })]);

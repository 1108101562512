import { useLazyQuery } from '@apollo/client';
import { searchDESByIndividualIdQuery } from '../queries';
import {
  SearchDesByIndividualIdQuery,
  SearchDesByIndividualIdQueryVariables,
} from '@typings/operations';

export const useSearchDESByIndividualId = () =>
  useLazyQuery<
    SearchDesByIndividualIdQuery,
    SearchDesByIndividualIdQueryVariables
  >(searchDESByIndividualIdQuery);

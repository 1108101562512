import { CSSProperties } from 'react';
import { StylesConfig } from '@external/react-select';
import { customStyles } from '@components/Formik/MultiSelectFilter/customStyles';

export default (): StylesConfig => ({
  ...customStyles,
  container: (provided: CSSProperties) => ({
    ...provided,
    width: '100%',
    position: 'absolute',
    zIndex: 1000,
  }),
});

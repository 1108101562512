// Libs
import { gql, useMutation } from '@apollo/client';

// Types
import {
  DeleteDistrictLeadershipMutation,
  DeleteDistrictLeadershipMutationVariables,
} from '@typings/operations';

export const deleteDistrictLeadershipQuery = gql`
  mutation deleteDistrictLeadership(
    $districtId: String!
    $leadershipId: String!
  ) {
    deleteDistrictLeadership(
      districtId: $districtId
      leadershipId: $leadershipId
    ) {
      id
      error
    }
  }
`;

export const useDeleteDistrictLeadership = () =>
  useMutation<
    DeleteDistrictLeadershipMutation,
    DeleteDistrictLeadershipMutationVariables
  >(deleteDistrictLeadershipQuery);

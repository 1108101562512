import { ClubSearchFormValues } from '@domain/clubs';

export const isLocationFilterComplete = (
  filters: ClubSearchFormValues
): boolean =>
  Boolean(
    filters.meetingLocationLatitude &&
      filters.meetingLocationLongitude &&
      filters.meetingLocationRange
  );

// curry :: ((a, b, ...) -> c) -> a -> b -> ... -> c
export const curry = (fn: Function) => {
  const arity = fn.length;

  return function $curry(...args: any[]): any {
    if (args.length < arity) {
      return $curry.bind(null, ...args);
    }

    return fn.call(null, ...args);
  };
};

export type Curry2<A, B, C> = (f: (a: A, b: B) => C) => (a: A) => (b: B) => C;

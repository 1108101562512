import { IORef } from 'fp-ts/lib/IORef';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';

export const getUsernameUsedForLoginPipe = (
  unUsedForLogin: IORef<O.Option<string>>,
  orElse = ''
): string =>
  pipe(
    unUsedForLogin.read(),
    O.getOrElse(() => orElse)
  );

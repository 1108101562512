import { gql } from '@apollo/client';

export const ClubMemberSearchRow = gql`
  fragment ClubMemberSearchRow on MemberSearchResult {
    id
    name
    localizedName
    email
    location
    photoUri
    phoneNumber
    leadershipsFromThisClub: thisClubLeadership(
      riClubId: $riClubId
      role: $clubRole
    ) {
      id
      role
      endDate
      startDate
    }
    membershipsFromThisClub: activeMemberships(riClubId: $riClubId) {
      admissionDate
      terminationDate
      type
    }
  }
`;

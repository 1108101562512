import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import CookieConsent from '@components/CookieConsent';
import Icon from '@components/Icon';
import Link from '@components/Link';
import MenuLinks from '@components/MenuLinks';
import SessionExpiredHeader from '@presenters/web/components/SessionExpiredHeader/SessionExpiredHeader';

import LanguageSwitcher from '../LanguageSwitcher';
import SearchSubmenu from '../Search/SearchSubmenu';
import UserLinks from '../UserLinks';
import MainMenu from './MainMenu';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';
import { useCaptureClickOutside } from '@hooks/useCaptureClickOutside';

import { Drupal } from '@typings/drupal';

import { AccountData } from '../types';

interface Props {
  homeMenuItem: Drupal.MenuItem;
  accountInfo: AccountData;
  canUserSearchMembers?: boolean;
}
const DesktopHeader: React.FC<Props> = ({
  homeMenuItem,
  accountInfo,
  canUserSearchMembers = true,
}) => {
  const { t, i18n } = useTranslation();
  const [searchSubmenuIsExpanded, setSearchSubmenuIsExpanded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useCaptureClickOutside(wrapperRef, () => setSearchSubmenuIsExpanded(false));

  const {
    data: globalMenu,
    error: globalMenuError,
    loading: globalMenuLoading,
  } = useMenu('menu-my-rotary-global', i18n.language);
  const {
    data: callToActionMenu,
    error: callToActionMenuError,
    loading: callToActionMenuLoading,
  } = useMenu('menu-my-rotary-call-to-action', i18n.language);
  const {
    data: mainMenu,
    error: mainMenuError,
    loading: mainMenuLoading,
  } = useMenu('menu-my-rotary-main', i18n.language);

  return (
    <>
      <SessionExpiredHeader />
      <CookieConsent />
      <div className="hidden desktop:block bg-dark-blue-600 text-white">
        <div className="DesktopHeaderTop OneColumn text-right text-xs">
          {!globalMenuLoading && !globalMenuError && globalMenu && (
            <MenuLinks
              menuItems={globalMenu}
              className="inline-block"
              itemClassName="inline-block"
              linkClassName="text-white mr-5 LinkDivider"
              LabelComponent={({ label }) => {
                const icon =
                  label === 'Rotary.org' ? 'ui/house' : 'ui/map-pin-outlined';
                return (
                  <>
                    <Icon
                      name={icon}
                      color="white"
                      size="14"
                      className={classNames(
                        'inline-block align-baseline mr-1',
                        // Force the club finder icon lower.
                        { 'relative top-2px': label !== 'Rotary.org' }
                      )}
                    />
                    {label}
                  </>
                );
              }}
            />
          )}
          <span className="LinkDivider">
            <LanguageSwitcher />
          </span>
          <UserLinks
            className="inline-block"
            linkClassName="mr-3 text-white"
            buttonClassName="text-white text-inherit leading-inherit font-normal hover:underline focus:underline"
            accountInfo={accountInfo}
          />
        </div>
      </div>
      <div className="hidden desktop:block bg-dark-blue-500 text-white pt-5">
        <div className="OneColumn">
          <div className="flex justify-between items-start">
            <div className="max-w-xs">
              {/* In order for i18next-storybook-index to work properly, the
                  logo.png cannot be must be imported at the top of the page.
                  Dunno why. So instead, the png is placed in the /static
                  folder.
              */}
              <Link
                to="/"
                title={t('global.logo-home-link', 'Back to homepage')}
                className="block hover:shadow-none"
              >
                <img
                  src="/logo.png"
                  alt={t('global.siteTitle', 'Rotary International')}
                  className="DesktopHeaderLogo"
                />
              </Link>
            </div>
            <div>
              {!callToActionMenuLoading &&
                !callToActionMenuError &&
                callToActionMenu && (
                  <MenuLinks
                    menuItems={callToActionMenu}
                    className="CallToActionContainer"
                    itemClassName="CallToActionItem"
                    linkClassName="CallToActionButton bg-dark-blue-400 font-bold text-bright-blue-100 text-center link-styles-off hover:underline focus:underline"
                  />
                )}
              <nav className="inline-block relative" ref={wrapperRef}>
                <button
                  type="button"
                  aria-expanded={searchSubmenuIsExpanded}
                  onClick={() =>
                    setSearchSubmenuIsExpanded(!searchSubmenuIsExpanded)
                  }
                  className="w-15 h-15 order-3"
                >
                  <span className="sr-only">
                    {searchSubmenuIsExpanded
                      ? t(
                          'header.navigation.hide-search-menu',
                          'Hide search menu'
                        )
                      : t(
                          'header.navigation.show-search-menu',
                          'Show search menu'
                        )}
                  </span>
                  <Icon
                    name="ui/search"
                    color="bright-blue-300"
                    size="24"
                    className="inline-block"
                  />
                </button>
                <SearchSubmenu
                  isExpanded={searchSubmenuIsExpanded}
                  clickHandler={() =>
                    setSearchSubmenuIsExpanded(!searchSubmenuIsExpanded)
                  }
                  canUserSearchMembers={canUserSearchMembers}
                />
              </nav>
            </div>
          </div>
        </div>
        {!mainMenuLoading && !mainMenuError && mainMenu && (
          <MainMenu menuItems={[homeMenuItem, ...mainMenu]} />
        )}
      </div>
    </>
  );
};

export default DesktopHeader;

import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

const countryPolyfill = new Map([['AQ', 672]]);

export const getCountryCode = (shortCode?: string) => {
  if (shortCode) {
    return (
      countryPolyfill.get(shortCode) || [
        getCountryCallingCode(shortCode as CountryCode),
      ]
    );
  }
  return '';
};

import { useQuery } from '@apollo/client';

import profileViewQuery from '../queries/profileViewQuery';

import {
  FetchProfileQuery,
  FetchProfileQueryVariables,
} from '@typings/operations';

export const useFetchProfileDetails = (
  individualId: string,
  skipFetching: boolean = false
) =>
  useQuery<FetchProfileQuery, FetchProfileQueryVariables>(profileViewQuery, {
    variables: { id: individualId },
    skip: skipFetching,
  });

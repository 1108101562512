import { gql } from '@apollo/client';
import { Profession } from '@repositories/profile/queries';

export const fetchProfessionalExperienceQuery = gql`
  query fetchProfessionalExperience($id: String!) {
    professionalExperience: getIndividual(id: $id) {
      primaryProfession {
        ...Profession
      }
      otherProfessions {
        ...Profession
      }
      sharingPermissionsExtended {
        profession {
          id
        }
      }
    }
  }
  ${Profession}
`;

import { gql } from '@apollo/client';

export const fetchRolesQuery = gql`
  query getRoles(
    $organizationType: [String!]
    $validOn: String
    $officerManagement: String
    $rolesToInclude: [String!]
  ) {
    roles(
      organizationType: $organizationType
      validOn: $validOn
      officerManagement: $officerManagement
      rolesToInclude: $rolesToInclude
    ) {
      id
      name
      isOfficer
      termLength
      type
    }
  }
`;

import { UnassignedRole } from '@domain/clubs/types';

import { calculateTermCoverage, mandatoryRoles } from '@utils/officers';

import { ClubLeadershipItem, Role } from '@typings/graphql';

export const getUnassignedClubOfficerRoles = (
  clubOfficers: ClubLeadershipItem[],
  clubRoles: Role[],
  term: number
): UnassignedRole[] =>
  clubRoles
    .map(({ id, name }) => {
      const filteredClubOfficers = clubOfficers.filter(
        leader => leader.role.id === id
      );

      return {
        id,
        name,
        mandatory: mandatoryRoles.includes(name),
        term: calculateTermCoverage(
          `${term}-07-01`,
          `${term + 1}-06-30`,
          filteredClubOfficers
        ).find(roleTerm => !roleTerm.individualName),
      };
    })
    .filter(role => role.term);

import { gql } from '@apollo/client';

export const fetchLocationsDataQuery = gql`
  query Locations {
    countries {
      id
      name
      shortCode
    }
    states {
      id
      name
      countryId
    }
  }
`;
